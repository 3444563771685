import { GroupModel } from 'service/model/GroupModel'
import { getFavIconUrlForSite } from 'service/utils'

const makeGroup = async (
    name: string,
    rawLinks: { name: string; url: string; description: string }[],
    flags?: Pick<GroupModel, 'mayRename' | 'mayDelete' | 'isSystem'>
) => {
    const links = await Promise.all(
        rawLinks.map(l => ({
            ...l,
            favicon24Url: getFavIconUrlForSite(l.url),
            favicon32Url: getFavIconUrlForSite(l.url, 32)
        }))
    )
    const group = {
        name,
        links,
        ...(flags || {})
    }
    return group
}

export const systemGroupNames = {
    __navbarLinks: 'Navbar Links',
    __userLinks: 'My Links'
}

const normalizeName = (name: string) => name.trim().replaceAll(/[ ]+/g, '').toLowerCase()

const normalizedSystemGroupNames = Object.values(systemGroupNames).map(normalizeName)

export const isSimilarToSystemGroupName = (name: string) =>
    normalizedSystemGroupNames.includes(normalizeName(name))

export const getSystemGroups = async () => ({
    _navBarLinks: await makeGroup(
        systemGroupNames.__navbarLinks,
        [
            {
                name: 'Gmail',
                url: 'https://mail.google.com',
                description: 'Gmail'
            },
            {
                name: 'Google Calendar',
                url: 'https://calendar.google.com',
                description: 'Google Calendar'
            },
            {
                name: 'Google Drive',
                url: 'https://drive.google.com',
                description: 'Google Drive'
            },
            {
                name: 'Google Classroom',
                url: 'https://classroom.google.com',
                description: 'Google Classroom'
            }
        ],
        {
            isSystem: true,
            mayRename: false,
            mayDelete: false
        }
    )
})
