export type NavType = 'top' | 'groups' | 'applications' | 'links'
export type NavInfo = {
    navType: NavType
    title: string
    groupId?: string
}

export type NavBarContentProps = {
    open: boolean
    navBarItemClickedHandler: (id: string, title: string) => void
    navInfo: NavInfo
    allBlue: boolean
}

export const altColor = '#153338'
export const otherColor = '#153151'
export const white = '#ffffff'

export type Writable<T> = {
    -readonly [K in keyof T]: T[K]
}
export const Tuple = <T extends readonly [unknown, ...unknown[]]>(v: T) =>
    v as Writable<T>

export type UnwrapSet<T> = T extends Set<infer U> ? U : T
export type UnwrapRecordKey<T> = T extends Record<infer K, unknown> ? K : T
export type UnwrapRecordValue<T> = T extends Record<string | number, infer V> ? V : T
export type UnwrapRecord<T> = T extends Record<infer K, infer V> ? [K, V] : T
export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>
