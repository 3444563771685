import { Autocomplete, Chip, TextField, Typography } from '@mui/material'
import { SyntheticEvent } from 'react'

let autoCompleteInput: any
type Option = { name: string; id: string }

type ChipProps = {
    options: Array<Option>
    onChange: (e: SyntheticEvent, value: any) => void
    onInputChange: (e: SyntheticEvent, value: any) => void
    value: Array<Option>
}

export default function ChipAutoComplete(props: ChipProps) {
    const { options, onChange, onInputChange, value } = props
    return (
        <Autocomplete
            multiple
            id='tags-filled'
            options={options}
            value={value}
            disabled={options.length === 0}
            style={{ width: '100%' }}
            isOptionEqualToValue={(option, val) => option.id === val.id}
            renderTags={(val, getTagProps) => (
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '5px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        width: '100%'
                    }}
                >
                    {val.map((option: any, index) => (
                        <div
                            key={option.id}
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Chip
                                label={
                                    <Typography style={{ whiteSpace: 'normal' }}>
                                        {option.name}
                                    </Typography>
                                }
                                {...getTagProps({ index })}
                                style={{ height: '100%' }}
                            />
                        </div>
                    ))}
                </div>
            )}
            onInputChange={(e, val) => {
                console.log(val)
                onInputChange(e, val)
            }}
            onChange={(e, val) => {
                console.log('change')

                onChange(e, val)
            }}
            getOptionLabel={(option: any) =>
                `${option.name} ${option.email} (${option.directMembersCount})`
            }
            renderInput={params => (
                <TextField
                    inputRef={input => input?.focus()}
                    {...params}
                    label={
                        options.length === 0 ? 'No Google Groups Found' : 'Google Groups'
                    }
                />
            )}
            /*               renderInput={params => (
                
               <TextField
                    multiline
                    rows={5}
                    style={{ width: '500px', borderRadius: '8px' }}
                    {...params}
                    placeholder='Enter Groups'
                /> 
            )} */
        />
    )
}
