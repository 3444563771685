import { googleLogout } from '@react-oauth/google'
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
    GoogleAuthProvider,
    // SAMLAuthProvider,
    createUserWithEmailAndPassword,
    getAuth,
    sendPasswordResetEmail,
    signInWithCredential,
    signInWithEmailAndPassword,
    signOut
} from 'firebase/auth'
import {
    CollectionReference,
    DocumentData,
    addDoc,
    collection,
    getFirestore
} from 'firebase/firestore'
import { AcademyModel } from 'service/model/AcademyModel'
import { GroupModel } from 'service/model/GroupModel'
import { PostModel, PostStatsModel } from 'service/model/Post'
import { SingletonsModel } from 'service/model/SingletonsModel'
import { UserModel } from 'service/model/User'
import { UserImportModel } from 'service/model/UserImportModel'
import { collectionNames } from './collectionNames'

// Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const analytics = getAnalytics(app)

// const samlProvider = new SAMLAuthProvider('saml.google')

const signInWithGoogle = async (credentialsResp: any) => {
    try {
        const credential = GoogleAuthProvider.credential(credentialsResp.credential)
        await signInWithCredential(auth, credential)
        // signInWithRedirect(auth, samlProvider)
    } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err)
        // eslint-disable-next-line no-alert
        alert(err.message)
    }
}

const setAnalyticUserId = (userId: string) => {
    try {
        setUserId(analytics, userId)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in setting analytic user ID', {
            userId,
            error
        })
    }
}

const logAnalyticEvent = (eventName: string, eventParams?: object) => {
    try {
        logEvent(analytics, eventName, eventParams)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in logging analytic event', {
            eventName,
            eventParams,
            error
        })
    }
}

const logInWithEmailAndPassword = async (email: string, password: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, password)
    } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err)
        // eslint-disable-next-line no-alert
        alert(err.message)
    }
}

const registerWithEmailAndPassword = async (
    name: string,
    email: string,
    password: string
) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password)
        const { user } = res
        await addDoc(collection(db, collectionNames.users), {
            uid: user.uid,
            name,
            authProvider: 'local',
            email
        })
    } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err)
        // eslint-disable-next-line no-alert
        alert(err.message)
    }
}

const sendPasswordReset: (email: string) => void = async email => {
    try {
        await sendPasswordResetEmail(auth, email)
        // eslint-disable-next-line no-alert
        alert('Password reset link sent!')
    } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err)
        // eslint-disable-next-line no-alert
        alert(err.message)
    }
}

const logout = async () => {
    await signOut(auth)
    googleLogout()
}

// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
    return collection(db, collectionName) as CollectionReference<T>
}

export const getCollection = createCollection

// export all your collections
const usersCollection = createCollection<UserModel>(collectionNames.users)

// const stagedUsersCollection = createCollection<UserImportModel>(
//     collectionNames.stagedUsers
// )

const postsStatsCollection = createCollection<PostStatsModel>(collectionNames.posts)

const postsCollection = createCollection<PostModel>(collectionNames.posts)

const groupsCollection = createCollection<GroupModel>(collectionNames.groups)

const academiesCollection = createCollection<AcademyModel>(collectionNames.academies)

const singletonsCollection = createCollection<SingletonsModel>(collectionNames.singletons)

const bqUsersCollection = getCollection<UserImportModel>(collectionNames.bqUsers)

export {
    academiesCollection,
    auth,
    bqUsersCollection,
    db,
    groupsCollection,
    logAnalyticEvent,
    logInWithEmailAndPassword,
    logout,
    // stagedUsersCollection,
    postsCollection,
    postsStatsCollection,
    registerWithEmailAndPassword,
    sendPasswordReset,
    setAnalyticUserId,
    signInWithGoogle,
    singletonsCollection,
    usersCollection
}
