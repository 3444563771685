/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { Box } from '@mui/material'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { memo } from 'react'

export const GridSection = memo(
    ({
        top,
        title,
        colWidths,
        color,
        backgroundColor,
        borderColor,
        children,
        height,
        topBorder,
        bottomBorder
    }: {
        top?: number
        title?: string
        colWidths: number[]
        color?: string
        backgroundColor?: string
        borderColor?: string
        children?: React.ReactNode
        height?: number
        topBorder?: boolean
        bottomBorder?: boolean
    }) => {
        const { theme } = useCustomTheme()

        const colSpecs = colWidths
            .map(w => ({ from: 0, to: w }))
            .reduce((acc, w) => {
                const { from: _from, to } =
                    acc.length > 0 ? acc[acc.length - 1] : { from: 0, to: 2 }
                return acc.concat({
                    from: to,
                    to: to + w.to
                })
            }, [] as { from: number; to: number }[])
            .filter(({ from, to }) => from !== to)
        const lastIndex = colSpecs.length - 1

        return (
            <>
                <Box
                    sx={{
                        backgroundColor: backgroundColor || theme.palette.primary.main,
                        color: color || theme.palette.common.white,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end',
                        padding: '0.25em 0.5em',
                        position: 'sticky',
                        left: 0,
                        top,
                        zIndex: 101,
                        gridColumnStart: 1,
                        gridColumnEnd: 2,
                        ...(borderColor
                            ? {
                                  border: `1px solid ${borderColor}`,
                                  borderWidth: `${topBorder ? '1px' : 0} 1px ${
                                      bottomBorder ? '1px' : 0
                                  } 1px`
                              }
                            : {}),
                        height
                    }}
                >
                    {!!children && <>{children}</>}
                    {!!title && <>{title}</>}
                </Box>
                {colSpecs.map(({ from, to }, i) => {
                    const key = `${i}_${from}_${to}`
                    // log(`key: '${key}'`)
                    return (
                        <Box
                            key={key}
                            sx={{
                                backgroundColor:
                                    backgroundColor || theme.palette.primary.main,
                                ...(borderColor
                                    ? {
                                          border: `1px solid ${borderColor}`,
                                          borderWidth: `${topBorder ? '1px' : 0} 1px ${
                                              bottomBorder ? '1px' : 0
                                          } ${i !== 0 ? '1px' : 0}`
                                      }
                                    : {}),
                                // backgroundColor: '#ff0000',
                                gridColumnStart: from,
                                gridColumnEnd: to,
                                // marginTop: '-1px',
                                marginRight: i === lastIndex ? 0 : '10px',
                                position: 'sticky',
                                top,
                                height
                            }}
                        />
                    )
                })}
            </>
        )
    }
)
