/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
import { Close } from '@mui/icons-material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MarkChatReadRoundedIcon from '@mui/icons-material/MarkChatReadRounded'
import MarkChatUnreadRoundedIcon from '@mui/icons-material/MarkChatUnreadRounded'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import {
    Box,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardProps,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { BoxProps } from '@mui/system'
import { animated, useSpring } from '@react-spring/web'
import { Timestamp } from 'firebase/firestore'
import React, { FC, useContext, useState } from 'react'
import { PostModel } from 'service/model/Post'
import AppContext from 'store/contexts/AppContext'

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    'alignItems': 'center',
    'justifyContent': 'center',
    'display': 'flex',
    'border': '1px solid rgba(145, 186, 227, 0.5)',
    'borderRadius': 4,
    'backgroundColor': 'rgba(145, 186, 227, 0.04)',
    'marginBottom': '10px',
    'transition': theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard
    }),
    '&:hover': {
        transform: 'scale(1.04)',
        backgroundColor: 'rgba(145, 186, 227, 0.1)'
    },
    '&:focus': {
        backgroundColor: 'rgba(145, 186, 227, 0.1)'
    }
}))

interface FooterProps extends BoxProps {
    post?: PostModel | null
    openFooter: boolean | undefined
    likeClick: React.MouseEventHandler<HTMLButtonElement> | undefined
    readClick: () => Promise<void> | undefined
    liked: boolean | undefined
    read: boolean | undefined
    fullScreen: boolean | undefined
    onEditCompletion: () => void
    isNotification?: boolean | false
    // mouseOver: React.MouseEventHandler<HTMLDivElement> | undefined
    // mouseLeave: React.MouseEventHandler<HTMLDivElement> | undefined
}

// const FooterImageWrapper = styled(Box)<FooterProps>(
//     ({ theme }) => ({})
// )

interface DocumentInformationProps extends CardProps {
    open: boolean
}

const DocumentInformation = styled(Card)<DocumentInformationProps>(({ theme, open }) => ({
    ...(!open && { display: 'none' }),
    background: '#F5F5F5',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    cursor: 'pointer',
    top: 5,
    left: 5,
    zIndex: 12,
    width: 'calc(100% - 10px)',
    height: 'calc(100% - 10px)'
}))

const Footer: FC<FooterProps> = ({
    post,
    openFooter,
    likeClick,
    readClick,
    liked,
    read,
    fullScreen,
    onEditCompletion,
    isNotification
}) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null)

    const [documentInformation, setDocumentInformation] = useState<boolean>(false)

    const handleClick = (event: any) => {
        setMenuAnchorEl(event.currentTarget)
    }
    const allCtx = useContext(AppContext)
    const { currentUser, hasPermission } = allCtx.state.auth

    const handleClose = () => setMenuAnchorEl(null)

    // console.log('openFooter => ', openFooter)

    /* const styles = useSpring({
        from: {
            bottom: fullScreen ? 0 : openFooter ? -100 : 0,
            opacity: fullScreen ? 1 : openFooter ? 0 : 1
        },
        to: {
            bottom: fullScreen ? 0 : openFooter ? 0 : -100,
            opacity: fullScreen ? 1 : openFooter ? 1 : 0
        }
    }) */

    const styles = useSpring({
        from: {
            opacity: fullScreen ? 1 : openFooter ? 0 : 0
        },
        to: {
            opacity: fullScreen ? 1 : openFooter ? 1 : 0
        }
    })
    /* const styles = useSpring({
        bottom: fullScreen ? 0 : openFooter ? 0 : -100
    }) */

    const editPermissionByPost = () => {
        // console.log('Current USER in FOOTER => ', currentUser)
        // console.log('POST in Footer => ', post)

        const userAcademyCodes = new Set(
            currentUser?.academyCodes ? currentUser.academyCodes : []
        )
        const userJobFamilies = new Set(
            currentUser?.jobFamilies ? currentUser.jobFamilies : []
        )
        const userRegions = new Set(currentUser?.regions ? currentUser.regions : [])

        /* console.log('============== USER THINGS ===============')
        console.log('userAcademyCodes => ', userAcademyCodes)
        console.log('userJobFamilies => ', userJobFamilies)
        console.log('userRegions => ', userRegions)
        console.log('============== END USER THINGS ===============') */

        const postAcademy = post?.postAcademy
        const postJobFamily = post?.postJobFamily
        const postRegion = post?.postRegion

        /* console.log('============== POST THINGS ===============')
        console.log('postAcademy => ', postAcademy)
        console.log('postJobFamily => ', postJobFamily)
        console.log('postRegion => ', postRegion)
        console.log('============== END POST THINGS ===============') */

        let hasPerm = false
        const rke = postAcademy?.find(code => userAcademyCodes?.has(code))
        if (rke) {
            hasPerm = true
        }

        if (!hasPerm) {
            const rjf = postJobFamily?.find(name => userJobFamilies?.has(name))
            if (rjf) {
                hasPerm = true
            }
        }

        if (!hasPerm) {
            const ra = postAcademy?.find(academyCode =>
                userAcademyCodes?.has(academyCode)
            )
            if (ra) {
                hasPerm = true
            }
        }

        if (!hasPerm) {
            const rr = postRegion?.find(regionName => userRegions?.has(regionName))
            if (rr) {
                hasPerm = true
            }
        }

        /* console.log('============ PERMISSION ============= ')
        console.log(`=========== ${hasPerm} =============`)
        console.log('============ END PERMISSION ============= ')
        console.log('                                         ')
        console.log('                                         ')
        console.log('                                         ') */

        if (post?.postVisibility === 'private' && hasPerm && hasPermission) {
            // if filter wise post apporved and global permission to edit then only it will true
            return hasPerm
        }
        // if (post?.postVisibility === 'public' && hasPermission) {
        //     // If user has edit permission and post is public they can edit
        //     return hasPermission
        // }
        // 08/03/2024 - Changed by JMa8
        // Public posts can only be edited by the owner
        if (post?.postVisibility === 'public' && hasPermission) {
            if (post?.ownedBy === currentUser?.email) {
                return true
            }
        }

        return hasPerm

        // return currentUser ? hasPermission : false
    }

    const AnimatedBox = animated(Box)
    const showEditButton = editPermissionByPost() // currentUser ? hasPermission : false

    return (
        <>
            <AnimatedBox
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    height: '52px',
                    background: '#F5F5F5',
                    alignItems: 'center',
                    justifyContent: !isNotification ? 'space-between' : 'center',
                    cursor: 'pointer',
                    zIndex: 101,
                    width: '100%',
                    bottom: 0
                }}
                style={styles}
                // onMouseOver={() => setOpenFooter(true)}
                // onMouseLeave={() => setOpenFooter(false)}
                // onMouseOver={mouseOver}
                // onMouseLeave={mouseLeave}
                // likeClick={likeClick}
                // readClick={readClick}
                // openFooter={openFooter}
                // post={post}
                // mouseOver={mouseOver}
                // mouseLeave={mouseLeave}
            >
                {!isNotification && (
                    <IconButton
                        sx={{
                            color: 'black'
                        }}
                        onClick={likeClick}
                    >
                        {liked ? (
                            <ThumbUpOffAltIcon
                                className='link'
                                sx={{
                                    fontSize: 25
                                }}
                                color='primary'
                            />
                        ) : (
                            <ThumbUpIcon
                                className='link'
                                sx={{
                                    fontSize: 25
                                }}
                                color='primary'
                            />
                        )}
                    </IconButton>
                )}

                <Box
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <IconButton
                        sx={{
                            color: 'black'
                        }}
                        onClick={readClick}
                    >
                        {read ? (
                            <MarkChatReadRoundedIcon
                                className='link'
                                sx={{
                                    fontSize: 25
                                }}
                                color='primary'
                            />
                        ) : (
                            <MarkChatUnreadRoundedIcon
                                className='link'
                                sx={{
                                    fontSize: 25
                                }}
                                color='primary'
                            />
                        )}
                    </IconButton>
                </Box>
                {!isNotification && (
                    <IconButton
                        sx={{
                            color: 'black'
                        }}
                        onClick={handleClick}
                    >
                        <MoreVertIcon
                            className='link'
                            sx={{
                                fontSize: 25
                            }}
                            color='primary'
                        />
                    </IconButton>
                )}
            </AnimatedBox>

            <Menu
                anchorEl={menuAnchorEl}
                onClose={handleClose}
                open={!!menuAnchorEl && openFooter === true}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                style={{ display: 'flex', borderRadius: 20 }}
            >
                <DocumentInformation
                    open={documentInformation}
                    sx={{ background: '#333333' }}
                >
                    <CardActionArea>
                        <CardHeader
                            // avatar={
                            //     <Avatar
                            //         sx={{ bgcolor: red[500] }}
                            //         aria-label='recipe'
                            //     >
                            //         R
                            //     </Avatar>
                            // }
                            action={
                                <IconButton
                                    aria-label='settings'
                                    onClick={() => setDocumentInformation(false)}
                                >
                                    <Close style={{ color: '#FFFFFF' }} />
                                </IconButton>
                            }
                            title={
                                <Typography
                                    fontStyle='normal'
                                    fontSize='15px'
                                    fontWeight={500}
                                    color='#FFFFFF'
                                    lineHeight='26px'
                                >
                                    {post?.title}
                                </Typography>
                            }
                            // subheader='September 14, 2016'
                        />
                        <CardContent sx={{ p: 2 }} />
                    </CardActionArea>
                    <CardActions sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box
                            sx={{
                                border: 0,
                                display: 'flex'
                            }}
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: 'calc(100% - 10px)'
                            }}
                        >
                            <Typography fontSize={12} fontWeight='bold' color='#FFFFFF'>
                                Owned by:
                            </Typography>
                            <Typography fontSize={12} sx={{ ml: 0.5 }} color='#FFFFFF'>
                                {post?.ownedBy}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                m: 0,
                                border: 0,
                                display: 'flex'
                            }}
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: 'calc(100% - 10px)',
                                marginLeft: 0
                            }}
                        >
                            <Typography fontSize={12} fontWeight='bold' color='#FFFFFF'>
                                Created at:
                            </Typography>
                            <Typography sx={{ ml: 0.5 }} fontSize={12} color='#FFFFFF'>
                                {post?.createdAt
                                    ? (post?.createdAt as unknown as Timestamp)
                                          .toDate()
                                          .toISOString()
                                          .split('T')[0]
                                    : ''}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                m: 0,
                                border: 0,
                                display: 'flex'
                            }}
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: 'calc(100% - 10px)',
                                marginLeft: 0
                            }}
                        >
                            <Typography fontSize={12} fontWeight='bold' color='#FFFFFF'>
                                Modified at:
                            </Typography>
                            <Typography sx={{ ml: 0.5 }} fontSize={12} color='#FFFFFF'>
                                {post?.updatedAt
                                    ? (post?.updatedAt as unknown as Timestamp)
                                          .toDate()
                                          .toISOString()
                                          .split('T')[0]
                                    : 'NA'}
                            </Typography>
                        </Box>
                    </CardActions>
                </DocumentInformation>
                <Box
                    sx={{
                        p: { xs: '20px', md: '20px', lg: '20px' }
                    }}
                >
                    {/* <StyledMenuItem
                        key={1}
                        onClick={() => {
                            // alert('menuitem1')
                            handleClose()
                        }}
                        sx={{
                            border: 1,
                            mb: '10px',
                            hover: '#91BAE37D'
                        }}
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            borderRadius: 4,
                            borderColor: '#91BAE37D',
                            backgroundColor: '#91BAE320'
                        }}
                    >
                        <Box
                            sx={{
                                border: 0,
                                display: 'flex'
                            }}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex'
                            }}
                        >
                            <ListItemText
                                sx={{
                                    pr: '10px'
                                }}
                            >
                                <Typography
                                    fontStyle='normal'
                                    fontSize='15px'
                                    fontWeight={500}
                                    color='#153338'
                                    lineHeight='26px'
                                >
                                    READ LATER
                                </Typography>
                            </ListItemText>
                            <ListItemIcon>
                                <AccessTimeIcon
                                    className='link'
                                    sx={{
                                        fontSize: 20
                                    }}
                                    color='primary'
                                />
                            </ListItemIcon>
                        </Box>
                    </StyledMenuItem> */}
                    {showEditButton ? (
                        <StyledMenuItem
                            key={1}
                            onClick={() => {
                                // alert('menuitem1')
                                onEditCompletion()
                            }}
                        >
                            <Box
                                sx={{
                                    border: 0,
                                    display: 'flex'
                                }}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}
                            >
                                <ListItemText
                                    sx={{
                                        pr: '10px'
                                    }}
                                >
                                    <Typography fontSize='15px' color='primary'>
                                        EDIT
                                    </Typography>
                                </ListItemText>
                                <ListItemIcon>
                                    <EditOutlinedIcon
                                        className='link'
                                        sx={{
                                            fontSize: 20
                                        }}
                                        color='primary'
                                    />
                                </ListItemIcon>
                            </Box>
                        </StyledMenuItem>
                    ) : null}
                    <StyledMenuItem key={2} onClick={readClick}>
                        <Box
                            sx={{
                                border: 0,
                                display: 'flex'
                            }}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex'
                            }}
                        >
                            <ListItemText
                                sx={{
                                    pr: '10px'
                                }}
                            >
                                <Typography fontSize='15px' color='primary'>
                                    MARK AS READ
                                </Typography>
                            </ListItemText>
                            <ListItemIcon>
                                <MarkChatReadRoundedIcon
                                    className='link'
                                    sx={{
                                        fontSize: 20
                                    }}
                                    color='primary'
                                />
                            </ListItemIcon>
                        </Box>
                    </StyledMenuItem>
                    {/* <StyledMenuItem key={3} onClick={handleClose}>
                        <Box
                            sx={{
                                border: 0,
                                display: 'flex'
                            }}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex'
                            }}
                        >
                            <ListItemText
                                sx={{
                                    pr: '10px'
                                }}
                            >
                                <Typography fontSize='15px' color='primary'>
                                    SHARE
                                </Typography>
                            </ListItemText>
                            <ListItemIcon>
                                <ShareOutlinedIcon
                                    className='link'
                                    sx={{
                                        fontSize: 20
                                    }}
                                    color='primary'
                                />
                            </ListItemIcon>
                        </Box>
                    </StyledMenuItem>
                    <StyledMenuItem
                        key={4}
                        onClick={() => {
                            // alert('menuitem1')
                            handleClose()
                        }}
                    >
                        <Box
                            sx={{
                                border: 0,
                                display: 'flex'
                            }}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex'
                            }}
                        >
                            <ListItemText
                                sx={{
                                    pr: '10px'
                                }}
                            >
                                <Typography fontSize='15px' color='primary'>
                                    NOT INTERESTED
                                </Typography>
                            </ListItemText>
                            <ListItemIcon>
                                <NotInterestedOutlinedIcon
                                    className='link'
                                    sx={{
                                        fontSize: 20
                                    }}
                                    color='primary'
                                />
                            </ListItemIcon>
                        </Box>
                    </StyledMenuItem> */}
                    <StyledMenuItem
                        key={5}
                        onClick={() => {
                            setDocumentInformation(true)
                            // handleClose()
                        }}
                    >
                        <Box
                            sx={{
                                border: 0,
                                display: 'flex'
                            }}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex'
                            }}
                        >
                            <ListItemText
                                sx={{
                                    pr: '10px'
                                }}
                            >
                                <Typography fontSize='15px' color='primary'>
                                    DOCUMENT INFORMATION
                                </Typography>
                            </ListItemText>
                            <ListItemIcon>
                                <InfoOutlinedIcon
                                    className='link'
                                    sx={{
                                        fontSize: 20
                                    }}
                                    color='primary'
                                />
                            </ListItemIcon>
                        </Box>
                    </StyledMenuItem>
                </Box>
            </Menu>
        </>
    )
}

export default Footer
