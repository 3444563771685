/* eslint-disable no-nested-ternary */
import { AcademyLookupItem } from 'service/controller/Academy'

export interface AcademyModel {
    academy: string
    academyShortName: string
    localAuthority: string
    academyCode: string
    dfeNumber: string
    urn: string
    predecessorSchoolName: string
    address: {
        lines: string[]
        postCode: string
    }
    web: string
    email: string
    telephone: string
    fax: string
    phase: string
    region: string
    regionalEducationDirector: string
    ages: string
    nurseryAetRunPrivateRun_: string
    dateOpen: string
    typeOfAcademy: string
    executivePrincipal: string
    executivePrincipalEmail: string
    principal: string
    principalName: string
    headteacherTitle: string
    headteacherFirstName: string
    headteacherSurname: string
    principalContact: string
    pa: string
    paContact: string
    adminOfficer: string
    adminOfficerContact: string
    chairOfGoverners: string
    chairOfGovsContact: string
    clerkToTheGovernors: string
    clerkToTheGovernorsContact: string
    senco: string
    sencoContact: string
    childProtection: string
    childProtectionContact: string
    designatedSafeguardingLead: string
    designatedSafeguardingLeadContact: string
    professionalServicesEstatesBusinessPartner: string
    professionalServicesFinanceOperationsBusinessPartner: string
    professionalServiceshrcontacts: string
    professionalServicesTalentResourcingPartner: string
    professionalServicesCurriculumLead: string
    govRegion: string
    regionalDirectorsJohnEdwardsDirectorGeneralRegionsgroup: string
    ofstedRegionalDirectorsNoteChrisRussellIsNationalDirector: string
    ward: string
    constituency: string
    memberOfParliament: string
    party: string
    ofstedRating: string
    ofstedReportPublishDate: string
    previousOfstedGrade: string
    localAuthorityContact: string
    localAuthorityContactemail: string
    systemLeader: string
    progressNotes: string
    academyHealthSafetyContact: string
    country: string
    county: string
    lAMatching: string
    academyMatching: string
    xEasting: string
    yNorthing: string
    latitude: string
    longitude: string
    emailDomain: string
    arborUrl: string
}

const phaseOrderLookup = {
    Primary: 0,
    Secondary: 1,
    Special: 2
} as Record<string, number>

export const getPhaseOrder = (phase: string) => phaseOrderLookup[phase] ?? -1

export const academyLookupSorter = (
    { academy: academy1, academyCode: academyCode1 }: AcademyLookupItem,
    { academy: academy2, academyCode: academyCode2 }: AcademyLookupItem
) => {
    const swu1 = academyCode1.startsWith('_')
    const swu2 = academyCode2.startsWith('_')

    return swu1 === swu2 ? academy1.localeCompare(academy2) : swu1 ? -1 : +1
}

export const getAcademyTooltip = (academy: AcademyLookupItem) => {
    const { phase, academy: academyName, academyCode } = academy
    const phasePrefix = phase ? `(${phase}) ` : ''
    return `${phasePrefix}${academyName} - ${academyCode}`
}
