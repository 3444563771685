/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogContent,
    Paper,
    Radio,
    Skeleton,
    Typography
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { a, animated, useSpring, useTransition } from '@react-spring/web'
import { Store, useStore } from 'appData/store'
// import DataTilesContainer from 'components/dataTiles/DataTilesContainer'
import { NavPortal } from 'components/navbar/NavPortal'
import NewsHeader from 'components/newsHeader/NewsHeader'
import NewsTile from 'components/tiles/newsTile/NewsTile'
import Header from 'components/tiles/newsTile/NewsTileHeader'
import WebNotification from 'components/utils/WebNotification'
import { useGlobalContext } from 'contexts/global-context'
import { onSnapshot, query, Timestamp, where } from 'firebase/firestore'
import { useCustomTheme } from 'hooks/useCustomTheme'
import AddPost from 'pages/AddPostPage/AddPost'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { useParams } from 'react-router-dom'
import useMeasure from 'react-use-measure'
import {
    checkPostsWebNotificationPermission,
    getLastPostCreatedAt,
    getPostPublicNews,
    getPostsLike,
    getPostsNotRead,
    getPostsPopupAnnouncementAll,
    getPostsPopupAnnouncementNotRead,
    getPostsRead
} from 'service/controller/Post'
import { ChangeAnnouncementStatus } from 'service/controller/User'
import { auth, postsCollection } from 'service/firebase'
import { PostModel } from 'service/model/Post'
import AppContext from 'store/contexts/AppContext'
import { StyledIconButton } from 'utils/animatedComponents/StyledIconButton'
import shallow from 'zustand/shallow'
import styles from './Masonary/styles.module.css'
import useMedia from './Masonary/useMedia'

/* eslint-disable import/extensions */

const storeSelector = (store: Store) => ({
    currentUser: store.currentUser,
    filter: store.filter,
    setFilter: store.setFilter,
    myFocusFilter: store.myFocusFilters,
    academies: store.academies,
    setMyFocusFilter: store.setMyFocusFilters
})

interface ExtendedPostModel extends PostModel {
    x: number
    y: number
    height: number
    width: number
}

export const HomePage: FC = () => {
    const { id } = useParams()
    const [user, loading, _error] = useAuthState(auth)

    const allCtx = useContext(AppContext)
    // eslint-disable-next-line prettier/prettier
    const [postDataRows, setPostDataRows] = useState<ExtendedPostModel[]>([])
    const [addPostMode, setAddPostMode] = useState(false)
    // const containerRef = useRef<HTMLDivElement>(null)
    const [editableTile, setEditableTile] = useState<ExtendedPostModel>()

    const [showAnnouncement, setShowAnnouncement] = useState(true)
    const [lastPostCreateAt, SetLastPostCreateAt] = useState<Timestamp | undefined>(
        undefined
    )
    const [announcementOpen, setAnnouncementOpen] = useState(false)
    const [announcementRows, setAnnouncementRows] = useState<PostModel[]>([])
    const [announcementRowIndex, setAnnouncementRowIndex] = useState<number>(0)
    const [announcementContent, setAnnouncementContent] = useState<string | undefined>(
        undefined
    )
    const [webNotificationPost, setWebNotificationPost] = useState<
        PostModel | undefined
    >()
    // const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('lg')

    // const { filter } = allCtx.state.news
    const { currentUser, hasPermission } = allCtx.state.auth

    const [fetching, setFetching] = useState(true)

    const {
        currentUser: zCurrentUser,
        filter,
        myFocusFilter,
        academies,
        setMyFocusFilter
    } = useStore(storeSelector, shallow)

    // log(`🌼🌼🌼 currentUser: ${json(currentUser)} | zCurrentUser: ${json(zCurrentUser)}`)

    // console.log('userEditPermission ', userEditPermission)
    const { fontColor } = useCustomTheme()
    const { mode, setMode } = useGlobalContext()
    const isLightModeOn = mode === 'light'

    const showAddButton = currentUser ? hasPermission : false

    const [ref, { width }] = useMeasure()

    const addModeStyles = useSpring({
        delay: addPostMode || editableTile ? 0 : 200,
        opacity: addPostMode || editableTile ? 0.0 : 1.0
    })
    const closeModeStyles = useSpring({
        delay: addPostMode || editableTile ? 200 : 0,
        duration: 300,
        opacity: addPostMode || editableTile ? 1.0 : 0.0
    })

    const addPostStyles = useSpring({
        delay: addPostMode ? 200 : 0,
        duration: 600,
        opacity: addPostMode ? 1.0 : 0.0
    })

    const editPostStyles = useSpring({
        delay: editableTile ? 200 : 0,
        duration: 600,
        opacity: editableTile ? 1.0 : 0.0
    })

    const AnimatedAddButton = animated(EditOutlinedIcon)
    const AnimatedCloseButton = animated(CloseRoundedIcon)

    const AnimatedPaper = animated(Paper)

    const theme = useCustomTheme()

    // log(`💥 HomePage.1 - zCurrentUser?.user: ${json(zCurrentUser?.user)}`)

    const fetchData = () => {
        // log(`💥💥 HomePage.2 - zCurrentUser?.user: ${json(zCurrentUser?.user)}`)
        const helper = async () => {
            // log(`💥💥💥 HomePage.3 - zCurrentUser?.user: ${json(zCurrentUser?.user)}`)
            // eslint-disable-next-line no-console
            console.log('fetchdata-filter', filter, myFocusFilter)
            if (zCurrentUser) {
                let posts: PostModel[] = []
                if (id) {
                    posts = await getPostPublicNews(id)
                } else if (filter === 'read') {
                    posts = await getPostsRead(currentUser?.uid as string, myFocusFilter)
                } else if (filter === 'like') {
                    posts = await getPostsLike(currentUser?.uid as string, myFocusFilter)
                } else if (filter === 'allPopupAnnouncement') {
                    posts = await getPostsPopupAnnouncementAll(
                        zCurrentUser.user.uid,
                        zCurrentUser.user.jobFamilies,
                        zCurrentUser.user.regions,
                        zCurrentUser.user.academyCodes,
                        zCurrentUser.user.academyPhases,
                        myFocusFilter
                    )
                } else {
                    posts = await getPostsNotRead(
                        filter as string,
                        zCurrentUser.user.uid,
                        zCurrentUser.user.jobFamilies,
                        zCurrentUser.user.regions,
                        zCurrentUser.user.academyCodes,
                        zCurrentUser.user.academyPhases,
                        myFocusFilter
                    )
                }
                console.log('✈️✈️✈️postOrder', posts)
                setPostDataRows(posts as ExtendedPostModel[])

                setFetching(false)
            } else {
                setFetching(false)
            }
        }
        helper()
    }

    const fetchAnnouncementData = () => {
        // log(`💥💥 HomePage.2 - zCurrentUser?.user: ${json(zCurrentUser?.user)}`)
        const helper = async () => {
            // log(`💥💥💥 HomePage.3 - zCurrentUser?.user: ${json(zCurrentUser?.user)}`)
            // eslint-disable-next-line no-console
            // console.log('fetchdata-filter', filter, myFocusFilter)
            let posts: PostModel[] = []
            posts = await getPostsPopupAnnouncementNotRead(
                currentUser?.uid as string,
                zCurrentUser?.user.jobFamilies,
                zCurrentUser?.user.regions,
                zCurrentUser?.user.academyCodes,
                zCurrentUser?.user.academyPhases
            )

            setAnnouncementRows(posts)

            console.log('getPostsPopupAnnouncement => ', posts)

            // console.log('getPostsPopupAnnouncement', posts[0].thumbnail)

            if (showAnnouncement && posts.length > 0) {
                setAnnouncementContent(posts[0].thumbnail)
                setShowAnnouncement(false)
                if (!announcementOpen) {
                    setAnnouncementOpen(true)
                }
            }
        }

        helper()
    }

    // useEffect(() => {
    //     if (loading) {
    //         return
    //     }
    //     if (zCurrentUser?.user) {
    //         console.log('HomePage-userEditPermission', allCtx.state.auth.hasPermission)
    //         fetchData()
    //     }
    // }, [user, loading, filter, zCurrentUser, myFocusFilter])

    // useEffect(() => {
    //     const q = query(
    //         postsCollection,
    //         where(
    //             'createdAt',
    //             '>',
    //             Timestamp.fromMillis(dayjs().subtract(10, 'second').valueOf())
    //         )
    //     )

    //     const unSubTile = onSnapshot(q, snapshot => {
    //         // eslint-disable-next-line no-console
    //         console.log('Home-Page-Announcement-Onsnapshot')
    //         // let isChanged = false
    //         snapshot.docChanges().forEach(change => {
    //             if (change.type === 'added') {
    //                 console.log('Announcement tile: ', change.doc.data().title)
    //                 setWebNotificationPost(change.doc.data())
    //                 // isChanged = true
    //             }
    //             // if (change.type === 'modified') {
    //             //     console.log('Modified tile: ', change.doc.data().title)
    //             //     isChanged = true
    //             // }
    //             // if (change.type === 'removed') {
    //             //     // console.log('Removed tile: ', change.doc.data().title)
    //             //     isChanged = true
    //             // }
    //         })
    //     })

    //     return unSubTile
    // }, [])

    useEffect(() => {
        console.log('Home-Page-useEffect')

        const helper = async () => {
            const postCreatedAt = await getLastPostCreatedAt()

            SetLastPostCreateAt(postCreatedAt ?? Timestamp.now())
        }
        helper()
    }, [])

    useEffect(() => {
        console.log('Home-Page-WebNotification-useEffect')
        if (lastPostCreateAt) {
            const q = query(postsCollection, where('createdAt', '>', lastPostCreateAt))

            const unSubTile = onSnapshot(q, snapshot => {
                // eslint-disable-next-line no-console
                console.log('Home-Page-WebNotification-Onsnapshot')
                let isChanged = false
                let changedPost: PostModel | undefined
                snapshot.docChanges().forEach(change => {
                    if (change.type === 'added') {
                        // console.log('Announcement tile: ', change.doc.data().title)
                        isChanged = true
                        changedPost = change.doc.data()
                    }
                    // if (change.type === 'modified') {
                    //     console.log('Modified tile: ', change.doc.data().title)
                    //     isChanged = true
                    // }
                    // if (change.type === 'removed') {
                    //     // console.log('Removed tile: ', change.doc.data().title)
                    //     isChanged = true
                    // }
                })

                if (
                    isChanged &&
                    changedPost &&
                    checkPostsWebNotificationPermission(
                        changedPost,
                        currentUser?.uid as string,
                        zCurrentUser?.user.jobFamilies,
                        zCurrentUser?.user.regions,
                        zCurrentUser?.user.academyCodes,
                        zCurrentUser?.user.academyPhases
                    )
                ) {
                    setWebNotificationPost(changedPost)
                }
            })

            return unSubTile
        }
        return undefined
    }, [lastPostCreateAt])

    useEffect(() => {
        console.log('Home-Page-useEffect')

        const unSubTile = onSnapshot(postsCollection, snapshot => {
            // eslint-disable-next-line no-console
            console.log('Home-Page-Onsnapshot', filter)
            let isChanged = false
            snapshot.docChanges().forEach(change => {
                if (change.type === 'added') {
                    // console.log('New tile: ', change.doc.data().title)
                    isChanged = true
                }
                // if (change.type === 'modified') {
                //     console.log('Modified tile: ', change.doc.data().title)
                //     isChanged = true
                // }
                if (change.type === 'removed') {
                    // console.log('Removed tile: ', change.doc.data().title)
                    isChanged = true
                }
            })
            if (isChanged) {
                fetchData()
            }
        })

        if (showAnnouncement) {
            fetchAnnouncementData()
        }

        return unSubTile
    }, [zCurrentUser, filter, myFocusFilter])

    let columns = useMedia(
        [
            '(min-width: 1700px)',
            '(min-width: 1500px)',
            '(min-width: 1300px)',
            '(min-width: 1100px)',
            '(min-width: 900px)',
            '(min-width: 600px)',
            '(min-width: 400px)'
        ],
        [6, 5, 4, 3, 3, 2, 1],
        1
    )

    // Calculate skeleton dimensions

    const skeletonWidth = 250 // Base size
    let basePadding = 20

    // We need to calculate on Width of the screen
    const calculatingValue = window.innerWidth
    let availableWidth = width // calculatingValue - 130

    if (calculatingValue < 500) {
        // skeletonWidth = availableWidth - 30 // + 40 // Adjusting dedcuted width above for mobile view
    } else if (calculatingValue > 600 && calculatingValue < 900) {
        // availableWidth -= 100
        basePadding = 20
        // We need to calculate probable tile size only if there is probability of more than 1 rows
        /* if (postDataRows.length > 3) {
            skeletonWidth = availableWidth / columns - basePadding
        } */
    } else if (calculatingValue > 900 && calculatingValue < 1200) {
        // availableWidth += 80
        basePadding = 20
        // We need to calculate probable tile size only if there is probability of more than 1 rows
        /* if (postDataRows.length > 4) {
            skeletonWidth = availableWidth / columns - basePadding
        } */
    } else {
        availableWidth = calculatingValue - basePadding
    }

    // const skeletonWidthWithPadding = skeletonWidth + basePadding * 2 + basePadding

    const skeletonTimes = columns // Math.ceil(availableWidth / skeletonWidthWithPadding)

    // columns = skeletonTimes

    if (columns === 0) {
        // For mobile devices
        columns = 1
    }

    const itemHeight = skeletonWidth
    interface Rows {
        row: number
        maxTileSize: number
        posts: ExtendedPostModel[]
        cols: number
        squareLayout: boolean
    }

    const rows: Rows[] = []
    const usedPosts: ExtendedPostModel[] = []
    const curRow = 0

    const getRowTiles = (arr: ExtendedPostModel[]) => {
        const individualRow: Rows[] = []
        let totalWidthWithPadding = 0
        let posts: ExtendedPostModel[] = []
        // const excludededPosts: ExtendedPostModel[] = []
        for (let i = 0; i < arr.length; i += 1) {
            const post = arr[i]
            totalWidthWithPadding +=
                (post.tileSize === 'single' ? itemHeight : itemHeight * 2) +
                basePadding * 2

            const validTotalWidth = totalWidthWithPadding // - basePadding * 2

            if (validTotalWidth <= availableWidth) {
                posts.push(post)
            }

            let addToRow = false

            if (totalWidthWithPadding >= availableWidth) {
                addToRow = true
            } else if (i === arr.length - 1) {
                addToRow = true
            }

            // Do a sanity check if we can accomodate any extra tile
            if (addToRow) {
                let totalAddedWidth = 0
                posts.forEach(post => {
                    totalAddedWidth +=
                        (post.tileSize === 'single' ? itemHeight : itemHeight * 2) +
                        basePadding * 2
                })

                const diff =
                    availableWidth - totalAddedWidth - (itemHeight + basePadding * 2)
                if (diff >= 0 && diff <= itemHeight) {
                    // Can add more..
                    // The next tile should be a single tile, look for it
                    for (let j = i; j < arr.length; j += 1) {
                        const post = arr[j]
                        if (post.tileSize === 'single') {
                            const exist =
                                posts.filter(item => item.id === post.id).length > 0
                            if (!exist) {
                                posts.push(post)
                            }
                            break
                        }
                    }
                }
            }

            if (addToRow) {
                // if loop ended and above condition is false, add row

                // Check how many double tiles in the row if any we need to reduce column size

                const nonSingleTiles = posts.filter(tile => tile.tileSize !== 'single')

                const singleTiles = posts.filter(tile => tile.tileSize === 'single')

                if (
                    nonSingleTiles.length === 1 &&
                    singleTiles.length > 0 &&
                    posts.length === columns
                ) {
                    singleTiles.pop()
                    posts = []

                    nonSingleTiles.forEach(tile => {
                        posts.push(tile)
                    })

                    singleTiles.forEach(tile => {
                        posts.push(tile)
                    })
                }
                posts.forEach(post => {
                    usedPosts.push(post)
                })

                let maxTileSize =
                    itemHeight * (availableWidth / 1075) +
                    (calculatingValue > 1500 ? basePadding / 2 : 5)

                maxTileSize = itemHeight

                // For width 1075 the tile works fine for width 250
                rows.push({
                    row: curRow,
                    maxTileSize,
                    posts,
                    cols: posts.length,
                    squareLayout: false
                })
                totalWidthWithPadding = 0
                posts = []

                break
            }
        }

        if (individualRow.length > 0) {
            rows.push(individualRow[0])
        }

        if (arr.length > 0) {
            // Filter used post
            const filtered = arr.filter(
                item1 => !usedPosts.find(({ id }) => id === item1.id)
            )
            if (filtered.length > 0 && usedPosts.length !== 0) {
                getRowTiles(filtered)
            }
        }
    }
    if (calculatingValue > 1200) {
        getRowTiles(postDataRows)
    } else {
        // Tile should be of same height and width if smaller resolution so no logic required.
        for (let index = 0; index < postDataRows.length; index += columns) {
            const rowPostChunks = postDataRows.slice(index, index + columns)
            rows.push({
                row: index,
                maxTileSize: itemHeight,
                cols: columns,
                posts: rowPostChunks,
                squareLayout: true
            })
        }
    }

    const [gridItems] = useMemo(() => {
        const allGridItems: ExtendedPostModel[] = []
        let y = 0
        let lastHeight = 0

        rows.forEach((row, irow) => {
            const { posts } = row
            let lastWidth = 0
            y = lastHeight
            posts.forEach((child, icol) => {
                const itemWidth = row.squareLayout
                    ? row.maxTileSize
                    : child.tileSize === 'single'
                    ? row.maxTileSize
                    : row.maxTileSize * 2 + basePadding

                const x = lastWidth

                lastWidth = lastWidth + itemWidth + basePadding

                allGridItems.push({
                    ...child,
                    x,
                    y,
                    width: itemWidth,
                    height: itemHeight
                } as ExtendedPostModel)
            })
            lastHeight = lastHeight + row.maxTileSize + basePadding
        })
        return [allGridItems]
    }, [columns, postDataRows, width])

    // Turn the static grid values into animated transitions, any addition, removal or change will be animated
    const transitions = useTransition(gridItems, {
        key: (item: ExtendedPostModel) => item.id,
        from: ({ x, y, width, height }) => ({
            x,
            y,
            width,
            height,
            opacity: 0
        }),
        enter: ({ x, y, width, height }) => ({
            x,
            y,
            width,
            height,
            opacity: 1
        }),
        update: ({ x, y, width, height }) => ({
            x,
            y,
            width,
            height
        }),
        leave: { height: 0, opacity: 0 },
        config: { mass: 5, tension: 500, friction: 100 },
        trail: 25
    })

    /**
     * paddingLeft={{
                    xs: columns === 2 ? '4%' : 1,
                    md: columns === 4 ? '3%' : '2%',
                    lg: '10%',
                    xl: columns === 5 ? '8%' : '6%'
                }}
                paddingRight={{
                    xs: columns === 2 ? '5%' : 1,
                    md: columns === 4 ? '3%' : '3%',
                    lg: '12%',
                    xl: columns === 5 ? '11%' : '7%'
                }}
     */

    /**
                 * 
                 * paddingLeft={{
                    xs: columns === 2 ? (isiPadPortraitMode ? 12 : 4) : 3,
                    md: columns === 4 ? 2 : 18,
                    lg: columns === 4 ? 11 : 5,
                    xl: columns === 5 ? 12 : 12
                }}
                paddingRight={{
                    xs: columns === 2 ? (isiPadPortraitMode ? 15 : 5) : 3,
                    md: columns === 4 ? 2 : 20,
                    lg: columns === 4 ? 12 : 5,
                    xl: columns === 5 ? 12 : 18
                }}
                 */

    const maxWidth = columns * itemHeight + columns + basePadding + basePadding
    // We need to take care of iPad Portrait mode which is same as Mobile Landscape mode but has more space width wise
    const isiPadPortraitMode = window.innerWidth < window.innerHeight && columns === 2
    const screenDiff = (window.innerWidth - maxWidth - 50) / 2
    const leftPadding =
        screenDiff > 50 && !isiPadPortraitMode ? screenDiff - 45 : screenDiff

    const AnimatedBox = animated(Box)

    const AnnouncementHandleClose = (event: object, reason: string) => {
        if (reason !== 'backdropClick') {
            setAnnouncementOpen(false)
        }
        return false
    }

    const AnnouncementOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(
            'AnnouncementOptionChange',
            Number((event.target as HTMLInputElement).value)
        )
        setAnnouncementRowIndex(Number((event.target as HTMLInputElement).value))
        setAnnouncementContent(
            announcementRows[Number((event.target as HTMLInputElement).value)].thumbnail
        )
    }

    const changeActiveAnnouncement = () => {
        if (
            announcementRows.length - 1 === 0 ||
            announcementRows.length - 1 === announcementRowIndex
        ) {
            setAnnouncementOpen(false)
        } else {
            setAnnouncementRowIndex(announcementRowIndex + 1)

            setAnnouncementContent(announcementRows[announcementRowIndex + 1].thumbnail)
        }
    }

    const deleteActiveAnnouncement = () => {
        if (
            announcementRows.length - 1 === 0 ||
            announcementRows.length - 1 === announcementRowIndex
        ) {
            setAnnouncementOpen(false)
        } else {
            const tmpAnnouncementRows = announcementRows.filter(
                (_, index) => index !== announcementRowIndex
            )
            setAnnouncementRows(tmpAnnouncementRows)

            setAnnouncementContent(tmpAnnouncementRows[announcementRowIndex].thumbnail)
        }
    }

    const modalForm =
        announcementRows.length > 0 && !id ? (
            <Dialog
                // sx={{ width: '500px' }}
                open={announcementOpen}
                onClose={AnnouncementHandleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                // maxWidth={maxWidth}
                fullWidth
                disableEscapeKeyDown
            >
                <DialogContent
                    sx={{
                        p: 0
                    }}
                >
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                            // height: { height },
                            // border: 1
                        }}
                    >
                        <Header
                            isTrending={false}
                            isNew={false}
                            isStatic={false}
                            isBackground={false}
                            linkValue={undefined}
                            isClose={undefined}
                            closeClick={() => setAnnouncementOpen(false)}
                            hideWidgetType
                            designerView={false}
                            isNotification={false}
                        />

                        <Box
                            padding={2}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            flexDirection='column'
                        >
                            <FroalaEditorView model={announcementContent} />
                            {announcementRows.length > 1 ? (
                                <Box
                                    sx={{
                                        mt: '20px'
                                    }}
                                    marginLeft={0}
                                    display={{
                                        xs: 'none',
                                        md: 'flex',
                                        lg: 'flex',
                                        xl: 'flex'
                                    }}
                                    paddingRight={{ xs: 0, md: 0, lg: 0 }}
                                    justifyContent='center'
                                    align-items='center'
                                >
                                    {announcementRows.map((_, index) => {
                                        if (index < 5) {
                                            return (
                                                <Radio
                                                    key={`announcementradio${index}`}
                                                    checked={
                                                        index === announcementRowIndex
                                                    }
                                                    // onChange={handleChange}
                                                    value={index}
                                                    onChange={AnnouncementOptionChange}
                                                    name='announcement-radio-buttons'
                                                    inputProps={{ 'aria-label': '' }}
                                                />
                                            )
                                        }
                                        return null
                                    })}
                                </Box>
                            ) : null}
                            <Box
                                sx={{
                                    mt: '20px'
                                }}
                                marginLeft={0}
                                display={{
                                    xs: 'none',
                                    md: 'flex',
                                    lg: 'flex',
                                    xl: 'flex'
                                }}
                                paddingRight={{ xs: 0, md: 0, lg: 0 }}
                                justifyContent='center'
                                align-items='center'
                            >
                                <Button
                                    sx={{
                                        mr: '20px',
                                        width: '200px',
                                        height: '50px',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '17px'
                                    }}
                                    variant='outlined'
                                    onClick={() => {
                                        changeActiveAnnouncement()
                                    }}
                                >
                                    READ LATER
                                </Button>
                                <Button
                                    sx={{
                                        width: '200px',
                                        height: '50px',
                                        // ml: '10px',
                                        // mr: '20px',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '17px'
                                    }}
                                    variant='contained'
                                    onClick={() => {
                                        ChangeAnnouncementStatus(
                                            zCurrentUser?.user.uid as string,
                                            'never',
                                            announcementRows[announcementRowIndex]
                                        )
                                        deleteActiveAnnouncement()
                                    }}
                                >
                                    CONFIRM READ
                                </Button>
                            </Box>
                        </Box>
                    </Card>
                </DialogContent>
            </Dialog>
        ) : null

    const _webNotification = () =>
        webNotificationPost ? (
            <WebNotification
                title={
                    webNotificationPost.postType === 'popupannouncement'
                        ? 'New Announcement'
                        : 'New Post'
                } // the title prop is required
                icon='images/png/aet_4by4_24px.png'
                body={webNotificationPost.title ?? null}
                timeout={9000}
                onClickFn={() => window.open(webNotificationPost.link, '_blank')} // open your own site on notification click
            />
        ) : null

    return (
        <div style={{ backgroundColor: isLightModeOn ? '#fff' : '#212121' }}>
            {_webNotification()}
            {modalForm}
            <Box
                paddingLeft={`${leftPadding}px`}
                paddingRight={`${screenDiff}px`}
                sx={{
                    backgroundColor: isLightModeOn ? '#fff' : '#212121',
                    minHeight: '90.6vh'
                }}
            >
                {editableTile ? (
                    <AnimatedPaper elevation={10} style={editPostStyles}>
                        <AddPost
                            onSaveCompletion={() => {
                                setEditableTile(undefined)
                                fetchData()
                            }}
                            onDeleteCompletion={() => {
                                fetchData()
                            }}
                            tileId={editableTile.id}
                            editMode
                        />
                    </AnimatedPaper>
                ) : addPostMode ? (
                    <AnimatedPaper elevation={10} style={addPostStyles}>
                        <AddPost
                            onSaveCompletion={() => {
                                setAddPostMode(false)
                                fetchData()
                            }}
                            onDeleteCompletion={() => {
                                fetchData()
                            }}
                            editMode={false}
                        />
                    </AnimatedPaper>
                ) : (
                    <>
                        <Grid
                            container
                            spacing={2}
                            border={0}
                            display={{
                                xs: 'none',
                                md: 'flex',
                                lg: 'flex',
                                xl: 'flex'
                            }}
                            paddingRight={{ xs: 0, md: 3, lg: 0 }}
                            justifyContent='center'
                        >
                            {/* <DataTilesContainer
                                zCurrentUser={zCurrentUser}
                                myFocusFilter={myFocusFilter}
                            /> */}
                            {/* Remove bellow `Grid` when `DataTilesContainer` is re-enabled, added for spacing */}
                            <Grid>
                                <div>&nbsp;</div>
                            </Grid>
                        </Grid>
                        <Grid my={2} marginRight={{ xs: 1, md: 2, lg: 0 }}>
                            <NewsHeader height={50} />
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            marginBottom={4}
                            width={maxWidth}
                            display='flex'
                            justifyContent='center'
                        >
                            {postDataRows.length > 0 ? (
                                <div
                                    ref={ref}
                                    className={styles.list}
                                    style={{
                                        height: rows.length * itemHeight + 145
                                    }}
                                >
                                    {transitions((style, item, t, i) => (
                                        <a.div style={style}>
                                            <NewsTile
                                                width={item.width}
                                                isTrending={item.widget === 'trending'}
                                                isNew={item.widget === 'new'}
                                                headerStatic={
                                                    item.headerType === 'static'
                                                }
                                                linkColour={item.linkColour}
                                                key={item.id}
                                                content={item.content}
                                                post={item}
                                                onMarkRead={() => {
                                                    const filteredArr =
                                                        postDataRows.filter(
                                                            ele => ele.id !== item.id
                                                        )
                                                    setPostDataRows(filteredArr)
                                                }}
                                                onEditCompletion={() => {
                                                    setEditableTile(item)
                                                }}
                                                publicNews={id !== undefined}
                                            >
                                                <FroalaEditorView
                                                    model={item.thumbnail}
                                                />
                                            </NewsTile>
                                        </a.div>
                                    ))}
                                </div>
                            ) : fetching ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        ml: 2,
                                        mt: 2
                                    }}
                                >
                                    {Array(skeletonTimes)
                                        .fill(null)
                                        .map((_, index) => (
                                            <Box
                                                key={`skeleton-${index}`}
                                                sx={{
                                                    mr: '10px'
                                                }}
                                            >
                                                <Skeleton
                                                    variant='text'
                                                    sx={{
                                                        fontSize: '1rem'
                                                    }}
                                                />

                                                <Skeleton
                                                    variant='rectangular'
                                                    width={skeletonWidth}
                                                />
                                                <Skeleton
                                                    variant='rectangular'
                                                    width={skeletonWidth}
                                                    height={skeletonWidth * 0.6}
                                                />
                                            </Box>
                                        ))}
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        ml: 2,
                                        mt: '20%'
                                    }}
                                >
                                    <Typography sx={{ opacity: 0.4 }} variant='h4'>
                                        🎉 You're all caught up!
                                    </Typography>{' '}
                                </Box>
                            )}
                        </Grid>
                    </>
                )}
            </Box>
            <NavPortal target='floating-links-homepage'>
                {/* <StyledIconButton sx={{ marginBottom: 3, color: fontColor }} onClick={() => isLightModeOn ? setMode('dark'): setMode('light')}>
                {isLightModeOn ? <Brightness4Icon /> : <Brightness7Icon />}
                </StyledIconButton> */}
                {showAddButton ? (
                    <StyledIconButton
                        disableRipple
                        sx={{
                            color: '#ffffff',
                            background: theme.themeColor,
                            width: 47,
                            height: 47,
                            display: {
                                xs: 'none',
                                md: 'none',
                                lg: 'flex'
                            }
                        }}
                        onClick={() => {
                            if (!editableTile) {
                                // This should toggle only in ADD MODE
                                setAddPostMode(!addPostMode)
                            }
                            setEditableTile(undefined)
                            // navigate('/AddPost', { replace: true })
                        }}
                    >
                        <AnimatedAddButton
                            sx={{
                                position: 'absolute',
                                width: 24,
                                height: 24
                            }}
                            style={addModeStyles}
                        />
                        <AnimatedCloseButton
                            sx={{
                                fontSize: 32,
                                position: 'absolute',
                                width: 32,
                                height: 32
                            }}
                            style={closeModeStyles}
                        />
                    </StyledIconButton>
                ) : null}

                {/* addPostMode ? null : (
                    <StyledIconButton
                        disableRipple
                        sx={{
                            color: theme.palette.common.white,
                            background: theme.palette.primary.main,
                            width: 48,
                            height: 48
                        }}
                    >
                        <SettingsAccessibilityOutlinedIcon
                            sx={{
                                fontSize: 32
                            }}
                        />
                    </StyledIconButton>
                        ) */}
            </NavPortal>
        </div>
    )
}

export default HomePage
