/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
import MarkChatReadRoundedIcon from '@mui/icons-material/MarkChatReadRounded'
import { Box, Card, CardProps, IconButton } from '@mui/material'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Grow from '@mui/material/Grow'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import { TransitionProps } from '@mui/material/transitions'
import { animated, useSpring } from '@react-spring/web'
import { useCustomTheme } from 'hooks/useCustomTheme'
import parse, {
    DOMNode,
    domToReact,
    Element,
    HTMLReactParserOptions
} from 'html-react-parser'
import React, { FC, forwardRef, useContext, useEffect, useState } from 'react'
import { addContentClickedToPost, queryContentClicked } from 'service/controller/Post'
import {
    addLiked,
    addRead,
    deleteLiked,
    deleteRead,
    queryLiked,
    queryRead,
    queryReadAnnouncement
} from 'service/controller/User'
import { LinksColourOptions, PostModel } from 'service/model/Post'
import AppContext from 'store/contexts/AppContext'
import {
    StyledNewsCard,
    StyledNormalNewsCard
} from 'utils/animatedComponents/StyledNewsCard'
import Footer from './NewsTileFooter'
import Header from './NewsTileHeader'

export type NewsTileProps = {
    xs?: number
    md?: number
    lg?: number
    xl?: number
    isTrending?: boolean
    isNew?: boolean
    isFavorite?: boolean
    linkColour?: LinksColourOptions
    headerBackground?: boolean
    headerStatic?: boolean
    height?: number
    content?: string
    contentPaddingLeft?: string
    designViewer?: boolean
    post?: PostModel | null
    width: number
    publicNews?: boolean
    onMarkRead?: () => void
    onEditCompletion?: () => void
}

interface CardParserProps {
    content: any
}

const CardParser: React.FC<CardParserProps> = ({ content }) => {
    const handleAnchorTag = (node: Element): React.ReactElement => {
        const { attribs, children } = node

        // Extract href, target, rel, and id attributes
        const href = attribs?.href || '#'
        const target = attribs?.target || '_blank'
        const rel = attribs?.rel || 'noopener noreferrer'
        const id = attribs?.id

        return (
            <a href={href} target={target} rel={rel} id={id}>
                {children && children.length > 0
                    ? domToReact(children as DOMNode[])
                    : href}
            </a>
        )
    }

    const renderContent = (htmlContent: any) => {
        const contentString =
            typeof htmlContent === 'string' ? htmlContent : String(htmlContent)

        const options: HTMLReactParserOptions = {
            replace: (domNode: DOMNode) => {
                if (domNode instanceof Element && domNode.name === 'a') {
                    return handleAnchorTag(domNode)
                }
                return domNode
            }
        }

        return parse(contentString, options)
    }

    return <div>{renderContent(content)}</div>
}

const ContentCard = styled(Box)(({ theme }) => ({
    // paddingLeft: '10px'
}))

const NewsTile = styled<FC<NewsTileProps & CardProps>>(
    ({
        xs,
        md,
        lg,
        xl,
        children,
        isTrending,
        isNew,
        isFavorite,
        headerBackground,
        headerStatic,
        height,
        contentPaddingLeft,
        content,
        linkColour,
        designViewer,
        post,
        onMarkRead,
        width,
        onEditCompletion,
        publicNews,
        ...rest
    }) => {
        const authCtx = useContext(AppContext)
        const { currentUser } = authCtx.state.auth
        const [openFooter, setOpenFooter] = useState(false)
        const [open, setOpen] = useState(false)
        const [liked, setLiked] = useState(false)
        const [contentClicked, setContentClicked] = useState(false)
        const [likedUpdated, setLikedUpdated] = useState(false)
        const [read, setRead] = useState(false)
        const [readPopupAnnouncement, setReadPopupAnnouncement] = useState(false)
        const [readUpdated, setReadUpdated] = useState(false)
        const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('lg')
        const [remove, setRemove] = useState(false)
        const [toggleMarkRead, setToggleMarkRead] = useState(false)
        const { appFonts } = useCustomTheme()

        // const handleOpen = () => setOpen(true)
        const handleClose = () => setOpen(false)

        const likeClick = async () => {
            if (liked) {
                setLiked(false)
                // console.log('likeClick', currentUser?.uid)
                addLiked(currentUser?.uid as string, post as PostModel).then(() => {})
            } else {
                setLiked(true)
                deleteLiked(currentUser?.uid as string, post as PostModel).then(() => {})
            }
        }

        const readClick = async () => {
            setRemove(true)
            setTimeout(() => {
                setToggleMarkRead(true)
            }, 50)
            if (read) {
                addRead(currentUser?.uid as string, post as PostModel).then(() => {
                    // SuccessNotification('Post Read')
                    setTimeout(() => {
                        if (onMarkRead) {
                            onMarkRead()
                        }
                    }, 500)
                    setReadUpdated(true)
                })
            } else {
                deleteRead(currentUser?.uid as string, post as PostModel).then(() => {
                    setTimeout(() => {
                        if (onMarkRead) {
                            onMarkRead()
                        }
                    }, 500)
                    // SuccessNotification('Read Removed')
                    setReadUpdated(true)
                })
            }
        }

        useEffect(() => {
            const checkLiked = async () => {
                const docRef = await queryLiked(
                    currentUser?.uid as string,
                    post?.id as string
                )
                // console.log('NewsTile-queryLiked', !docRef)
                setLiked(!docRef)
            }

            const checkContentClicked = async () => {
                const docRef = await queryContentClicked(
                    currentUser?.uid as string,
                    post?.id as string
                )

                // eslint-disable-next-line no-console
                // console.log('NewsTile-queryContentClicked', docRef !== undefined)
                setContentClicked(docRef !== undefined)
            }

            if (!designViewer) {
                checkLiked()
                checkContentClicked()
                setLikedUpdated(false)
            }

            if (publicNews === true && post?.content !== '') {
                setOpen(publicNews)
            }
        }, [likedUpdated, publicNews])

        useEffect(() => {
            const checkRead = async () => {
                const docRef = await queryRead(
                    currentUser?.uid as string,
                    post?.id as string
                )
                // console.log('NewsTile', docRef)
                setRead(!docRef)
            }
            if (!designViewer) {
                checkRead()
                setReadUpdated(false)
            }
        }, [readUpdated])

        useEffect(() => {
            const checkReadAnnouncement = async () => {
                const docRef = await queryReadAnnouncement(
                    currentUser?.uid as string,
                    post?.id as string
                )
                // console.log('NewsTile', docRef)
                setReadPopupAnnouncement(docRef !== undefined)
            }
            if (!designViewer) {
                checkReadAnnouncement()
            }
        }, [])

        const config2 = {
            events: {
                // eslint-disable-next-line func-names, object-shorthand
                initialized: async function () {
                    // eslint-disable-next-line no-promise-executor-return
                    await new Promise(resolve =>
                        // eslint-disable-next-line no-promise-executor-return
                        setTimeout(resolve, 1)
                    )

                    // eslint-disable-next-line no-eval
                    const xeditor = eval('this')
                    const markdownEditor = document.querySelectorAll(
                        '.fr-wrapper'
                    )[0] as HTMLElement | null

                    if (markdownEditor != null) {
                        // console.log('NewsTile', element)
                        markdownEditor.style.visibility = 'hidden'
                        markdownEditor.style.overflow = 'hidden'
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('.fr-wrapperNull')
                    }

                    xeditor.toolbar.hide()

                    xeditor.markdown.toggle()

                    xeditor.html.set(content)

                    xeditor.markdown.toggle()
                    xeditor.markdown.toggle()
                    // $('.fr-markdown-editor').css('display', 'none')

                    // let element = document.querySelectorAll(
                    //     '.fr-wrapper-markdown'
                    // )[0] as HTMLElement | null
                    // if (element != null) {
                    //     // console.log('NewsTile', element)
                    //     element.style.display = 'none'
                    // }

                    let element = markdownEditor?.querySelectorAll(
                        '.fr-markdown-editor'
                    )[0] as HTMLElement | null
                    if (element != null) {
                        // console.log('NewsTile', element)
                        element.style.display = 'none'
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('.fr-markdown-editornull')
                    }

                    element = markdownEditor?.querySelectorAll(
                        '.gutter-horizontal'
                    )[0] as HTMLElement | null
                    if (element != null) {
                        // console.log('NewsTile', element)
                        element.style.display = 'none'
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('.gutter-horizontalnull')
                    }

                    element = markdownEditor?.querySelectorAll(
                        '.fr-markdown-view'
                    )[0] as HTMLElement | null
                    if (element != null) {
                        // console.log('NewsTile', element)
                        element.style.width = 'calc(100% - 1px)'
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('.fr-markdown-viewNull')
                    }

                    if (markdownEditor != null) {
                        // console.log('NewsTile', element)
                        markdownEditor.style.visibility = 'visible'
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('.fr-wrapperNull')
                    }

                    // element = document.querySelectorAll(
                    //     '.fr-wrapper-markdown'
                    // )[0] as HTMLElement | null
                    // if (element != null) {
                    //     // console.log('NewsTile', element)
                    //     element.style.display = 'flex'
                    // }

                    // console.log('NewsTile', xeditor)
                }
            },
            key: 'Ne2C1sA4F4B3A11A10C7D7kOPVi1b1b2Le2e1G1AWMSGSAFDTGHWsE7D7D6C5A1I4C2C3A6D5==',
            placeholderText: 'Edit Your Content Here!!!',
            charCounterCount: false,
            // toolbarButtons: toolbarMarkDown,
            fontFamily: {
                'Roboto,sans-serif': 'Roboto',
                'Manrope,sans-serif': appFonts.Manrope,
                'Montserrat,sans-serif': 'Montserrat',
                "'Open Sans Condensed',sans-serif": 'Open Sans Condensed'
            },
            fontSize: ['8', '10', '12', '14', '17', '18', '30', '60', '96'],
            heightMin: '75%',
            heightMax: '75%'
        }

        const Transition = forwardRef(
            (
                props: TransitionProps & {
                    children: React.ReactElement<any, any>
                },
                ref: React.Ref<unknown>
            ) => {
                return <Grow ref={ref} {...props} />
            }
        )

        const modalForm = (
            <Dialog
                // sx={{ width: '650px' }}
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                maxWidth={maxWidth}
                // fullWidth
                TransitionComponent={Transition}
            >
                <DialogContent
                    sx={{
                        p: 0
                        // width: '650px'
                    }}
                >
                    <Card
                        {...rest}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            minWidth: '400px'
                            // height: { height },
                            // border: 1
                        }}
                    >
                        <Header
                            isTrending={isTrending}
                            isNew={post?.postType === 'news' && isNew}
                            isStatic={false}
                            isBackground={false}
                            linkValue={post?.link}
                            isClose
                            closeClick={!designViewer ? handleClose : undefined}
                            hideWidgetType
                            designerView={designViewer}
                            isNotification={post?.postType === 'notification'}
                            title={post?.title}
                        />

                        <Box padding={2} paddingTop='25px'>
                            {/* <FroalaEditorView model={content} /> */}
                            <CardParser content={content} />
                            <Box marginBottom={10} marginLeft={-2}>
                                <Footer
                                    // onMouseOver={() => setOpenFooter(true)}
                                    // onMouseLeave={() => setOpenFooter(false)}

                                    openFooter={!designViewer ? openFooter : undefined}
                                    likeClick={!designViewer ? likeClick : undefined}
                                    readClick={!designViewer ? readClick : async () => {}}
                                    post={post}
                                    liked={liked}
                                    read={read}
                                    fullScreen
                                    onEditCompletion={() => {
                                        if (onEditCompletion) {
                                            onEditCompletion()
                                        }
                                    }}
                                    isNotification={post?.postType === 'notification'}
                                />
                            </Box>
                        </Box>
                    </Card>
                </DialogContent>
            </Dialog>
        )

        let tileSize = post?.tileSize

        if (!tileSize) {
            tileSize = 'single'
        }

        let tileHeight = height

        if (!tileHeight) {
            tileHeight = 250
        }

        // Determine width and height of Tile based on breakpoint
        const mixin = (theme: Theme): CSSObject => ({
            [theme.breakpoints.up('xs')]: {
                width,
                height: width
            },
            [theme.breakpoints.up('md')]: {
                width,
                height: width
            },
            [theme.breakpoints.up('lg')]: {
                width,
                height:
                    (designViewer
                        ? width === 250
                            ? width
                            : width! / 2
                        : post?.tileSize === 'single'
                        ? width
                        : Math.floor(width! / 2) - 10) ?? width
            }
        })

        const ExtendedStyledNewsCard = styled(
            designViewer ? StyledNormalNewsCard : StyledNewsCard
        )(({ theme }) => ({
            ...mixin(theme)
        }))

        const AnimatedBox = animated(Box)
        const AnimatedNewsCard = animated(ExtendedStyledNewsCard)

        // const stylesCard = useSpring({ opacity: remove ? 0 : 1 })
        const markReadStyles = useSpring({
            opacity: toggleMarkRead ? 1.0 : 0.0
        })

        const modalOpen = () => {
            if (post?.content !== '') {
                if (!contentClicked) {
                    setContentClicked(true)
                    // eslint-disable-next-line no-console
                    console.log('contentClick', currentUser?.uid)
                    addContentClickedToPost(
                        currentUser?.uid as string,
                        post as PostModel
                    ).then(() => {
                        // SuccessNotification('Post Liked')
                    })
                }

                if (!designViewer) {
                    if (!open) {
                        setOpen(true)
                    }
                }
            }
        }

        return (
            <Grid
                item
                onMouseOver={() =>
                    designViewer || open
                        ? () => {
                              return false
                          }
                        : !openFooter
                        ? setOpenFooter(true)
                        : null
                }
                onMouseLeave={() =>
                    designViewer || open
                        ? () => {
                              return false
                          }
                        : openFooter
                        ? setOpenFooter(false)
                        : false
                }
            >
                <AnimatedNewsCard
                    {...rest}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: 0,
                        padding: 0
                    }}
                >
                    {remove ? (
                        <AnimatedBox
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'white',
                                overflowY: 'hidden'
                            }}
                            display='flex'
                            justifyContent='center'
                            style={markReadStyles}
                        >
                            <IconButton disableRipple disableFocusRipple color='primary'>
                                <MarkChatReadRoundedIcon
                                    sx={{
                                        fontSize: 40
                                    }}
                                    color='primary'
                                />
                            </IconButton>
                        </AnimatedBox>
                    ) : (
                        <>
                            <Header
                                isBackground={headerBackground}
                                isStatic={headerStatic}
                                isTrending={isTrending}
                                isNew={post?.postType === 'news' && isNew}
                                isLink
                                closeClick={undefined}
                                linkColour={linkColour}
                                linkValue={post?.link}
                                designerView={designViewer}
                                isNotification={post?.postType === 'notification'}
                                isReadPopupAnnouncement={readPopupAnnouncement}
                            />
                            <Box onClick={modalOpen} height='100%'>
                                {!designViewer && modalForm}

                                <Box>
                                    <ContentCard
                                        sx={{
                                            pl: contentPaddingLeft || undefined,
                                            marginTop: '-2px',
                                            marginLeft: '-2px',
                                            marginRight: '-2px'
                                        }}
                                    >
                                        {children}
                                    </ContentCard>
                                </Box>
                            </Box>
                            {!designViewer ? (
                                <Footer
                                    openFooter={openFooter && !designViewer}
                                    likeClick={likeClick}
                                    readClick={readClick}
                                    post={post}
                                    liked={liked}
                                    read={read}
                                    fullScreen={false}
                                    onEditCompletion={() => {
                                        if (onEditCompletion) {
                                            onEditCompletion()
                                        }
                                    }}
                                    isNotification={post?.postType === 'notification'}
                                />
                            ) : null}
                        </>
                    )}
                </AnimatedNewsCard>
            </Grid>
        )
    }
)<NewsTileProps>(({ theme }) => ({
    borderRadius: '8px',
    boxShadow: '0px 0px 12px rgba(51,51,51,0.15)',
    position: 'relative',
    display: 'flex'
}))

NewsTile.defaultProps = {
    headerBackground: false,
    headerStatic: false,
    height: 240,
    xs: 12,
    md: 4,
    lg: 3,
    xl: 2,
    isTrending: false,
    isNew: false,
    isFavorite: false,
    designViewer: false
}

export default NewsTile
