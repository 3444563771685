import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import {
    Box,
    Menu,
    MenuItem,
    Select,
    styled,
    SxProps,
    TextField,
    Typography
} from '@mui/material'
import TouchRipple from '@mui/material/ButtonBase'
import { Children, cloneElement, FC, ReactElement, useState } from 'react'

import shallow from 'zustand/shallow'
import { Store, useStore } from '../../appData/store'

type NewsHeaderProps = {
    height: number
}

const FeedFocus = styled(TextField)(() => ({
    background: '#FFFFFF',
    borderRadius: 300,
    input: {
        'background': '#FFFFFF',
        '&::placeholder': {
            color: 'black',
            opacity: 1
        },
        'color': 'black' // if you also want to change the color of the input, this is the prop you'd use
    }
}))

const MyFocusDropDown = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    display: 'flex',
    whiteSpace: 'pre',
    borderTopRightRadius: 300,
    borderBottomRightRadius: 300,
    borderLeft: `0px solid ${theme.palette.text.disabled}`,
    [theme.breakpoints.down('xs')]: { display: 'none' }
}))

type MyFocusMenuProps = {
    sx?: SxProps
    open?: boolean
    shouldCloseOnItemClick?: boolean
    direction?: 'left' | 'right' | 'center'
    myFocusItem: string | null
    children: ReactElement | ReactElement[]
}

const myFocusList = [
    { value: 'Relevant', key: 'relevant' },
    { value: 'Newest', key: 'new' },
    { value: 'Trending/Popular', key: 'trending' },
    { value: 'Read', key: 'read' },
    { value: 'Liked', key: 'like' },
    { value: 'Announcements', key: 'allPopupAnnouncement' },
    { value: 'Expired', key: 'expired' }
]

// const myFocusList = [
//     'Relevant',
//     'Newest',
//     // 'Notification',
//     // 'Interest',
//     'Trending/Popular'
// ]

const storeSelector = (store: Store) => ({
    filter: store.filter,
    setFilter: store.setFilter
})

const MyFocusMenu: FC<MyFocusMenuProps> = ({
    open,
    direction,
    shouldCloseOnItemClick,
    myFocusItem,
    children,
    ...props
}) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => setAnchorEl(null)

    const handleMenuItemClick = (customOnClick: any) => () => {
        if (customOnClick) {
            customOnClick()
        }
        if (shouldCloseOnItemClick) {
            handleClose()
        }
    }

    return (
        <>
            <MyFocusDropDown
                px={3}
                gap={0.5}
                height='100%'
                color='grey.700'
                bgcolor='grey.100'
                alignItems='center'
                component={TouchRipple}
                onClick={handleClick}
            >
                {myFocusItem}
                <KeyboardArrowDownOutlined fontSize='small' color='inherit' />
            </MyFocusDropDown>

            <Menu
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open !== undefined ? open : !!anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: direction || 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: direction || 'left'
                }}
                {...props}
            >
                {Children.map(children, (child: ReactElement) =>
                    cloneElement(child, {
                        onClick: handleMenuItemClick(child.props.onClick)
                    })
                )}
            </Menu>
        </>
    )
}

MyFocusMenu.defaultProps = {
    direction: 'left',
    shouldCloseOnItemClick: true
}

const NewsHeader: FC<NewsHeaderProps> = ({ height }) => {
    // const newsCtx = useContext(AppContext)
    // const { filter, setFilter } = newsCtx.state.news
    const { filter, setFilter } = useStore(storeSelector, shallow)
    const [myFocusItem, setMyFocusItem] = useState(filter)

    const handleFocusChange = (focus: any) => {
        console.log('handleFocusChange22', focus)
        setMyFocusItem(focus)
        // let catVal: WidgetOptions | 'relevant' | undefined
        // switch (cat) {
        //     case 'Relevant':
        //         catVal = 'relevant'
        //         break
        //     case 'Newest':
        //         catVal = 'new'
        //         break
        //     // case 'Notification':
        //     //     catVal = 'notification'
        //     //     break
        //     // case 'Interest':
        //     //     catVal = 'interest'
        //     //     break
        //     case 'Trending/Popular':
        //         catVal = 'trending'
        //         break
        //     default:
        //         break
        // }
        setFilter(focus)
    }

    /* const myFocusMenu = (
        <MyFocusMenu
            direction='right'
            sx={{ zIndex: 1502 }}
            myFocusItem=''
        >
            {myFocusList.map(item => (
                <MenuItem
                    key={item}
                    onClick={handleFocusChange(item)}
                >
                    {item}
                </MenuItem>
            ))}
        </MyFocusMenu>
    ) */

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
            }}
            flexWrap='wrap'
        >
            <Typography mb='5px' variant='h3'>
                Your feed
            </Typography>
            <Box>
                <Select
                    variant='outlined'
                    value={myFocusItem}
                    fullWidth
                    displayEmpty
                    sx={{
                        height: 50,
                        width: { xs: '100%', md: 200, lg: 200 },
                        borderRadius: 300,
                        fontSize: '16px',
                        mr: { xs: 0, lg: 8 }
                    }}
                    onChange={event => {
                        console.log('event handleFocusChange', event.target.value)
                        handleFocusChange(event.target.value)
                    }}
                >
                    {myFocusList.map(option => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.value}
                        </MenuItem>
                    ))}
                </Select>
                {/* <FeedFocus
                    variant='outlined'
                    placeholder='Feed Focus'
                    fullWidth
                    value={myFocusItem}
                    // onChange={hanldeSearch}
                    InputProps={{
                        sx: {
                            height: 44,
                            borderRadius: 300,
                            paddingRight: 0,
                            overflow: 'hidden',
                            boxShadow:
                                '0 3px 1px -2px rgba( 51,51,51, .2 ), 0 2px 2px 0 rgba( 51,51,51, .14 ), 0 1px 5px 0 rgba( 51,51,51, .12 )',
                            fontSize: '16px'
                        },
                        endAdornment: myFocusMenu
                        // endAdornment: (
                        //     <KeyboardArrowDownOutlined
                        //         fontSize='small'
                        //         // color='inherit'
                        //         style={{
                        //             marginRight: 20
                        //         }}
                        //     />
                        // )
                        // startAdornment: <SearchOutlinedIcon fontSize="small" />,
                    }}
                /> */}
            </Box>
        </Box>
    )
}

export default NewsHeader
