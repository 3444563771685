import AddLinkIcon from '@mui/icons-material/AddCircle'
import { Button, Stack } from '@mui/material'
import { systemGroupNames } from 'appData/store/systemGroups'
import { NavBarEdit } from 'components/navbar/Content/Styled/NavBarEdit'
import {
    NavButton,
    NavItemText
} from 'components/navbar/Content/Styled/StyledNavComponents'
import { otherColor, white } from 'components/navbar/types'
import { useCallback, useState } from 'react'
import { GroupModel } from 'service/model/GroupModel'
import { object, string } from 'yup'

type Mode = 'ready' | 'editing'

const groupSchema = object({
    groupName: string()
        .required('Folder name is required')
        .notOneOf(Object.values(systemGroupNames), 'This is a reserved name')
    //     'not-system-name',
    //     'this is a reserved name',
    //     (value, context) => !!value && !isSystemGroupName(value)
    // )
})
export const AddEditGroup = ({
    open,
    completed,
    cancelled,
    allBlue,
    groupToEdit: linkToEdit
}: {
    open: boolean
    completed: (name: string) => Promise<boolean>
    cancelled: () => void
    allBlue: boolean
    groupToEdit?: GroupModel
}) => {
    const initialData = linkToEdit || { name: '', url: '', description: '' }

    const [mode, setMode] = useState<Mode>(linkToEdit ? 'editing' : 'ready')
    const [groupName, setGroupName] = useState(initialData.name)

    const doSetGroupName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        doValidation({ groupName: e.target.value })
        setGroupName(e.target.value)
    }

    const [validations, setValidations] = useState<Record<string, string[]>>()

    const doValidation = useCallback(
        (curTargetPatch: Record<string, string>) => {
            const formData = {
                groupName,
                ...curTargetPatch
            }
            groupSchema
                .validate(formData, { strict: true, abortEarly: false })
                .then(data => {
                    setValidations({})
                })
                .catch(val => {
                    const vals = (val.inner as { path: string; errors: string[] }[])
                        .map(({ path, errors }) => ({
                            field: path,
                            errors
                        }))
                        .reduce(
                            (acc, { field, errors }) => ({ ...acc, [field]: errors }),
                            {} as Record<string, string[]>
                        )
                    setValidations(vals)
                })
        },
        [groupName]
    )

    const valid = !!validations && Object.keys(validations).length === 0

    const color = allBlue ? white : otherColor
    return (
        <Stack sx={{ flex: '1 0' }}>
            {mode === 'ready' && (
                <NavButton onClick={() => {}}>
                    <AddLinkIcon
                        style={{
                            color,
                            width: 24,
                            height: 24
                        }}
                    />
                    <NavItemText
                        open={open}
                        onClick={() => setMode('editing')}
                        altColor={color}
                    >
                        Add Folder
                    </NavItemText>
                </NavButton>
            )}
            {mode === 'editing' && (
                <Stack direction='column' sx={{ flex: 1 }}>
                    <NavBarEdit
                        id='nameField'
                        label='Folder name'
                        variant='outlined'
                        value={groupName}
                        onChange={doSetGroupName}
                        sx={{ color, borderColor: color, mt: 2 }}
                        altColor={allBlue}
                        allBlue={allBlue}
                        autoComplete='off'
                        error={!!validations?.groupName}
                        helperText={validations?.groupName}
                        autoFocus
                    />
                    <Stack
                        direction='row'
                        spacing={1}
                        justifyContent='space-between'
                        sx={{ pt: 1 }}
                    >
                        <Button
                            sx={{ flex: 1 }}
                            variant='contained'
                            onClick={() => {
                                cancelled()
                                setValidations(undefined)
                                setMode('ready')
                                setGroupName('')
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{ flex: 1 }}
                            variant='contained'
                            onClick={async () => {
                                const success = await completed(groupName)
                                if (success) {
                                    setMode('ready')
                                    setGroupName('')
                                }
                            }}
                            disabled={!valid}
                        >
                            {linkToEdit ? 'Update Folder' : 'Add Folder'}
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
}
