import { _doSingletonUpdate, _getSingleton } from 'service/controller/Singletons'
import { flavour, Permission, SingletonsModel } from 'service/model/SingletonsModel'
import { makeMapLookup } from 'service/utils'

export const _getPermissions = async () => {
    const data = await _getSingleton('permissions')
    if (data?.flavour === 'permissions') {
        return data.permissions
    }
    return undefined
}

export const _updatePermissions = async (
    updates: {
        name: Permission
        requiredKnownEmails: string[]
        requiredJobFamilies: string[]
        requiredAcademies: string[]
        requiredRegions: string[]
    }[]
) => {
    return _doSingletonUpdate((lookupModel: SingletonsModel) => {
        if (lookupModel.flavour !== 'permissions') {
            throw new Error(`Flavour should be '${flavour('permissions')}'`)
        }
        const updateMap = makeMapLookup(
            updates,
            ({ name }) => name,
            u => u
        )

        const updated = {
            ...lookupModel,
            permissions: lookupModel.permissions.map(p => {
                const u = updateMap.get(p.name)
                if (u) {
                    return { ...p, ...u }
                }
                return p
            })
        }

        return updated
    }, 'permissions')
}
