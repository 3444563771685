/* eslint-disable @typescript-eslint/no-redeclare */
import { StringUnion } from '../StringUnion'
import { keyUserPerAcademyPermissions } from './User'

export type AcademiesLookupSingletonModel = {
    flavour: 'academies'
    academies: {
        academy: string
        academyCode: string
        region: string
        emailDomain: string
        phase: string
    }[]
}

export type KnownEmailsSingletonModel = {
    flavour: 'knownEmails'
    knownEmails: string[]
}

export type JobFamiliesSingletonModel = {
    flavour: 'jobFamilies'
    jobFamilies: string[]
}

export type UsersSummarySingletonModel = {
    flavour: 'users'
    users: {
        name: string
        email: string
        linkedEmails?: string[]
        domain?: string
        jobFamilies?: string[]
        academyCodes?: string[]
        regions?: string[]
        academyPhases?: string[]
    }[]
}

export type Permission =
    | 'edit permissions'
    | 'edit groups'
    | 'edit posts'
    | 'access myFocus'

export type PermissionsSingletonModel = {
    flavour: 'permissions'
    permissions: {
        name: Permission
        requiredKnownEmails?: string[]
        requiredJobFamilies?: string[]
        requiredAcademies?: string[]
        requiredRegions?: string[]
    }[]
}

export const PerAcademyPermission = StringUnion(keyUserPerAcademyPermissions)
export type PerAcademyPermission = typeof PerAcademyPermission.type

export type PerAcademyPermissionSingletonModel = {
    flavour: 'perAcademyPermissions'
    perAcademyPermissions: {
        name: PerAcademyPermission
        academyPermissions: {
            academyCode: string
            requiredKnownEmails?: string[]
            requiredJobFamilies?: string[]
            requiredAcademies?: string[]
            requiredRegions?: string[]
        }[]
    }[]
}

export type SingletonsModel =
    | AcademiesLookupSingletonModel
    | JobFamiliesSingletonModel
    | KnownEmailsSingletonModel
    | UsersSummarySingletonModel
    | PermissionsSingletonModel
    | PerAcademyPermissionSingletonModel

export type SingletonFlavour = SingletonsModel['flavour']
export const flavour = (candidateFlavour: SingletonFlavour) => candidateFlavour
