import {
    doc,
    getDoc,
    getDocs,
    query,
    runTransaction,
    collection
} from 'firebase/firestore'
import {
    db,
    singletonsCollection
    // usersCollection
} from 'service/firebase'
import { collectionNames } from 'service/firebase/collectionNames'
import { SingletonFlavour, SingletonsModel } from 'service/model/SingletonsModel'

export const _doSingletonUpdate = async (
    updater: (lookupModel: SingletonsModel) => SingletonsModel | undefined,
    flavour: SingletonFlavour
) => {
    let success = false

    await runTransaction(db, async transaction => {
        const docRef = doc(singletonsCollection, flavour)
        const dsSingleton = await transaction.get(docRef)
        if (dsSingleton.exists()) {
            const data = dsSingleton.data()
            if (data.flavour === flavour) {
                const updated = updater(data)
                if (updated) {
                    transaction.set(docRef, updated)
                    success = true
                }
            }
        }
    })
    return success
}

export const _createOrUpdateSingleton = async (
    creator: () => SingletonsModel,
    updater: (lookupModel: SingletonsModel) => SingletonsModel,
    flavour: SingletonFlavour
) => {
    let success = false

    await runTransaction(db, async transaction => {
        const docRef = doc(singletonsCollection, flavour)
        const dsSingleton = await transaction.get(docRef)
        if (dsSingleton.exists()) {
            const data = dsSingleton.data()
            if (data?.flavour === flavour) {
                const updated = updater(data)
                if (updated) {
                    transaction.set(docRef, updated)
                    success = true
                }
            }
        } else {
            const created = creator()
            if (created) {
                transaction.set(docRef, created)
                success = true
            }
        }
    })
    return success
}

export const _getSingleton = async (flavour: SingletonFlavour) => {
    const docRef = doc(singletonsCollection, flavour)
    const dsSingleton = await getDoc(docRef)
    if (dsSingleton.exists()) {
        const data = dsSingleton.data()
        return data
    }
    return undefined
}

// export const _getUsersSingleton = async () => {
//     const queryUsers = query(usersCollection)
//     const users = await getDocs(queryUsers)
//     if (users) {
//         const data = users.docs.map(user => user.data)
//         const arrUsers: any[] = []
//         data.forEach(user => {
//             const userObject = { ...user }
//             arrUsers.push(userObject)
//         })
//         const singletonObject: SingletonsModel = { flavour: 'users', users: arrUsers }
//         return singletonObject
//     }
//     return undefined
// }

export const _getUsersSingleton = async () => {
    const users: any[] = []
    const querySnapshot = await getDocs(collection(db, collectionNames.singletonUsers))
    querySnapshot.forEach(document => {
        // doc.data() is never undefined for query doc snapshots
        users.push(document.data())
    })

    const singletonObject: SingletonsModel = { flavour: 'users', users }
    // console.log('singletonObject', singletonObject)

    // Test
    // const queryUsers1 = query(singletonUsersCollection)
    // const users1 = await getDocs(queryUsers1)

    // const data = users1.docs.map(user => user.data)
    // const arrUsers: any[] = []
    // data.forEach(user => {
    //     const userObject = { ...user }
    //     arrUsers.push(userObject)
    // })
    // const singletonObject1: SingletonsModel = { flavour: 'users', users: arrUsers }
    // console.log('singletonObject1', singletonObject1)

    return singletonObject

    // const queryUsers = query(singletonUsersCollection)
    // const users = await getDocs(queryUsers)

    // if (users) {
    //     const data = users.docs.map(user => user.data)
    //     const arrUsers: any[] = []
    //     data.forEach(user => {
    //         const userObject = { ...user }
    //         arrUsers.push(userObject)
    //     })
    //     const singletonObject: SingletonsModel = { flavour: 'users', users: arrUsers }
    //     return singletonObject
    // }
    // return undefined
}
