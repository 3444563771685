'use client'

import { createContext, useContext } from 'react'
import { GlobalContextProps } from '../types'

export const GlobalContext = createContext({} as GlobalContextProps)

export const useGlobalContext = () => {
    const context = useContext(GlobalContext)
    if (!context) {
        throw new Error('useGlobalContext must be used inside GlobalProvider')
    }
    return context
}
