import allGroups from './aetGroups.json'

type Option = { name: string; id: string }

export async function queryGroups(q: string): Promise<Array<Option>> {
    console.log(q)
    return new Promise((resolve, reject) => {
        if (q.length < 3) {
            resolve([])
        } else {
            setTimeout(
                () =>
                    resolve(
                        (allGroups as Array<Option>).filter(g =>
                            g.name.toLocaleLowerCase().includes(q.toLocaleLowerCase())
                        )
                    ),
                100
            )
        }
    })
}
