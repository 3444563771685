/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Checkbox, FormControlLabel } from '@mui/material'
import { PermXCoordinate } from 'pages/Permissions/PermissionsPage'
import { useCallback } from 'react'

export const ColumnGroupToggle = ({
    coord,
    showColumnGroup,
    onChange
}: {
    coord: keyof PermXCoordinate
    showColumnGroup: boolean
    onChange: (coord: keyof PermXCoordinate, show: boolean, modifier: boolean) => void
}) => {
    const localOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            const { shiftKey } = e.nativeEvent as PointerEvent
            onChange(coord, checked, shiftKey)
        },
        [coord, onChange]
    )
    const color = '#ffffff'

    return (
        <FormControlLabel
            control={<Checkbox checked={showColumnGroup} onChange={localOnChange} />}
            label=''
            title={
                showColumnGroup
                    ? `Show the column-group '${coord}'`
                    : `Hide the column-group '${coord}'`
            }
            sx={{
                color,
                '& .MuiCheckbox-root, & .MuiFormControlLabel-label': {
                    color: `${color} !important`
                },
                'margin': '0 -12px 0 -4px'
            }}
        />
    )
}
