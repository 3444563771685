/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-plusplus */

import { Tuple } from 'components/navbar/types'
import emojiRegexBuilder from 'emoji-regex'
import fastDeepEqual from 'fast-deep-equal/es6'
import {
    getDocs,
    limit,
    orderBy,
    query,
    QueryDocumentSnapshot,
    startAfter
} from 'firebase/firestore'
import { getCollection } from 'service/firebase'
import { AcademySummaryLookup } from 'service/model/AcademySummaryModel'
import { UserModel } from 'service/model/User'
import wu from 'wu'
import { typedEntries } from '../utils/typedEntries'

/* eslint-disable no-param-reassign */
export const px = (val: number) => `${val}px`

export const json = (val: any) => JSON.stringify(val, null, 2)

export const { log } = console
// export const log = (...args:any[]) => {};

export const getErrorMessage = (error: unknown) => {
    if (error instanceof Error) {
        return error.message
    }
    return String(error)
}

export const emojiRegex = emojiRegexBuilder()
export const emojiRegexWithIndices = new RegExp(emojiRegex.source, 'd')
export const escapeRegex = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string

export const getEmojiPrefixAndRemainder = (str: string) => {
    const m = emojiRegexWithIndices.exec(str)
    if (m) {
        const ind = (m as any).indices
        if (ind) {
            const from = ind[0][0]
            const to = ind[0][1]
            const emoji = str.substring(from, to)
            const rest = str.substring(to).trim()

            // log(
            //     `getEmojis('${str}') | m: ${json(m)} | ind: ${json(ind)} | from: ${json(
            //         from
            //     )} | to: ${json(to)} | emoji: '${emoji}' | rest: '${rest}'`
            // )
            return [emoji, rest]
        }
    }
    // log(`'${str}' is just text`)
    return undefined
}

export const venn = <L, R, V>(
    left: L[],
    right: R[],
    leftSelector: (one: L) => V,
    rightSelector: (two: R) => V
): {
    leftOnly: L[]
    intersection: [L, R][]
    rightOnly: R[]
} => {
    const lm = makeMapLookup(left, leftSelector, v => v)
    const la = [...lm.entries()]

    const rm = makeMapLookup(right, rightSelector, v => v)
    const ra = [...rm.entries()]

    const leftOnly_ = la.filter(([k, _]) => !rm.has(k))
    const intersection_ = la
        .filter(([k, _]) => rm.has(k))
        .map(([k, l]) => Tuple([l, k, rm.get(k) as R]))
    const rightOnly_ = ra.filter(([k, _]) => !lm.has(k))

    const leftOnly = leftOnly_.map(([_, l]) => l)
    const intersection = intersection_.map(([l, _, r]) => Tuple([l, r]))
    const rightOnly = rightOnly_.map(([_, r]) => r)

    return {
        leftOnly,
        intersection,
        rightOnly
    }
}

export const makeLookup = <T, K extends string, V>(
    things: readonly T[],
    keySelector: (thing: T) => K,
    valueSelector: (thing: T) => V
) =>
    things.reduce(
        (acc, thing) => ({
            ...acc,
            [keySelector(thing)]: valueSelector(thing)
        }),
        {} as Record<K, V>
    )

export const sortedIndex = <T>(
    ar: T[],
    item: T,
    comparer: (one: T, two: T) => number
) => {
    for (let i = 0; i < ar.length; i++) {
        const arItem = ar[i]
        if (comparer(item, arItem) < 0) {
            return i
        }
    }
    return ar.length
}

export const sortedInsertInPlace = <T>(
    ar: T[],
    item: T,
    comparer: (one: T, two: T) => number
) => {
    ar.splice(sortedIndex(ar, item, comparer), 0, item)
}

export const sortedInsert = <T>(
    ar: T[],
    item: T,
    comparer: (one: T, two: T) => number
) => {
    ar.splice(sortedIndex(ar, item, comparer), 0, item)
    const idx = sortedIndex(ar, item, comparer)
    return [...ar.slice(0, idx), item, ...ar.slice(idx)]
}

// page through <items>, <itemsPerPage> items at a time,
// calling pageHandler for each page
// pageHandler returns true to continue paging
export const pageThrough = async <T>(
    items: Iterable<T>,
    itemsPerPage: number,
    pageHandler: (context: { pageItems: T[]; pageIndex: number }) => Promise<boolean>
) => {
    const pages = wu(items).chunk(itemsPerPage).enumerate().toArray()

    // eslint-disable-next-line no-restricted-syntax
    for await (const page of pages) {
        const [pageItems, pageIndex] = page
        const wantMore = await pageHandler({ pageItems, pageIndex })
        if (!wantMore) {
            break
        }
    }
}

export const processInPages = async <T>(
    collectionName: string,
    callback: (pageItems: T[], firstItemIndex: number) => boolean,
    orderByKey: keyof T,
    pageSize = 10
) => {
    const coll = getCollection<T>(collectionName)
    let q = query(coll, orderBy(orderByKey as string), limit(pageSize))

    let firstItemIndex = 0
    let qdsLast: QueryDocumentSnapshot<T>

    // let qs = await coll
    //     .orderBy(orderByKey as string)
    //     .limit(pageSize)
    //     .get()
    let qs = await getDocs(q)

    let more = true

    while (more) {
        const morePlease = callback(
            qs.docs.map(qds => qds.data()),
            firstItemIndex
        )
        const len = qs.docs.length
        firstItemIndex += len

        qdsLast = qs.docs[len - 1]

        more = morePlease && len === pageSize
        if (more) {
            // qs = await coll
            //     .orderBy(orderByKey as string)
            //     .startAfter(qdsLast.data())
            //     .limit(pageSize)
            //     .get()
            q = query(
                coll,
                orderBy(orderByKey as string),
                limit(pageSize),
                startAfter(qdsLast)
            )
            // eslint-disable-next-line no-await-in-loop
            qs = await getDocs(q)
        }
    }
}

// export const pagingTest = async () => {
//     processInPages<AcademyModel>(
//         collectionNames.academies,
//         (pageItems, fii) => {
//             pageItems.forEach(({ academy }, i) => log(`${fii + i}) ${academy}`))
//             return true
//         },
//         'academy'
//     )
// }

export const except = <T extends Record<string, unknown>>(obj: T, keys: (keyof T)[]) => {
    return Object.entries(obj).reduce(
        (acc, [k, v]) => (!keys.includes(k) ? { ...acc, [k]: v } : acc),
        {} as Partial<T>
    )
}

export const isDeepEqual = (object1: any, object2: any) => {
    return fastDeepEqual(object1, object2)
}

export const deepClone = <T>(ob: T) => JSON.parse(json(ob)) as T

export const exceptCreatedAndUpdated = <T extends Record<string, unknown>>(obj: T) =>
    except(obj, ['createdAt', 'updatedAt'])

export const makeSet = <T, V>(things: readonly T[], valueSelector: (thing: T) => V) =>
    things.reduce((acc, thing) => {
        acc.add(valueSelector(thing))
        return acc
    }, new Set<V>())

export const makeMapLookup = <T, K, V>(
    things: readonly T[],
    keySelector: (thing: T) => K,
    valueSelector: (thing: T) => V
) =>
    things.reduce((acc, thing) => {
        acc.set(keySelector(thing), valueSelector(thing))
        return acc
    }, new Map<K, V>())

export const dedupeArray = <T>(
    things: readonly T[],
    keySelector: (thing: T) => string
) => [...makeMapLookup(things, keySelector, v => v).values()]

export const makeDedupedLookup = <T, V>(
    things: readonly T[],
    keySelector: (thing: T) => string,
    valueSelector: (thing: T) => V
) => {
    const keys = new Set<string>()

    return things.reduce((acc, thing) => {
        const key = keySelector(thing)
        if (!keys.has(key)) {
            keys.add(key)
            return {
                ...acc,
                [key]: valueSelector(thing)
            }
        }
        return acc
    }, {} as Record<string, V>)
}

export const groupBy = <T, K extends keyof any>(
    things: readonly T[],
    keySelector: (i: T) => K
) =>
    things.reduce((groups, item) => {
        ;(groups[keySelector(item)] ||= []).push(item)
        return groups
    }, {} as Record<K, T[]>)

/*
 * Process a bunch of things: T into groups
 *  the things in a particular group have the same output for groupKeySelector(thing)
 *
 *  Each group has meta-data, something shared between all things in the group
 *  which isn't used to identify the group because keys may
 *  only be string | number | symbol
 *
 * Metadata is specified with the groupMetadataSelector(groupKey, thing)
 *
 * Each input item may be transformed from a T to an arbirarily-shaped object
 * using valueSelector (thing, groupKey)
 *
 * thus we have an output structure of:
 * {
 *    [k1]: {
 *      metadata: {...},
 *      items: [...] as V[]
 *    },
 *    [k2]: {
 *      metadata: {...},
 *      items: [...] as V[]
 *    }
 * }
 */
export const groupProjectWithGroupMetadata = <
    T,
    GK extends string | number | symbol,
    GM,
    G extends { metadata: GM; items: V[] },
    V
>(
    things: readonly T[],
    groupKeySelector: (t: T) => GK,
    groupMetadataSelector: (gk: GK, t: T) => GM,
    valueSelector: (t: T, gk: GK) => V
) =>
    things.reduce((groups, t) => {
        const gk = groupKeySelector(t)
        const v = valueSelector(t, gk)

        if (groups[gk]) {
            groups[gk].items.push(v)
        } else {
            groups[gk] = {
                metadata: groupMetadataSelector(gk, t),
                items: [v]
            } as G
        }

        return groups
    }, {} as Record<GK, G>)

export const groupProject = <T, K extends keyof any, V>(
    things: readonly T[],
    keySelector: (i: T) => K,
    valueSelector: (i: T) => V
) =>
    things.reduce((groups, item) => {
        ;(groups[keySelector(item)] ||= []).push(valueSelector(item))
        return groups
    }, {} as Record<K, V[]>)

export const minMaxProject = <T>(
    items: T[],
    selector: (item: T) => number,
    comparator: (a: number, b: number) => number
) => {
    const max = items.reduce((acc, item) => {
        if (acc) {
            const itemValue = selector(item)
            if (comparator(itemValue, acc.opValue) === itemValue) {
                return { opValue: itemValue, opItem: item }
            }
            return acc
        }
        return { opValue: selector(item), opItem: item }
    }, undefined as { opValue: number; opItem: T } | undefined)

    return max?.opItem
}
export const minProject = <T>(items: T[], selector: (item: T) => number) =>
    minMaxProject(items, selector, Math.min)

export const maxProject = <T>(items: T[], selector: (item: T) => number) =>
    minMaxProject(items, selector, Math.max)

export const localeCapitalized = (text: string) =>
    `${text[0].toLocaleUpperCase()}${text.substring(1).toLocaleLowerCase()}`

// export const getImageInfo = (img: HTMLImageElement) => {
//     try {
//         const info = {
//             width: img.width,
//             height: img.height
//         }
//         return info
//     } catch (err) {
//         // eat the error
//     }
//     return undefined
// }

// export const getImageInfoFromUrl = async (url: string) => {
//     let ret: Promise<ReturnType<typeof getImageInfo>>
//     try {
//         ret = new Promise((res, rej) => {
//             try {
//                 const img = document.createElement('img')
//                 img.onload = ev => {
//                     res(getImageInfo(img))
//                 }
//                 img.onerror = ev => {
//                     rej(new Error())
//                 }
//                 img.src = url
//             } catch (err) {
//                 // eat the error
//             }
//         })
//     } catch (err) {
//         // eat the error
//     }
//     return ret!
// }

/*
a: H1, H2
b: fi, fp, ifi, ifp

lengths: [2, 4]
indices: [0, 0]
context: 
    [H1, fi]
    [H1, fp]
    [H1, ifi]
    [H1, ifp]

    [H2, fi]
    [H2, fp]
    [H2, ifi]
    [H2, ifp]
*/
type Combinations<A, B, C, D, E, F> = {
    a: A
    b: B
    c?: C
    d?: D
    e?: E
    f?: F
}

type CombinationsCallbackParams<A, B, C, D, E, F> = {
    values: Combinations<A, B, C, D, E, F>
    lengths: number[]
    indices: number[]
}
type CombinationsCallback<A, B, C, D, E, F> = (
    params: CombinationsCallbackParams<A, B, C, D, E, F>
) => Promise<boolean | undefined>

// Combine up to six arrays in a typed manner - calls a callback with each combination
export const combine = async <A, B, C, D, E, F>(
    inputs: Combinations<A[], B[], C[], D[], E[], F[]>,
    callback: CombinationsCallback<A, B, C, D, E, F>
) => {
    const len = <T>(t?: T[]) => (t || []).length
    const lengths = [] as number[]
    const indices = [] as number[]
    const push = <T>(t?: T[]) => {
        if (t) {
            lengths.push(t.length)
            indices.push(0)
        }
    }

    const { a, b, c, d, e, f } = inputs
    push(a)
    push(b)
    push(c)
    push(d)
    push(e)
    push(f)

    const context = {
        values: {} as Combinations<A, B, C, D, E, F>,
        lengths,
        indices
    }
    const p = ['a', 'b', 'c', 'd', 'e', 'f'] as const
    const vv = [a, b, c, d, e, f] as const
    const load = () =>
        indices.forEach((idx, i) => {
            const a_f = p[i]
            const va = vv[i]
            const vav = va![idx]
            context.values[a_f] = vav as any
        })

    const ll = len(lengths)
    const r = ll - 1
    let more = true

    const carry = (k: number) => {
        if (k >= 0) {
            indices[k] += 1
            if (indices[k] >= lengths[k]) {
                indices[k] = 0
                carry(k - 1)
            }
        } else {
            more = false
        }
    }
    while (more) {
        for (let i = 0; more && i < lengths[r]; i++) {
            indices[r] = i
            load()
            // eslint-disable-next-line no-await-in-loop
            const finished = await callback(context)
            if (finished) {
                more = false
            }
        }
        carry(r - 1)
    }
}

export const joinPaths = (...paths: string[]) => {
    const joined = paths.reduce((acc, p) => {
        const as = acc.endsWith('/')
        const ps = p.startsWith('/')

        if (as && ps) {
            return `${acc.substring(0, acc.length - 1)}${p}`
        }
        return `${acc}${p}`
    }, '')
    return joined
}
type IconSize = '24' | '32'
const exceptions = {
    'rmunify.com/sso/google': '/images/png/RM_Education_(logo)2.png',
    'rmunify.com/': {
        '24': '/images/png/RM_Education_(logo)2a.png',
        '32': '/images/png/RM_Education_(logo)2a.png'
    },
    'classroom.google.com/': '/images/png/classroom2.png',
    'sites.google.com/aetinet.org/one-aet': '/images/png/aet_4by4_24px.png',
    'googlemail.com/': '/images/png/gmail1.png',
    'mail.google.com/': '/images/png/gmail1.png'
} as Record<string, string | Record<IconSize, string>>

const normalise = (url: string) => {
    const { host, pathname, search } = new URL(url)
    return `${host}${pathname}${search}`.toLowerCase()
}

export const getFavIconUrlForSite = (siteUrl: string, size = 24) => {
    if (size !== 24 && size !== 32) {
        throw new Error('size should be 24 or 32')
    }
    const normalised = normalise(siteUrl)
    const iconSize = `${size}` as IconSize
    const ex_ = exceptions[normalised]
    // log(`💥 typeof '${ex_}': '${typeof ex_}'`)
    const ex = !ex_
        ? undefined
        : typeof ex_ === 'string'
        ? ex_
        : ex_[iconSize] || ex_['24']

    // log(
    //     `getFavIconUrlForSite('${siteUrl}', ${size}) - normalised: '${normalised}' - exceptions: ${json(
    //         exceptions
    //     )} - ex_: '${ex_}' - ex: '${ex}'`
    // )
    const ret =
        ex ||
        `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${siteUrl}&size=${24}`

    // log(`getFavIconUrlForSite('${siteUrl}', ${size}) => '${ret}'`)
    return ret
}

export const getIconSize = (open: boolean) => (open ? 24 : 32)

export const firstNonEmptyKeyValue = <T extends Record<string, unknown>>(
    obj: T,
    propsOfInterest: (keyof T)[]
) => {
    const entries = typedEntries(obj).filter(
        ([k, _v]) => propsOfInterest.includes(k) && obj[k]
    )
    return entries.length > 0 ? entries[0] : undefined
}

export const getAcademiesRegionsAndAcademyPhasesForUser = (
    adlm: AcademySummaryLookup,
    user: UserModel
) => {
    let linkedAcademies = [user.email, ...(user.linkedEmails || [])].map(le => {
        const [, ld] = le.split('@')
        const la = adlm[ld]
        return la
    })

    linkedAcademies = linkedAcademies.filter(academies => academies !== undefined)
    const academyCodes = linkedAcademies?.map(({ academyCode }) => academyCode)
    const regions = linkedAcademies?.map(({ region }) => region || null)
    const academyPhases = linkedAcademies?.map(({ phase }) => phase || null)
    return {
        academyCodes,
        regions,
        academyPhases
    }
}

export const nameOrEmailPrefix = (name: string, email: string) =>
    name || email ? email.split('@')?.[0] : '' || ''

/** Bypassing Typescript for some fields */
export const makeFieldNullIfUndefined = (data: any) => {
    Object.keys(data).forEach(key => {
        if (data[key] === undefined) {
            data[key] = null
        }
    })
}
