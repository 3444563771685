/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import {
    Box,
    Button,
    Card,
    Divider,
    Fade,
    IconButton,
    Menu,
    MenuItem,
    Select,
    styled,
    Typography
} from '@mui/material'
import { debounce } from '@mui/material/utils'
import { queryGroups } from 'components/autocomplete/aetGroups'
import ChipAutoComplete from 'components/autocomplete/ChipAutoComplete'
import FlexBox from 'FlexBox'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react'
import useMeasure from 'react-use-measure'
import { AcademyLookupItem } from 'service/controller/Academy'
import { keyUserPerAcademyPermissions } from 'service/model/User'
import shallow from 'zustand/shallow'
import { IGoogleGroup, Store, useStore } from '../../appData/store'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:hover, &.Mui-focusVisible': {
        backgroundColor: '#ffffff'
    }
}))

const storeSelector = (store: Store) => ({
    zCurrentUser: store.currentUser,
    // setMyFocus: store.setMyFocus,
    // myFocus: store.myFocus,
    setMyFocusFilter: store.setMyFocusFilters,
    myFocusFilter: store.myFocusFilters,
    userHasPermission: store.userHasPermission,
    jobFamilies: store.jobFamilies,
    academies: store.academies,
    regions: store.regions,
    academyCodeLookup: store.academyCodeLookup
})

// styled components
// also used in the GrocerySearchBox component
export const SearchOutlinedIcon = styled(SearchOutlined)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginRight: 6
}))

// also used in the GrocerySearchBox component
export const SearchResultCard = styled(Card)(({ theme }) => ({
    position: 'absolute',
    top: '100%',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    width: '100%',
    zIndex: 99
}))

const DropDownHandler = styled(FlexBox)(({ theme }) => ({
    borderTopRightRadius: 300,
    borderBottomRightRadius: 300,
    whiteSpace: 'pre',
    borderLeft: `1px solid ${theme.palette.text.disabled}`,
    [theme.breakpoints.down('xs')]: {
        display: 'none'
    }
}))

const MyFocus: FC = () => {
    const {
        zCurrentUser,
        myFocusFilter,
        setMyFocusFilter,
        userHasPermission,
        jobFamilies,
        academies,
        regions,
        academyCodeLookup
    } = useStore(storeSelector, shallow)
    const { fontColor, themeColor } = useCustomTheme()

    const academyGroups = ['Primary', 'Secondary', 'Special']

    const [category, setCategory] = useState('MyFocus')
    const [resultList, setResultList] = useState<string[]>([])
    const [selectedAdemyGroup, setSelectedAcademyGroup] = useState<string>('')
    const [ref, { width }] = useMeasure()
    const [myFocusItems, setMyFocusItems] = useState<string[]>([])

    const [selectedRegion, setSelectedRegion] = useState('')
    const [selectedAcademics, setSelectedAcademics] = useState<Array<AcademyLookupItem>>(
        []
    )
    const [selectedJobFamilies, setSelectedJobFamilies] = useState<Array<string>>([])
    const [selectedGoogleGroups, setSelectedGoogleGroups] = useState<Array<IGoogleGroup>>(
        []
    )

    const [filterOnceApplied, setFilterOnceApplied] = useState(false)

    const accessMyFocus = userHasPermission('access myFocus')

    const userAcademies = academies.filter(acad =>
        zCurrentUser?.user.academyCodes?.includes(acad.academyCode)
    )

    const [filteredAcademics, setFilteredAcademics] =
        useState<Array<AcademyLookupItem>>(academies)

    const menuRef = useRef<HTMLDivElement>(null)
    const [recentFocusItems, setRecentFocusItems] = useState<
        | {
              value: string
              text: string
          }
        | undefined
    >(undefined)

    const [anchorElMyFocus, setAnchorElMyFocus] = useState<null | HTMLElement>(null)
    const openMyFocus = Boolean(anchorElMyFocus)
    const handleClickMyFocus = () => {
        setAnchorElMyFocus(menuRef.current)
    }
    const handleCloseMyFocus = () => {
        setAnchorElMyFocus(null)
    }

    const [anchorElJobFamilies, setanchorElJobFamilies] = useState<null | HTMLElement>(
        null
    )

    const [anchorElGoogleGroups, setanchorElGoogleGroups] = useState<null | HTMLElement>(
        null
    )
    const openJobFamilies = Boolean(anchorElJobFamilies)
    const handleClickGoogleGroups = (event: React.MouseEvent<HTMLElement>) => {
        handleCloseMyFocus()
        setanchorElGoogleGroups(menuRef.current)
    }
    const handleClickJobFamilies = (event: React.MouseEvent<HTMLElement>) => {
        handleCloseMyFocus()
        setanchorElJobFamilies(menuRef.current)
    }
    const handleCloseJobFamilies = () => {
        setanchorElJobFamilies(null)
    }
    const handleCloseGoogleGroups = () => {
        setanchorElGoogleGroups(null)
    }

    const [groupsList, setGroupsList] = useState([])

    const [anchorElAcademyGroups, setanchorElAcademyGroups] =
        useState<null | HTMLElement>(null)
    const openAcademyGroups = Boolean(anchorElAcademyGroups)
    const handleClickAcademyGroups = () => {
        handleCloseMyFocus()
        setanchorElAcademyGroups(menuRef.current)
    }
    const handleCloseAcademyGroups = () => {
        setanchorElAcademyGroups(null)
    }

    const handleCloseClickAcademyGroups = (event: React.MouseEvent<HTMLElement>) => {
        handleCloseMyFocus()
        setanchorElAcademies(menuRef.current)
        handleCloseAcademyGroups()
    }

    const [anchorElAcademies, setanchorElAcademies] = useState<null | HTMLElement>(null)
    const openAcademies = Boolean(anchorElAcademies)
    const handleClickAcademies = (event: React.MouseEvent<HTMLElement>) => {
        handleCloseMyFocus()
        setanchorElAcademies(menuRef.current)
    }
    const handleCloseAcademies = () => {
        setanchorElAcademies(null)
    }

    const [anchorElRegions, setanchorElRegions] = useState<null | HTMLElement>(null)
    const openRegions = Boolean(anchorElRegions)
    const handleClickRegions = (event: React.MouseEvent<HTMLElement>) => {
        handleCloseMyFocus()
        setanchorElRegions(menuRef.current)
    }
    const handleCloseRegions = () => {
        setanchorElRegions(null)
    }

    const [anchorElAcademyPermissions, setanchorElAcademyPermissions] =
        useState<null | HTMLElement>(null)

    const openAcademyPermissions = Boolean(anchorElAcademyPermissions)
    const handleClickAcademyPermissions = (event: React.MouseEvent<HTMLElement>) => {
        setanchorElAcademyPermissions(event.currentTarget)
    }
    const handleCloseAcademyPermissions = () => {
        setanchorElAcademyPermissions(null)
    }

    const handleCategoryChange = (cat: string) => {
        // if (
        //     cat !== 'AllJobFamilies' &&
        //     cat !== 'AllRegions' &&
        //     cat !== 'AllAcademies' &&
        //     cat !== 'AllAcademyGroups' &&
        //     cat !== 'AcademyPermissions'
        // ) {
        //     setMyFocus(cat)
        //     // setMyFocus(cat)
        // }
    }

    const search = debounce(e => {
        const value = e.target?.value

        if (!value) {
            setResultList([])
        } else {
            setResultList(dummySearchResult)
        }
    }, 200)

    // const hanldeSearch = useCallback((event) => {
    //   event.persist()
    //   search(event)
    // }, [])

    const handleDocumentClick = () => {
        setResultList([])
    }

    useEffect(() => {
        window.addEventListener('click', handleDocumentClick)
        return () => {
            window.removeEventListener('click', handleDocumentClick)
        }
    }, [])

    useEffect(() => {
        const menuItems: string[] = []

        jobFamilies.forEach(element => {
            menuItems.push(element)
        })

        regions.forEach(element => {
            menuItems.push(element)
        })

        academies.forEach(element => {
            menuItems.push(element.academyCode)
        })

        setMyFocusItems(menuItems)
    }, [])

    // select doesb't accept fragmen so I splitted the menu itens to functions
    const adminOnly = () => {
        if (accessMyFocus) {
            return (
                <Typography
                    marginLeft={2}
                    fontSize='12px'
                    fontWeight={300}
                    // color={color}
                    lineHeight='15px'
                    mt='5px'
                    color='rgba(0, 0, 0, 0.6)'
                >
                    Filter Posts
                </Typography>
            )
        }
        return null
    }
    const TheMenuItem = !accessMyFocus ? StyledMenuItem : MenuItem

    const jobFamiliesList = () => {
        if (!accessMyFocus && zCurrentUser?.user.jobFamilies?.length === 0) {
            return null
        }
        return (
            <TheMenuItem
                key='AllJobFamilies'
                value='AllJobFamilies'
                aria-controls={openJobFamilies ? 'fade-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openJobFamilies ? 'true' : undefined}
                onClick={!accessMyFocus ? undefined : handleClickJobFamilies}
                sx={{ width }}
                disableRipple={!accessMyFocus}
                disableTouchRipple={!accessMyFocus}
                // disabled={!accessMyFocus}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                        display='flex'
                        justifyContent='space-between'
                        // style={markReadStyles}
                    >
                        <Box>Job Families</Box>
                        <Box />
                        {accessMyFocus ? (
                            <Box display='flex' alignItems='center'>
                                <KeyboardArrowRightIcon
                                    sx={{
                                        fontSize: 20
                                    }}
                                    color='primary'
                                />
                            </Box>
                        ) : null}
                    </Box>
                    <Box>
                        {selectedJobFamilies.length === 0 &&
                            zCurrentUser?.user.jobFamilies &&
                            zCurrentUser?.user.jobFamilies.map(family => (
                                <Typography
                                    fontSize={11}
                                    color={themeColor}
                                    fontWeight='bold'
                                >
                                    {family}
                                </Typography>
                            ))}
                        {selectedJobFamilies.map(family => (
                            <Typography key={family} fontSize={11} color={fontColor}>
                                {family}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </TheMenuItem>
        )
    }

    const googleGroupsList = () => {
        if (!accessMyFocus && zCurrentUser?.user.googleGroups?.length === 0) {
            return null
        }
        return (
            <TheMenuItem
                key='AllGoogleGroups'
                value='AllGoogleGroups'
                aria-controls={openJobFamilies ? 'fade-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openJobFamilies ? 'true' : undefined}
                onClick={!accessMyFocus ? undefined : handleClickGoogleGroups}
                sx={{ width }}
                disableRipple={!accessMyFocus}
                disableTouchRipple={!accessMyFocus}
                // disabled={!accessMyFocus}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                        display='flex'
                        justifyContent='space-between'
                        // style={markReadStyles}
                    >
                        <Box>Google Groups</Box>
                        <Box />
                        {accessMyFocus ? (
                            <Box display='flex' alignItems='center'>
                                <KeyboardArrowRightIcon
                                    sx={{
                                        fontSize: 20
                                    }}
                                    color='primary'
                                />
                            </Box>
                        ) : null}
                    </Box>
                    <Box>
                        {selectedGoogleGroups.length === 0 &&
                            zCurrentUser?.user.googleGroups &&
                            zCurrentUser?.user.googleGroups.map(gg => (
                                <Typography
                                    fontSize={11}
                                    color={themeColor}
                                    fontWeight='bold'
                                >
                                    {`${gg.name} ${gg.email}`}
                                </Typography>
                            ))}
                        {selectedGoogleGroups.map(group => (
                            <Typography fontSize={11} color={fontColor}>
                                {group.name}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </TheMenuItem>
        )
    }

    const regionsList = () => {
        return (
            <TheMenuItem
                key='AllRegions'
                value='AllRegions'
                aria-controls={openRegions ? 'fade-menu1' : undefined}
                aria-haspopup='true'
                aria-expanded={openRegions ? 'true' : undefined}
                onClick={!accessMyFocus ? undefined : handleClickRegions}
                disableRipple={!accessMyFocus}
                disableTouchRipple={!accessMyFocus}
                sx={{ width }}
                // disabled={!accessMyFocus}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                        display='flex'
                        justifyContent='space-between'
                        // style={markReadStyles}
                    >
                        <Box>Region</Box>
                        <Box />
                        {accessMyFocus ? (
                            <Box display='flex' alignItems='center'>
                                <KeyboardArrowRightIcon
                                    sx={{
                                        fontSize: 20
                                    }}
                                    color='primary'
                                />
                            </Box>
                        ) : null}
                    </Box>
                    <Box>
                        {selectedRegion === '' ? (
                            <Typography
                                fontSize={11}
                                // color={themeColor}
                                fontWeight='bold'
                            >
                                {zCurrentUser?.user.regions &&
                                zCurrentUser?.user.regions?.length > 0
                                    ? zCurrentUser?.user.regions[0]
                                    : selectedRegion}
                            </Typography>
                        ) : (
                            <Typography fontSize={11} color={fontColor}>
                                {selectedRegion}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </TheMenuItem>
        )
    }
    const academiesList = () => {
        return (
            <TheMenuItem
                key='AllAcademyGroups'
                value='AllAcademyGroups'
                aria-controls={openAcademyGroups ? 'fade-menu2' : undefined}
                aria-haspopup='true'
                aria-expanded={openAcademyGroups ? 'true' : undefined}
                onClick={!accessMyFocus ? undefined : handleClickAcademyGroups}
                // disabled={!accessMyFocus}
                disableRipple={!accessMyFocus}
                disableTouchRipple={!accessMyFocus}
                sx={{ width }}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                        display='flex'
                        justifyContent='space-between'
                        // style={markReadStyles}
                    >
                        <Box>Academies</Box>
                        <Box />
                        {accessMyFocus ? (
                            <Box display='flex' alignItems='center'>
                                <KeyboardArrowRightIcon
                                    sx={{
                                        fontSize: 20
                                    }}
                                    color='primary'
                                />
                            </Box>
                        ) : null}
                    </Box>
                    <Box>
                        {selectedAcademics.length === 0 &&
                            userAcademies.map(academic => (
                                <Typography
                                    fontSize={11}
                                    // color={themeColor}
                                    fontWeight='bold'
                                    key={academic.academyCode}
                                >
                                    {academic.academy}
                                </Typography>
                            ))}
                        {selectedAcademics.map(academic => (
                            <Typography
                                key={academic.academyCode}
                                fontSize={11}
                                color={fontColor}
                            >
                                {academic.academy}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </TheMenuItem>
        )
    }
    const checkPerm5 = () => {
        if (
            zCurrentUser?.userPerAcademyPermissions[keyUserPerAcademyPermissions] &&
            zCurrentUser.userPerAcademyPermissions[keyUserPerAcademyPermissions].length >
                0
        ) {
            return (
                <Typography
                    marginLeft={2}
                    fontSize='12px'
                    fontWeight={300}
                    // color={color}
                    lineHeight='15px'
                    mt='5px'
                    color='rgba(0, 0, 0, 0.6)'
                >
                    ACADEMY PERMISSIONS
                </Typography>
            )
        }
        return null
    }
    const checkPerm6 = () => {
        if (
            zCurrentUser?.userPerAcademyPermissions[keyUserPerAcademyPermissions] &&
            zCurrentUser.userPerAcademyPermissions[keyUserPerAcademyPermissions].length >
                0
        ) {
            return (
                <MenuItem
                    key='AcademyPermissions'
                    value='AcademyPermissions'
                    aria-controls={openAcademyPermissions ? 'fade-menu4' : undefined}
                    aria-haspopup='true'
                    aria-expanded={openAcademyPermissions ? 'true' : undefined}
                    onClick={handleClickAcademyPermissions}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                        display='flex'
                        justifyContent='space-between'
                        // style={markReadStyles}
                    >
                        <Box>Academy Permissions</Box>
                        <Box />
                        <Box>
                            <KeyboardArrowRightIcon
                                sx={{
                                    fontSize: 20
                                }}
                                color='primary'
                            />
                        </Box>
                    </Box>
                </MenuItem>
            )
        }
        return null
    }

    const checkPerm7 = () => {
        if (accessMyFocus) {
            return (
                <MenuItem
                    key='AllAcademies'
                    value='AllAcademies'
                    aria-controls={openAcademies ? 'fade-menu3' : undefined}
                    aria-haspopup='true'
                    aria-expanded={openAcademies ? 'true' : undefined}
                    onClick={handleClickAcademies}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                        display='flex'
                        justifyContent='space-between'
                        // style={markReadStyles}
                    >
                        <Box>All Academies</Box>
                        <Box />
                        <Box>
                            <KeyboardArrowRightIcon
                                sx={{
                                    fontSize: 20
                                }}
                                color='primary'
                            />
                        </Box>
                    </Box>
                </MenuItem>
            )
        }
        return null
    }

    const focusedItem = () => {
        // if (userHasPermission('access myFocus') && recentFocusItems) {
        //     return (
        //         <MenuItem key={recentFocusItems.value} value={recentFocusItems.value}>
        //             {selectedMenuItem(
        //                 myFocus === recentFocusItems.value,
        //                 recentFocusItems.text
        //             )}
        //         </MenuItem>
        //     )
        // }
        return null
    }

    const dividerItem = () => {
        if (accessMyFocus) {
            return <Divider />
        }
        return null
    }

    const selectedMenuItem = (isLink: boolean, value: string) => (
        <div>
            {/* {isLink ? (
                <CircleIcon
                    sx={{
                        fontSize: 10,
                        color: 'black',
                        mr: '5px'
                    }}
                />
            ) : null} */}
            {/* {isLink ? (
                <Typography
                    fontSize='20px'
                    fontWeight={600}
                    // color={color}
                    lineHeight='15px'
                    mt='5px'
                >
                    *&nbsp;
                </Typography>
            ) : null} */}

            {value}
        </div>
    )

    const checkIfAnyFilterApplied = () => {
        if (selectedRegion !== '') {
            return true
        }

        if (selectedAcademics.length > 0) {
            return true
        }

        if (selectedJobFamilies.length > 0) {
            return true
        }

        return false
    }

    const clearFilters = () => {
        setSelectedRegion('')
        setSelectedAcademics([])
        setSelectedJobFamilies([])
        setSelectedGoogleGroups([])
        setMyFocusFilter({
            jobFamilies: [],
            academics: [],
            region: '',
            googleGroups: []
        })
    }

    useEffect(() => {
        let filtered: AcademyLookupItem[] = []
        if (selectedRegion !== '' && selectedAdemyGroup !== '') {
            filtered = academies.filter(
                academy =>
                    academy.phase === selectedAdemyGroup &&
                    academy.region === selectedRegion
            )
        } else if (selectedRegion !== '' && selectedAdemyGroup === '') {
            filtered = academies.filter(academy => academy.region === selectedRegion)
        } else if (selectedRegion === '' && selectedAdemyGroup !== '') {
            filtered = academies.filter(academy => academy.phase === selectedAdemyGroup)
        }
        setFilteredAcademics(filtered)
    }, [selectedRegion, selectedAdemyGroup])

    return (
        <Box flex='2 1 0' ml='25px' ref={ref}>
            <Box key={1} position='relative' ref={menuRef}>
                <Select
                    variant='outlined'
                    value=''
                    fullWidth
                    sx={{
                        height: 50,
                        borderRadius: 300,
                        fontSize: '16px',
                        backgroundColor: '#fff'
                    }}
                />
                <Box
                    sx={{
                        height: 50,
                        position: 'absolute',
                        top: '25%',
                        left: 0,
                        width,
                        cursor: 'pointer'
                    }}
                    onClick={handleClickMyFocus}
                >
                    <Typography sx={{ px: 2.5 }} fontSize={16}>
                        {selectedRegion === '' &&
                        selectedAcademics.length === 0 &&
                        selectedJobFamilies.length === 0
                            ? 'My focus'
                            : 'Multiple Filters'}
                    </Typography>
                </Box>
            </Box>
            <Menu
                // variant='outlined'
                // onChange={event => {
                //     // handleCategoryChange(event.target.value)
                // }}
                // ref={menuRef}
                key={2}
                anchorEl={anchorElMyFocus}
                open={openMyFocus}
                onClose={handleCloseMyFocus}
                TransitionComponent={Fade}
            >
                {/* <MenuItem key='All' value='All' sx={{ width }}>
                    &nbsp;&nbsp;My focus
                </MenuItem> */}
                {/* <MenuItem key='All' value='All'>
                    {selectedMenuItem(myFocus === 'All', 'Include all')}
                </MenuItem> */}

                {adminOnly()}
                {regionsList()}
                {academiesList()}
                {jobFamiliesList()}
                {googleGroupsList()}

                {/* {checkPerm5()}
                {checkPerm6()}
                {checkPerm7()} */}
                {/* {focusedItem()} */}
                {/* {dividerItem()} */}

                {/* {zCurrentUser?.user?.jobFamilies &&
                zCurrentUser?.user?.jobFamilies?.length > 0 ? (
                    <Typography
                        marginLeft={2}
                        fontSize='12px'
                        fontWeight={300}
                        // color={color}
                        lineHeight='15px'
                        mt='5px'
                        color='rgba(0, 0, 0, 0.6)'
                    >
                        YOUR JOB FAMILIES
                    </Typography>
                ) : null}

                {zCurrentUser?.user?.jobFamilies?.map(option => (
                    <StyledMenuItem
                        key={option}
                        value={`jobfamilies;${option}`}
                        disableRipple
                        disableTouchRipple
                    >
                        {selectedMenuItem(false, option)}
                    </StyledMenuItem>
                ))} */}

                {/* {zCurrentUser?.user?.jobFamilies &&
                zCurrentUser?.user?.jobFamilies?.length > 0 ? (
                    <Divider />
                ) : null} */}

                {/* <Typography
                    marginLeft={2}
                    fontSize='12px'
                    fontWeight={300}
                    // color={color}
                    lineHeight='15px'
                    mt='5px'
                    color='rgba(0, 0, 0, 0.6)'
                >
                    YOUR REGIONS
                </Typography>
                {zCurrentUser?.user?.regions?.map(option => (
                    <StyledMenuItem
                        key={option}
                        value={`region;${option}`}
                        sx={{ width }}
                        disableRipple
                        disableTouchRipple
                    >
                        {selectedMenuItem(false, option)}
                    </StyledMenuItem>
                ))} */}

                {/* {zCurrentUser?.user?.academyPhases &&
                zCurrentUser?.user?.academyPhases.filter(x => x !== '').length > 0 ? (
                    <div>
                        <Divider />
                        <Typography
                            marginLeft={2}
                            fontSize='12px'
                            fontWeight={300}
                            // color={color}
                            lineHeight='15px'
                            mt='5px'
                            color='rgba(0, 0, 0, 0.6)'
                        >
                            Academy Groups
                        </Typography>
                    </div>
                ) : null}

                {zCurrentUser?.user?.academyPhases?.map(option => {
                    return option !== '' ? (
                        <MenuItem key={option} value={`academyGroups;${option}`}>
                            {selectedMenuItem(
                                myFocus === `academyGroups;${option}`,
                                option
                            )}
                        </MenuItem>
                    ) : null
                })}
                <Divider /> */}

                {/* <Typography
                    marginLeft={2}
                    fontSize='12px'
                    fontWeight={300}
                    // color={color}
                    lineHeight='15px'
                    mt='5px'
                    color='rgba(0, 0, 0, 0.6)'
                >
                    YOUR ACADEMIES
                </Typography>
                {zCurrentUser?.user?.academyCodes?.map(option => (
                    <StyledMenuItem
                        key={option}
                        value={`academy;${option}`}
                        sx={{ width }}
                        disableRipple
                        disableTouchRipple
                    >
                        {selectedMenuItem(
                            false,
                            academyCodeLookup[option]?.academy || ''
                        )}
                    </StyledMenuItem>
                ))} */}
                <Divider />
                <Typography
                    marginLeft={2}
                    fontSize='12px'
                    fontWeight={300}
                    // color={color}
                    lineHeight='15px'
                    mt='5px'
                    color='rgba(0, 0, 0, 0.6)'
                    my={2}
                    mr={2}
                >
                    {zCurrentUser?.user?.email}
                </Typography>

                {accessMyFocus ? <Divider /> : null}
                {accessMyFocus ? (
                    <StyledMenuItem
                        key='action'
                        sx={{ width }}
                        disableRipple
                        disableTouchRipple
                    >
                        <Box>
                            <Button
                                sx={{ mr: 2 }}
                                onClick={() => {
                                    clearFilters()
                                    handleCloseMyFocus()
                                }}
                                variant='outlined'
                            >
                                Clear Filters
                            </Button>
                            <Button
                                onClick={() => {
                                    handleCloseMyFocus()
                                    const userJobFamilies =
                                        zCurrentUser?.user.jobFamilies ?? []
                                    const userGoogleGroups =
                                        zCurrentUser?.user.googleGroups ?? []
                                    /* setMyFocusFilter({
                                        jobFamilies: [
                                            ...userJobFamilies,
                                            ...selectedJobFamilies
                                        ],
                                        academics: [
                                            ...userAcademies,
                                            ...selectedAcademics
                                        ],
                                        region:
                                            selectedRegion === ''
                                                ? zCurrentUser?.user.regions &&
                                                  zCurrentUser?.user.regions?.length > 0
                                                    ? zCurrentUser?.user.regions[0]
                                                    : selectedRegion
                                                : selectedRegion
                                    }) */

                                    setMyFocusFilter({
                                        googleGroups: (selectedGoogleGroups.length > 0
                                            ? [...selectedGoogleGroups]
                                            : [...userGoogleGroups]
                                        ).map(g => g.id),
                                        jobFamilies:
                                            selectedJobFamilies.length > 0
                                                ? [...selectedJobFamilies]
                                                : [...userJobFamilies],
                                        academics:
                                            selectedAcademics.length > 0
                                                ? [...selectedAcademics]
                                                : [...userAcademies],
                                        region:
                                            selectedRegion === ''
                                                ? zCurrentUser?.user.regions &&
                                                  zCurrentUser?.user.regions?.length > 0
                                                    ? zCurrentUser?.user.regions[0]
                                                    : selectedRegion
                                                : selectedRegion
                                    })
                                }}
                                // disabled={
                                //     selectedRegion === '' &&
                                //     selectedAcademics.length === 0 &&
                                //     selectedJobFamilies.length === 0 &&
                                //     !filterOnceApplied
                                // }
                                variant='contained'
                            >
                                Apply Filters
                            </Button>
                        </Box>
                    </StyledMenuItem>
                ) : null}
            </Menu>
            {/*  Job Families */}
            <Menu
                key={3}
                id='fade-menu'
                MenuListProps={{
                    'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorElJobFamilies}
                open={openJobFamilies}
                onClose={handleCloseJobFamilies}
                TransitionComponent={Fade}
            >
                <StyledMenuItem key='disabled'>
                    <Box display='flex' justifyContent='center' alignItems='center'>
                        <IconButton
                            onClick={() => {
                                handleCloseJobFamilies()
                                handleClickMyFocus()
                            }}
                        >
                            <ArrowBackRoundedIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                        <Typography key={1} fontSize={12} sx={{ opacity: 0.5 }}>
                            JOB FAMILIES
                        </Typography>
                        <Typography
                            key={2}
                            fontSize={10}
                            // sx={{ color: themeColor }}
                        >
                            (Max 10 selections)
                        </Typography>
                    </Box>
                </StyledMenuItem>
                {selectedJobFamilies.length > 0 ? (
                    <StyledMenuItem key='action'>
                        <Button
                            onClick={() => {
                                handleCloseJobFamilies()
                                handleClickMyFocus()
                            }}
                            variant='contained'
                            disabled={selectedJobFamilies.length === 0}
                        >
                            Apply Selections
                        </Button>
                        <Button
                            onClick={() => {
                                setSelectedJobFamilies([])
                                handleCloseJobFamilies()
                                handleClickMyFocus()
                            }}
                            variant='outlined'
                            disabled={selectedJobFamilies.length === 0}
                            sx={{ ml: 1 }}
                        >
                            Clear
                        </Button>
                    </StyledMenuItem>
                ) : null}
                {jobFamilies.map(JobFamilyItem => (
                    <MenuItem
                        key={JobFamilyItem}
                        value={JobFamilyItem}
                        selected={selectedJobFamilies.includes(JobFamilyItem)}
                        sx={{
                            color: zCurrentUser?.user.jobFamilies?.includes(JobFamilyItem)
                                ? themeColor
                                : fontColor,
                            fontWeight: zCurrentUser?.user.jobFamilies?.includes(
                                JobFamilyItem
                            )
                                ? 'bold'
                                : 'normal'
                        }}
                        onClick={() => {
                            // if (
                            //     zCurrentUser?.user?.jobFamilies &&
                            //     zCurrentUser?.user?.jobFamilies?.indexOf(
                            //         JobFamilyItem,
                            //         0
                            //     ) < 0 &&
                            //     recentFocusItems?.value !== JobFamilyItem
                            // ) {
                            //     setRecentFocusItems({
                            //         value: `jobfamilies;${JobFamilyItem}`,
                            //         text: JobFamilyItem
                            //     })
                            // }
                            // setMyFocus(`jobfamilies;${JobFamilyItem}`)

                            // Allow selection to only job families which aren't included with user

                            if (
                                selectedJobFamilies.filter(item => item === JobFamilyItem)
                                    .length === 0
                            ) {
                                if (selectedJobFamilies.length < 10) {
                                    setSelectedJobFamilies([
                                        ...new Set([
                                            ...selectedJobFamilies,
                                            JobFamilyItem
                                        ])
                                    ])
                                }
                            } else {
                                const filtered = selectedJobFamilies.filter(
                                    item => item !== JobFamilyItem
                                )
                                setSelectedJobFamilies([...new Set([...filtered])])
                            }

                            setFilterOnceApplied(true)

                            // setCategory(`jobfamilies;${JobFamilyItem}`)
                            // handleCloseJobFamilies()
                            // handleClickMyFocus()
                        }}
                    >
                        {JobFamilyItem}
                    </MenuItem>
                ))}
            </Menu>
            {/*  end Job Families */}
            {/*  Google Groups */}
            <Menu
                key={4}
                id='fade-menu-0'
                MenuListProps={{
                    'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorElGoogleGroups}
                open={Boolean(anchorElGoogleGroups)}
                onClose={handleCloseGoogleGroups}
                TransitionComponent={Fade}
            >
                <StyledMenuItem key='disabled'>
                    <Box display='flex' justifyContent='center' alignItems='center'>
                        <IconButton
                            onClick={() => {
                                handleCloseGoogleGroups()
                                handleClickMyFocus()
                            }}
                        >
                            <ArrowBackRoundedIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                        <Typography key={1} fontSize={12} sx={{ opacity: 0.5 }}>
                            GOOGLE GROUPS
                        </Typography>
                        <Typography key={2} fontSize={10} sx={{ color: themeColor }}>
                            (Max 10 selections)
                        </Typography>
                    </Box>
                </StyledMenuItem>
                {selectedGoogleGroups.length > 0 ? (
                    <StyledMenuItem key='action'>
                        <Button
                            onClick={() => {
                                handleCloseGoogleGroups()
                                handleClickMyFocus()
                            }}
                            variant='contained'
                            disabled={selectedGoogleGroups.length === 0}
                        >
                            Apply Selections
                        </Button>
                        <Button
                            onClick={() => {
                                setSelectedGoogleGroups([])
                                handleCloseGoogleGroups()
                                handleClickMyFocus()
                            }}
                            variant='outlined'
                            disabled={selectedGoogleGroups.length === 0}
                            sx={{ ml: 1 }}
                        >
                            Clear
                        </Button>
                    </StyledMenuItem>
                ) : null}
                <ChipAutoComplete
                    options={groupsList}
                    value={selectedGoogleGroups}
                    onChange={async (event: SyntheticEvent, value: any) => {
                        console.log(value)
                        setSelectedGoogleGroups(value)
                    }}
                    onInputChange={async (event: SyntheticEvent, value: any) => {
                        console.log(value)
                        const options: Array<never> = (await queryGroups(
                            value
                        )) as Array<never>
                        setGroupsList(options)
                    }}
                />
                {/*                 {jobFamilies.map(JobFamilyItem => (
                    <MenuItem
                        key={JobFamilyItem}
                        value={JobFamilyItem}
                        selected={selectedJobFamilies.includes(JobFamilyItem)}
                        sx={{
                            color: zCurrentUser?.user.jobFamilies?.includes(JobFamilyItem)
                                ? themeColor
                                : fontColor,
                            fontWeight: zCurrentUser?.user.jobFamilies?.includes(
                                JobFamilyItem
                            )
                                ? 'bold'
                                : 'normal'
                        }}
                        onClick={() => {
                            // if (
                            //     zCurrentUser?.user?.jobFamilies &&
                            //     zCurrentUser?.user?.jobFamilies?.indexOf(
                            //         JobFamilyItem,
                            //         0
                            //     ) < 0 &&
                            //     recentFocusItems?.value !== JobFamilyItem
                            // ) {
                            //     setRecentFocusItems({
                            //         value: `jobfamilies;${JobFamilyItem}`,
                            //         text: JobFamilyItem
                            //     })
                            // }
                            // setMyFocus(`jobfamilies;${JobFamilyItem}`)

                            // Allow selection to only job families which aren't included with user

                            if (
                                selectedJobFamilies.filter(item => item === JobFamilyItem)
                                    .length === 0
                            ) {
                                if (selectedJobFamilies.length < 10) {
                                    setSelectedJobFamilies([
                                        ...new Set([
                                            ...selectedJobFamilies,
                                            JobFamilyItem
                                        ])
                                    ])
                                }
                            } else {
                                const filtered = selectedJobFamilies.filter(
                                    item => item !== JobFamilyItem
                                )
                                setSelectedJobFamilies([...new Set([...filtered])])
                            }

                            setFilterOnceApplied(true)

                            // setCategory(`jobfamilies;${JobFamilyItem}`)
                            // handleCloseJobFamilies()
                            // handleClickMyFocus()
                        }}
                    >
                        {JobFamilyItem}
                    </MenuItem>
                ))} */}
            </Menu>
            {/*  end Google Groups */}
            <Menu
                key={5}
                id='fade-menu1'
                MenuListProps={{
                    'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorElRegions}
                open={openRegions}
                onClose={handleCloseRegions}
                TransitionComponent={Fade}
            >
                <StyledMenuItem key='disabled'>
                    <Box display='flex' justifyContent='center' alignItems='center'>
                        <IconButton
                            onClick={() => {
                                handleCloseRegions()
                                handleClickMyFocus()
                            }}
                        >
                            <ArrowBackRoundedIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                        <Typography fontSize={12} sx={{ opacity: 0.5 }}>
                            REGIONS
                        </Typography>
                    </Box>
                </StyledMenuItem>

                {regions.map(regionyItem => (
                    <MenuItem
                        key={regionyItem}
                        value={regionyItem}
                        sx={{
                            width,
                            color: zCurrentUser?.user.regions?.includes(regionyItem)
                                ? themeColor
                                : fontColor,
                            fontWeight: zCurrentUser?.user.regions?.includes(regionyItem)
                                ? 'bold'
                                : 'normal'
                        }}
                        selected={selectedRegion === regionyItem}
                        onClick={() => {
                            // if (
                            //     zCurrentUser?.user?.regions &&
                            //     zCurrentUser?.user?.regions?.indexOf(regionyItem, 0) <
                            //         0 &&
                            //     recentFocusItems?.value !== regionyItem
                            // ) {
                            //     setRecentFocusItems({
                            //         value: `region;${regionyItem}`,
                            //         text: regionyItem
                            //     })
                            // }
                            // setMyFocus(`region;${regionyItem}`)

                            // Allow selection of Region which in not of user's
                            // if (!zCurrentUser?.user.regions?.includes(regionyItem)) {
                            if (selectedRegion === regionyItem) {
                                setSelectedRegion('')
                            } else {
                                setSelectedRegion(regionyItem)
                            }

                            // setCategory(`region;${regionyItem}`)
                            handleCloseRegions()

                            handleClickMyFocus()

                            setFilterOnceApplied(true)
                            // }
                        }}
                    >
                        {regionyItem}
                    </MenuItem>
                ))}
            </Menu>
            <Menu
                key={6}
                id='fade-menu2'
                MenuListProps={{
                    'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorElAcademyGroups}
                open={openAcademyGroups}
                onClose={handleCloseAcademyGroups}
                TransitionComponent={Fade}
            >
                <StyledMenuItem key='disabled'>
                    <Box display='flex' justifyContent='center' alignItems='center'>
                        <IconButton
                            onClick={() => {
                                handleCloseAcademyGroups()
                                handleClickMyFocus()
                            }}
                        >
                            <ArrowBackRoundedIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                        <Typography fontSize={12} sx={{ opacity: 0.5 }}>
                            ACADEMICS
                        </Typography>
                    </Box>
                </StyledMenuItem>

                {academyGroups.map(academyGroupItem => (
                    <MenuItem
                        sx={{ width }}
                        key={academyGroupItem}
                        value={academyGroupItem}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            // if (
                            //     zCurrentUser?.user?.academyPhases &&
                            //     zCurrentUser?.user?.academyPhases?.indexOf(
                            //         academyGroupItem,
                            //         0
                            //     ) < 0 &&
                            //     recentFocusItems?.value !== academyGroupItem
                            // ) {
                            //     setRecentFocusItems({
                            //         value: `academyGroups;${academyGroupItem}`,
                            //         text: academyGroupItem
                            //     })
                            // }
                            // setMyFocus(`academyGroups;${academyGroupItem}`)
                            // setCategory(`region;${regionyItem}`)
                            setSelectedAcademyGroup(academyGroupItem)
                            handleCloseClickAcademyGroups(event)
                        }}
                    >
                        {academyGroupItem}
                    </MenuItem>
                ))}
            </Menu>
            <Menu
                key={7}
                id='fade-menu3'
                MenuListProps={{
                    'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorElAcademies}
                open={openAcademies}
                onClose={handleCloseAcademies}
                TransitionComponent={Fade}
            >
                <StyledMenuItem key='disabled'>
                    <Box display='flex' justifyContent='center' alignItems='center'>
                        <IconButton
                            onClick={() => {
                                handleCloseAcademies()
                                handleClickAcademyGroups()
                            }}
                        >
                            <ArrowBackRoundedIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                        <Typography key={1} fontSize={12} sx={{ opacity: 0.5 }}>
                            {selectedAdemyGroup}
                            {selectedRegion !== '' ? `(${selectedRegion})` : ''}
                        </Typography>
                        <Typography key={2} fontSize={10} sx={{ color: themeColor }}>
                            (Max 10 selections)
                        </Typography>
                    </Box>
                </StyledMenuItem>
                {filteredAcademics.length > 0 ? (
                    <StyledMenuItem key='action'>
                        <Button
                            onClick={() => {
                                handleCloseAcademies()
                                handleClickMyFocus()
                            }}
                            variant='contained'
                            disabled={selectedAcademics.length === 0}
                        >
                            Apply Selections
                        </Button>
                        <Button
                            onClick={() => {
                                setSelectedAcademics([])
                                handleCloseAcademies()
                                handleClickMyFocus()
                            }}
                            variant='outlined'
                            disabled={selectedAcademics.length === 0}
                            sx={{ ml: 1 }}
                        >
                            Clear
                        </Button>
                    </StyledMenuItem>
                ) : null}
                {filteredAcademics.length === 0 ? (
                    <MenuItem disabled>
                        <Typography>No academics for the selected Region</Typography>
                    </MenuItem>
                ) : (
                    filteredAcademics.map(_academy => {
                        const filtered = selectedAcademics.filter(
                            academy => academy.academyCode === _academy.academyCode
                        )
                        const defaultSelected = zCurrentUser?.user.academyCodes?.includes(
                            _academy.academyCode
                        )
                        const selected = filtered.length > 0
                        return (
                            <MenuItem
                                key={_academy.academyCode}
                                value={_academy.academyCode}
                                sx={{
                                    width,
                                    color: defaultSelected ? themeColor : fontColor,
                                    fontWeight: defaultSelected ? 'bold' : 'normal'
                                }}
                                selected={selected}
                                onClick={() => {
                                    // if (
                                    //     zCurrentUser?.user?.academyCodes &&
                                    //     zCurrentUser?.user?.academyCodes?.indexOf(
                                    //         _academy.academyCode,
                                    //         0
                                    //     ) < 0 &&
                                    //     recentFocusItems?.value !== _academy.academyCode
                                    // ) {
                                    //     setRecentFocusItems({
                                    //         value: `academy;${_academy.academyCode}`,
                                    //         text:
                                    //             academyCodeLookup[_academy.academyCode]
                                    //                 ?.academy || ''
                                    //     })
                                    // }
                                    // setMyFocus(`academy;${_academy.academyCode}`)

                                    // setCategory(`academy;${_academy.academyCode}`)

                                    // handleCloseAcademies()

                                    // Allow selection of academies not related to loggedin user
                                    // if (
                                    //     !zCurrentUser?.user.academyCodes?.includes(
                                    //         _academy.academyCode
                                    //     )
                                    // ) {
                                    if (
                                        selectedAcademics.filter(
                                            acad =>
                                                acad.academyCode === _academy.academyCode
                                        ).length === 0
                                    ) {
                                        if (selectedAcademics.length < 10) {
                                            setSelectedAcademics([
                                                ...new Set([
                                                    ...selectedAcademics,
                                                    _academy
                                                ])
                                            ])
                                        }
                                    } else {
                                        const filtered = selectedAcademics.filter(
                                            acad =>
                                                acad.academyCode !== _academy.academyCode
                                        )
                                        setSelectedAcademics([...new Set([...filtered])])
                                    }

                                    setFilterOnceApplied(true)
                                    // }
                                }}
                            >
                                {_academy.academy}
                            </MenuItem>
                        )
                    })
                )}
            </Menu>
            <Menu
                key={8}
                id='fade-menu4'
                MenuListProps={{
                    'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorElAcademyPermissions}
                open={openAcademyPermissions}
                onClose={handleCloseAcademyPermissions}
                TransitionComponent={Fade}
                sx={{ width }}
            >
                {zCurrentUser?.userPerAcademyPermissions[
                    keyUserPerAcademyPermissions
                ]?.map(_academy => (
                    <MenuItem
                        key={_academy}
                        value={_academy}
                        onClick={() => {
                            if (
                                zCurrentUser?.user?.academyCodes &&
                                zCurrentUser?.user?.academyCodes?.indexOf(_academy, 0) <
                                    0 &&
                                recentFocusItems?.value !== _academy
                            ) {
                                setRecentFocusItems({
                                    value: `academy;${_academy}`,
                                    text: academyCodeLookup[_academy]?.academy || ''
                                })
                            }
                            // setMyFocus(`academy;${_academy}`)

                            // setCategory(`academy;${_academy.academyCode}`)
                            handleCloseAcademyPermissions()

                            setFilterOnceApplied(true)
                        }}
                    >
                        {academyCodeLookup[_academy]?.academy || ''}
                    </MenuItem>
                ))}
            </Menu>
            {!!resultList.length && (
                <SearchResultCard key={9} elevation={2}>
                    {resultList.map(item => (
                        <MenuItem key={item}>{item}</MenuItem>
                    ))}
                </SearchResultCard>
            )}
        </Box>
    )
}

const categories = [
    'My focus',
    'School1',
    'School2',
    'School3',
    'School4',
    'School5',
    'School6',
    'School7'
]

const dummySearchResult = [
    'Search Result1',
    'Search Result2',
    'Search Result3',
    'Search Result4'
]

export default MyFocus
