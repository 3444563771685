/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/NotInterested'
import { Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { StyledMenu } from 'components/navbar/StyledMenu'
import { useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { Store, useStore } from '../../appData/store'

const navBarStoreSelector = (store: Store) => ({
    settings: store.settings,
    toggleSingleColorNav: store.toggleSingleColorNav,
    toggleEditingMenu: store.toggleEditingMenu,
    userHasPermission: store.userHasPermission
})

export const SettingsMenu = ({
    register
}: {
    register: (triggerFunc: (e: React.MouseEvent) => void) => void
}) => {
    const { settings, toggleSingleColorNav, toggleEditingMenu, userHasPermission } =
        useStore(navBarStoreSelector, shallow)

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number
        mouseY: number
    } | null>(null)

    useEffect(() => {
        const handleContextMenu = (event: React.MouseEvent) => {
            event.preventDefault()
            setContextMenu(
                contextMenu === null
                    ? {
                          mouseX: event.clientX + 2,
                          mouseY: event.clientY - 6
                      }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                      // Other native context menus might behave different.
                      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                      null
            )
        }
        register(handleContextMenu)
    }, [contextMenu, register])

    const handleCloseContextMenu = () => {
        setContextMenu(null)
    }
    // ----------------------

    const userMayEditMenu = userHasPermission('edit groups')
    return (
        <StyledMenu
            open={contextMenu !== null}
            onClose={handleCloseContextMenu}
            anchorReference='anchorPosition'
            anchorPosition={
                contextMenu !== null
                    ? {
                          top: contextMenu.mouseY,
                          left: contextMenu.mouseX
                      }
                    : undefined
            }
            sx={{ zIndex: 10050 }}
        >
            <MenuItem onClick={toggleSingleColorNav}>
                <ListItemIcon>
                    {settings?.singleColorNav && <CheckIcon fontSize='small' />}
                </ListItemIcon>
                <ListItemText>Single Nav colour</ListItemText>
            </MenuItem>
            {userMayEditMenu && (
                <MenuItem onClick={toggleEditingMenu}>
                    <ListItemIcon>
                        {settings?.editingMenu && <CheckIcon fontSize='small' />}
                    </ListItemIcon>
                    <ListItemText>Edit menu</ListItemText>
                </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={handleCloseContextMenu}>
                <ListItemIcon>
                    <CloseIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Close</ListItemText>
            </MenuItem>
        </StyledMenu>
    )
}
