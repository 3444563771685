import { alpha, OutlinedTextFieldProps, styled, TextField } from '@mui/material'

interface NavBarEditProps extends OutlinedTextFieldProps {
    altColor?: boolean
    allBlue?: boolean
}
export const NavBarEdit = styled(TextField, {
    shouldForwardProp: prop => {
        const include = !['altColor', 'allBlue'].includes(prop.toString())
        return include
    }
})<NavBarEditProps>(({ theme, altColor, allBlue }) => {
    const textColor = altColor ? theme.palette.common.white : theme.palette.primary.main
    const labelColor = alpha(textColor, 0.56)

    return {
        '& .MuiOutlinedInput-notchedOutline': {
            // border: `1px solid ${textColor} !important`
        },
        '& .MuiInputLabel-formControl': {
            color: `${labelColor} !important`
        },
        '& .MuiInputBase-input': {
            color: `${textColor} !important`
        },
        '& .MuiFormHelperText-root.Mui-error': {
            ...(allBlue ? { color: '#ffffff' } : {})
        },
        '& .MuiFormLabel-root': {
            color: '#ffffff !important'
        },
        '& fieldset': {
            border: '1px solid #ffffff60 !important'
        },
        '&:hover': {
            'fieldset': {
                borderColor: '#ffffff !important'
            },
            '.MuiFormLabel-root': {
                color: '#ffffff !important'
            }
        },
        '& .MuiButtonBase-root': {
            color: '#ffffff'
        }
    }
})
