import { useEffect } from 'react'
import { createPortal } from 'react-dom'

export type NavPortalType = 'nav-close' | 'floating-links-homepage'

export const NavPortal = ({
    target,
    children
}: {
    target: NavPortalType
    children: React.ReactNode
}) => {
    const targetId = `${target}-portal-root`
    const portalRoot = document.getElementById(targetId)
    const el = document.createElement('div')

    useEffect(() => {
        portalRoot?.appendChild(el)
        return () => {
            portalRoot?.removeChild(el)
        }
    }, [el, portalRoot])

    return createPortal(children, el)
}
