/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-eval */
/* eslint-disable func-names */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/extensions */

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { styled, Theme, useTheme } from '@mui/material/styles'

import 'pages/FroalaScript/FroalaEditorCustomScripts'
import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import FroalaEditor from 'react-froala-wysiwyg'

import CircleIcon from '@mui/icons-material/Circle'

// Include special components if required.
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Link,
    ListItemText,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material'
import NewsTile from 'components/tiles/newsTile/NewsTile'
import ListPost from 'pages/ListPostPage/ListPost'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ReactComponent as AbsoluteHeader } from 'assets/absolute.svg'
import { ReactComponent as StaticHeader } from 'assets/static.svg'
import { queryGroups } from 'components/autocomplete/aetGroups'
import ChipAutoComplete from 'components/autocomplete/ChipAutoComplete'
import Placeholder from 'components/utils/SelectPlaceholder'
import { ErrorNotification, SuccessNotification } from 'components/utils/toastify'
import dayjs, { Dayjs } from 'dayjs'
import { Timestamp } from 'firebase/firestore'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { addPost, getPost, getPostStatsMore, updatePost } from 'service/controller/Post'
import {
    HeaderTypeOptions,
    LinksColourOptions,
    PostAcademyGroupOptions,
    PostModel,
    PostPriorityOptions,
    PostStatsModel,
    PostStatusOptions,
    PostTypeOptions,
    PostVisibilityOptions,
    QueryTypeOptions,
    TileSizeOptions,
    WidgetOptions
} from 'service/model/Post'
import { AcademiesLookupSingletonModel } from 'service/model/SingletonsModel'
import { keyUserPerAcademyPermissions } from 'service/model/User'
import shallow from 'zustand/shallow'
import { Store, useStore } from '../../appData/store'

const storeSelector = (store: Store) => ({
    academies: store.academies,
    jobFamilies: store.jobFamilies,
    regions: store.regions,
    currentUser: store.currentUser,
    academyCodeLookup: store.academyCodeLookup
})

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
}

type Option = { name: string; id: string }

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        'id': `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    }
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium
    }
}

interface IScreenProps {
    onSaveCompletion?: () => void
    onDeleteCompletion?: () => void
    editMode?: boolean
    tileId?: string // need to open screen in edit mode
}

export default function HomePage({
    onSaveCompletion,
    onDeleteCompletion,
    editMode,
    tileId
}: IScreenProps) {
    const {
        jobFamilies,
        academies,
        regions,
        currentUser: zCurrentUser,
        academyCodeLookup
    } = useStore(storeSelector, shallow)
    const { themeColor } = useCustomTheme()

    const defaultContent = ''

    // const { id } = useParams()
    let refEditor = useRef()

    // #region Use States
    const [documentId, setDocumentId] = useState<string | undefined>(undefined)

    const [formValidate, setFormValidate] = useState<boolean | undefined>(undefined)

    const [postStats, setPostStats] = useState<PostStatsModel | undefined>()

    const [title, setTitle] = useState<string | null>('')
    // const [slug, setSlug] = useState<string | null>('')
    const [titleFocusOut] = useState(false)
    const refTitle = useRef<HTMLTextAreaElement>(null)

    const [link, setLink] = useState<string | null>('')
    // const [defaultLinkURL, setDefaultLinkURL] = useState<string | null>('')
    const [model, setModel] = useState<string | null>(defaultContent)
    const [model2, setModel2] = useState<string | null>(defaultContent)

    const [tileSize, setTileSize] = useState<TileSizeOptions | null>('single')

    const [headerType, setHeaderType] = useState<HeaderTypeOptions | null>('absolute')
    const [postVisibility, setPostVisibility] = useState<PostVisibilityOptions | null>(
        'private'
    )
    const [queryType, setQueryType] = useState<QueryTypeOptions | null>('or')

    const [postType, setPostType] = useState<PostTypeOptions | null>('news')
    const [linkColour, setLinkColour] = useState<LinksColourOptions | null>('black')
    const [widget, setWidget] = useState<WidgetOptions | null>('new')

    const [tabValue, setTabValue] = useState(0)

    const [ownedBy, setOwnedBy] = useState<string | null>('')
    const [createdAt, setCreatedAt] = useState<Timestamp | null>(null)
    const [updatedAt, setUpdatedAt] = useState<Timestamp | null>(null)
    const [showPostLists, setShowPostLists] = useState(false)
    const [postListType, setPostListType] = useState('')

    const [jobsFamily, setJobsFamily] = useState<string[]>([])
    const [region, setRegion] = useState<string[]>(zCurrentUser?.user.regions ?? [])
    const [academy, setAcademy] = useState<string[]>(
        zCurrentUser?.user.academyCodes ?? []
    )
    const [academyGroup, setAcademyGroup] = useState<string[]>(['None'])
    const [priority, setPriority] = useState<PostPriorityOptions | null>('Standard')

    const [thumbnailInitialized, setThumbnailInitialized] = useState(editMode)

    const [jobFamilyError, setJobFamilyError] = useState('')
    const [regionError, setRegionError] = useState('')
    const [academyError, setAcademyError] = useState('')

    const [selectedGoogleGroups, setSelectedGoogleGroups] = useState([])
    const [groupsList, setGroupsList] = useState([])

    // #endregion

    const [liveDateValue, setLiveDateValue] = useState<Timestamp | null>(Timestamp.now())
    const [expriyDateValue, setExpriyDateValue] = useState<Timestamp | null>(
        Timestamp.fromMillis(dayjs().add(10, 'day').valueOf())
    )

    // const LiveDateHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setLiveDateValue(Timestamp.fromDate(new Date(event.target.value)))
    // }
    const LiveDateHandleChange = (newValue: Dayjs | null) => {
        setLiveDateValue(
            newValue ? Timestamp.fromMillis(newValue.valueOf()) : Timestamp.now()
        )
    }
    const ExpiryDateHandleChange = (newValue: Dayjs | null) => {
        setExpriyDateValue(
            newValue ? Timestamp.fromMillis(newValue.valueOf()) : Timestamp.now()
        )
    }

    // #region target Change events
    const titleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value)
    }

    // const linkTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setLink(event.target.value)
    // }

    const tileSizeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTileSize(event.target.value as TileSizeOptions)
    }

    // const headerTypeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setHeaderType(event.target.value as HeaderTypeOptions)
    // }

    const modelHandleChange = (editorModel: any) => {
        setModel(editorModel)
    }

    const modelHandleChange2 = (editorModel: any) => {
        // eslint-disable-next-line no-console
        // console.log('modelHandleChange2', editorModel)
        setModel2(editorModel)
    }

    const postTypeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostType(event.target.value as PostTypeOptions)
    }

    const linkColourHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLinkColour(event.target.value as LinksColourOptions)
    }

    // const widgetHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const val = event.target.value as WidgetOptions
    //     setWidget(val)
    //     // if (val === 'none') {
    //     //     setHeaderType('static' as HeaderTypeOptions)
    //     // } else {
    //     //     setHeaderType('dynamic' as HeaderTypeOptions)
    //     // }
    // }

    const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }

    const priorityHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPriority(event.target.value as PostPriorityOptions)
    }

    const jobsFieldChange = (event: SelectChangeEvent<typeof jobsFamily>) => {
        const {
            target: { value }
        } = event

        let tmpValue = value as string[]

        if (tmpValue.filter(x => x === 'All' || x === 'None').length === 2) {
            if (jobsFamily.includes('None')) {
                tmpValue = ['All']
            } else {
                tmpValue = ['None']
            }
        }

        if (academy.includes('None') && tmpValue.length === 2) {
            tmpValue = tmpValue.filter(x => x !== 'None')
        }

        if (tmpValue.includes('None')) {
            tmpValue = ['None']
        }

        // If un check any of the item in full checked job Families
        if (tmpValue.includes('All') && tmpValue.length === jobFamilies.length) {
            if (jobsFamily.includes('All')) {
                tmpValue = tmpValue.filter(x => x !== 'All')
            } else {
                tmpValue = ['All']
            }
        }

        if (tmpValue.includes('All')) {
            tmpValue = []
            jobFamilyArry().forEach(jobFamilyItem => {
                if (jobFamilyItem !== 'None' && jobFamilyItem !== 'Divider') {
                    tmpValue.push(jobFamilyItem)
                }
            })
        }

        // Teaching - All Staff
        if (tmpValue.includes('Teaching - All Staff')) {
            jobFamilyArry().forEach(jobFamilyItem => {
                if (jobFamilyItem.split('-')[0].trim() === 'Teaching') {
                    tmpValue.push(jobFamilyItem)
                }
            })
        }

        // Support - All Staff
        if (tmpValue.includes('Support - All Staff')) {
            jobFamilyArry().forEach(jobFamilyItem => {
                if (jobFamilyItem.split('-')[0].trim() === 'Support') {
                    tmpValue.push(jobFamilyItem)
                }
            })
        }

        if (tmpValue.filter(x => x === 'All' || x === 'None').length === 2) {
            ErrorNotification('You can not select All and None filter together')
        } else if (
            tmpValue.filter(x => x === 'None').length === 1 &&
            tmpValue.length > 1
        ) {
            ErrorNotification('You can use None filter alone')
        } else {
            setJobsFamily(tmpValue)
        }

        setJobFamilyError('')
    }

    const regionFieldChange = (event: SelectChangeEvent<typeof jobsFamily>) => {
        const {
            target: { value }
        } = event

        let tmpValue = value as string[]

        if (tmpValue.filter(x => x === 'All' || x === 'None').length === 2) {
            if (region.includes('None')) {
                tmpValue = ['All']
            } else {
                tmpValue = ['None']
            }
        }

        if (academy.includes('None') && tmpValue.length === 2) {
            tmpValue = tmpValue.filter(x => x !== 'None')
        }

        if (tmpValue.includes('None')) {
            tmpValue = ['None']
        }

        // If un check any of the item in full checked job Families
        if (tmpValue.includes('All') && tmpValue.length === regions.length) {
            if (region.includes('All')) {
                tmpValue = tmpValue.filter(x => x !== 'All')
            } else {
                tmpValue = ['All']
            }
        }

        if (tmpValue.includes('All')) {
            tmpValue = []
            regionArry().forEach(regionItem => {
                if (regionItem !== 'None' && regionItem !== 'Divider') {
                    tmpValue.push(regionItem)
                }
            })
        }

        if (tmpValue.filter(x => x === 'All' || x === 'None').length === 2) {
            ErrorNotification('You can not select All and None filter together')
        } else if (
            tmpValue.filter(x => x === 'None').length === 1 &&
            tmpValue.length > 1
        ) {
            ErrorNotification('You can use None filter alone')
        } else {
            setRegion(tmpValue)
        }

        setRegionError('')
    }

    const academyAll: AcademiesLookupSingletonModel['academies'] = [
        { academy: 'All', academyCode: 'All', region: 'All', emailDomain: '', phase: '' }
    ]

    const academyNone: AcademiesLookupSingletonModel['academies'] = [
        {
            academy: 'None',
            academyCode: 'None',
            region: 'None',
            emailDomain: '',
            phase: ''
        }
    ]

    const academyDivider: AcademiesLookupSingletonModel['academies'] = [
        {
            academy: 'Divider',
            academyCode: 'Divider',
            region: 'Divider',
            emailDomain: '',
            phase: ''
        }
    ]

    const academyFieldChange = (event: SelectChangeEvent<typeof academy>) => {
        const {
            target: { value }
        } = event

        const allAcademies = [...academyAll, ...academyNone, ...academies]

        // console.log(event.target);

        let tmpValue = value as string[]

        if (tmpValue.filter(x => x === 'All' || x === 'None').length === 2) {
            if (academy.includes('None')) {
                tmpValue = ['All']
            } else {
                tmpValue = ['None']
            }
        }

        if (academy.includes('None') && tmpValue.length === 2) {
            tmpValue = tmpValue.filter(x => x !== 'None')
        }

        if (tmpValue.includes('None')) {
            tmpValue = ['None']
        }

        // If un check any of the item in full checked job Families
        if (tmpValue.includes('All') && tmpValue.length === academies.length) {
            if (academy.includes('All')) {
                tmpValue = tmpValue.filter(x => x !== 'All')
            } else {
                tmpValue = ['All']
            }
        }

        if (tmpValue.includes('All')) {
            tmpValue = []
            allAcademies.forEach(academyItem => {
                if (
                    academyItem.academyCode !== 'None' &&
                    academyItem.academyCode !== 'Divider'
                ) {
                    tmpValue.push(academyItem.academyCode)
                }
            })
        }

        if (tmpValue.filter(x => x === 'All' || x === 'None').length === 2) {
            ErrorNotification('You can not select All and None filter together')
        } else if (
            tmpValue.filter(x => x === 'None').length === 1 &&
            tmpValue.length > 1
        ) {
            ErrorNotification('You can use None filter alone')
        } else {
            setAcademy(tmpValue)
        }

        setAcademyError('')
    }
    // const academyGroupFieldChange = (event: SelectChangeEvent<typeof academy>) => {
    //     const {
    //         target: { value }
    //     } = event

    //     // console.log(event.target);

    //     const tmpValue = value as string[]

    //     if (tmpValue.filter(x => x === 'All' || x === 'None').length === 2) {
    //         ErrorNotification('You can not select All and None filter together')
    //     } else if (
    //         tmpValue.filter(x => x === 'None').length === 1 &&
    //         tmpValue.length > 1
    //     ) {
    //         ErrorNotification('You can use None filter alone')
    //     } else {
    //         setAcademyGroup(tmpValue)
    //     }
    // }
    // #endregion

    const theme = useTheme()

    const validateForm = () => {
        const isValid = title !== ''
        refTitle.current?.focus()
        return isValid
    }

    const slugify = (str: string) =>
        str
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '')

    const post: PostModel = {
        title: title as string,
        slug: slugify(title ?? '') as string,
        link: link as string,
        thumbnail: model as string,
        content: model2 as string,
        headerType: headerType as HeaderTypeOptions,
        postType: postType as PostTypeOptions,
        linkColour: linkColour as LinksColourOptions,
        widget: widget as WidgetOptions,
        queryType: queryType as QueryTypeOptions,
        postVisibility: postVisibility as PostVisibilityOptions,
        tileSize: tileSize as TileSizeOptions,
        id: '',
        ownedBy: ownedBy || '',
        createdAt: createdAt || null,
        updatedAt: updatedAt || null,
        postStatus: 'draft',
        postPriority: priority as PostPriorityOptions,
        postJobFamily: jobsFamily as string[],
        postRegion: region as string[],
        postAcademy: academy as string[],
        score: null,
        academyGroups: academyGroup,
        liveDate: liveDateValue || null,
        expiryDate: expriyDateValue || null,
        selectedGoogleGroups: selectedGoogleGroups as Array<Option>,
        selectedGoogleGroupIds: (selectedGoogleGroups as Array<Option>).map(o => o.id)
    }

    const calcScore = (calcPost: PostModel) => {
        let score = 0
        switch (calcPost.postPriority) {
            case 'High':
                score = 20
                break
            case 'Medium':
                score = 10
                break
            case 'Standard':
                score = 5
                break
            case 'Low':
                score = -10
                break

            default:
                break
        }
        return score
    }

    const savePost = async (postStatus: PostStatusOptions) => {
        try {
            let message = ''
            post.score = calcScore(post)

            if (jobsFamily.length === 0) {
                if (post.postVisibility === 'public') {
                    post.postJobFamily = ['All']
                } else {
                    setJobFamilyError('error')
                    return
                }

                // setJobsFamily(['None'])
                // post.postJobFamily = ['None']
            } else if (post.postVisibility === 'public') {
                post.postJobFamily = ['All']
            }

            if (region.length === 0) {
                if (post.postVisibility === 'public') {
                    post.postRegion = ['All']
                } else {
                    setRegionError('error')
                    // ErrorNotification('Please choose a Region')
                    return
                }
                // setRegion(['None'])
                // post.postRegion = ['None']
            } else if (post.postVisibility === 'public') {
                post.postRegion = ['All']
            }

            if (academy.length === 0) {
                if (post.postVisibility === 'public') {
                    post.postAcademy = ['All']
                } else {
                    setAcademyError('error')
                    // ErrorNotification('Please choose a Academy')
                    return
                }
                // setAcademy(['None'])
                // post.postAcademy = ['None']
            } else if (post.postVisibility === 'public') {
                post.postAcademy = ['All']
            }

            if (academyGroup.length === 0) {
                setAcademyGroup(['None'])
                post.academyGroups = ['None']
            }

            if (post.postVisibility === 'public') {
                post.link = `${window.location.href}PublicNews/${post.slug}`
            } else {
                post.link = `${window.location.href}News/${post.slug}`
            }

            setLink(post.link)
            if (!documentId) {
                const _createDate = Timestamp.now()
                // let _ownedBy = zCurrentUser?.user?.name as string

                let _ownedBy
                if (zCurrentUser?.user?.name) {
                    _ownedBy = zCurrentUser.user.name
                }

                if (!_ownedBy || _ownedBy.trim() === '') {
                    // Unable to determine current user name
                    if (zCurrentUser?.user?.email) {
                        _ownedBy = zCurrentUser.user.email
                    }
                }

                if (!_ownedBy || _ownedBy.trim() === '') {
                    // Unable to determine current user name and email
                    // eslint-disable-next-line no-alert
                    alert('Error UDNF1000, unable to create post')

                    return
                }

                setCreatedAt(_createDate)
                setOwnedBy(_ownedBy)
                post.createdAt = _createDate
                post.ownedBy = _ownedBy
                // eslint-disable-next-line no-console
                // console.log('Addpost', _createDate, _ownedBy)
                const refDocId = await addPost(post)
                // if (link === '') {
                //     setLink(`${window.location.href}PublicNews/${post.slug}`)
                //     setDefaultLinkURL(`${window.location.href}PublicNews/${post.slug}`)
                // }
                setDocumentId(refDocId)

                message = 'News Added'
            } else {
                post.updatedAt = Timestamp.now()
                await updatePost(documentId, post)
                message = 'News Updated'
            }

            switch (postStatus) {
                case 'draft':
                    SuccessNotification('Saved to the Draft')
                    break
                case 'archived':
                    SuccessNotification('Archived')
                    break
                default:
                    SuccessNotification(message)
                    break
            }

            // Callback to close the Add Post screen and reload
            if (onSaveCompletion) {
                // Give sometime for notification to display
                setTimeout(() => {
                    onSaveCompletion()
                }, 200)
            }
        } catch (error) {
            console.error('Error in saving post', error)
        }
    }

    // const archiveNews = () => {
    //     post.postStatus = 'archived'
    //     savePost('archived')
    // }

    const draftNews = () => {
        post.postStatus = 'draft'
        savePost('draft')
    }

    const publishNews = () => {
        const isValid = validateForm()
        setFormValidate(isValid)

        if (isValid) {
            post.postStatus = 'published'
            savePost('published')
        } else {
            ErrorNotification('Please enter valid data')
        }
    }

    const ownedByHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setOwnedBy(event.target.value as string)
    }

    const VisbilityHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostVisibility(
            (event.target as HTMLInputElement).value as PostVisibilityOptions
        )
    }

    const QueryTypeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQueryType((event.target as HTMLInputElement).value as QueryTypeOptions)
    }

    const academyGroupFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value }
        } = event

        // console.log(event.target);

        // const tmpValue = value as string[]

        // if (tmpValue.filter(x => x === 'All' || x === 'None').length === 2) {
        //     ErrorNotification('You can not select All and None filter together')
        // } else if (
        //     tmpValue.filter(x => x === 'None').length === 1 &&
        //     tmpValue.length > 1
        // ) {
        //     ErrorNotification('You can use None filter alone')
        // } else {
        //     setAcademyGroup(tmpValue)
        // }

        setAcademyGroup([
            (event.target as HTMLInputElement).value as PostAcademyGroupOptions
        ])
    }

    // useEffect(() => {
    //     const f = async () => {
    //         await setPost(post, documentId as string)
    //         // setDefaultLinkURL(null)
    //         SuccessNotification('Default News Link Updated')
    //     }
    //     if (defaultLinkURL) {
    //         f()
    //     }
    // }, [defaultLinkURL])

    // useEffect(() => {
    //     if (queryType === 'or') {
    //         setAcademy(['None'])
    //         // setAcademyGroup(['None'])
    //         setJobsFamily(['None'])
    //         setRegion(['None'])
    //     } else {
    //         if (
    //             zCurrentUser?.userPerAcademyPermissions[keyUserPerAcademyPermissions] &&
    //             zCurrentUser.userPerAcademyPermissions[keyUserPerAcademyPermissions]
    //                 .length > 0
    //         ) {
    //             setAcademy([
    //                 zCurrentUser.userPerAcademyPermissions[
    //                     keyUserPerAcademyPermissions
    //                 ][0]
    //             ])
    //         } else {
    //             setAcademy(['None'])
    //         }

    //         // setAcademyGroup(['None'])
    //         setJobsFamily(['None'])
    //         setRegion(['None'])
    //     }
    // }, [queryType, zCurrentUser?.userPerAcademyPermissions])

    // useEffect(() => {
    //     if (
    //         zCurrentUser?.userPerAcademyPermissions[keyUserPerAcademyPermissions] &&
    //         zCurrentUser.userPerAcademyPermissions[keyUserPerAcademyPermissions]
    //             .length > 0
    //     ) {
    //         setQueryType('and')
    //     } else {
    //         setQueryType('or')
    //     }
    // }, [zCurrentUser?.userPerAcademyPermissions])

    useEffect(() => {
        if (tileId) {
            setDocumentId(tileId)
        }
    }, [tileId])

    useEffect(() => {
        async function fetchData(docId: string) {
            const document = await getPost(docId)
            // const postStatsdoc = await getPostStats(docId)
            // if (postStatsdoc) {
            //     setPostStats(postStatsdoc)
            // }
            const postStatsMore = await getPostStatsMore(docId)
            if (postStatsMore) {
                setPostStats(postStatsMore)
            }
            // eslint-disable-next-line no-console
            // console.log('postStatsMore  ', postStatsMore)

            if (document) {
                // setDocument(doc)

                setTitle(document.title ? document.title : '')

                setLink(document.link ? document.link : '')

                setModel(document.thumbnail ? document.thumbnail : '')

                setModel2(document.content ? document.content : '')

                setTileSize(document.tileSize ? document.tileSize : 'single')

                setHeaderType(document.headerType ? document.headerType : 'static')

                setPostType(document.postType ? document.postType : 'news')

                setLinkColour(document.linkColour ? document.linkColour : 'white')

                setWidget(document.widget ? document.widget : 'none')

                setPostVisibility(
                    document.postVisibility ? document.postVisibility : 'public'
                )

                setQueryType(document.queryType ? document.queryType : 'or')

                setOwnedBy(document.ownedBy)

                setPriority(document.postPriority)

                setJobsFamily(
                    document.postJobFamily.includes('None') ? [] : document.postJobFamily
                )

                setRegion(document.postRegion.includes('None') ? [] : document.postRegion)

                setAcademy(
                    document.postAcademy.includes('None') ? [] : document.postAcademy
                )

                // console.log('postAcademy', document.postAcademy)

                setCreatedAt(document.createdAt ? document.createdAt : null)

                setUpdatedAt(document.updatedAt ? document.updatedAt : null)

                setLiveDateValue(document.liveDate ? document.liveDate : null)

                setExpriyDateValue(document.expiryDate ? document.expiryDate : null)

                setAcademyGroup(document.academyGroups)

                setSelectedGoogleGroups(
                    (document.selectedGoogleGroups as Array<never>) || []
                )
            }
        }

        if (documentId) {
            fetchData(documentId)
        }
    }, [documentId])

    const thumbnailToolbarButtons = {
        moreText: {
            buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting'
            ]
        },

        markdown: { buttons: ['markdown'] },

        moreParagraph: {
            buttons: [
                'alignLeft',
                'alignCenter',
                'formatOLSimple',
                'alignRight',
                'alignJustify',
                'formatOL',
                'formatUL',
                'paragraphFormat',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
                'quote'
            ]
        },

        moreRich: {
            buttons: [
                'insertLink',
                'insertImage',
                'insertVideo',
                'insertTable',
                'emoticons',
                'fontAwesome',
                'specialCharacters',
                'embedly',
                'insertFile',
                'insertHR'
            ]
        },

        moreMisc: {
            buttons: [
                'undo',
                'redo',
                tileSize === 'double' ? 'Template 1' : 'Sample 1',
                tileSize === 'double' ? 'Template 2' : 'Sample 2',
                'Sample 3',
                'fullscreen',
                // 'print',
                // 'getPDF',
                'spellChecker',
                'selectAll',
                'html',
                // 'help',
                // 'alert',
                'clear'
                // 'insert',
                // 'insert2',
            ],

            align: 'right',

            buttonsVisible: 11
        }
    }

    const contentToolbarButtons = {
        moreText: {
            buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting'
            ]
        },

        markdown: { buttons: ['markdown'] },

        moreParagraph: {
            buttons: [
                'alignLeft',
                'alignCenter',
                'formatOLSimple',
                'alignRight',
                'alignJustify',
                'formatOL',
                'formatUL',
                'paragraphFormat',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
                'quote'
            ]
        },

        moreRich: {
            buttons: [
                'insertLink',
                'insertImage',
                'insertVideo',
                'insertTable',
                'emoticons',
                'fontAwesome',
                'specialCharacters',
                'embedly',
                'insertFile',
                'insertHR'
            ]
        },

        moreMisc: {
            buttons: [
                'undo',
                'redo',
                'fullscreen',
                'print',
                'getPDF',
                'spellChecker',
                'selectAll',
                'html',
                'clear'
            ],

            align: 'right',

            buttonsVisible: 2
        }
    }

    // const toolbarMarkDown = [
    //     ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'markdown']
    // ]

    const config = {
        key: 'Ne2C1sA4F4B3A11A10C7D7kOPVi1b1b2Le2e1G1AWMSGSAFDTGHWsE7D7D6C5A1I4C2C3A6D5==',
        placeholderText: 'Edit Your Content Here!!!',
        charCounterCount: false,
        toolbarButtons: tabValue === 1 ? thumbnailToolbarButtons : contentToolbarButtons,
        imageUploadRemoteUrls: false,
        imageUploadURL: '',
        imageUploadMethod: 'PUT',
        imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
        attribution: false,
        imageStyles: {
            imageRadius: 'Radius',
            imageBorder: 'Border'
        },
        tableCellStyles: {
            tblCellLeftPadding: 'Padding Left',
            tblCellTopPadding: 'Padding Top'
        },
        tableStyles: {
            tblLeftPadding: 'Padding Left',
            tblTopPadding: 'Padding Top'
        },
        inlineClasses: {
            'fr-class-code': 'Code',
            'fr-class-highlighted': 'Highlighted',
            'fr-class-transparency': 'Transparent'
        },
        fontFamily: {
            'Roboto,sans-serif': 'Roboto',
            'Manrope,sans-serif': 'Manrope',
            'Montserrat,sans-serif': 'Montserrat',
            "'Open Sans Condensed',sans-serif": 'Open Sans Condensed'
        },
        fontSize: ['8', '10', '12', '14', '17', '18', '30', '60', '96'],
        // fontFamilySelection: true,
        colorsBackground: [
            '#15E67F',
            '#E3DE8C',
            '#D8A076',
            '#D83762',
            '#76B6D8',
            'REMOVE',
            '#1C7A90',
            '#249CB8',
            '#4ABED9',
            '#FBD75B',
            '#FBE571',
            '#FFFFFF'
        ],
        colorsStep: 6,
        colorsText: [
            '#15E67F',
            '#E3DE8C',
            '#D8A076',
            '#D83762',
            '#76B6D8',
            'REMOVE',
            '#1C7A90',
            '#249CB8',
            '#4ABED9',
            '#FBD75B',
            '#FBE571',
            '#FFFFFF'
        ],
        emoticonsStep: 4,
        emoticonsSet: [
            {
                id: 'people',
                name: 'Smileys & People',
                code: '1f600',
                emoticons: [
                    {
                        code: '1f600',
                        desc: 'Grinning face'
                    },
                    {
                        code: '1f601',
                        desc: 'Grinning face with smiling eyes'
                    },
                    {
                        code: '1f602',
                        desc: 'Face with tears of joy'
                    },
                    {
                        code: '1f603',
                        desc: 'Smiling face with open mouth'
                    },
                    {
                        code: '1f604',
                        desc: 'Smiling face with open mouth and smiling eyes'
                    },
                    {
                        code: '1f605',
                        desc: 'Smiling face with open mouth and cold sweat'
                    },
                    {
                        code: '1f606',
                        desc: 'Smiling face with open mouth and tightly-closed eyes'
                    },
                    {
                        code: '1f607',
                        desc: 'Smiling face with halo'
                    }
                ]
            },
            {
                id: 'nature',
                name: 'Animals & Nature',
                code: '1F435',
                emoticons: [
                    {
                        code: '1F435',
                        desc: 'Monkey Face'
                    },
                    {
                        code: '1F412',
                        desc: 'Monkey'
                    },
                    {
                        code: '1F436',
                        desc: 'Dog Face'
                    },
                    {
                        code: '1F415',
                        desc: 'Dog'
                    },
                    {
                        code: '1F429',
                        desc: 'Poodle'
                    },
                    {
                        code: '1F43A',
                        desc: 'Wolf Face'
                    },
                    {
                        code: '1F431',
                        desc: 'Cat Face'
                    },
                    {
                        code: '1F408',
                        desc: 'Cat'
                    },
                    {
                        code: '1F42F',
                        desc: 'Tiger Face'
                    },
                    {
                        code: '1F405',
                        desc: 'Tiger'
                    },
                    {
                        code: '1F406',
                        desc: 'Leopard'
                    },
                    {
                        code: '1F434',
                        desc: 'Horse Face'
                    },
                    {
                        code: '1F40E',
                        desc: 'Horse'
                    },
                    {
                        code: '1F42E',
                        desc: 'Cow Face'
                    },
                    { code: '1F402', desc: 'Ox' },
                    {
                        code: '1F403',
                        desc: 'Water Buffalo'
                    }
                ]
            }
        ],

        heightMin: 300,
        heightMax: 300,
        events: {
            'image.beforeUpload': function (images: FileList) {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const editor: any = this
                const uploadData = images[0]

                // Get upload URL
                fetch('https://pre-signed-url-o55hc4pqba-nw.a.run.app').then(response => {
                    response.json().then(data => {
                        const requestOptions = {
                            method: 'PUT',
                            headers: { 'Content-Type': 'image/jpeg' },
                            body: uploadData
                        }

                        // Upload file
                        fetch(data.uploadUrl, requestOptions).then(() => {
                            // Update editor
                            editor.image.insert(
                                data.resourceUrl,
                                null,
                                null,
                                editor.image.get()
                            )
                        })
                    })
                })

                return false
            },
            'initialized': async function () {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const xeditor: any = this // eval('this')
                // eslint-disable-next-line no-promise-executor-return
                await new Promise(resolve => setTimeout(resolve, 0.5))
                // eslint-disable-next-line react/no-this-in-sfc, no-eval
                // eval("this.html.set('html set')")
                // eslint-disable-next-line no-eval
                if (tabValue === 1 && !thumbnailInitialized) {
                    // Do it only for thumbnail tab
                    const width = tileSize === 'double' ? '600px' : '300px'
                    if (model === '') {
                        xeditor.html.insert(
                            `<table><tbody><tr><td><img src="/images/media/OffasMeadAcademy1.png" style="width: ${width}" class="fr-fic fr-dib"></td></tr><tr><td class="tblCellLeftPadding tblCellTopPadding" style="width: 100.0000%;">Edit your content here</td></tr></tbody></table>`
                        )
                    }

                    xeditor.undo.saveStep()

                    setThumbnailInitialized(true)
                }
            }
        }
    }

    // const config2 = {
    //     events: {
    //         // eslint-disable-next-line func-names, object-shorthand
    //         initialized: async function () {
    //             // eslint-disable-next-line no-promise-executor-return
    //             await new Promise(resolve => setTimeout(resolve, 1))
    //             // eslint-disable-next-line react/no-this-in-sfc, no-eval
    //             // eval("this.html.set('html set')")
    //             // eslint-disable-next-line no-eval
    //             const xeditor = eval('this')

    //             // setEditor(xeditor)

    //             xeditor.markdown.toggle()

    //             xeditor.html.set(model2)
    //             // console.log('AddpostContent', document?.content)

    //             xeditor.markdown.toggle()
    //             xeditor.markdown.toggle()

    //             const element = document.querySelectorAll(
    //                 '.fr-wrapper'
    //             )[0] as HTMLElement | null
    //             if (element != null) {
    //                 // console.log('NewsTile', element)
    //                 element.style.overflow = 'hidden'
    //             }
    //         },
    //         // eslint-disable-next-line func-names, object-shorthand
    //         focus: function () {
    //             // eslint-disable-next-line no-promise-executor-return
    //             // await new Promise(resolve => setTimeout(resolve, 1))

    //             // eslint-disable-next-line no-eval
    //             const xeditor = eval('this')
    //             console.log('AddPostfocus', xeditor.selection.get())
    //         }
    //     },
    //     key: 'Ne2C1sA4F4B3A11A10C7D7kOPVi1b1b2Le2e1G1AWMSGSAFDTGHWsE7D7D6C5A1I4C2C3A6D5==',
    //     placeholderText: 'Edit Your Content Here!!!',
    //     charCounterCount: false,
    //     toolbarButtons: toolbarMarkDown,
    //     fontFamily: {
    //         'Roboto,sans-serif': 'Roboto',
    //         'Manrope,sans-serif': 'Manrope',
    //         'Montserrat,sans-serif': 'Montserrat',
    //         "'Open Sans Condensed',sans-serif": 'Open Sans Condensed'
    //     },
    //     fontSize: ['8', '10', '12', '14', '17', '18', '30', '60', '96'],
    //     heightMin: 350,
    //     heightMax: 350
    // }

    const closeList = (id: string) => {
        setShowPostLists(false)
        setPostListType('')
        setDocumentId(id)
    }

    const calcFirebaseDate = (dateVal: Timestamp | null) => {
        // eslint-disable-next-line no-console
        // console.log(
        //     'AddPost-calcFirebaseDate',
        //     typeof dateVal,
        //     dateVal?.toDate(),
        //     dateVal?.toDate().toISOString().split('T')[0]
        // )
        return dateVal ? dateVal.toDate().toISOString().split('T')[0] : ''
    }

    const formatDate = (date: Timestamp) =>
        date.seconds !== null
            ? date // Already a javascript date object
            : date.toDate()

    const StyledSingleBox = styled(Box)(() => ({
        borderRadius: '8px',
        boxShadow: '0px 0px 15px rgba(51,51,51,0.2)'
    }))

    const academyGroupsArry = () =>
        queryType === 'or'
            ? [
                  {
                      value: 'All',
                      text: 'All'
                  },
                  {
                      value: 'Primary',
                      text: 'All Primary'
                  },
                  {
                      value: 'Secondary',
                      text: 'All Secondary'
                  },
                  {
                      value: 'Special',
                      text: 'All Special'
                  }
              ]
            : [
                  {
                      value: 'All',
                      text: 'All'
                  },
                  {
                      value: 'Primary',
                      text: 'All Primary'
                  },
                  {
                      value: 'Secondary',
                      text: 'All Secondary'
                  },
                  {
                      value: 'Special',
                      text: 'All Special'
                  }
              ]

    const primaryAcademy = () => (
        <Typography
            marginLeft={2}
            fontSize='18px'
            fontWeight={600}
            // color={color}
            lineHeight='15px'
            mt='5px'
            color='rgba(0, 0, 0, 0.6)'
        >
            Primary
        </Typography>
    )
    const secondaryAcademy = () => (
        <Typography
            marginLeft={2}
            fontSize='18px'
            fontWeight={600}
            // color={color}
            lineHeight='15px'
            mt='5px'
            color='rgba(0, 0, 0, 0.6)'
        >
            Secondary
        </Typography>
    )

    const specialAcademy = () => (
        <Typography
            marginLeft={2}
            fontSize='18px'
            fontWeight={600}
            // color={color}
            lineHeight='15px'
            mt='5px'
            color='rgba(0, 0, 0, 0.6)'
        >
            Special
        </Typography>
    )

    const allAcademyArry = () => {
        // ...academyNone
        const allNullItems =
            queryType === 'or'
                ? [...academyAll, ...academyDivider]
                : [...academyAll, ...academyDivider]

        const mixedAcademyMenuItems: JSX.Element[] = []

        allNullItems.forEach(_academy => {
            if (_academy.academyCode !== 'Divider') {
                mixedAcademyMenuItems.push(
                    <MenuItem key={_academy.academyCode} value={_academy.academyCode}>
                        <Checkbox checked={academy.indexOf(_academy.academyCode) > -1} />
                        <ListItemText primary={_academy.academy} />
                    </MenuItem>
                )
            } else {
                mixedAcademyMenuItems.push(
                    <Divider sx={{ background: themeColor, height: '1px' }} />
                )
            }
        })

        const academyMenuItemsByPhase = (academyGroupItem: string) =>
            academies.forEach(_academy => {
                if (academyGroupItem === _academy.phase) {
                    mixedAcademyMenuItems.push(
                        <MenuItem key={_academy.academyCode} value={_academy.academyCode}>
                            <Checkbox
                                checked={academy.indexOf(_academy.academyCode) > -1}
                            />
                            <ListItemText primary={_academy.academy} />
                        </MenuItem>
                    )
                }
            })

        const academyMenuItems = () =>
            academies.forEach(_academy => {
                mixedAcademyMenuItems.push(
                    <MenuItem key={_academy.academyCode} value={_academy.academyCode}>
                        <Checkbox checked={academy.indexOf(_academy.academyCode) > -1} />
                        <ListItemText primary={_academy.academy} />
                    </MenuItem>
                )
            })

        //   mixedAcademyMenuItems.push(primaryAcademy())

        // academyMenuItemsByPhase('Primary')

        // mixedAcademyMenuItems.push(secondaryAcademy())

        // academyMenuItemsByPhase('Secondary')

        // mixedAcademyMenuItems.push(specialAcademy())

        // academyMenuItemsByPhase('Special')

        academyMenuItems()

        return mixedAcademyMenuItems
    }

    const academyArry = () => {
        // console.log(
        //     'academyArry1',
        //     zCurrentUser?.user.name,
        //     zCurrentUser?.userPerAcademyPermissions[keyUserPerAcademyPermissions]
        // )

        if (
            zCurrentUser?.userPerAcademyPermissions[keyUserPerAcademyPermissions] &&
            zCurrentUser.userPerAcademyPermissions[keyUserPerAcademyPermissions].length >
                0
        ) {
            return zCurrentUser?.userPerAcademyPermissions[
                keyUserPerAcademyPermissions
            ]?.map(_academy => (
                <MenuItem key={_academy} value={_academy}>
                    <Checkbox checked={academy.indexOf(_academy) > -1} />
                    <ListItemText
                        sx={{
                            wordBreak: 'break-word',
                            whiteSpace: 'break-spaces !important'
                        }}
                        primary={academyCodeLookup[_academy]?.academy || ''}
                    />
                </MenuItem>
            ))
        }

        return allAcademyArry()
    }

    const jobFamilyArry = () =>
        queryType === 'or'
            ? ['All', 'Divider', ...jobFamilies]
            : ['All', 'Divider', ...jobFamilies]

    const regionArry = () =>
        queryType === 'or'
            ? ['All', 'Divider', ...regions]
            : ['All', 'Divider', ...regions]

    const jobFamiliesGridItem = () => (
        <Grid item xs={6} key='jobFamiliesGridItem'>
            <FormControl
                sx={{
                    m: 0,
                    width: '100%'
                }}
            >
                <InputLabel
                    id='demo-multiple-job-family-label'
                    sx={{ backgroundColor: 'white', padding: '0px 2px' }}
                    shrink
                >
                    Job Family*
                </InputLabel>
                <Select
                    labelId='demo-multiple-job-family-label'
                    id='demo-multiple-job-family'
                    multiple
                    displayEmpty
                    required
                    value={jobsFamily}
                    onChange={jobsFieldChange}
                    input={<OutlinedInput label='Job Family' />}
                    renderValue={
                        jobsFamily.length > 0
                            ? selected => selected.join(', ')
                            : () => <Placeholder>Select Job Family</Placeholder>
                    }
                    MenuProps={MenuProps}
                    error={jobFamilyError !== ''}
                    endAdornment={
                        jobsFamily.length > 0 ? (
                            <IconButton
                                onClick={() => {
                                    setJobsFamily([])
                                }}
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        ) : null
                    }
                >
                    {jobFamilyArry().map(JobFamilyItem =>
                        JobFamilyItem !== 'Divider' ? (
                            <MenuItem
                                sx={{ wordBreak: 'break-word', whiteSpace: 'unset' }}
                                key={JobFamilyItem}
                                value={JobFamilyItem}
                            >
                                <Checkbox
                                    checked={jobsFamily.indexOf(JobFamilyItem) > -1}
                                />
                                <ListItemText primary={JobFamilyItem} />
                            </MenuItem>
                        ) : (
                            <Divider sx={{ background: themeColor, height: '1px' }} />
                        )
                    )}
                </Select>
                {jobFamilyError !== '' ? <FormHelperText>Required</FormHelperText> : null}
            </FormControl>
        </Grid>
    )

    const regionsGridItem = () => (
        <Grid item xs={6} key='regionsGridItem'>
            <FormControl
                sx={{
                    m: 0,
                    width: '100%'
                }}
            >
                <InputLabel
                    id='demo-multiple-region-label'
                    sx={{ backgroundColor: 'white', padding: '0px 2px' }}
                    shrink
                >
                    Regions*
                </InputLabel>
                <Select
                    labelId='demo-multiple-region-label'
                    id='demo-multiple-region'
                    multiple
                    required
                    value={region}
                    displayEmpty
                    onChange={regionFieldChange}
                    input={<OutlinedInput label='Region' />}
                    error={regionError !== ''}
                    renderValue={
                        region.length > 0
                            ? selected => selected.join(', ')
                            : () => <Placeholder>Select Region</Placeholder>
                    }
                    MenuProps={MenuProps}
                    endAdornment={
                        region.length > 0 ? (
                            <IconButton
                                onClick={() => {
                                    setRegion([])
                                }}
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        ) : null
                    }
                >
                    {regionArry().map(regionItem =>
                        regionItem !== 'Divider' ? (
                            <MenuItem
                                key={regionItem}
                                value={regionItem}
                                style={getStyles(regionItem, regions, theme)}
                            >
                                <Checkbox checked={region.indexOf(regionItem) > -1} />
                                <ListItemText primary={regionItem} />
                            </MenuItem>
                        ) : (
                            <Divider sx={{ background: themeColor, height: '1px' }} />
                        )
                    )}
                </Select>
                {regionError !== '' ? <FormHelperText>Required</FormHelperText> : null}
            </FormControl>
        </Grid>
    )

    const academiesGridItem = () => (
        <Grid item xs={6} key='academiesGridItem'>
            <FormControl
                sx={{
                    m: 0,
                    width: '100%'
                }}
            >
                <InputLabel
                    id='demo-multiple-academy-label'
                    sx={{ backgroundColor: 'white', padding: '0px 2px' }}
                    shrink
                >
                    Academy*
                </InputLabel>
                <Select
                    labelId='demo-multiple-academy-label'
                    id='demo-multiple-academy'
                    multiple
                    value={academy}
                    displayEmpty
                    required
                    onChange={academyFieldChange}
                    input={<OutlinedInput label='Academy' />}
                    error={academyError !== ''}
                    renderValue={
                        academy.length > 0
                            ? selected =>
                                  selected
                                      .map(x => {
                                          if (x === 'None') {
                                              return 'None'
                                          }

                                          if (x === 'All') {
                                              return 'All'
                                          }

                                          return academyCodeLookup[x]?.academy
                                      })
                                      .join(', ')
                            : () => <Placeholder>Select Academy</Placeholder>
                    }
                    MenuProps={MenuProps}
                    endAdornment={
                        academy.length > 0 ? (
                            <IconButton
                                onClick={() => {
                                    setAcademy([])
                                }}
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        ) : null
                    }
                >
                    {academyArry()}
                </Select>
                {academyError !== '' ? <FormHelperText>Required</FormHelperText> : null}
            </FormControl>
        </Grid>
    )

    const academGroupsGridItem = () => (
        <Grid item xs={12} key='academGroupsGridItem'>
            <FormControl fullWidth>
                <FormLabel id='demo-radio-buttons-group-label-academy-group'>
                    Academy Group
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby='demo-radio-buttons-group-label-academy-group'
                    value={academyGroup}
                    name='radio-buttons-group-Academy-Group'
                    onChange={academyGroupFieldChange}
                >
                    {academyGroupsArry().map(group => (
                        <FormControlLabel
                            key={`academy-group-opt-${group.value}`}
                            value={group.value}
                            control={<Radio />}
                            label={group.text}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Grid>
    )

    const restrictionsGridItems = () => {
        // if (
        //     zCurrentUser?.userPerAcademyPermissions[keyUserPerAcademyPermissions] &&
        //     zCurrentUser.userPerAcademyPermissions[keyUserPerAcademyPermissions].length >
        //         0
        // ) {
        //     return [academiesGridItem, jobFamiliesGridItem].map(x => x())
        // }
        return [
            jobFamiliesGridItem,
            regionsGridItem,
            academiesGridItem
            // academGroupsGridItem
        ].map(x => x())
    }

    const TileSizeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTileSize(event.target.value as TileSizeOptions)
    }

    return (
        <>
            {showPostLists ? (
                <Box
                    sx={{
                        pt: '10px'
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ListPost
                                postType={postListType}
                                closeList={closeList}
                                onDeleteCompletion={onDeleteCompletion}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                justifyContent='flex-end'
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    pr: 2,
                                    marginBottom: 2
                                }}
                            >
                                <Divider />{' '}
                                <Button
                                    sx={{
                                        height: '42px',
                                        width: '108px'
                                    }}
                                    onClick={() => {
                                        setShowPostLists(false)
                                    }}
                                    variant='outlined'
                                >
                                    Close
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Box sx={{ minHeight: 450 }}>
                    <Tabs value={tabValue} onChange={tabHandleChange}>
                        <Tab label='META' {...a11yProps(0)} />
                        <Tab label='THUMBNAIL' {...a11yProps(1)} />
                        <Tab label='CONTENT' {...a11yProps(2)} />
                        <Tab label='STATS' {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant='h5'
                                    sx={{
                                        mt: '10px',
                                        mb: '10px'
                                    }}
                                >
                                    New Post:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} my={0}>
                                    <Grid item xs={8}>
                                        <TextField
                                            fullWidth
                                            required
                                            autoFocus
                                            error={!title && formValidate === false}
                                            // onBlur={() => setTitleFocusOut(true)}
                                            id='outlined-basic'
                                            label='Title'
                                            placeholder='e.g. Ofsted offer “Outstanding” result to St Mary’s Primary School'
                                            variant='outlined'
                                            onChange={titleTextFieldChange}
                                            value={title}
                                            helperText={
                                                !title &&
                                                (titleFocusOut || formValidate === false)
                                                    ? "Title can't be empty."
                                                    : null
                                            }
                                            inputRef={refTitle}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            select // tell TextField to render select
                                            value={priority}
                                            label='Priority'
                                            onChange={priorityHandleChange}
                                        >
                                            <MenuItem selected value='High'>
                                                High
                                            </MenuItem>
                                            <MenuItem value='Medium'>Medium</MenuItem>
                                            <MenuItem value='Standard'>Standard</MenuItem>
                                            <MenuItem value='Low'>Low</MenuItem>
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    justifyContent='flex-start'
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography
                                        lineHeight='20px'
                                        fontSize='14px'
                                        fontWeight={400}
                                        sx={{}}
                                    >
                                        70 characters Max
                                    </Typography>
                                    <div style={{ width: '10px' }} />
                                    <Link href={link ?? undefined}> {link}</Link>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2} my={0}>
                                    <Grid item xs={6} textAlign='left'>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        lineHeight='15px'
                                                        sx={
                                                            {
                                                                // mt: '10px',
                                                                // mb: '10px'
                                                            }
                                                        }
                                                    >
                                                        TILE SIZES
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <StyledSingleBox
                                                        justifyContent='center'
                                                        sx={{
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center'
                                                        }}
                                                        onClick={() => {
                                                            setTileSize('single')
                                                        }}
                                                    >
                                                        <AbsoluteHeader
                                                            style={{
                                                                width: '90%',
                                                                height: '90%',
                                                                paddingTop: '-10px'
                                                            }}
                                                        />

                                                        <Radio
                                                            checked={
                                                                tileSize === 'single'
                                                            }
                                                            onChange={
                                                                TileSizeHandleChange
                                                            }
                                                            value='single'
                                                            name='tile-size-radio-buttons'
                                                            inputProps={{
                                                                'aria-label': 'A'
                                                            }}
                                                        />
                                                    </StyledSingleBox>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <StyledSingleBox
                                                        justifyContent='center'
                                                        sx={{
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center'
                                                        }}
                                                        onClick={() => {
                                                            setTileSize('double')
                                                        }}
                                                    >
                                                        <StaticHeader
                                                            style={{
                                                                width: '90%',
                                                                height: '90%'
                                                            }}
                                                        />

                                                        <Radio
                                                            checked={
                                                                tileSize === 'double'
                                                            }
                                                            onChange={
                                                                TileSizeHandleChange
                                                            }
                                                            value='double'
                                                            name='tile-size-radio-buttons'
                                                            inputProps={{
                                                                'aria-label': 'A'
                                                            }}
                                                        />
                                                    </StyledSingleBox>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                    >
                                                        <DateTimePicker
                                                            inputFormat='DD-MM-YYYY HH:mm:ss'
                                                            // margin='normal'
                                                            label='Live Date'
                                                            value={
                                                                liveDateValue
                                                                    ? dayjs.unix(
                                                                          liveDateValue.seconds
                                                                      )
                                                                    : dayjs()
                                                            }
                                                            renderInput={(
                                                                params: any
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        placeholder:
                                                                            'dd/mm/aaaa'
                                                                    }}
                                                                    fullWidth
                                                                />
                                                            )}
                                                            onChange={
                                                                LiveDateHandleChange
                                                            }
                                                            // variant='filled'
                                                            // style={{ marginTop: 0 }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                    >
                                                        <DateTimePicker
                                                            inputFormat='DD-MM-YYYY HH:mm:ss'
                                                            // margin='normal'
                                                            label='Expiry Date'
                                                            // fullWidth
                                                            value={
                                                                expriyDateValue
                                                                    ? dayjs.unix(
                                                                          expriyDateValue.seconds
                                                                      )
                                                                    : dayjs()
                                                            }
                                                            renderInput={(
                                                                params: any
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        placeholder:
                                                                            'dd/mm/aaaa'
                                                                    }}
                                                                    fullWidth
                                                                />
                                                            )}
                                                            onChange={
                                                                ExpiryDateHandleChange
                                                            }
                                                            // variant='filled'
                                                            // style={{ marginTop: 0 }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <Grid item xs={12}>
                                            <Box
                                                justifyContent='flex-start'
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    mt: '-30px',
                                                    mb: '10px'
                                                }}
                                            >
                                                <div style={{ width: '125px' }}>
                                                    Query Type
                                                </div>
                                                <FormControl
                                                    component='fieldset'
                                                    fullWidth
                                                >
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby='demo-radio-buttons-group-label-Query'
                                                        value={queryType}
                                                        name='radio-buttons-group'
                                                        onChange={QueryTypeHandleChange}
                                                    >
                                                        <FormControlLabel
                                                            key='or'
                                                            value='or'
                                                            control={<Radio />}
                                                            label='Or'
                                                        />
                                                        <FormControlLabel
                                                            key='and'
                                                            value='and'
                                                            control={<Radio />}
                                                            label='And'
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Grid> */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    select // tell TextField to render select
                                                    id='demo-simple-select'
                                                    fullWidth
                                                    value={postType}
                                                    label='Post Type'
                                                    onChange={postTypeHandleChange}
                                                >
                                                    <MenuItem selected value='news'>
                                                        News
                                                    </MenuItem>
                                                    {/* <MenuItem value='notification'>
                                                        Notification
                                                    </MenuItem> */}
                                                    {/* <MenuItem value='announcement'>
                                                Announcement
                                            </MenuItem> */}
                                                    <MenuItem value='popupannouncement'>
                                                        Popup Announcement
                                                    </MenuItem>
                                                    {/* <MenuItem value='stat'>Stat</MenuItem> */}
                                                </TextField>
                                            </Grid>
                                            {postVisibility === 'public'
                                                ? null
                                                : restrictionsGridItems()}

                                            {/* <Grid item xs={6}>
                                                <FormControl
                                                    sx={{
                                                        m: 0,
                                                        width: '100%'
                                                    }}
                                                >
                                                    <InputLabel id='demo-multiple-name-label'>
                                                        Academy Group
                                                    </InputLabel>
                                                    <Select
                                                        labelId='demo-multiple-name-label'
                                                        id='demo-multiple-name'
                                                        multiple
                                                        value={academyGroup}
                                                        onChange={academyGroupFieldChange}
                                                        input={
                                                            <OutlinedInput label='Academy' />
                                                        }
                                                        MenuProps={MenuProps}
                                                    >
                                                        {AcademyGroups.map(group => (
                                                            <MenuItem
                                                                key={group.value}
                                                                value={group.value}
                                                                style={getStyles(
                                                                    group.value,
                                                                    AcademyGroups.map(
                                                                        x => x.value
                                                                    ),
                                                                    theme
                                                                )}
                                                            >
                                                                {group.text}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid> */}

                                            <Grid item xs={12}>
                                                <FormControl
                                                    component='fieldset'
                                                    fullWidth
                                                >
                                                    <FormLabel id='demo-radio-buttons-group-label'>
                                                        Visibility
                                                    </FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby='demo-radio-buttons-group-label'
                                                        value={postVisibility}
                                                        name='radio-buttons-group'
                                                        onChange={VisbilityHandleChange}
                                                    >
                                                        <FormControlLabel
                                                            value='public'
                                                            control={<Radio />}
                                                            label='Public'
                                                        />
                                                        <FormControlLabel
                                                            value='private'
                                                            control={<Radio />}
                                                            label='Private'
                                                        />
                                                        {/* <FormControlLabel
                                                            value='members'
                                                            control={<Radio />}
                                                            label='Specific Members'
                                                        /> */}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl
                                                    component='fieldset'
                                                    fullWidth
                                                >
                                                    <FormLabel id='demo-radio-buttons-group-label'>
                                                        Groups
                                                    </FormLabel>
                                                    <ChipAutoComplete
                                                        options={groupsList}
                                                        value={selectedGoogleGroups}
                                                        onChange={async (
                                                            event: SyntheticEvent,
                                                            value: any
                                                        ) => {
                                                            setSelectedGoogleGroups(value)
                                                        }}
                                                        onInputChange={async (
                                                            event: SyntheticEvent,
                                                            value: any
                                                        ) => {
                                                            const options: Array<never> =
                                                                (await queryGroups(
                                                                    value
                                                                )) as Array<never>
                                                            setGroupsList(options)
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select // tell TextField to render select
                                            value={headerType}
                                            label='Header Type'
                                            onChange={headerTypeHandleChange}
                                        >
                                            <MenuItem selected value='static'>
                                                Static Header
                                            </MenuItem>
                                            <MenuItem value='absolute'>
                                                Absolute Header
                                            </MenuItem>
                                        </TextField>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Typography
                                    lineHeight='15px'
                                    sx={{
                                        mt: '10px',
                                        mb: '10px'
                                    }}
                                >
                                    Members Names
                                </Typography>
                                <TextField
                                    fullWidth
                                    id='outlined-multiline-flexible'
                                    // label='Multiline'
                                    placeholder='e.g. Hannah Latawski, Andrew Howden, James Dean'
                                    multiline
                                    rows={3}
                                    // value={value}
                                    // onChange={tabHandleChange}
                                />
                            </Grid> */}
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={tileSize === 'double' ? 12 : 4}>
                                <Typography sx={{ mt: '10px', mb: '10px' }} variant='h5'>
                                    Tile Preview
                                </Typography>
                                <NewsTile
                                    // xs={tileSize === 'single' ? 6 : 12}
                                    // md={tileSize === 'single' ? 6 : 12}
                                    // lg={tileSize === 'single' ? 6 : 12}
                                    width={tileSize === 'single' ? 250 : 250 * 2}
                                    isTrending={widget === 'trending'}
                                    isNew={postType === 'news'}
                                    headerStatic={headerType === 'static'}
                                    designViewer
                                    post={post}
                                >
                                    <FroalaEditorView model={model} />
                                </NewsTile>
                            </Grid>
                            <Grid item xs={tileSize === 'double' ? 12 : 8}>
                                {tileSize === 'double' ? <Divider /> : null}
                                <Typography variant='h5' sx={{ mt: '10px', mb: '10px' }}>
                                    Tile Editing Form
                                </Typography>{' '}
                                <FroalaEditor
                                    tag='textarea'
                                    config={config}
                                    model={model}
                                    onModelChange={modelHandleChange}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ mt: '10px', mb: '10px' }} variant='h5'>
                                    Content Editing Form
                                </Typography>
                                <FroalaEditor
                                    // eslint-disable-next-line no-return-assign
                                    ref={(ref: any) => {
                                        refEditor = ref
                                        return refEditor
                                    }}
                                    tag='textarea'
                                    config={config}
                                    model={model2}
                                    onModelChange={modelHandleChange2}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <Grid container spacing={2} sx={{ minHeight: 450 }}>
                            <Grid item xs={12} border={1}>
                                <Typography variant='h5'>Stats</Typography>

                                <Grid container columns={18} spacing={2} my={0}>
                                    <Grid item xs={6}>
                                        <Typography variant='h5'>Like Count</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='h5'>Read Count</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='h5'>
                                            Content Click Count
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container columns={18} spacing={2} my={0}>
                                    <Grid item xs={2}>
                                        <Typography variant='h5'>Last Month</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='h5'>Last 7 Days</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='h5'>Total</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='h5'>Last Month</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='h5'>Last 7 Days</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='h5'>Total</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='h5'>Last Month</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='h5'>Last 7 Days</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='h5'>Total</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container columns={18} spacing={2} my={0}>
                                    <Grid item xs={2}>
                                        <Link href='#' underline='none'>
                                            {postStats?.lastMonthLiked}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Link href='#' underline='none'>
                                            {postStats?.last7DaysLiked}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Link href='#' underline='none'>
                                            {postStats?.likeCount}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Link href='#' underline='none'>
                                            {postStats?.lastMonthRead}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Link href='#' underline='none'>
                                            {postStats?.last7DaysRead}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Link href='#' underline='none'>
                                            {postStats?.readCount}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Link href='#' underline='none'>
                                            {postStats?.lastMonthContentClicked}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Link href='#' underline='none'>
                                            {postStats?.last7DaysContentClicked}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Link href='#' underline='none'>
                                            {postStats?.contentClickedCount}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <Grid container spacing={2} px={2}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                justifyContent='space-between'
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    ml: '10px',
                                    mb: '10px'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        ml: '10px',
                                        mb: '10px'
                                    }}
                                >
                                    <Typography
                                        lineHeight='10px'
                                        sx={{
                                            mt: '10px',
                                            mb: '10px'
                                        }}
                                        fontSize={12}
                                    >
                                        {`OWNED BY:  ${ownedBy}`}
                                    </Typography>
                                    <CircleIcon
                                        sx={{
                                            fontSize: 5,
                                            color: 'black',
                                            ml: '5px',
                                            mr: '5px'
                                        }}
                                    />
                                    <Typography
                                        lineHeight='10px'
                                        sx={{
                                            mt: '10px',
                                            mb: '10px'
                                        }}
                                        fontSize={12}
                                    >
                                        {`CREATED AT:${calcFirebaseDate(createdAt)}`}
                                    </Typography>
                                    <CircleIcon
                                        sx={{
                                            fontSize: 5,
                                            color: 'black',
                                            ml: '5px',
                                            mr: '5px'
                                        }}
                                    />
                                    <Typography
                                        lineHeight='10px'
                                        sx={{
                                            mt: '10px',
                                            mb: '10px'
                                        }}
                                        fontSize={12}
                                    >
                                        {`MODIFIED AT:${calcFirebaseDate(updatedAt)}`}
                                    </Typography>
                                </Box>
                                <Box>
                                    {editMode ? null : (
                                        <>
                                            <Button
                                                sx={{
                                                    mr: '10px',
                                                    height: '42px',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    lineHeight: '17px'
                                                }}
                                                variant='outlined'
                                                onClick={() => {
                                                    setPostListType('notification')
                                                    setShowPostLists(true)
                                                    // navigate('/ListPost', {
                                                    //     replace: true
                                                    // })
                                                }}
                                            >
                                                Notification List
                                            </Button>
                                            <Button
                                                sx={{
                                                    mr: '10px',
                                                    height: '42px',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    lineHeight: '17px'
                                                }}
                                                variant='outlined'
                                                onClick={() => {
                                                    setPostListType('news')
                                                    setShowPostLists(true)
                                                    // navigate('/ListPost', {
                                                    //     replace: true
                                                    // })
                                                }}
                                            >
                                                Post List
                                            </Button>
                                        </>
                                    )}
                                    {/* <Button
                                    sx={{
                                        mr: '10px',
                                        height: '42px',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '17px'
                                    }}
                                    onClick={archiveNews}
                                    variant='outlined'
                                >
                                    Save Archive
                                </Button> */}
                                    {/* <Button
                                    sx={{
                                        mr: '10px',
                                        height: '42px',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '17px'
                                    }}
                                    onClick={draftNews}
                                    variant='outlined'
                                >
                                    Save Draft
                                </Button> */}
                                    <Button
                                        sx={{
                                            height: '42px',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '17px'
                                        }}
                                        onClick={publishNews}
                                        variant='contained'
                                    >
                                        Publish
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    )
}
