import { Typography } from '@mui/material'
import { memo } from 'react'

const Denied = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <Typography sx={{ opacity: 0.5 }} fontSize={24}>
                ACCESS DENIED
            </Typography>
        </div>
    )
}

export default memo(Denied)
