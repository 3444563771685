/* eslint-disable no-empty */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Chip, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, useState } from 'react'

import { Link as LinkIcon } from '@mui/icons-material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { BoxProps } from '@mui/system'
import { SuccessNotification } from 'components/utils/toastify'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { LinksColourOptions } from 'service/model/Post'

interface HeaderProps extends BoxProps {
    isBackground: boolean | undefined
    isStatic: boolean | undefined
    isTrending: boolean | undefined
    isNew: boolean | undefined
    isLink?: boolean | undefined
    isClose?: boolean | undefined
    closeClick: React.MouseEventHandler<HTMLButtonElement> | undefined
    linkColour?: LinksColourOptions
    linkValue?: string | undefined
    hideWidgetType?: boolean | false
    designerView?: boolean | false
    isNotification?: boolean | false
    isReadPopupAnnouncement?: boolean | false
    title?: string | undefined
}

// const HeaderImageWrapper = styled(Box)<HeaderProps>(
//     ({ theme }) => ({})
// )

interface DocumentInformationProps extends BoxProps {
    isbackground_: string
    isstatic_: string
}

const DocumentInformation = styled(Box)<DocumentInformationProps>(
    ({ theme, isstatic_, isbackground_ }) => ({
        paddingLeft: '10px',
        paddingTop: '10px',
        paddingBottom: '8px',
        height: '50px',
        backgroundColor:
            isbackground_ === 'true' ? theme.palette.primary.main : undefined,
        position: isstatic_ === 'true' ? 'static' : 'absolute',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        marginBottom: '15px'
    })
)

const StyledChip = styled(Chip)(() => ({
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.16px',
    zIndex: 11,
    height: '32px'
}))

const HoverIconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '0px',
    right: '0px',
    cursor: 'pointer',
    zIndex: 2
}))

const Header: FC<HeaderProps> = ({
    isBackground,
    isStatic,
    isTrending,
    isNew,
    isLink,
    isClose,
    closeClick,
    linkColour,
    linkValue,
    hideWidgetType,
    designerView,
    isNotification,
    isReadPopupAnnouncement,
    title
}) => {
    const { theme } = useCustomTheme()

    const [clipBoardValue, setClipBoardValue] = useState<string | null>(linkValue ?? '')
    const [copied, setCopied] = useState<boolean | null>(null)
    const onCopy = () => {
        setCopied(true)
    }

    const toggleLink = async () => {
        console.log('Header')

        /* alert('URL Copied')
        setClipBoardValue('test Value') */
        if (linkValue) {
            try {
                await navigator.clipboard.writeText(linkValue)
                SuccessNotification('URL Copied')
            } catch (err) {}
        }
    }

    return (
        <DocumentInformation
            isbackground_={isBackground === true ? 'true' : 'false'}
            isstatic_={isStatic === true ? 'true' : 'false'}
        >
            {title && (
                <Typography variant='h5' marginLeft='5px'>
                    {title}
                </Typography>
            )}

            {isNotification === true && (
                <StyledChip
                    sx={{
                        borderRadius: '10px',
                        color: theme.palette.common.white,
                        background: theme.palette.common.pink
                    }}
                    label='Notification'
                />
            )}
            {isReadPopupAnnouncement === true && (
                <StyledChip
                    sx={{
                        borderRadius: '10px',
                        color: theme.palette.common.white,
                        background: theme.palette.common.pink
                    }}
                    label='Read'
                />
            )}
            {!isNotification && isTrending === true && !hideWidgetType && (
                <StyledChip
                    sx={{
                        borderRadius: '10px',
                        color: theme.palette.common.white,
                        background: theme.palette.common.pink
                    }}
                    label='Trending'
                />
            )}

            {!isNotification && isNew === true && !hideWidgetType && (
                <StyledChip
                    sx={{
                        borderRadius: '10px',
                        color: theme.palette.common.white,
                        background: theme.palette.common.pink
                    }}
                    label='New'
                />
            )}
            {isNotification && !designerView && (
                <HoverIconWrapper>
                    <IconButton
                        sx={{
                            display: isLink ? 'display' : 'none',
                            mr: 0,
                            position: 'absolute',
                            mt: '5px',
                            // marginBlockStart: '5px',
                            right: '0',
                            color: linkColour === 'white' ? 'white' : 'black'
                        }}
                        // onClick={toggleLink}
                    >
                        <CalendarTodayIcon
                            sx={{
                                fontSize: 25
                            }}
                        />
                    </IconButton>

                    {isClose && (
                        <IconButton
                            sx={{
                                display: isClose ? 'display' : 'none',
                                mr: 0,
                                position: 'absolute',
                                mt: '5px',
                                // marginBlockStart: '5px',
                                right: '0'
                                // color: linkColour === 'white' ? 'white' : 'black'
                            }}
                            onClick={closeClick}
                        >
                            <CloseOutlinedIcon
                                sx={{
                                    fontSize: 25
                                }}
                            />
                        </IconButton>
                    )}
                </HoverIconWrapper>
            )}
            {!isNotification && (isLink || isClose) && !designerView && (
                <HoverIconWrapper>
                    {isLink && (
                        <CopyToClipboard onCopy={onCopy} text={clipBoardValue ?? ''}>
                            <IconButton
                                sx={{
                                    display: isLink ? 'display' : 'none',
                                    mr: 0,
                                    position: 'absolute',
                                    mt: '5px',
                                    // marginBlockStart: '5px',
                                    right: '0',
                                    color: linkColour === 'white' ? 'white' : 'black'
                                }}
                                onClick={toggleLink}
                            >
                                <LinkIcon
                                    className='link'
                                    sx={{
                                        fontSize: 25
                                    }}
                                />
                            </IconButton>
                        </CopyToClipboard>
                    )}
                    {isClose && (
                        <IconButton
                            sx={{
                                display: isClose ? 'display' : 'none',
                                mr: 0,
                                position: 'absolute',
                                mt: '5px',
                                // marginBlockStart: '5px',
                                right: '0'
                                // color: linkColour === 'white' ? 'white' : 'black'
                            }}
                            onClick={closeClick}
                        >
                            <CloseOutlinedIcon
                                sx={{
                                    fontSize: 25
                                }}
                            />
                        </IconButton>
                    )}
                </HoverIconWrapper>
            )}
        </DocumentInformation>
    )
}

export default Header
