import { IGoogleGroup } from 'appData/store'
import { Timestamp } from 'firebase/firestore'
import { GroupModel } from 'service/model/GroupModel'

export const keyUserPerAcademyPermissions = 'create or edit posts for academy'

export interface UserModel {
    uid: string
    name: string
    authProvider: string
    email: string

    domain?: string

    academyCodes?: string[]
    regions?: string[]
    academyPhases?: string[]

    jobFamilies?: string[]
    googleGroups?: IGoogleGroup[]
    linkedEmails?: string[]

    createdAt?: Timestamp
    updatedAt?: Timestamp

    userLinksFolder?: GroupModel

    displayName?: string
    photoUrl?: string
    emailVerified?: boolean
    phoneNumber?: string
    lastLoginAt?: string
}
