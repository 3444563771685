/* eslint-disable @typescript-eslint/no-shadow */
import { Store } from 'appData/store'
import {
    addDoc,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    Query,
    query,
    QuerySnapshot,
    runTransaction,
    setDoc,
    updateDoc,
    where
} from 'firebase/firestore'
import {
    _doSingletonUpdate,
    _getSingleton,
    _getUsersSingleton
} from 'service/controller/Singletons'
import {
    bqUsersCollection,
    db,
    // stagedUsersCollection,
    usersCollection
} from 'service/firebase'
import { AcademySummaryLookup } from 'service/model/AcademySummaryModel'
import { GroupModel, GroupPermission } from 'service/model/GroupModel'
import { LinkModel } from 'service/model/LinkModel'
import { PostModel, PostTypeAnnouncementStatus } from 'service/model/Post'
import {
    flavour,
    PermissionsSingletonModel,
    SingletonsModel,
    UsersSummarySingletonModel
} from 'service/model/SingletonsModel'
import { UserModel } from 'service/model/User'
import {
    exceptCreatedAndUpdated,
    getAcademiesRegionsAndAcademyPhasesForUser,
    json,
    log,
    makeFieldNullIfUndefined,
    makeMapLookup
} from 'service/utils'

export const getUser = async (id: string) => {
    const userDocRef = doc(usersCollection, id)
    const userDoc = await getDoc(userDocRef)
    return userDoc.data()
}

export const mapUserToUserSummaryModel = (
    usr: Pick<
        UserModel,
        | 'name'
        | 'email'
        | 'linkedEmails'
        | 'domain'
        | 'academyCodes'
        | 'regions'
        | 'jobFamilies'
        | 'academyPhases'
    >
) => {
    const {
        name,
        email,
        linkedEmails,
        domain,
        academyCodes,
        regions,
        jobFamilies,
        academyPhases
    } = usr
    if (
        !name ||
        !email ||
        !linkedEmails ||
        !domain ||
        !academyCodes ||
        !regions ||
        !jobFamilies ||
        !academyPhases
    ) {
        log(`null field for user ${json(usr)}`)
    }
    return {
        name,
        email,
        linkedEmails,
        domain,
        academyCodes,
        regions,
        jobFamilies,
        academyPhases
    } as UsersSummarySingletonModel['users'][0]
}

// export const _getUserSummaryOld = async () => {
//     const data = await _getSingleton('users')
//     if (data?.flavour === 'users') {
//         return data.users
//     }

//     return undefined
// }

export const _getUserSummary = async () => {
    const data = await _getUsersSingleton()
    if (data) {
        return data.users
    }
    return undefined
}

export const _updateUserSummary = async ({
    additions = [],
    updates = [],
    deletes = []
}: {
    additions?: UserModel[]
    updates?: UserModel[]
    deletes?: { email: string }[]
}) => {
    return _doSingletonUpdate((lookupModel: SingletonsModel) => {
        if (lookupModel.flavour !== 'users') {
            throw new Error(
                `Flavour should be '${flavour('users')}' but was '${lookupModel.flavour}'`
            )
        }
        const updateMap = makeMapLookup(
            updates,
            ({ email }) => email,
            mapUserToUserSummaryModel
        )
        const deleteMap = makeMapLookup(
            deletes,
            ({ email }) => email,
            u => u
        )
        const existingMap = makeMapLookup(
            lookupModel.users,
            ({ email }) => email,
            u => u
        )

        const updated: SingletonsModel = {
            ...lookupModel,
            users: [
                ...lookupModel.users
                    .filter(({ email }) => !deleteMap.has(email))
                    .map(lu => {
                        const u = updateMap.get(lu.email)
                        if (u) {
                            return { ...lu, ...u }
                        }
                        return lu
                    }),
                ...additions
                    .filter(({ email }) => !existingMap.has(email))
                    .map(mapUserToUserSummaryModel)
            ].sort(({ name: name1 }, { name: name2 }) =>
                name1 !== null && name2 !== null ? name1.localeCompare(name2) : -1
            )
        }

        return updated
    }, 'users')
}

export const getUserByEmail = async (email: string) => {
    const users = await getDocs(query(usersCollection, where('email', '==', email)))
    if (users.size > 1) {
        throw new Error(`Expected a single user matching the supplied email '${email}'`)
    }
    return users.docs[0].data()
}

export const getStagedUserDocSnapByEmail = async (email: string) => {
    const qs = await getDocs(query(bqUsersCollection, where('email', '==', email)))
    if (qs.size > 1) {
        throw new Error(
            `Expected a single stagedUser matching the supplied email '${email}'`
        )
    }
    return qs.docs[0]
}

export const getUptoTenUsersByEmail = async (emails: string[]) => {
    const users = await getDocs(query(usersCollection, where('email', 'in', emails)))
    return users.docs.map(u => u.data())
}

// export const getUsers = async () => {
//     const userDocs = await getDocs(usersCollection)
//     userDocs.docs.forEach(userDoc => {
//         const user = userDoc.data()
//         console.log(user.title)
//     })
// }

export const getUsersWithQuery = async (query_: Query<UserModel>) =>
    getDocs<UserModel>(query_)

export const getUsers = async (filter: string) => {
    let userDocs: QuerySnapshot<UserModel>
    if (!filter) {
        userDocs = await getDocs(usersCollection)
    } else {
        const q = query(usersCollection, where('widget', '==', filter))
        userDocs = await getDocs(q)
    }

    const usersData: UserModel[] = []

    userDocs.docs.forEach(userDoc => {
        usersData.push({ ...userDoc.data() })
    })

    return usersData
}

export const getExistingUsers = async () => {
    const qs = await getDocs(usersCollection)
    const existingUsers = qs.docs.map(u => {
        const { id } = u
        const data = u.data()
        return {
            ...data,
            id
        } as UserModel
    })
    return existingUsers
}

// export const getExistingStagedUsers = async () => {
//     const existingStagedUsers = (await getDocs(stagedUsersCollection)).docs.map(qs =>
//         qs.data()
//     )
//     return existingStagedUsers
// }

export const addLiked = async (userID: string, postModel: PostModel) => {
    const docRef = doc(db, `users/${userID}/userLiked`, postModel.id)
    await setDoc(docRef, {
        id: postModel.id,
        title: postModel.title,
        createdAt: new Date(Date.now())
    })
}

// export const queryContentClicked = async (userID: string, postID: string) => {
//     const docRef = doc(db, `users/${userID}/userContentClicked`, postID)
//     const likedDoc = await getDoc(docRef)
//     return likedDoc.data()
// }

// export const addContentClickedToUser = async (userID: string, postModel: PostModel) => {
//     const docRef = doc(db, `users/${userID}/userContentClicked`, postModel.id)
//     await setDoc(docRef, {
//         id: postModel.id,
//         title: postModel.title,
//         createdAt: new Date(Date.now())
//     })
// }

export const deleteLiked = async (userID: string, postModel: PostModel) => {
    const docRef = doc(db, `users/${userID}/userLiked`, postModel.id)
    deleteDoc(docRef)
}

export const queryLiked = async (userID: string, postID: string) => {
    const docRef = doc(db, `users/${userID}/userLiked`, postID)
    const likedDoc = await getDoc(docRef)
    return likedDoc.data()
}

export const addRead = async (userID: string, postModel: PostModel) => {
    const docRef = doc(db, `users/${userID}/userRead`, postModel.id)
    await setDoc(docRef, {
        id: postModel.id,
        title: postModel.title,
        createdAt: new Date(Date.now())
    })
}

export const deleteRead = async (userID: string, postModel: PostModel) => {
    const docRef = doc(db, `users/${userID}/userRead`, postModel.id)
    deleteDoc(docRef)
}

export const queryRead = async (userID: string, postID: string) => {
    const docRef = doc(db, `users/${userID}/userRead`, postID)
    const likedDoc = await getDoc(docRef)
    return likedDoc.data()
}

export const ChangeAnnouncementStatus = async (
    userID: string,
    status: PostTypeAnnouncementStatus,
    postModel: PostModel
) => {
    const docRef = doc(db, `users/${userID}/userPopupAnnouncement`, postModel.id)
    await setDoc(docRef, {
        id: postModel.id,
        title: postModel.title,
        status,
        createdAt: new Date(Date.now())
    })
}

export const queryReadAnnouncement = async (userID: string, postID: string) => {
    const docRef = doc(db, `users/${userID}/userPopupAnnouncement`, postID)
    const likedDoc = await getDoc(docRef)
    return likedDoc.data()
}

export const addUser = async (props: UserModel) => {
    // const userRef = doc(usersCollection, 'user_12345')
    // await setDoc(userRef, {
    //   age: 30,
    //   firstName: 'Jamie',
    //   lastName: 'Curnow'
    // })

    // {
    //     title: props.title,
    //     link: props.link,
    //     thumbnail: props.thumbnail,
    //     content: props.content,
    //     headerType: props.headerType,
    //     userType: props.userType,
    //     userVisibility: props.userVisibility,
    //     tileSize: props.tileSize
    // }

    const docRef = await addDoc(usersCollection, props)

    await _updateUserSummary({ additions: [props] })

    return docRef.id
}

export const promoteStagedUser = async (
    userModel: UserModel,
    adlm: AcademySummaryLookup
) => {
    let merged: UserModel

    const qdsStagedUser = await getStagedUserDocSnapByEmail(userModel.email)
    if (qdsStagedUser) {
        let { academyCodes, regions, academyPhases } =
            getAcademiesRegionsAndAcademyPhasesForUser(adlm, userModel)

        academyPhases = academyPhases.filter(phase => phase !== null)
        academyCodes = academyCodes.filter(code => code !== null)
        regions = regions.filter(region => region !== null)

        const staged = qdsStagedUser.data()
        merged = {
            ...userModel,
            ...exceptCreatedAndUpdated(staged),
            academyCodes,
            regions,
            academyPhases
        } as UserModel

        makeFieldNullIfUndefined(merged)

        await runTransaction(db, async transaction => {
            const uRef = doc(usersCollection, userModel.uid)
            // const suRef = qdsStagedUser.ref

            transaction.set(uRef, merged)
        })

        // await _updateUserSummary({ additions: [merged] })

        return merged
    }

    return undefined
}

export const gracefullyUpdate = async (
    userModel: UserModel,
    adlm: AcademySummaryLookup
) => {
    // Sanity check if in case previously any user got registered in the database
    const qdsStagedUser = await getStagedUserDocSnapByEmail(userModel.email)
    if (qdsStagedUser) {
        // Check if in case academyCode is blank
        if (userModel.academyCodes?.length === 0) {
            const { academyCodes, regions, academyPhases } =
                getAcademiesRegionsAndAcademyPhasesForUser(adlm, userModel)

            const merged = {
                ...userModel,
                academyCodes,
                regions,
                academyPhases
            } as UserModel

            await runTransaction(db, async transaction => {
                const uRef = doc(usersCollection, userModel.uid)
                // const suRef = qdsStagedUser.ref

                transaction.set(uRef, merged)
            })

            // await _updateUserSummary({ additions: [merged] })

            return merged
        }
        return userModel
    }
    return undefined
}

export const annotateLinksWithUserPermission = (
    links: LinkModel[],
    {
        knownEmailsSet,
        jobFamiliesSet,
        academyCodeSet,
        regionSet
    }: {
        knownEmailsSet: Set<string>
        jobFamiliesSet: Set<string>
        academyCodeSet: Set<string>
        regionSet: Set<string>
    },
    writableLinks?: LinkModel[]
) => {
    const targetLinks = writableLinks || links

    const annotatedLinks = links.map((link, i) => {
        const {
            requiredKnownEmails,
            requiredAcademies,
            requiredRegions,
            requiredJobFamilies
        } = link
        let perm: GroupPermission | undefined

        if (knownEmailsSet) {
            const rke = requiredKnownEmails?.find(({ email }) =>
                knownEmailsSet.has(email)
            )
            if (rke) {
                perm = rke.permission
            }
        }

        if (!perm && jobFamiliesSet) {
            const rjf = requiredJobFamilies?.find(({ name }) => jobFamiliesSet.has(name))
            if (rjf) {
                perm = rjf.permission
            }
        }

        if (!perm && academyCodeSet) {
            const ra = requiredAcademies?.find(({ academyCode }) =>
                academyCodeSet.has(academyCode)
            )
            if (ra) {
                perm = ra.permission
            }
        }

        if (!perm && regionSet) {
            const rr = requiredRegions?.find(({ regionName }) =>
                regionSet.has(regionName)
            )
            if (rr) {
                perm = rr.permission
            }
        }

        return { ...targetLinks[i], userPermission: perm || 'none' }
    })

    return annotatedLinks
}

export const annotateGroupWithUserPermission = (
    group: GroupModel,
    {
        knownEmailsSet,
        jobFamiliesSet,
        academyCodeSet,
        regionSet
    }: {
        knownEmailsSet: Set<string>
        jobFamiliesSet: Set<string>
        academyCodeSet: Set<string>
        regionSet: Set<string>
    }
) => {
    const {
        requiredKnownEmails,
        requiredAcademies,
        requiredRegions,
        requiredJobFamilies
    } = group

    let perm: GroupPermission | undefined

    if (knownEmailsSet) {
        const rke = requiredKnownEmails?.find(({ email }) => knownEmailsSet.has(email))
        if (rke) {
            perm = rke.permission
        }
    }

    if (!perm && jobFamiliesSet) {
        const rjf = requiredJobFamilies?.find(({ name }) => jobFamiliesSet.has(name))
        if (rjf) {
            perm = rjf.permission
        }
    }

    if (!perm && academyCodeSet) {
        const ra = requiredAcademies?.find(({ academyCode }) =>
            academyCodeSet.has(academyCode)
        )
        if (ra) {
            perm = ra.permission
        }
    }

    if (!perm && regionSet) {
        const rr = requiredRegions?.find(({ regionName }) => regionSet.has(regionName))
        if (rr) {
            perm = rr.permission
        }
    }

    return {
        ...group,
        links: annotateLinksWithUserPermission(group.links, {
            knownEmailsSet,
            jobFamiliesSet,
            academyCodeSet,
            regionSet
        }),
        userPermission: perm || 'none'
    }
}

export const annotateGroupsWithUserPermission = (
    groups: GroupModel[],
    {
        knownEmailsSet,
        jobFamiliesSet,
        academyCodeSet,
        regionSet
    }: {
        knownEmailsSet: Set<string>
        jobFamiliesSet: Set<string>
        academyCodeSet: Set<string>
        regionSet: Set<string>
    }
) => {
    const annotatedGroups = groups.map(group =>
        annotateGroupWithUserPermission(group, {
            knownEmailsSet,
            jobFamiliesSet,
            academyCodeSet,
            regionSet
        })
    )

    return annotatedGroups
}

export const _userHasPermission = (
    permission: PermissionsSingletonModel['permissions'][0],
    currentUser: Store['currentUser']
) => {
    if (!permission || !currentUser) {
        return false
    }
    const {
        requiredKnownEmails,
        requiredJobFamilies,
        requiredAcademies,
        requiredRegions,
        name
    } = permission

    const {
        userKnownEmailsSet,
        userJobFamiliesSet,
        userAcademySet,
        userRegionSet,
        userPerAcademyPermissions
    } = currentUser

    if (name === 'access myFocus') {
        let hasPerm = false

        if (!hasPerm) {
            const rjf = requiredJobFamilies?.find(name => userJobFamiliesSet?.has(name))
            if (rjf) {
                hasPerm = true
            }
        }

        if (!hasPerm) {
            const ra = requiredAcademies?.find(academyCode =>
                userAcademySet?.has(academyCode)
            )
            if (ra) {
                hasPerm = true
            }
        }

        if (!hasPerm) {
            const rr = requiredRegions?.find(regionName => userRegionSet?.has(regionName))
            if (rr) {
                hasPerm = true
            }
        }
        if (userKnownEmailsSet) {
            const rke = requiredKnownEmails?.find(email => userKnownEmailsSet?.has(email))
            if (rke) {
                hasPerm = true
            } else {
                hasPerm = false
            }
        }

        return hasPerm
    }

    let hasPerm = false

    if (userKnownEmailsSet) {
        const rke = requiredKnownEmails?.find(email => userKnownEmailsSet?.has(email))
        if (rke) {
            hasPerm = true
        }
    }

    if (!hasPerm) {
        const rjf = requiredJobFamilies?.find(name => userJobFamiliesSet?.has(name))
        if (rjf) {
            hasPerm = true
        }
    }

    if (!hasPerm) {
        const ra = requiredAcademies?.find(academyCode =>
            userAcademySet?.has(academyCode)
        )
        if (ra) {
            hasPerm = true
        }
    }

    if (!hasPerm) {
        const rr = requiredRegions?.find(regionName => userRegionSet?.has(regionName))
        if (rr) {
            hasPerm = true
        }
    }

    return hasPerm
}

export const setUser = async (props: UserModel, id: string) => {
    const userRef = doc(usersCollection, id)

    setDoc(userRef, props)

    return id
}

export const updateUser = async (id: string, user: UserModel) => {
    const userRef = doc(usersCollection, id)

    // await setDoc(userRef, {
    //     age: 30,
    //     firstName: 'Jamie',
    //     lastName: 'Curnow'
    //   })

    await updateDoc(userRef, user)
    await _updateUserSummary({ updates: [user] })
}

export const deleteUser = async (id: string) => {
    const userRef = doc(usersCollection, id)

    deleteDoc(userRef)
}
