import SearchOutlined from '@mui/icons-material/SearchOutlined'
import { Box, Card, MenuItem, TextField, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { debounce } from '@mui/material/utils'
import { animated, useSpring } from '@react-spring/web'
import FlexBox from 'FlexBox'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from 'service/firebase'

// styled components
// also used in the GrocerySearchBox component
export const SearchOutlinedIcon = styled(SearchOutlined)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginRight: 6
}))

// also used in the GrocerySearchBox component
export const SearchResultCard = styled(Card)(({ theme }) => ({
    position: 'absolute',
    top: '100%',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    width: '100%',
    zIndex: 99
}))

const DropDownHandler = styled(FlexBox)(({ theme }) => ({
    borderTopRightRadius: 300,
    borderBottomRightRadius: 300,
    whiteSpace: 'pre',
    borderLeft: `1px solid ${theme.palette.text.disabled}`,
    [theme.breakpoints.down('xs')]: {
        display: 'none'
    }
}))

export const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) {
        newWindow.opener = null
    }
}

const SearchBox: FC = () => {
    const { themeColor } = useCustomTheme()
    const [category, setCategory] = useState('All Categories')
    const [resultList, setResultList] = useState<string[]>([])
    const parentRef = useRef()
    const [user, loading, error] = useAuthState(auth)
    const [searchQuery, setSearchQuery] = useState('')
    const { theme } = useCustomTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const handleCategoryChange = (cat: any) => () => {
        setCategory(cat)
    }

    const search = debounce(e => {
        const value = e.target?.value

        if (!value) {
            setResultList([])
        } else {
            setResultList(dummySearchResult)
        }
    }, 200)

    const handleSearch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            event.persist()
            search(event)
        },
        [search]
    )

    const handleDocumentClick = () => {
        setResultList([])
    }

    useEffect(() => {
        window.addEventListener('click', handleDocumentClick)
        return () => {
            window.removeEventListener('click', handleDocumentClick)
        }
    }, [])
    const searching = searchQuery !== ''
    const AnimatedSearchBox = animated(Box)
    const boxStyles = useSpring({
        backgroundColor: searching ? themeColor : 'white'
    })
    const AnimatedSearchIcon = animated(SearchOutlinedIcon)
    const iconStyles = useSpring({
        color: searching ? 'white' : themeColor
    })
    const NAVBAR_LOGO_WIDTH = isSmallScreen ? 40 : 120

    return (
        <>
            <img
                alt='Lift School Navbar Logo'
                width={NAVBAR_LOGO_WIDTH}
                height={40}
                src={`${process.env.PUBLIC_URL}/images/png/lift_school_teal_logo${
                    isSmallScreen ? '_mini' : ''
                }.png`}
                style={{
                    position: 'absolute',
                    left: `${isSmallScreen ? '5px' : '7px'}`,
                    top: '18px'
                }}
            />
            <Box
                display={{
                    xs: 'none',
                    sm: 'flex',
                    paddingLeft: `${NAVBAR_LOGO_WIDTH}px`
                }}
                flex={{ lg: '6.2 1 0' }}
                {...{ ref: parentRef }}
            >
                <TextField
                    variant='outlined'
                    placeholder='Search your Google Drive...'
                    fullWidth
                    onChange={event => {
                        const value = event.target?.value
                        setSearchQuery(value)
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        sx: {
                            'height': '50px',
                            'backgroundColor': '#fff',
                            'borderRadius': '104px',
                            'paddingRight': 0,
                            'input-label': {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: '100%'
                            },

                            'input': {
                                '&::placeholder': {
                                    textOverflow: 'ellipsis !important',
                                    color: 'black'
                                }
                            },
                            'fontSize': '16px'
                        },
                        endAdornment: (
                            <AnimatedSearchBox
                                sx={{
                                    height: '100%',
                                    borderTopRightRadius: 25,
                                    borderBottomRightRadius: 25,
                                    cursor: searching ? 'pointer' : 'auto'
                                }}
                                style={boxStyles}
                            >
                                <AnimatedSearchIcon
                                    fontSize='small'
                                    sx={{
                                        marginRight: 2,
                                        justifyContent: 'center',
                                        marginTop: 2,
                                        marginLeft: 2
                                    }}
                                    onClick={() => {
                                        if (searching) {
                                            openInNewTab(
                                                `https://drive.google.com/drive/search?q=${searchQuery}`
                                            )
                                        }
                                    }}
                                    style={iconStyles}
                                />
                            </AnimatedSearchBox>
                        )
                        // startAdornment: <SearchOutlinedIcon fontSize='small' />,
                    }}
                />
                {!!resultList.length && (
                    <SearchResultCard elevation={2}>
                        {resultList.map(item => (
                            <MenuItem key={item}>{item}</MenuItem>
                        ))}
                    </SearchResultCard>
                )}
            </Box>
        </>
    )
}

const dummySearchResult = [
    'Macbook Air 13',
    'Asus K555LA',
    'Acer Aspire X453',
    'iPad Mini 3'
]

export default SearchBox
