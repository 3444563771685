/* eslint-disable import/extensions */
// Require Editor JS files.

// import 'froala-editor/js/froala_editor.pkgd.min.js'

// // Require Editor CSS files.
// import 'froala-editor/css/froala_editor.pkgd.min.css'
// import 'froala-editor/css/froala_style.min.css'

// import 'froala-editor/css/plugins.pkgd.min.css'
// import 'froala-editor/js/plugins.pkgd.min.js'

// import 'froala-editor/css/third_party/font_awesome.min.css'
// import 'froala-editor/js/third_party/font_awesome.min.js'

// import 'froala-editor/css/third_party/embedly.min.css'
// import 'froala-editor/js/third_party/embedly.min.js'

// import 'froala-editor/css/third_party/image_tui.min.css'
// import 'froala-editor/js/third_party/image_tui.min.js'

// import './FroalaEditorCustomCssClasses.css'

// // import 'froala-editor/css/third_party/spell_checker.min.css'
// // import 'froala-editor/js/third_party/spell_checker.min.js'

// // Require Font Awesome.
// // Require Font Awesome.
// import 'font-awesome/css/font-awesome.css'

import { Link as LinkIcon } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import FroalaEditor from 'react-froala-wysiwyg'

// Include special components if required.
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Tab,
    Tabs,
    TextField,
    Typography,
    useTheme
} from '@mui/material'
import NewsTile from 'components/tiles/newsTile/NewsTile'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

import 'pages/FroalaScript/FroalaEditorCustomScripts'

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        'id': `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    }
}

export default function HomePage() {
    const [model, setModel] = useState<string | null>('Example text')
    const [model2, setModel2] = useState<string | null>('Example text')
    const [leftPadding, setLeftPadding] = useState<string | null>('10px')
    const [tileSize, setTileSize] = useState<string | null>('single')
    const [headerType, setHeaderType] = useState<string | null>('static')

    const leftPaddingHandleChange = (event: SelectChangeEvent) => {
        localStorage.setItem('leftPadding', event.target.value)
        setLeftPadding(event.target.value)
    }

    const tileSizeHandleChange = (event: SelectChangeEvent) => {
        localStorage.setItem('tileSize', event.target.value)
        setTileSize(event.target.value)
    }

    const headerTypeHandleChange = (event: SelectChangeEvent) => {
        localStorage.setItem('headerType', event.target.value)
        setHeaderType(event.target.value)
    }

    const modelHandleChange = (editorModel: any) => {
        localStorage.setItem('model', editorModel)
        setModel(editorModel)
    }

    const modelHandleChange2 = (editorModel: any) => {
        localStorage.setItem('model2', editorModel)
        setModel2(editorModel)
    }

    const theme = useTheme()
    const [value, setValue] = useState(0)

    useEffect(() => {}, [])

    useEffect(() => {
        setModel(
            localStorage.getItem('model') !== null ? localStorage.getItem('model') : ''
        )

        setModel2(
            localStorage.getItem('model2') !== null ? localStorage.getItem('model2') : ''
        )

        setTileSize(
            localStorage.getItem('tileSize') !== null
                ? localStorage.getItem('tileSize')
                : ''
        )

        setHeaderType(
            localStorage.getItem('headerType') !== null
                ? localStorage.getItem('headerType')
                : ''
        )

        setLeftPadding(
            localStorage.getItem('leftPadding') !== null
                ? localStorage.getItem('leftPadding')
                : ''
        )
    }, [])

    const allToolbarButtons = {
        moreText: {
            buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting'
            ]
        },

        markdown: { buttons: ['markdown'] },

        moreParagraph: {
            buttons: [
                'alignLeft',
                'alignCenter',
                'formatOLSimple',
                'alignRight',
                'alignJustify',
                'formatOL',
                'formatUL',
                'paragraphFormat',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
                'quote'
            ]
        },

        moreRich: {
            buttons: [
                'insertLink',
                'insertImage',
                'insertVideo',
                'insertTable',
                'emoticons',
                'fontAwesome',
                'specialCharacters',
                'embedly',
                'insertFile',
                'insertHR'
            ]
        },

        moreMisc: {
            buttons: [
                'undo',
                'redo',
                'fullscreen',
                'print',
                'getPDF',
                'spellChecker',
                'selectAll',
                'html',
                // 'help',
                'alert',
                'clear',
                'insert',
                'insert2',
                'insertTile',
                'insertTile2'
            ],

            align: 'right',

            buttonsVisible: 2
        }
    }

    const toolbarButtons = [
        'bold',
        'italic',
        'underline',
        'paragraphFormat',
        'align',
        'markdown',
        'undo',
        'redo'
    ]

    const toolbarMarkDown = [
        ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'markdown']
    ]

    const config = {
        placeholderText: 'Edit Your Content Here!!!',
        charCounterCount: false,
        toolbarButtons: allToolbarButtons,
        imageStyles: {
            imageRadius: 'Radius',
            imageBorder: 'Border'
        },
        tableCellStyles: {
            tblCellLeftPadding: 'Padding Left',
            tblCellTopPadding: 'Padding Top'
        },
        tableStyles: {
            tblLeftPadding: 'Padding Left',
            tblTopPadding: 'Padding Top'
        },
        inlineClasses: {
            'fr-class-code': 'Code',
            'fr-class-highlighted': 'Highlighted',
            'fr-class-transparency': 'Transparent'
        },
        fontFamily: {
            'Roboto,sans-serif': 'Roboto',
            'Manrope,sans-serif': 'Manrope',
            'Montserrat,sans-serif': 'Montserrat',
            "'Open Sans Condensed',sans-serif": 'Open Sans Condensed'
        },
        fontSize: ['8', '10', '12', '14', '17', '18', '30', '60', '96'],
        // fontFamilySelection: true,
        colorsBackground: [
            '#15E67F',
            '#E3DE8C',
            '#D8A076',
            '#D83762',
            '#76B6D8',
            'REMOVE',
            '#1C7A90',
            '#249CB8',
            '#4ABED9',
            '#FBD75B',
            '#FBE571',
            '#FFFFFF'
        ],
        colorsStep: 6,
        colorsText: [
            '#15E67F',
            '#E3DE8C',
            '#D8A076',
            '#D83762',
            '#76B6D8',
            'REMOVE',
            '#1C7A90',
            '#249CB8',
            '#4ABED9',
            '#FBD75B',
            '#FBE571',
            '#FFFFFF'
        ],
        emoticonsStep: 4,
        emoticonsSet: [
            {
                id: 'people',
                name: 'Smileys & People',
                code: '1f600',
                emoticons: [
                    {
                        code: '1f600',
                        desc: 'Grinning face'
                    },
                    {
                        code: '1f601',
                        desc: 'Grinning face with smiling eyes'
                    },
                    {
                        code: '1f602',
                        desc: 'Face with tears of joy'
                    },
                    {
                        code: '1f603',
                        desc: 'Smiling face with open mouth'
                    },
                    {
                        code: '1f604',
                        desc: 'Smiling face with open mouth and smiling eyes'
                    },
                    {
                        code: '1f605',
                        desc: 'Smiling face with open mouth and cold sweat'
                    },
                    {
                        code: '1f606',
                        desc: 'Smiling face with open mouth and tightly-closed eyes'
                    },
                    {
                        code: '1f607',
                        desc: 'Smiling face with halo'
                    }
                ]
            },
            {
                id: 'nature',
                name: 'Animals & Nature',
                code: '1F435',
                emoticons: [
                    {
                        code: '1F435',
                        desc: 'Monkey Face'
                    },
                    {
                        code: '1F412',
                        desc: 'Monkey'
                    },
                    {
                        code: '1F436',
                        desc: 'Dog Face'
                    },
                    {
                        code: '1F415',
                        desc: 'Dog'
                    },
                    {
                        code: '1F429',
                        desc: 'Poodle'
                    },
                    {
                        code: '1F43A',
                        desc: 'Wolf Face'
                    },
                    {
                        code: '1F431',
                        desc: 'Cat Face'
                    },
                    {
                        code: '1F408',
                        desc: 'Cat'
                    },
                    {
                        code: '1F42F',
                        desc: 'Tiger Face'
                    },
                    {
                        code: '1F405',
                        desc: 'Tiger'
                    },
                    {
                        code: '1F406',
                        desc: 'Leopard'
                    },
                    {
                        code: '1F434',
                        desc: 'Horse Face'
                    },
                    {
                        code: '1F40E',
                        desc: 'Horse'
                    },
                    {
                        code: '1F42E',
                        desc: 'Cow Face'
                    },
                    { code: '1F402', desc: 'Ox' },
                    {
                        code: '1F403',
                        desc: 'Water Buffalo'
                    }
                ]
            }
        ],

        heightMin: 300,
        heightMax: 300
    }

    const config2 = {
        placeholderText: 'Edit Your Content Here!!!',
        charCounterCount: false,
        toolbarButtons: toolbarMarkDown,
        fontFamily: {
            'Roboto,sans-serif': 'Roboto',
            'Manrope,sans-serif': 'Manrope',
            'Montserrat,sans-serif': 'Montserrat',
            "'Open Sans Condensed',sans-serif": 'Open Sans Condensed'
        },
        fontSize: ['8', '10', '12', '14', '17', '18', '30', '60', '96'],
        heightMin: 350,
        heightMax: 350
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Box
            sx={{
                borderBottom: 1,
                borderColor: 'divider'
                // border: 1
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                    title: 'indicator',
                    hidden: true,
                    sx: { backgroundColor: 'red', height: 4 }
                }}
                sx={{
                    'borderBottom': 1,
                    'borderColor': 'divider',
                    'backgroundColor': '#F5F5F5',
                    'color': theme.palette.primary.main,
                    '& button': { borderRadius: 0 },
                    '& button:hover': {
                        backgroundColor: '#F5F5F5'
                    },
                    '& button:focus': {
                        backgroundColor: '#FFFFFF'
                    },
                    '& button:active': {
                        backgroundColor: '#F5F5F5'
                    }
                }}
            >
                <Tab label='META' {...a11yProps(0)} />
                <Tab label='THUMBNAIL' {...a11yProps(1)} />
                <Tab label='CONTENT' {...a11yProps(2)} />
                <Tab label='STATS' {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Box>
                    <Typography
                        fontStyle='normal'
                        fontSize='18px'
                        fontWeight={700}
                        color='#333333'
                        lineHeight='24px'
                        sx={{
                            mt: '10px',
                            mb: '10px'
                        }}
                    >
                        New Post :
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            ml: '10px',
                            mb: '10px'
                        }}
                    >
                        <TextField
                            id='outlined-basic'
                            label='Title'
                            placeholder='e.g. Ofsted offer “Outstanding” result to St Mary’s Primary School'
                            variant='outlined'
                            sx={{ width: '885px' }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            ml: '10px',
                            mb: '10px'
                        }}
                    >
                        <TextField
                            id='outlined-basic'
                            label='Link'
                            placeholder=''
                            variant='outlined'
                            sx={{ width: '885px' }}
                            InputProps={{
                                sx: {},
                                endAdornment: (
                                    <LinkIcon
                                        className='link'
                                        sx={{
                                            fontSize: 25
                                        }}
                                    />
                                )
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            ml: '10px',
                            mb: '10px'
                        }}
                    >
                        <TextField
                            select // tell TextField to render select
                            id='demo-simple-select'
                            // value={tileSize || 'single'}
                            label='Post Type'
                            // onChange={tileSizeHandleChange}
                            sx={{ width: '339px' }}
                        >
                            <MenuItem selected value='News'>
                                News
                            </MenuItem>
                            <MenuItem value='Notification'>Notification</MenuItem>
                            <MenuItem value='Notification'>Stat</MenuItem>
                        </TextField>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            ml: '10px',
                            mb: '10px'
                        }}
                    >
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={tileSize || 'single'}
                            label='Tile Size'
                            onChange={tileSizeHandleChange}
                            sx={{
                                mr: '10px'
                            }}
                        >
                            <MenuItem selected value='single'>
                                Single Tile
                            </MenuItem>
                            <MenuItem value='double'>Double Tile</MenuItem>
                        </Select>

                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={headerType || 'static'}
                            label='Header Type'
                            onChange={headerTypeHandleChange}
                            sx={{
                                mr: '10px'
                            }}
                        >
                            <MenuItem selected value='static'>
                                Static Header
                            </MenuItem>
                            <MenuItem value='absolute'>Absolute Header</MenuItem>
                        </Select>

                        {/* <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={leftPadding || '0px'}
                            label='Left Padding'
                            onChange={leftPaddingHandleChange}
                        >
                            <MenuItem selected value='0px'>
                                0px
                            </MenuItem>
                            <MenuItem value='5px'>5px</MenuItem>
                            <MenuItem value='10px'>10px</MenuItem>
                            <MenuItem value='15px'>15px</MenuItem>
                            <MenuItem value=' 20px'>20px</MenuItem>
                        </Select> */}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            ml: '10px',
                            mb: '10px'
                        }}
                    >
                        <Box
                            sx={{
                                mr: '150px'
                            }}
                        >
                            <FormControl>
                                <FormLabel id='demo-radio-buttons-group-label'>
                                    Visiblity
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby='demo-radio-buttons-group-label'
                                    defaultValue='female'
                                    name='radio-buttons-group'
                                >
                                    <FormControlLabel
                                        value='Public'
                                        control={<Radio />}
                                        label='Public'
                                    />
                                    <FormControlLabel
                                        value='Private'
                                        control={<Radio />}
                                        label='Private'
                                    />
                                    <FormControlLabel
                                        value='Specific Members'
                                        control={<Radio />}
                                        label='Specific Members'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box>
                            <Typography
                                fontStyle='normal'
                                fontSize='12px'
                                fontWeight={400}
                                color='#333333'
                                lineHeight='15px'
                                sx={{ mt: '10px', mb: '10px' }}
                            >
                                ENTER MEMBERS NAMES
                            </Typography>
                            <TextField
                                id='outlined-multiline-flexible'
                                // label='Multiline'
                                placeholder='e.g. Hannah Latawski, Andrew Howden, James Dean'
                                multiline
                                rows={3}
                                sx={{ width: '632px' }}
                                // value={value}
                                // onChange={handleChange}
                            />
                        </Box>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box sx={{ mr: '10px' }}>
                        <Typography
                            fontStyle='normal'
                            fontSize='17px'
                            fontWeight={700}
                            color='#333333'
                            lineHeight='15px'
                            sx={{ mt: '10px', mb: '10px' }}
                        >
                            Tile Editing Form
                        </Typography>
                        <Box sx={{ width: '900px' }}>
                            <FroalaEditor
                                tag='textarea'
                                config={config}
                                model={model}
                                onModelChange={modelHandleChange}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            fontStyle='normal'
                            fontSize='17px'
                            fontWeight={700}
                            color='#333333'
                            lineHeight='15px'
                            sx={{ mt: '10px', mb: '10px' }}
                        >
                            Tile Preview
                        </Typography>
                        <Grid columns={5} container spacing={2} border={0}>
                            <NewsTile
                                xs={12}
                                md={8}
                                lg={2}
                                width={window.innerWidth}
                                xl={tileSize === 'single' ? 1 : 2}
                                isTrending
                                headerStatic={headerType === 'static'}
                                contentPaddingLeft={leftPadding || '0px'}
                            >
                                <FroalaEditorView model={model} />
                            </NewsTile>
                        </Grid>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Box sx={{ mr: '10px' }}>
                    <Typography
                        fontStyle='normal'
                        fontSize='17px'
                        fontWeight={700}
                        color='#333333'
                        lineHeight='15px'
                        sx={{ mt: '10px', mb: '10px' }}
                    >
                        Content Editing Form
                    </Typography>
                    <Box sx={{ width: '900px' }}>
                        <FroalaEditor
                            tag='textarea'
                            config={config2}
                            model={model2}
                            onModelChange={modelHandleChange2}
                        />
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
                Stats
            </TabPanel>
            <Box
                justifyContent='flex-end'
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: '10px',
                    mb: '10px'
                }}
            >
                <Button
                    sx={{
                        mr: '10px',
                        width: '134px',
                        height: '42px',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '17px'
                    }}
                    variant='outlined'
                >
                    Save Draft
                </Button>
                <Button
                    sx={{
                        width: '108px',
                        height: '42px',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '17px'
                    }}
                    variant='contained'
                >
                    Publish
                </Button>
            </Box>
        </Box>
    )
}
