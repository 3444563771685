import { _doSingletonUpdate, _getSingleton } from 'service/controller/Singletons'
import { SingletonsModel } from 'service/model/SingletonsModel'

export const _updateKnownEmails = async (
    updater: (model: SingletonsModel) => SingletonsModel | undefined
) => {
    return _doSingletonUpdate(updater, 'knownEmails')
}

export const _getKnownEmails = async () => {
    const data = await _getSingleton('knownEmails')
    if (data?.flavour === 'knownEmails') {
        return data.knownEmails
    }
    return undefined
}
export const knownEmailSorter = (email1: string, email2: string) =>
    email1.localeCompare(email2)
