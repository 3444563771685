/* eslint-disable no-nested-ternary */
import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

function Loader() {
    const backdropStyle = {
        color: '#fff !important',
        zIndex: (theme: { zIndex: { drawer: number } }) => theme.zIndex.drawer + 1
    }

    return (
        <Backdrop open sx={backdropStyle}>
            <CircularProgress color='inherit' />
        </Backdrop>
    )
}

export default Loader

Loader.defaultProps = {
    items: false,
    floating: false
}
