import { Divider } from '@mui/material'
import { PrimaryListItems } from 'components/navbar/Content/PrimaryListItems'
import { SecondaryListItems } from 'components/navbar/Content/SecondaryListItems'
import { TertiaryListItems } from 'components/navbar/Content/TertiaryListItems'
import { NavBarContentProps } from 'components/navbar/types'

export const TopNavBarContent = (navContext: NavBarContentProps) => {
    const { open } = navContext
    return (
        <>
            <PrimaryListItems {...navContext} />
            {open && <Divider />}

            <SecondaryListItems {...navContext} />
            {open && <Divider />}

            <TertiaryListItems {...navContext} />
        </>
    )
}
