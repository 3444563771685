import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography
} from '@mui/material'
import { Store, useStore } from 'appData/store'
import { ImageComponent } from 'components/utils/ImageComponent'
import dayjs from 'dayjs'
import { Timestamp } from 'firebase/firestore'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { useContext, useEffect } from 'react'
import AppContext from 'store/contexts/AppContext'
import shallow from 'zustand/shallow'

const getPrimaryThing = (things: any[]) => things?.find(thing => !!thing.metadata.primary)

const extractPersonDetail = (json: any) => {
    const name1 = getPrimaryThing(json.names)
    const name = {
        firstName: name1?.givenName,
        surName: name1?.familyName,
        displayName: name1?.displayName
    }
    const photoUri = getPrimaryThing(json.photos)?.url
    const email = getPrimaryThing(json.emailAddresses)?.value

    return {
        name,
        photoUri,
        email
    }
}

const storeSelector = (store: Store) => ({
    zCurrentUser: store.currentUser,
    currentUserInfo: store.currentUserInfo,
    setCurrentUserInfo: store.setCurrentUserInfo,
    jobFamilies: store.jobFamilies,
    academies: store.academies,
    regions: store.regions,
    academyCodeLookup: store.academyCodeLookup
})

function NewProfilePage() {
    const authCtx = useContext(AppContext)
    const { themeColor } = useCustomTheme()

    const { zCurrentUser, academies, setCurrentUserInfo } = useStore(
        storeSelector,
        shallow
    )

    // useLogAuthContext('ProfilePage')

    useEffect(() => {
        fetch(
            'https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses,photos,organizations&sources=READ_SOURCE_TYPE_PROFILE',
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authCtx.state.auth.credentialToken}`
                }
            }
        )
            .then(resp => resp.json())
            .then(extractPersonDetail)
            .then(personDetail => setCurrentUserInfo(personDetail))
    }, [authCtx.state.auth.credentialToken, setCurrentUserInfo])

    const formatFirebaseDate = (dateVal: Timestamp | null) => {
        return dateVal ? dayjs(dateVal.toDate()).format('DD/MM/YYYY hh:mm A') : ''
    }

    const personImageSize = 60

    const userAcademies = academies
        .filter(acad => zCurrentUser?.user.academyCodes?.includes(acad.academyCode))
        .map(academy => academy.academy)
        .join(', ')

    // const lastLoggedInDate = new Date(zCurrentUser?.user.lastLoginAt ?? '')
    const strLastLoggedInDate = dayjs(zCurrentUser?.user.lastLoginAt ?? '').format(
        'DD/MM/YYYY hh:mm A'
    ) // lastLoggedInDate.toLocaleString()

    return (
        <Box display='flex' justifyContent='center' alignItems='center' mt={4}>
            <Paper elevation={3} sx={{ p: 2 }}>
                {zCurrentUser ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack flexDirection='row' columnGap={2}>
                                <ImageComponent
                                    src={zCurrentUser?.user.photoUrl ?? ''}
                                    alt='Profile'
                                    width={personImageSize}
                                    height={personImageSize}
                                />
                                <Box>
                                    <Box
                                        pt={0.5}
                                        pb={0.5}
                                        mb={2}
                                        justifyContent='center'
                                        alignContent='center'
                                        alignItems='center'
                                    >
                                        <Typography variant='h3'>
                                            {zCurrentUser?.user.displayName ?? 'NA'}
                                        </Typography>
                                        <Typography fontSize={16}>
                                            {zCurrentUser?.user.email}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box display='flex' mt={2}>
                                        <Typography
                                            mr={1}
                                            fontWeight='bold'
                                            color={themeColor}
                                            fontSize={16}
                                        >
                                            Academies -
                                        </Typography>
                                        <Typography fontSize={16}>
                                            {userAcademies}
                                        </Typography>
                                    </Box>

                                    {zCurrentUser?.user.regions &&
                                    zCurrentUser?.user.regions.length > 0 ? (
                                        <Box display='flex'>
                                            <Typography
                                                mr={1}
                                                fontWeight='bold'
                                                color={themeColor}
                                                fontSize={16}
                                            >
                                                Region -
                                            </Typography>
                                            <Typography fontSize={16}>
                                                {zCurrentUser?.user.regions}
                                            </Typography>
                                        </Box>
                                    ) : null}

                                    {zCurrentUser?.user.jobFamilies &&
                                    zCurrentUser?.user.jobFamilies.length > 0 ? (
                                        <Box display='flex'>
                                            <Typography
                                                mr={1}
                                                fontWeight='bold'
                                                color={themeColor}
                                                fontSize={16}
                                            >
                                                Job Families -
                                            </Typography>
                                            <Typography fontSize={16}>
                                                {zCurrentUser?.user.jobFamilies}
                                            </Typography>
                                        </Box>
                                    ) : null}

                                    <Box display='flex' mb={2}>
                                        <Typography
                                            mr={1}
                                            fontWeight='bold'
                                            color={themeColor}
                                            fontSize={16}
                                        >
                                            Joined on -
                                        </Typography>
                                        <Typography fontSize={16}>
                                            {zCurrentUser?.user.createdAt
                                                ? formatFirebaseDate(
                                                      zCurrentUser?.user.createdAt
                                                  )
                                                : 'NA'}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box display='flex' mt={2}>
                                        <Typography
                                            mr={1}
                                            fontWeight='bold'
                                            color={themeColor}
                                            fontSize={16}
                                        >
                                            Last logged in at -
                                        </Typography>
                                        <Typography fontSize={16}>
                                            {strLastLoggedInDate}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                ) : (
                    <CircularProgress />
                )}
            </Paper>
        </Box>
    )
}

export default NewProfilePage
