/* eslint-disable no-nested-ternary */
import PermissionsIcon from '@mui/icons-material/Key'
import {
    cellEmphasisColor,
    CollapsedPerAcademyPermissionExceptions
} from 'pages/Permissions/PermissionsPage'
import { Fragment, memo } from 'react'

import { Box, Stack } from '@mui/material'
import { Store } from 'appData/store'
import { UnwrapRecordValue } from 'components/navbar/types'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { NavPermissionAssignmentButton } from 'pages/Permissions/PermissionsPage/NavPermissionAssignmentButton'
import { PerAcademyPermissionRowsParams } from 'pages/Permissions/PermissionsPage/PerAcademyPermissionRows'
import { getAcademyTooltip } from 'service/model/AcademyModel'
import { PerAcademyPermissionSingletonModel } from 'service/model/SingletonsModel'

export const PerAcademyPermissionAcademyRows = memo(
    ({
        blockAdjacency,
        perAcademyPermission,
        academyCodeLookup,
        collapsedException,
        knownEmails,
        jobFamilies,
        academies,
        regions,
        changedPerAcademyPerms,
        transitionStr,
        onPerAcademyPermAssignmentChanged,
        onPerAcademyPermissionClick,
        emphasized
    }: Omit<
        PerAcademyPermissionRowsParams,
        'perAcademyPermissions' | 'collapsedPerAcademyPermissionExceptions'
    > & {
        perAcademyPermission: PerAcademyPermissionSingletonModel['perAcademyPermissions'][0]
        academyCodeLookup: Store['academyCodeLookup']
        collapsedException: UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions>
    }) => {
        const { theme } = useCustomTheme()
        const lastKnownEmailIndex = knownEmails.length - 1
        const lastJobFamilyIndex = jobFamilies.length - 1
        const lastAcademyIndex = academies.length - 1
        const lastRegionIndex = regions.length - 1

        const {
            knownEmailsHasFollowingBlock,
            jobFamiliesIsFirstBlock,
            jobFamiliesHasFollowingBlock,
            academiesHasFollowingBlock,
            academiesIsFirstBlock,
            regionsIsFirstBlock
        } = blockAdjacency

        const academyPermissions =
            collapsedException === 'open' ? perAcademyPermission.academyPermissions : []
        return (
            <>
                {academyPermissions.map(
                    ({
                        academyCode: papAcademyCode,
                        requiredKnownEmails,
                        requiredJobFamilies,
                        requiredAcademies,
                        requiredRegions
                    }) => {
                        const localOnClick = () => {
                            onPerAcademyPermissionClick?.(
                                perAcademyPermission.name,
                                papAcademyCode
                            )
                        }

                        const academy = academyCodeLookup[papAcademyCode]

                        const papKnownEmailPerms = new Set(requiredKnownEmails || [])
                        const papJobFamilyPerms = new Set(requiredJobFamilies || [])
                        const papAcademyPerms = new Set(requiredAcademies || [])
                        const papRegionPerms = new Set(requiredRegions || [])

                        const changedPerm =
                            changedPerAcademyPerms[perAcademyPermission.name]?.[
                                papAcademyCode
                            ]

                        const changedPermOriginalKnownEmailRights = changedPerm
                            ? changedPerm?.originalKnownEmailRights
                            : papKnownEmailPerms

                        const changedPermOriginalJobFamilyRights = changedPerm
                            ? changedPerm?.originalJobFamilyRights
                            : papJobFamilyPerms

                        const changedPermOriginalAcademyRights = changedPerm
                            ? changedPerm?.originalAcademyRights
                            : papAcademyPerms

                        const changedPermOriginalRegionRights = changedPerm
                            ? changedPerm?.originalRegionRights
                            : papRegionPerms

                        const emphasizeRow =
                            emphasized.perAcademyPermissions[perAcademyPermission.name]?.[
                                papAcademyCode
                            ]

                        const topBorderWidth = 0

                        return (
                            <Fragment key={papAcademyCode}>
                                <Box
                                    sx={{
                                        backgroundColor: emphasizeRow
                                            ? cellEmphasisColor
                                            : '#ffffff',
                                        padding: '0.25em 0.5em',
                                        whiteSpace: 'nowrap',
                                        position: 'sticky',
                                        top: 0,
                                        left: 0,
                                        zIndex: 100,
                                        ...(emphasizeRow
                                            ? { transition: transitionStr }
                                            : {}),
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        border: `1px solid ${theme.palette.primary.main}`,
                                        borderWidth: `${topBorderWidth} 1px 1px 1px`,
                                        cursor: 'pointer',
                                        color: theme.palette.primary.main,
                                        pl: 4
                                    }}
                                    onClick={localOnClick}
                                >
                                    <Stack direction='row' alignItems='center'>
                                        <PermissionsIcon />
                                        <span
                                            style={{
                                                marginLeft: '8px',
                                                whiteSpace: 'nowrap'
                                            }}
                                            title={getAcademyTooltip(academy)}
                                        >
                                            {academy.academy}
                                        </span>
                                    </Stack>
                                </Box>
                                {knownEmails.map((knownEmail, i) => {
                                    const assigned = papKnownEmailPerms.has(knownEmail)
                                    const orig =
                                        changedPermOriginalKnownEmailRights?.has(
                                            knownEmail
                                        )
                                    const isChanged = orig !== assigned
                                    const lastCol = i === lastKnownEmailIndex
                                    const cellEmphasized =
                                        emphasizeRow || emphasized.knownEmails[knownEmail]

                                    return (
                                        <Box
                                            key={knownEmail}
                                            sx={{
                                                backgroundColor: cellEmphasized
                                                    ? cellEmphasisColor
                                                    : '#ffffff',

                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: `1px solid ${theme.palette.primary.main}`,
                                                borderWidth: `${topBorderWidth} 1px 1px 0`,
                                                marginRight:
                                                    lastCol &&
                                                    knownEmailsHasFollowingBlock
                                                        ? '10px'
                                                        : 0
                                                // width: '46px'
                                            }}
                                        >
                                            {/* {perm} */}
                                            <NavPermissionAssignmentButton
                                                assigned={assigned}
                                                isChanged={isChanged}
                                                state={{
                                                    perAcademyPermission:
                                                        perAcademyPermission.name,
                                                    papAcademyCode,
                                                    knownEmail
                                                }}
                                                onAssignmentChanged={
                                                    onPerAcademyPermAssignmentChanged
                                                }
                                                emphasized={cellEmphasized}
                                            />
                                        </Box>
                                    )
                                })}
                                {jobFamilies.map(({ text: jf }, i) => {
                                    const assigned = papJobFamilyPerms.has(jf)
                                    const orig =
                                        changedPermOriginalJobFamilyRights?.has(jf)
                                    const isChanged = orig !== assigned
                                    const lastCol = i === lastJobFamilyIndex
                                    const cellEmphasized =
                                        emphasizeRow || emphasized.jobFamilies[jf]

                                    return (
                                        <Box
                                            key={jf}
                                            sx={{
                                                backgroundColor: cellEmphasized
                                                    ? cellEmphasisColor
                                                    : '#ffffff',

                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: `1px solid ${theme.palette.primary.main}`,
                                                borderWidth:
                                                    !jobFamiliesIsFirstBlock && i === 0
                                                        ? `${topBorderWidth} 1px 1px 1px`
                                                        : `${topBorderWidth} 1px 1px 0`,
                                                marginRight:
                                                    lastCol &&
                                                    jobFamiliesHasFollowingBlock
                                                        ? '10px'
                                                        : 0
                                                // width: '46px'
                                            }}
                                        >
                                            {/* {perm} */}
                                            <NavPermissionAssignmentButton
                                                assigned={assigned}
                                                isChanged={isChanged}
                                                state={{
                                                    perAcademyPermission:
                                                        perAcademyPermission.name,
                                                    papAcademyCode,
                                                    jobFamily: jf
                                                }}
                                                onAssignmentChanged={
                                                    onPerAcademyPermAssignmentChanged
                                                }
                                                emphasized={cellEmphasized}
                                            />
                                        </Box>
                                    )
                                })}
                                {academies.map(({ academyCode }, i) => {
                                    const assigned = papAcademyPerms.has(academyCode)
                                    const orig =
                                        changedPermOriginalAcademyRights?.has(academyCode)
                                    const isChanged = orig !== assigned
                                    const lastCol = i === lastAcademyIndex
                                    const cellEmphasized =
                                        emphasizeRow ||
                                        emphasized.academyCodes[academyCode]

                                    return (
                                        <Box
                                            key={academyCode}
                                            sx={{
                                                backgroundColor: cellEmphasized
                                                    ? cellEmphasisColor
                                                    : '#ffffff',

                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: `1px solid ${theme.palette.primary.main}`,
                                                borderWidth:
                                                    !academiesIsFirstBlock && i === 0
                                                        ? `${topBorderWidth} 1px 1px 1px`
                                                        : `${topBorderWidth} 1px 1px 0`,
                                                marginRight:
                                                    lastCol && academiesHasFollowingBlock
                                                        ? '10px'
                                                        : 0
                                                // width: '46px'
                                            }}
                                        >
                                            {/* {perm} */}
                                            <NavPermissionAssignmentButton
                                                assigned={assigned}
                                                isChanged={isChanged}
                                                state={{
                                                    perAcademyPermission:
                                                        perAcademyPermission.name,
                                                    papAcademyCode,
                                                    academy: academyCode
                                                }}
                                                onAssignmentChanged={
                                                    onPerAcademyPermAssignmentChanged
                                                }
                                                emphasized={cellEmphasized}
                                            />
                                        </Box>
                                    )
                                })}
                                {regions.map((rgn, i) => {
                                    const assigned = papRegionPerms.has(rgn)
                                    const orig = changedPermOriginalRegionRights?.has(rgn)
                                    const isChanged = orig !== assigned
                                    const lastCol = i === lastRegionIndex
                                    const cellEmphasized =
                                        emphasizeRow || emphasized.regions[rgn]

                                    return (
                                        <Box
                                            key={rgn}
                                            sx={{
                                                backgroundColor: cellEmphasized
                                                    ? cellEmphasisColor
                                                    : '#ffffff',

                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: `1px solid ${theme.palette.primary.main}`,
                                                borderWidth:
                                                    !regionsIsFirstBlock && i === 0
                                                        ? `${topBorderWidth} 1px 1px 1px`
                                                        : `${topBorderWidth} 1px 1px 0`,
                                                marginRight: lastCol ? 0 : 0
                                                // width: '46px'
                                            }}
                                        >
                                            {/* {perm} */}
                                            <NavPermissionAssignmentButton
                                                assigned={assigned}
                                                isChanged={isChanged}
                                                state={{
                                                    perAcademyPermission:
                                                        perAcademyPermission.name,
                                                    papAcademyCode,
                                                    region: rgn
                                                }}
                                                onAssignmentChanged={
                                                    onPerAcademyPermAssignmentChanged
                                                }
                                                emphasized={cellEmphasized}
                                            />
                                        </Box>
                                    )
                                })}
                            </Fragment>
                        )
                    }
                )}
            </>
        )
    }
)
