import { addDoc, getDocs, query, where } from 'firebase/firestore'
import { _doSingletonUpdate, _getSingleton } from 'service/controller/Singletons'
import { academiesCollection } from 'service/firebase'
import { AcademyModel } from 'service/model/AcademyModel'
import {
    AcademiesLookupSingletonModel,
    SingletonsModel
} from 'service/model/SingletonsModel'

export const _createAcademy = async (academy: AcademyModel, isSystem = false) => {
    if (academy.academy.startsWith('_') && !isSystem) {
        return undefined
    }
    if (!(await _findAcademyByName(academy.academy))) {
        const docRef = await addDoc(academiesCollection, academy)
        return docRef
    }
    return undefined
}

export const _updateAcademiesLookup = async (
    updater: (model: SingletonsModel) => SingletonsModel
) => {
    return _doSingletonUpdate(updater, 'academies')
}

export const _findAcademyByName = async (academyName: string) => {
    const q = query(academiesCollection, where('Academy', '==', academyName))
    const existingAcademies = await getDocs(q)
    return existingAcademies.docs?.[0]
}

export const _getAcademiesLookup = async () => {
    const data = await _getSingleton('academies')
    if (data?.flavour === 'academies') {
        return data.academies
    }
    return undefined
}
export type AcademyLookupItem = AcademiesLookupSingletonModel['academies'][0]

// export const _findAcademyByDomain = async (domain: string) => {
//     const q = query(academiesCollection, where('emailDomain', '==', domain))
//     const existingAcademies = await getDocs(q)
//     return existingAcademies.docs?.[0]
// }
