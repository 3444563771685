import { Scrollbars } from 'react-custom-scrollbars-2'

export const ThinScrollbars = ({
    children,
    grandparentStyle,
    grandparentClassName,
    childStyle,
    childClassName,
    trackStyle,
    thumbStyle
}: {
    children: React.ReactNode
    grandparentStyle?: React.CSSProperties
    grandparentClassName?: string
    childStyle?: React.CSSProperties
    childClassName?: string
    trackStyle?: React.CSSProperties
    thumbStyle?: React.CSSProperties
}) => {
    return (
        <Scrollbars
            style={grandparentStyle}
            className={grandparentClassName}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            // renderTrackHorizontal={({ style }) => (
            //     <div
            //         style={style}
            //         // style={{ ...style, ...(trackStyle || {}) }}
            //         className='track-horizontal'
            //     />
            // )}
            // renderThumbHorizontal={({ style }) => (
            //     <div
            //         // style={{ ...style, ...(thumbStyle || {}) }}
            //         style={style}
            //         className='thumb-horizontal'
            //     />
            // )}
            renderTrackVertical={props => {
                const { style, ...otherProps } = props
                const combinedStyle = {
                    ...style,
                    ...(trackStyle || {})
                }
                return (
                    <div
                        {...otherProps}
                        style={combinedStyle}
                        className='track-vertical'
                    />
                )
            }}
            renderThumbVertical={props => {
                const { style, ...otherProps } = props
                const combinedStyle = {
                    ...style,
                    ...(thumbStyle || {})
                }
                return (
                    <div
                        {...otherProps}
                        // style={style}
                        style={combinedStyle}
                        className='thumb-vertical'
                    />
                )
            }}
            renderView={props => {
                const { style, ...otherProps } = props
                return (
                    <div
                        {...otherProps}
                        className={childClassName}
                        style={{
                            ...style,
                            ...(childStyle || {})
                        }}
                    />
                )
            }}
        >
            {children}
        </Scrollbars>
    )
}
