/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import EditIcon from '@mui/icons-material/ModeEdit'
import ViewIcon from '@mui/icons-material/Visibility'
import NoViewIcon from '@mui/icons-material/VisibilityOff'

import { alpha } from '@mui/material'

import { altColor } from 'components/navbar/types'
import { nextGroupPerm, PermXCoordinate } from 'pages/Permissions/PermissionsPage'
import { useCallback } from 'react'
import { GroupPermission } from 'service/model/GroupModel'

const titleLookup = {
    none: 'No permissions',
    view: 'View permissions',
    edit: 'Edit permissions'
} as Record<GroupPermission, string>

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
const loFi = 'png' as 'text' | 'png' | 'svg'

export const NavGroupPermButton = ({
    perm,
    isChanged,
    state,
    onPermChanged,
    emphasized
}: {
    perm: GroupPermission
    isChanged: boolean
    state: { groupId: string; linkUrl?: string } & PermXCoordinate

    onPermChanged: (
        state: {
            groupId: string
            linkUrl?: string
        } & PermXCoordinate,
        perm: GroupPermission,
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void
    emphasized: boolean
}) => {
    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()

            const nextPerm = nextGroupPerm(perm)
            onPermChanged(state, nextPerm, e)
        },
        [onPermChanged, perm, state]
    )

    const iconStyle = {
        color: altColor,
        ...(isChanged
            ? {
                  outline: '3px inset red',
                  borderRadius: '6px'
                  //   outlineOffset: '2px'
              }
            : {})
        // border: '1px solid red'
        // width: '26px',
        // height: '26px'
        // ...(isChanged ? { color: '#ff0000' } : {})
    }

    const fadedColor = alpha(altColor, 0.15)

    return (
        <div
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                padding: '0.25em 0.5em',
                display: 'flex',
                cursor: 'pointer'
            }}
            onClick={handleClick}
            title={`${titleLookup[perm]}${isChanged ? ' (modified)' : ''}`}
        >
            {loFi === 'text' ? (
                perm === 'none' ? (
                    <span>-</span>
                ) : perm === 'view' ? (
                    <span>v</span>
                ) : perm === 'edit' ? (
                    <span>e</span>
                ) : (
                    <span />
                )
            ) : loFi === 'png' ? (
                perm === 'view' ? (
                    <img
                        alt='Visibility'
                        width={24}
                        height={24}
                        src={
                            // eslint-disable-next-line prefer-template
                            process.env.PUBLIC_URL +
                            '/images/png/PermissionsPage/Visibility.png'
                        }
                        style={iconStyle}
                    />
                ) : perm === 'edit' ? (
                    <img
                        alt='ModeEdit'
                        width={24}
                        height={24}
                        src={
                            // eslint-disable-next-line prefer-template
                            process.env.PUBLIC_URL +
                            '/images/png/PermissionsPage/ModeEdit.png'
                        }
                        style={iconStyle}
                    />
                ) : perm === 'none' ? (
                    <img
                        alt='VisibilityOff'
                        width={24}
                        height={24}
                        src={
                            // eslint-disable-next-line prefer-template
                            process.env.PUBLIC_URL +
                            `/images/png/PermissionsPage/VisibilityOff${
                                emphasized ? '-dark' : ''
                            }.png`
                        }
                        style={{ ...iconStyle, color: fadedColor }}
                    />
                ) : (
                    <span />
                )
            ) : loFi === 'svg' ? (
                perm === 'view' ? (
                    <ViewIcon style={iconStyle} />
                ) : perm === 'edit' ? (
                    <EditIcon style={iconStyle} />
                ) : perm === 'none' ? (
                    <NoViewIcon style={{ ...iconStyle, color: fadedColor }} />
                ) : (
                    <span />
                )
            ) : (
                <span />
            )}
        </div>
    )
}
