import {
    AcademyGroupPermission,
    GroupPermission,
    JobFamilyGroupPermission,
    KnownEmailGroupPermission,
    RegionGroupPermission
} from 'service/model/GroupModel'
import { getEmojiPrefixAndRemainder } from 'service/utils'

export interface LinkModel {
    name: string
    url: string
    favicon24Url?: string
    favicon32Url?: string
    description: string

    requiredKnownEmails?: KnownEmailGroupPermission[]
    requiredJobFamilies?: JobFamilyGroupPermission[]
    requiredAcademies?: AcademyGroupPermission[]
    requiredRegions?: RegionGroupPermission[]
    userPermission?: GroupPermission
}

export type LinkViewModel = LinkModel & {
    onClipboard: boolean
    withinDeleteCycle: boolean
}
export const toLinkViewModel = (
    link: LinkModel,
    onClipboardResolver?: (link: LinkModel) => boolean
) =>
    ({
        ...link,
        onClipboard: !!onClipboardResolver?.(link),
        withinDeleteCycle: false
    } as LinkViewModel)

export const toLinkModel = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClipboard,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    withinDeleteCycle,
    ...otherProps
}: LinkViewModel) =>
    ({
        ...otherProps
    } as LinkModel)

export const linkSorter = (
    { name: linkName1 }: LinkModel,
    { name: linkName2 }: LinkModel
) => {
    const emojiSplit1 = getEmojiPrefixAndRemainder(linkName1)
    const emojiSplit2 = getEmojiPrefixAndRemainder(linkName2)

    const name1 = emojiSplit1 ? emojiSplit1[1] : linkName1
    const name2 = emojiSplit2 ? emojiSplit2[1] : linkName2

    return name1.localeCompare(name2)
}
