import { useMemo, useState } from 'react'
import { GlobalProviderProps, ModeType } from '../types'
import { GlobalContext } from './global-context'

export function GlobalProvider({ children }: GlobalProviderProps) {
    const [mode, setMode] = useState<ModeType>('light')

    const contextValue = useMemo(() => {
        return { mode, setMode }
    }, [mode, setMode])

    return (
        <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
    )
}
