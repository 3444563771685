/* eslint-disable prettier/prettier */
import { alpha, createTheme } from '@mui/material';
import '@mui/material/styles';
import { useGlobalContext } from 'contexts/global-context';
import { useMemo } from 'react';

declare module '@mui/material/styles' {
  interface CommonColors {
    teal: string;
    darkGreen: string;
    darkGreener: string;
    pink: string;
    darkGray: string;
    lightGray: string;
    errorRed: string;
  }
}

export const useCustomTheme = () => {
const { mode } = useGlobalContext();
const isLightModeOn  = mode === 'light';

const appFonts = {
    Manrope: 'Manrope',
    Montserrat: 'Montserrat'
}

const COLORS = {
    teal: '#82ffff',
    darkGreen: '#1f454a',
    darkGreener: '#153338',
    pink: '#ff5278',
    darkGray: '#212121',
    lightGray: '#C0C0C0',
    errorRed: '#D00000',
    white: '#ffffff',
};

// const themeColor = isLightModeOn ? '#1E4675' : '#212121'
const themeColor = isLightModeOn ? COLORS.darkGreener : COLORS.darkGray
const fontColor = isLightModeOn ? '#333333' : COLORS.lightGray
const focusedColor = isLightModeOn ? COLORS.darkGreen : COLORS.lightGray
const errorColor = COLORS.errorRed
const fontFamily = [appFonts.Manrope, 'Arial'].join(',')

const elevation = '51,51,51'

const createTypographyStyles = () => ({
    body1: { fontStyle: 'normal', color: fontColor, fontWeight: '300', fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.15px' },
    body2: { fontStyle: 'normal', color: fontColor, fontWeight: '300', fontSize: '17px', lineHeight: 1.43, letterSpacing: '0.17px' },
    subtitle1: { fontStyle: 'normal', color: fontColor, fontWeight: '300', fontSize: '16px', lineHeight: 1.75, letterSpacing: '0.15px' },
    subtitle2: { fontStyle: 'normal', color: fontColor, fontWeight: '500', fontSize: '14px', lineHeight: 1.57, letterSpacing: '0.1px' },
    caption: { fontStyle: 'normal', color: fontColor, fontWeight: '400', fontSize: '12px', lineHeight: 1.66, letterSpacing: '0.4px' },
    overline: { fontStyle: 'normal', color: fontColor, fontWeight: '400', fontSize: '12px', lineHeight: 2.66, letterSpacing: '1px', textTransform: 'uppercase' },
    h1: { fontStyle: 'normal', color: fontColor, fontWeight: '700', fontSize: '46px', lineHeight: 1.167, letterSpacing: '-1.5px' },
    h2: { fontStyle: 'normal', color: fontColor, fontWeight: '700', fontSize: '30px', lineHeight: 1.2, letterSpacing: '-0.5px' },
    h3: { fontStyle: 'normal', color: fontColor, fontWeight: '600', fontSize: '28px', lineHeight: 1.167 },
    h4: { fontStyle: 'normal', color: fontColor, fontWeight: '600', fontSize: '20px', lineHeight: 1.235, letterSpacing: '0.25px' },
    h5: { fontStyle: 'normal', color: fontColor, fontWeight: '600', fontSize: '18px', lineHeight: 1.334 },
    h6: { fontStyle: 'normal', color: fontColor, fontWeight: '500', fontSize: '17px', lineHeight: 1.6, letterSpacing: '0.15px' },
    fontFamily
});

const createComponentOverrides = () => ({
    MuiList: { styleOverrides: { root: { '&:hover': {} } } },
    MuiListItem: {
        styleOverrides: {
            root: { 
                '&$selected': { 'backgroundColor': themeColor, '&:hover': { backgroundColor: alpha(themeColor, 0.08) }},
                '&:hover': { backgroundColor: alpha(themeColor, 0.08) },
                '&.no-hover:hover': {
                    backgroundColor: 'initial'
                }
            },
            button: {
                '&:hover': { backgroundColor: alpha(themeColor, 0.08) },
                '&$selected': { backgroundColor: themeColor }
            }
        }
    },
    MuiMenuItem: { styleOverrides: { root: { whiteSpace: 'normal' } } },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                'color': fontColor,
                '&$selected': { 'color': themeColor, '&:hover': { color: themeColor } },
                '&:hover': { color: themeColor }
            }
        }
    },
    MuiListItemIcon: { styleOverrides: { root: { color: fontColor } } },
    MuiFormHelperText: { styleOverrides: { root: { color: errorColor } } },
    MuiListItemText: {
        styleOverrides: {
            root: {
                'color': fontColor,
                '&$selected': { 'color': themeColor, '&:hover': { color: themeColor } },
                '&:hover': { color: themeColor }
            }
        }
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                // input label when focused
                '& label.Mui-focused': { color: focusedColor},
                // focused color for input with variant='standard'
                '& .MuiInput-underline:after': { borderBottomColor: focusedColor },
                // focused color for input with variant='filled'
                '& .MuiFilledInput-underline:after': { borderBottomColor: focusedColor },
                // focused color for input with variant='outlined'
                '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: focusedColor} },
                '& .MuiInputBase-input::placeholder': { color: fontColor, },
                // placeholder color specifically for filled variant
                '& .MuiFilledInput-input::placeholder': { color: fontColor, },
            }
        }
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: { '&.Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: fontColor, borderWidth: 2 } },
            notchedOutline: { borderColor: alpha(fontColor, 0.5), boxShadow: `0 3px 1px -2px rgba(${elevation}, .2 ), 0 2px 2px 0 rgba(${elevation}, .14 ), 0 1px 5px 0 rgba(${elevation}, .12 )` }
        }
    },
    MuiInputLabel: { styleOverrides: { root: { '&.Mui-focused': { color: focusedColor } } } },
    MuiAppBar: { styleOverrides: { root: { boxShadow: `0px 2px 4px -1px rgba(${elevation},0.2), 0px 4px 5px 0px rgba(${elevation},0.14), 0px 1px 10px 0px rgba(${elevation},0.12)` } } }
})

const memoizedThemeValue: object = useMemo(() => ({
    mode,
    navBarWidth: 300,
    palette: {
        primary: { main: themeColor },
        background: { default: themeColor },
        secondary: { main: fontColor },
        text: { primary: fontColor, secondary: themeColor },
        action: { hover: alpha(themeColor, 0.08) },
        common: COLORS
    },
    typography: createTypographyStyles(),
    components: createComponentOverrides()
}), [mode])

const theme = createTheme(memoizedThemeValue)

const themeComponents = { appFonts, fontColor, fontFamily, theme, themeColor };
return themeComponents;
}

