// https://stackoverflow.com/questions/36836011/checking-validity-of-string-literal-union-type-at-runtime

// TypeScript will infer a string union type from the literal values passed to
// this function. Without `extends string`, it would instead generalize them
// to the common string type.

/*
    Example Definition
    We also need a line of boilerplate to extract the generated type and merge its definition with its namespace object. If this definition is exported and imported into another module, they will get the merged definition automatically; consumers won't need to re-extract the type themselves.

    const Race = StringUnion(
    "orc",
    "human",
    "night elf",
    "undead",
    );
    type Race = typeof Race.type;
    Example Use
    At compile-time, the Race type works the same as if we'd defined a string union normally with:
     "orc" | "human" | "night elf" | "undead".

    We also have a .guard(...) function that returns whether or not a value is a member of the union
    and may be used as a type guard, and a .check(...) function that returns the passed value if it's
    valid or else throws a TypeError.

    let r: Race;
    const zerg = "zerg";

    // Compile-time error:
    // error TS2322: Type '"zerg"' is not assignable to type '"orc" | "human" | "night elf" | "undead"'.
    r = zerg;

    // Run-time error:
    // TypeError: Value '"zerg"' is not assignable to type '"orc" | "human" | "night elf" | "undead"'.
    r = Race.check(zerg);

    // Not executed:
    if (Race.guard(zerg)) {
    r = zerg;
    }
*/
export const StringUnion = <UnionType extends string>(...values: UnionType[]) => {
    Object.freeze(values)
    const valueSet: Set<string> = new Set(values)

    const guard = (value: string): value is UnionType => valueSet.has(value)

    const check = (value: string): UnionType => {
        if (!guard(value)) {
            const actual = JSON.stringify(value)
            const expected = values.map(s => JSON.stringify(s)).join(' | ')
            throw new TypeError(
                `Value '${actual}' is not assignable to type '${expected}'.`
            )
        }
        return value
    }

    const unionNamespace = { guard, check, values }
    return Object.freeze(unionNamespace as typeof unionNamespace & { type: UnionType })
}
