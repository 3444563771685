export default async function getGoogleGroups(userKey: string) {
    const url = `https://get-google-groups-bq4rr6uxsa-nw.a.run.app?u=${userKey}`
    const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
            Authorization:
                'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjFhYWU4ZDdjOTIwNThiNWVlYTQ1Njg5NWJmODkwODQ1NzFlMzA2ZjMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTE4MDUyMDAxMTExMjM2NDgyNjUwIiwiaGQiOiJub3J0aDI3LmNvLnVrIiwiZW1haWwiOiJkZXZlbG9wZXJAbm9ydGgyNy5jby51ayIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiZFNrdVVPSEkwX0pWM2o3ZjE4SmdxdyIsImlhdCI6MTY4MDA5MTcxOSwiZXhwIjoxNjgwMDk1MzE5LCJqdGkiOiIzNDZlNjJkZTU1OTBjMTdjOTI3ZmUxOWZlODBiYTRmMTJkNjc5M2I0In0.hwPQhe8aTcxSWDKTBuZPMwDD_DGhlC1vnbz7UjFxAXuSw46ipXtzLjEoHQoi-S3AmCZWvmlswE0J6CJFiUAsj1ZmHL7p62D9Oek2v0iwEKU79o1XFTLSHsXhu8lP5w4GLqcVqB1ZwpL0vTtz9kVmsab1TeHRXFqQel_jUfR-rANucgK0yMJu-ILIVVrKB9Ju_LJJ8kYuVtv4LfkdrRXtuU3Yp8C4zn9O7bIQtzYphpQ0-pVk9cEIdCJR2XgsxqKktF29FgEjBqZfxl7BnSWokq8oyyNOus8ofOefunICL7Utvs5nioyQQ0ByFKiPAebs2JrrBwJVDEnm7lcSwRSoGA'
        })
    })

    if (response.status !== 200) {
        console.error(response)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedResponse: any = await response.json()
    return parsedResponse
}
