/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import BackIcon from '@mui/icons-material/ChevronLeft'
import NavBarCloseIcon from '@mui/icons-material/CloseRounded'
import MenuIcon from '@mui/icons-material/Menu'
import {
    Box,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Stack
} from '@mui/material'
import shallow from 'zustand/shallow'

import { DrawerProps } from '@mui/material/Drawer'
import List from '@mui/material/List'
import { ApplicationsContent } from 'components/navbar/Content/ApplicationsContent'
import { GroupsContent } from 'components/navbar/Content/GroupsContent'
import { Drawer } from 'components/navbar/Content/Styled/Drawer'
import { NavPasteLinkIcon } from 'components/navbar/Content/Styled/NavPasteLinkIcon'
import {
    NavButton,
    NavGroup,
    NavItem,
    NavItemText
} from 'components/navbar/Content/Styled/StyledNavComponents'
import { TopNavBarContent } from 'components/navbar/Content/TopNavBarContent'
// import { NavPortal } from 'components/navbar/NavPortal'
import { SettingsMenu } from 'components/navbar/SettingsMenu'
import { altColor, NavBarContentProps, NavInfo, NavType } from 'components/navbar/types'
import { AlertDialog, defaultAlertProps } from 'components/utils/AlertDialog'
import { useCustomTheme } from 'hooks/useCustomTheme'
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import AppContext from 'store/contexts/AppContext'
import { Store, useStore } from '../../appData/store'
import { logout } from '../../service/firebase'

const LinksContent = ({ open, allBlue }: NavBarContentProps) => {
    return (
        <ListItem
            sx={{
                padding: 0,
                flexDirection: 'row'
                // ...(debugNavBar && {
                //     border: '1px dotted pink'
                // })
            }}
        >
            <NavGroup>
                <NavItem open={open}>
                    <NavButton onClick={() => alert('hi')}>
                        <NavItemText open={open} altColor={allBlue ? 'white' : altColor}>
                            CLICK ME!
                        </NavItemText>
                    </NavButton>
                </NavItem>
            </NavGroup>
        </ListItem>
    )
}

const NavBarContent = (navContext: NavBarContentProps) => {
    const { navInfo } = navContext

    return (
        <>
            {navInfo.navType === 'top' && <TopNavBarContent {...navContext} />}
            {navInfo.navType === 'groups' && <GroupsContent {...navContext} />}
            {navInfo.navType === 'applications' && (
                <ApplicationsContent {...navContext} />
            )}
            {navInfo.navType === 'links' && <LinksContent {...navContext} />}
        </>
    )
}

const navBarStoreSelector = (store: Store) => ({
    settings: store.settings,
    currentGroup: store.currentGroup,
    setCurrentGroup: store.setCurrentGroup,
    pasteLinksToCurrentGroup: store.pasteLinksToCurrentGroup,
    linkClipboard: store.linkClipboard
})

interface MuiDrawerProps extends DrawerProps {
    onOpenChanged?: (open: boolean) => void
}

const NavBar: FC<MuiDrawerProps> = ({ onOpenChanged }) => {
    const {
        settings,
        currentGroup,
        setCurrentGroup,
        pasteLinksToCurrentGroup,
        linkClipboard
    } = useStore(navBarStoreSelector, shallow)

    const { theme } = useCustomTheme()

    const [alertDialog, setAlertDialog] = useState(defaultAlertProps)
    const [open, _setOpen] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setOpen = useCallback(
        (isOpen: boolean) => {
            _setOpen(isOpen)
            onOpenChanged?.(isOpen)
        },
        [onOpenChanged]
    )

    const toggleOpen = useCallback(() => {
        setOpen(!open)
    }, [open, setOpen])

    const openDrawer = () => {
        setOpen(true)
    }

    const closeDrawer = () => {
        setOpen(false)
    }

    const defaultNavInfo = useMemo(
        () =>
            ({
                navType: 'top',
                title: '',
                groupId: undefined
            } as NavInfo),
        []
    )

    const [navInfo, setNavInfo] = useState<NavInfo>(defaultNavInfo)
    const navInfoStack = useRef<NavInfo[]>([])

    const pushNavInfo = useCallback(
        (nt: NavType, title: string, groupId?: string) => {
            navInfoStack.current.push(navInfo)
            setNavInfo({ navType: nt, title, groupId })
        },
        [navInfo]
    )

    const popNavType = useCallback(() => {
        if (navInfoStack.current.length > 0) {
            setNavInfo(navInfoStack.current.pop() || defaultNavInfo)
        }
    }, [defaultNavInfo])

    useEffect(() => {
        if (!currentGroup) {
            setNavInfo(defaultNavInfo)
        }
    }, [currentGroup, defaultNavInfo])

    const authCtx = useContext(AppContext)

    const navBarItemClickedHandler = useCallback(
        (id: string, title: string) => {
            const groupMatch = id.match(/_menu_item_group_(.+)/)
            const applicationMatch = id.match(/Application_(.+)/)

            if (groupMatch) {
                const groupId = groupMatch?.[1]

                // openDrawer()

                setCurrentGroup(groupId)
                pushNavInfo('applications', title, groupId)
            } else if (applicationMatch) {
                // const applicationId = applicationMatch?.[1]
                // openDrawer()
                pushNavInfo('links', title)
            } else {
                switch (id) {
                    case 'myLinksLink':
                        window.open(title)
                        break
                    case 'Applications':
                        // openDrawer()

                        pushNavInfo('groups', 'Folders')
                        break
                    case 'IT_Support':
                        window.open('http://aetitds.org/')
                        break

                    case 'RM Unify':
                        window.open('https://rmunify.com/Sso/Google')
                        break

                    case 'Logout':
                        setAlertDialog({
                            ...alertDialog,
                            isOpen: true,
                            title: 'Logout',
                            message: 'Do you really want to log out?'
                        })

                        break

                    default:
                        // eslint-disable-next-line no-alert
                        alert(`You clicked '${id} !!!`)
                }
            }
        },
        [alertDialog, pushNavInfo, setCurrentGroup]
    )

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            const { key } = event

            if (key === 'Escape') {
                if (open) {
                    toggleOpen()
                }
            }
        },
        [open, toggleOpen]
    )

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress, true)
        return () => {
            document.removeEventListener('keydown', handleKeyPress, true)
        }
        return undefined
    }, [handleKeyPress])

    const triggerSettingsMenu = useRef<React.MouseEventHandler>()
    const navContext = {
        open,
        navBarItemClickedHandler,
        navInfo,
        allBlue: settings?.singleColorNav
    } as NavBarContentProps

    const pasteLinksGuard =
        !!navInfo.groupId &&
        !!linkClipboard?.sourceGroupId &&
        Object.keys(linkClipboard.linkLookup).length > 0 &&
        linkClipboard.sourceGroupId !== navInfo.groupId

    return (
        <>
            <Drawer
                variant='permanent'
                drawerWidth={50}
                sx={{
                    'width': '50px',
                    'boxShadow': '6px 0px 15px rgba(51, 51, 51, 0.2)',
                    '& .MuiPaper-root': {
                        overflow: 'hidden',
                        border: 'none',
                        height: '100vh'
                    },
                    'position': 'fixed',
                    'zIndex': 1101, // 10000 breaks the Modal - Ved
                    'paddingTop': '75px'
                }}
                anchor='left'
                open={open}
            >
                <SettingsMenu
                    register={triggerMenu => {
                        triggerSettingsMenu.current = triggerMenu
                    }}
                />
                <nav
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        ...(navInfo.navType !== 'top' && !navContext.allBlue
                            ? {
                                  backgroundColor: 'white'
                              }
                            : {}),
                        height: '100%',
                        paddingTop: '8px',
                        backgroundColor: theme.palette.common.darkGreen
                    }}
                >
                    <Stack
                        direction={open ? 'row' : 'column'}
                        justifyContent='flex-start'
                        position='relative'
                        sx={{ alignItems: 'center', ...(open ? { height: '50px' } : {}) }}
                    >
                        {/* {open && (
                            <NavbarLogo
                                onContextMenu={e => triggerSettingsMenu.current?.(e)}
                                open={open}
                                foreColor={
                                    navInfo.navType === 'top' || navContext.allBlue
                                        ? white
                                        : altColor
                                }
                            />
                        )} */}
                        {/* <div id='nav-close-portal-root' /> */}

                        {/* TODO: REMOVE THE NAV LOGOS FROM THE DRAWER WHEN NO LONGER NEEDED */}
                        {/* { open ?
                        <Box sx={{ cursor: "pointer", position: 'absolute', left: 10, }} onClick={closeDrawer}>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/png/lift_school_teal_logo.png`}
                                width={120}
                                height={40}
                                alt='Lift school Logo'
                            />
                        </Box>:
                        <Box sx={{ cursor: "pointer" }} onClick={openDrawer}>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/png/lift_school_teal_logo_mini.png`}
                                width={40}
                                height={40}
                                alt='Lift school mini Logo'
                                style={{ flex: 0 }}
                            />
                        </Box>
                        } */}

                        <ListItemButton
                            sx={{
                                minHeight: 50,
                                justifyContent: open ? 'right' : 'center',
                                px: 1.6,
                                flexGrow: 0
                                // cursor: 'context-menu'
                            }}
                            onClick={open ? closeDrawer : openDrawer}
                        >
                            <ListItemIcon
                                color='inherit'
                                aria-label='open drawer'
                                sx={{
                                    minWidth: 0,
                                    cursor: 'pointer'
                                }}
                            >
                                {open ? (
                                    <Box display='flex'>
                                        <NavBarCloseIcon
                                            sx={{
                                                color:
                                                    navInfo.navType === 'top'
                                                        ? 'white'
                                                        : navContext.allBlue
                                                        ? 'white'
                                                        : altColor
                                            }}
                                        />
                                        <NavItemText
                                            open={open}
                                            altColor={
                                                navContext.allBlue ? 'white' : altColor
                                            }
                                        >
                                            Close
                                        </NavItemText>
                                    </Box>
                                ) : (
                                    <MenuIcon
                                        sx={{
                                            color:
                                                navInfo.navType === 'top'
                                                    ? 'white'
                                                    : navContext.allBlue
                                                    ? 'white'
                                                    : altColor
                                        }}
                                    />
                                )}
                            </ListItemIcon>
                        </ListItemButton>
                        {/* {!open && <NavbarLogo open={open} foreColor={altColor} />} */}
                    </Stack>
                    {open && <Divider />}

                    {navInfo.navType !== 'top' && (
                        <ListItem
                            sx={{
                                padding: 0,
                                flexDirection: 'row'
                                // ...(debugNavBar && {
                                //     border: '1px dotted pink'
                                // })
                            }}
                        >
                            <NavGroup>
                                {/* <NavPortal target='nav-close'> */}
                                <NavItem open={open} className='no-hover'>
                                    <NavButton onClick={popNavType}>
                                        <BackIcon
                                            style={{
                                                color: navContext.allBlue
                                                    ? 'white'
                                                    : altColor,
                                                width: 22.82,
                                                height: 21.82
                                            }}
                                        />
                                        <NavItemText
                                            open={open}
                                            altColor={
                                                navContext.allBlue ? 'white' : altColor
                                            }
                                            underlined
                                        >
                                            {navInfo.title}
                                        </NavItemText>
                                    </NavButton>
                                </NavItem>
                                {/* </NavPortal> */}
                                {pasteLinksGuard && (
                                    <NavItem open={open} className='no-hover'>
                                        <NavButton onClick={pasteLinksToCurrentGroup}>
                                            <NavPasteLinkIcon
                                                altColor={altColor}
                                                allBlue={navContext.allBlue}
                                                style={{
                                                    color: navContext.allBlue
                                                        ? 'white'
                                                        : altColor,
                                                    width: 24,
                                                    height: 24
                                                }}
                                            />
                                            <NavItemText
                                                open={open}
                                                altColor={
                                                    navContext.allBlue
                                                        ? 'white'
                                                        : altColor
                                                }
                                            >
                                                {`Paste ${
                                                    Object.keys(linkClipboard.linkLookup)
                                                        .length
                                                } link${
                                                    Object.keys(linkClipboard.linkLookup)
                                                        .length !== 1
                                                        ? 's'
                                                        : ''
                                                }`}
                                            </NavItemText>
                                        </NavButton>
                                        {open && <Divider />}
                                    </NavItem>
                                )}
                            </NavGroup>
                        </ListItem>
                    )}
                    <List
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flex: '1 1',
                            padding: '0 !important'
                        }}
                    >
                        <NavBarContent {...navContext} />
                    </List>
                </nav>
            </Drawer>
            <AlertDialog
                alertDialog={alertDialog}
                setAlertDialog={setAlertDialog}
                onConfirmAction={() => {
                    logout().then(() => {
                        authCtx.state.auth.logout()
                        window.location.reload()
                    })
                }}
            />
        </>
    )
}

export default NavBar
