/* eslint-disable prefer-template */
import { ListItem } from '@mui/material'
import { Store, useStore } from 'appData/store'
import { SecondaryLinksSection } from 'components/navbar/Content/SecondaryLinksSection'
import { NavGroup } from 'components/navbar/Content/Styled/StyledNavComponents'
import { StyledScrollbars } from 'components/navbar/StyledScrollbars'
import { NavBarContentProps } from 'components/navbar/types'
import { getIconSize } from 'service/utils'
import shallow from 'zustand/shallow'

const storeSelector = (store: Store) => ({
    myLinksGroup: store.myLinksGroup,
    getUserLinksFolder: store.getUserLinksFolder
})

export const SecondaryListItems = (nbcp: NavBarContentProps) => {
    const { open, allBlue } = nbcp
    const { myLinksGroup, getUserLinksFolder } = useStore(storeSelector, shallow)

    const iconSize = getIconSize(open)

    const userLinks = getUserLinksFolder()?.links || []

    return (
        <ListItem
            sx={{
                padding: 0,
                flexDirection: 'row',
                flex: 1
            }}
            className='no-hover'
        >
            <NavGroup sx={{ height: '100%', paddingBottom: 0 }}>
                <StyledScrollbars open={open} allBlue={allBlue}>
                    <SecondaryLinksSection
                        links={myLinksGroup?.links}
                        iconSize={iconSize}
                        nbcp={nbcp}
                    />
                    <SecondaryLinksSection
                        links={userLinks}
                        iconSize={iconSize}
                        nbcp={nbcp}
                    />
                </StyledScrollbars>
            </NavGroup>
        </ListItem>
    )
}
