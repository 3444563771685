export const collectionNames = {
    // @TODO
    // Rename posts to tiles
    posts: 'tiles',
    users: 'users',
    // stagedUsers: 'stagedUsers',
    groups: 'groups',
    academies: 'academies',
    singletons: 'singletons',
    bqJobFamilies: 'bigQueryRawData_job_families_ttl1d',
    bqStaff: 'bigQueryRawData_Staff_Manual_CentralStaffITrentLinks_Static',
    bqUsers: 'bigQueryRawData_Users_BigQuery_intranetUsers_ttl1d',
    bqPupilsOnRoll: 'bigQueryRawData_data_tile_pupilsOnRollCurrent_ttl1d',
    bqDataTiles: 'bigQueryRawData_data_tiles_ttl1d',
    bqPupilsByYear: 'bigQueryRawData_testing_currentPupilsByYearGroupObject',
    singletonUsers: 'singletonUsers'
}
