import {
    List,
    ListItem,
    ListItemButton,
    ListItemButtonProps,
    ListItemProps,
    ListItemText,
    ListItemTextProps
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

export const UnstyledRouterLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none !important',
    flex: 1
}))

interface NavButtonProps extends ListItemButtonProps {
    open?: boolean
}
export const NavButton = styled(ListItemButton, {
    shouldForwardProp: prop => {
        const include = !['open'].includes(prop.toString())
        return include
    }
})<NavButtonProps>(({ theme }) => ({
    'padding': 0,
    'flexGrow': 1,
    '&:hover': {
        backgroundColor: 'initial'
    }
}))

export const NavGroup = styled(List)(({ theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    margin: 0
}))

interface NavItemProps extends ListItemProps {
    open?: boolean
}
export const NavItem = styled(ListItem, {
    shouldForwardProp: prop => {
        const include = !['open'].includes(prop.toString())
        return include
    }
})<NavItemProps>(({ theme, open }) => ({
    display: 'flex',
    flexGrow: 0,
    flexDirection: open ? 'row' : 'column',
    alignItems: open ? 'flex-start' : 'center',
    paddingLeft: open ? 13 : 0,
    paddingRight: open ? 13 : 0
}))
interface NavItemTextProps extends ListItemTextProps {
    open?: boolean
    altColor?: string
    underlined?: boolean
    lineClamp?: number
}
export const NavItemText = styled(ListItemText, {
    shouldForwardProp: prop => {
        const include = !['open', 'altColor', 'underlined', 'lineClamp'].includes(
            prop.toString()
        )
        return include
    }
})<NavItemTextProps>(({ theme, open, altColor, underlined, lineClamp }) => ({
    ...(!open && { display: 'none' }),
    'fontWeight': 700,
    'marginTop': 0,
    'marginBottom': 0,
    'paddingLeft': 12,
    '& .MuiTypography-root': {
        ...(lineClamp
            ? {
                  'whiteSpace': 'normal',
                  'display': '-webkit-box',
                  '-webkit-line-clamp': lineClamp,
                  '-webkit-box-orient': 'vertical',
                  'height': `${(lineClamp || 1) * 1.5}em`
              }
            : {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
              }),
        color: altColor || 'white',
        ...(underlined ? { textDecoration: 'underline' } : {})
    }
}))

export const NavItemSubText = styled(ListItemText, {
    shouldForwardProp: prop => {
        const include = !['open', 'altColor', 'underlined', 'lineClamp'].includes(
            prop.toString()
        )
        return include
    }
})<NavItemTextProps>(({ theme, open, altColor, underlined, lineClamp }) => ({
    ...(!open && { display: 'none' }),
    'paddingLeft': 22,
    'fontWeight': 700,
    '& .MuiTypography-root': {
        ...(lineClamp
            ? {
                  whiteSpace: 'normal',
                  display: '-webkit-box',
                  WebkitLineClamp: `${lineClamp}`,
                  WebkitBoxOrient: 'vertical',
                  height: `${(lineClamp || 1) * 1.5}em`
              }
            : {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
              }),
        color: altColor || 'white',
        fontSize: '12px',
        ...(underlined ? { textDecoration: 'underline' } : {})
    }
}))
