import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import { PermissionsPageFilters, Store } from 'appData/store'
import { NavBarEdit } from 'components/navbar/Content/Styled/NavBarEdit'
import { CurrentUserLookups, PermXCoordinate } from 'pages/Permissions/PermissionsPage'
import { ColumnGroupToggle } from 'pages/Permissions/PermissionsPage/ColumnGroupToggle'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { escapeRegex } from 'service/utils'
import { debounce } from 'utils/debounce'

export const Filters = ({
    onFiltersChanged,
    userLookups,
    academyCodeLookup,
    sharedFilter,
    onSharedFilterChanged
}: {
    onFiltersChanged: (filters: PermissionsPageFilters) => void
    userLookups: CurrentUserLookups | undefined
    academyCodeLookup: Store['academyCodeLookup']
    sharedFilter: boolean
    onSharedFilterChanged: (sharedFilters: boolean) => void
}) => {
    const emptyFilters = useMemo(
        () => ({
            text: {
                knownEmail: '',
                jobFamily: '',
                academy: '',
                region: ''
            },
            visibility: {
                knownEmail: true,
                jobFamily: true,
                academy: true,
                region: true
            }
        }),
        []
    )

    const [filters, setFilters] =
        useState<Parameters<typeof onFiltersChanged>[0]>(emptyFilters)
    const [userColumns, setUserColumns] = useState(false)

    useEffect(() => {
        const [localOnFiltersChanged, cancel] = debounce(() => {
            onFiltersChanged(filters)
        })
        localOnFiltersChanged()
        return cancel
    }, [filters, onFiltersChanged])

    const onChangeKnownEmail = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = e.target
        setFilters(prev =>
            sharedFilter
                ? {
                      ...prev,
                      text: {
                          ...prev.text,
                          knownEmail: value,
                          jobFamily: value,
                          academy: value,
                          region: value
                      }
                  }
                : { ...prev, text: { ...prev.text, knownEmail: value } }
        )
    }
    const onChangeJobFamily = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFilters(prev => ({
            ...prev,
            text: { ...prev.text, jobFamily: e.target.value }
        }))
    }
    const onChangeAcademy = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFilters(prev => ({
            ...prev,
            text: { ...prev.text, academy: e.target.value }
        }))
    }
    const onChangeRegion = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFilters(prev => ({ ...prev, text: { ...prev.text, region: e.target.value } }))
    }
    // --
    const onChangeColumnGroupVisibility = (
        coord: keyof PermXCoordinate,
        show: boolean,
        modifier: boolean
    ) => {
        setFilters(prev =>
            modifier
                ? {
                      ...prev,
                      visibility: {
                          knownEmail: false,
                          jobFamily: false,
                          academy: false,
                          region: false,
                          [coord]: true
                      }
                  }
                : {
                      ...prev,
                      visibility: { ...prev.visibility, [coord]: show }
                  }
        )
    }

    const doSetUserColumnFilters = useCallback(() => {
        setFilters({
            text: {
                knownEmail: [...(userLookups?.knownEmailsSet.values() || [])].join(','),
                jobFamily: [...(userLookups?.jobFamilySet.values() || [])].join(','),
                academy: [...(userLookups?.academyCodeSet.values() || [])]
                    .map(ac => escapeRegex(academyCodeLookup[ac].academy))
                    .join(','),
                region: [...(userLookups?.regionSet.values() || [])].join(',')
            },
            visibility: {
                knownEmail: true,
                jobFamily: true,
                academy: true,
                region: true
            }
        })
    }, [
        userLookups?.knownEmailsSet,
        userLookups?.jobFamilySet,
        userLookups?.academyCodeSet,
        userLookups?.regionSet,
        academyCodeLookup
    ])

    useEffect(() => {
        if (userColumns) {
            doSetUserColumnFilters()
        }
    }, [doSetUserColumnFilters, userColumns, userLookups])

    const clearFilters = useCallback(() => {
        setFilters(emptyFilters)
    }, [emptyFilters])

    const localOnSharedFilterChanged = (
        event: React.SyntheticEvent<Element, Event>,
        checked: boolean
    ) => {
        setUserColumns(false)

        // if (checked) {
        //     clearFilters()
        // }
        onSharedFilterChanged(checked)
    }
    const onUserColumnsChanged = (
        event: React.SyntheticEvent<Element, Event>,
        checked: boolean
    ) => {
        setUserColumns(checked)
        // onSharedFilterChanged(true)
        if (checked) {
            doSetUserColumnFilters()
        } else {
            clearFilters()
        }
    }
    const color = '#ffffff'
    const allBlue = true

    return (
        <Stack
            direction='column'
            sx={{
                my: 0,
                mx: 1,
                // border: '1px solid #ffffff',
                padding: 0
            }}
        >
            {!userColumns && (
                <Stack
                    direction='row'
                    justifyContent='flex-start'
                    sx={{
                        mt: 1
                    }}
                >
                    <NavBarEdit
                        label={!sharedFilter ? 'Email filter' : 'Column filter'}
                        title='Columns in the first group should match this pattern / Regular Expression - supports commas'
                        variant='outlined'
                        value={filters.text.knownEmail}
                        onChange={onChangeKnownEmail}
                        sx={{
                            color,
                            flex: 1
                        }}
                        altColor={allBlue}
                        allBlue={allBlue}
                        autoComplete='off'
                        autoFocus={sharedFilter}
                    />
                    {!sharedFilter && (
                        <ColumnGroupToggle
                            coord='knownEmail'
                            showColumnGroup={filters.visibility.knownEmail}
                            onChange={onChangeColumnGroupVisibility}
                        />
                    )}
                </Stack>
            )}
            {!sharedFilter && !userColumns && (
                <>
                    <Stack
                        direction='row'
                        justifyContent='flex-start'
                        sx={{
                            mt: 2
                        }}
                    >
                        <NavBarEdit
                            label='Job Family filter'
                            title='Columns in the second group should match this pattern / Regular Expression - supports commas'
                            variant='outlined'
                            value={filters.text.jobFamily}
                            onChange={onChangeJobFamily}
                            sx={{
                                color,
                                flex: 1
                            }}
                            altColor={allBlue}
                            allBlue={allBlue}
                            autoComplete='off'
                            autoFocus
                        />
                        <ColumnGroupToggle
                            coord='jobFamily'
                            showColumnGroup={filters.visibility.jobFamily}
                            onChange={onChangeColumnGroupVisibility}
                        />
                    </Stack>
                    <Stack
                        direction='row'
                        justifyContent='flex-start'
                        sx={{
                            mt: 2
                        }}
                    >
                        <NavBarEdit
                            label='Academy filter'
                            title='Columns in the third group should match this pattern / Regular Expression - supports commas'
                            variant='outlined'
                            value={filters.text.academy}
                            onChange={onChangeAcademy}
                            sx={{
                                color,
                                flex: 1
                            }}
                            altColor={allBlue}
                            allBlue={allBlue}
                            autoComplete='off'
                        />
                        <ColumnGroupToggle
                            coord='academy'
                            showColumnGroup={filters.visibility.academy}
                            onChange={onChangeColumnGroupVisibility}
                        />
                    </Stack>
                    <Stack
                        direction='row'
                        justifyContent='flex-start'
                        sx={{
                            mt: 2
                        }}
                    >
                        <NavBarEdit
                            label='Region filter'
                            title='Columns in the fourth group should match this pattern / Regular Expression - supports commas'
                            variant='outlined'
                            value={filters.text.region}
                            onChange={onChangeRegion}
                            sx={{
                                color,
                                flex: 1
                            }}
                            altColor={allBlue}
                            allBlue={allBlue}
                            autoComplete='off'
                        />
                        <ColumnGroupToggle
                            coord='region'
                            showColumnGroup={filters.visibility.region}
                            onChange={onChangeColumnGroupVisibility}
                        />
                    </Stack>
                </>
            )}
            <Stack direction='row' justifyContent='flex-start'>
                {!userColumns && (
                    <FormControlLabel
                        control={<Checkbox checked={sharedFilter} />}
                        label='Shared'
                        title={
                            sharedFilter
                                ? 'Filter all column-groups with this shared value'
                                : 'Each column-group is filtered individually'
                        }
                        onChange={localOnSharedFilterChanged}
                        // value={sharedFilter}
                        sx={{
                            color,
                            '& .MuiCheckbox-root, & .MuiFormControlLabel-label': {
                                color: `${color} !important`
                            },
                            'flex': 1
                        }}
                    />
                )}
                <FormControlLabel
                    control={<Checkbox checked={userColumns} />}
                    label='Active columns'
                    title={
                        userColumns
                            ? 'Showing columns which are applicable to the User selected in the drop-down above'
                            : 'Showing all columns'
                    }
                    onChange={onUserColumnsChanged}
                    // value={userColumns}
                    sx={{
                        color,
                        '& .MuiCheckbox-root, & .MuiFormControlLabel-label': {
                            color: `${color} !important`
                        }
                    }}
                />
            </Stack>
        </Stack>
    )
}
