/* eslint-disable no-nested-ternary */
import DeleteIcon from '@mui/icons-material/DeleteForever'
import { alpha, styled } from '@mui/material'

interface NavDeleteIconProps {
    altColor: string
    allBlue: boolean
    requestConfirmation?: boolean
}
export const NavDeleteIcon = styled(DeleteIcon, {
    shouldForwardProp: prop => {
        const include = !['altColor', 'allBlue', 'requestConfirmation'].includes(
            prop.toString()
        )
        return include
    }
})<NavDeleteIconProps>(({ theme, altColor, allBlue, requestConfirmation }) => ({
    'width': 24,
    'height': 24,
    'color': requestConfirmation
        ? '#ff0000'
        : alpha(allBlue ? '#ffffff' : altColor, 0.256),
    'transition': `${theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short
    })}`,
    'display': 'none',
    '&:hover': {
        color: requestConfirmation ? '#ff0000' : allBlue ? '#ffffff' : altColor,
        transform: 'scale(1.15)'
    }
}))
