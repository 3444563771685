import { ThinScrollbars } from 'components/navbar/ThinScrollbar'
import { useMemo } from 'react'

export const StyledScrollbars = ({
    open,
    allBlue,
    children,
    spreadChildren
}: {
    open: boolean
    allBlue: boolean
    children: React.ReactNode
    spreadChildren?: boolean
}) => {
    const scrollbarStyles = useMemo(
        () => ({
            track: {
                backgroundColor: allBlue ? '#24548c' : '#c4c4c4',
                width: open ? '5px' : '3px',
                borderRadius: open ? '2.5px' : '1.5px',
                height: '100%',
                right: 0
            },
            thumb: {
                backgroundColor: allBlue ? '#4199ff' : '#959595',
                width: open ? '5px' : '3px',
                borderRadius: open ? '2.5px' : '1.5px'
            }
        }),
        [allBlue, open]
    )

    return (
        <ThinScrollbars
            grandparentStyle={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1',
                justifyContent: 'space-between'
            }}
            grandparentClassName='scrollfix-grandparent'
            childStyle={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1',
                ...(spreadChildren ? { justifyContent: 'space-between' } : {})
            }}
            childClassName='scrollfix-child'
            trackStyle={scrollbarStyles.track}
            thumbStyle={scrollbarStyles.thumb}
        >
            {children}
        </ThinScrollbars>
    )
}
