import { Stack } from '@mui/material'
import { Store } from 'appData/store'
import { StyledScrollbars } from 'components/navbar/StyledScrollbars'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { CurrentUserLookups } from 'pages/Permissions/PermissionsPage'
import { Filters } from 'pages/Permissions/PermissionsPage/Filters'
import { UsersDropDown } from 'pages/Permissions/PermissionsPage/UsersDropDown'
import { memo } from 'react'
import { UsersSummarySingletonModel } from 'service/model/SingletonsModel'

export const TopLeftCell = memo(
    ({
        userSummary,
        selectedUser,
        selectedUserChanged,
        onFiltersChanged,
        sharedFilter,
        onSharedFilterChanged,
        columnCount,
        height,
        userLookups,
        academyCodeLookup
    }: {
        userSummary: Store['userSummary']
        selectedUser: UsersSummarySingletonModel['users'][0] | undefined
        selectedUserChanged: (
            event: React.SyntheticEvent,
            value: Store['userSummary'][0] | null
        ) => void
        onFiltersChanged: Parameters<typeof Filters>[0]['onFiltersChanged']
        sharedFilter: boolean
        onSharedFilterChanged: (sharedFilters: boolean) => void
        columnCount: number
        height: number | string
        userLookups: CurrentUserLookups | undefined
        academyCodeLookup: Store['academyCodeLookup']
    }) => {
        const { theme } = useCustomTheme()

        return (
            <Stack
                direction='column'
                justifyContent='space-between'
                sx={{
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 104,
                    backgroundColor: theme.palette.common.darkGreen,
                    color: '#ffffff',
                    border: `1px solid ${
                        columnCount > 0
                            ? theme.palette.common.white
                            : theme.palette.common.darkGreen
                    }`,
                    borderWidth: '0 1px 0 0',
                    paddingTop: 0.5,
                    // overflowX: 'hidden',
                    flex: 1,
                    overflowY: 'auto',
                    height
                }}
            >
                <StyledScrollbars open allBlue>
                    <Stack direction='column' justifyContent='flex-start'>
                        <UsersDropDown
                            userSummary={userSummary}
                            selectedUser={selectedUser}
                            selectedUserChanged={selectedUserChanged}
                        />
                        <Filters
                            onFiltersChanged={onFiltersChanged}
                            userLookups={userLookups}
                            academyCodeLookup={academyCodeLookup}
                            sharedFilter={sharedFilter}
                            onSharedFilterChanged={onSharedFilterChanged}
                        />
                    </Stack>
                </StyledScrollbars>
            </Stack>
        )
    }
)
