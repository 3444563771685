/* eslint-disable react-hooks/exhaustive-deps */
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { FC, useEffect, useState } from 'react'
// Include special components if required.
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Theme,
    useTheme
} from '@mui/material'
import { AlertDialog, defaultAlertProps } from 'components/utils/AlertDialog'
import Placeholder from 'components/utils/SelectPlaceholder'
import { Timestamp } from 'firebase/firestore'
import { deletePostFull, getAllPosts } from 'service/controller/Post'
import { PostModel } from 'service/model/Post'
import { keyUserPerAcademyPermissions } from 'service/model/User'
import shallow from 'zustand/shallow'
import { Store, useStore } from '../../appData/store'

const storeSelector = (store: Store) => ({
    currentUser: store.currentUser,
    academies: store.academies,
    jobFamilies: store.jobFamilies,
    regions: store.regions,
    academyCodeLookup: store.academyCodeLookup
})

type ListPostProps = {
    postType: string
    closeList: (id: string) => void
    onDeleteCompletion?: () => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium
    }
}

const ListPost: FC<ListPostProps> = ({ postType, closeList, onDeleteCompletion }) => {
    const {
        currentUser: zCurrentUser,
        jobFamilies,
        academies,
        regions
    } = useStore(storeSelector, shallow)

    const [alertDialog, setAlertDialog] = useState(defaultAlertProps)
    const [selectedPost, setSelectedPost] = useState<GridRenderCellParams>()
    // console.log('ListPost-currentUser', allCtx.state.auth)

    const [postDataRows, setPostDataRows] = useState<PostModel[]>([])

    const [filteredPostDataRows, setFilteredPostDataRows] = useState<PostModel[]>([])

    const [jobsFamily, setJobsFamily] = useState<string>('')
    const [region, setRegion] = useState<string>('')
    const [academy, setAcademy] = useState<string>('')

    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            minWidth: 250,
            editable: true
        },
        // {
        //     field: 'publishDate',
        //     headerName: 'Publish Date',
        //     type: 'date',
        //     width: 150,
        //     editable: true
        // },
        {
            field: 'postType',
            headerName: 'Post Type',
            type: 'string',
            width: 100,
            editable: true
        },
        {
            field: '_createdAt',
            headerName: 'Created At',
            type: 'string',
            width: 125,
            editable: true,
            renderCell: (params: GridRenderCellParams<string>) => {
                return params.value
            }
        },
        {
            field: 'buttonid',
            headerName: '',
            width: 200,
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <strong>
                        <Button
                            variant='contained'
                            size='small'
                            style={{ marginLeft: 16 }}
                            tabIndex={params.hasFocus ? 0 : -1}
                            onClick={() => {
                                // navigate(`/AddPost/${params.value}`, {
                                //     replace: true
                                // })
                                closeList(params.value as string)
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            variant='contained'
                            size='small'
                            style={{ marginLeft: 16 }}
                            tabIndex={params.hasFocus ? 0 : -1}
                            onClick={() => {
                                setSelectedPost(params)
                                setAlertDialog({
                                    ...alertDialog,
                                    isOpen: true,
                                    title: 'Delete',
                                    message: 'Are you sure you want to delete this post?'
                                })
                            }}
                        >
                            Delete
                        </Button>
                    </strong>
                )
            }
        }
    ]

    useEffect(() => {
        async function fetchData(_postType: string) {
            // You can await here
            const postsWithID = await getAllPosts(
                '',
                zCurrentUser?.user.uid as string,
                zCurrentUser?.userPerAcademyPermissions[keyUserPerAcademyPermissions],
                _postType
            )
            setPostDataRows(postsWithID)
            setFilteredPostDataRows(postsWithID)
            // console.log(response)
        }

        fetchData(postType)
    }, [])

    const handleDelete = () => {
        if (selectedPost) {
            deletePostFull(selectedPost.value as string)

            // Locally deleting for better UX
            const filteredPosts = postDataRows.filter(
                item => item.id !== selectedPost.value
            )
            setPostDataRows(filteredPosts)

            const filteredFilteredPosts = filteredPostDataRows.filter(
                item => item.id !== selectedPost.value
            )
            setFilteredPostDataRows(filteredFilteredPosts)

            if (onDeleteCompletion) {
                // Will refresh the content on Home screen
                onDeleteCompletion()
            }
        }
    }

    const calcFirebaseDate = (dateVal: Timestamp | null) => {
        // eslint-disable-next-line no-console
        // console.log('AddPost-create date', typeof createdAt, createdAt)
        return dateVal ? dateVal.toDate().toISOString().split('T')[0] : ''
    }

    /** Filters */

    const jobsFieldChange = (event: SelectChangeEvent<typeof jobsFamily>) => {
        const {
            target: { value }
        } = event

        setJobsFamily(value)
    }

    const regionFieldChange = (event: SelectChangeEvent<typeof jobsFamily>) => {
        const {
            target: { value }
        } = event

        setRegion(value)
    }

    const academyFieldChange = (event: SelectChangeEvent<typeof academy>) => {
        const {
            target: { value }
        } = event

        setAcademy(value)
    }

    const filterByJobFamily = (posts: PostModel[]) =>
        posts.filter(post => post.postJobFamily.includes(jobsFamily))

    const filterByRegion = (posts: PostModel[]) =>
        posts.filter(post => post.postRegion.includes(region))

    const filterByAcademy = (posts: PostModel[]) =>
        posts.filter(post => post.postAcademy.includes(academy))

    useEffect(() => {
        let posts: PostModel[] = postDataRows
        if (jobsFamily !== '') {
            if (academy !== '') {
                const academyFiltered = filterByAcademy(posts)
                posts = academyFiltered
            }
            if (region !== '') {
                const regionFiltered = filterByRegion(posts)
                posts = regionFiltered
            }
            const filtered = filterByJobFamily(posts)
            setFilteredPostDataRows(filtered)
        } else {
            if (academy !== '') {
                const academyFiltered = filterByAcademy(posts)
                posts = academyFiltered
            }
            if (region !== '') {
                const regionFiltered = filterByRegion(posts)
                posts = regionFiltered
            }

            setFilteredPostDataRows(posts)
        }
    }, [jobsFamily])

    useEffect(() => {
        let posts: PostModel[] = postDataRows
        if (academy !== '') {
            if (region !== '') {
                const regionFiltered = filterByRegion(posts)
                posts = regionFiltered
            }
            if (jobsFamily !== '') {
                const familyFiltered = filterByJobFamily(posts)
                posts = familyFiltered
            }

            const filtered = filterByAcademy(posts)
            setFilteredPostDataRows(filtered)
        } else {
            if (region !== '') {
                const regionFiltered = filterByRegion(posts)
                posts = regionFiltered
            }
            if (jobsFamily !== '') {
                const familyFiltered = filterByJobFamily(posts)
                posts = familyFiltered
            }

            setFilteredPostDataRows(posts)
        }
    }, [academy])

    useEffect(() => {
        let posts: PostModel[] = postDataRows
        if (region !== '') {
            if (academy !== '') {
                const academyFiltered = filterByAcademy(posts)
                posts = academyFiltered
            }
            if (jobsFamily !== '') {
                const familyFiltered = filterByJobFamily(posts)
                posts = familyFiltered
            }

            const filtered = filterByRegion(posts)
            setFilteredPostDataRows(filtered)
        } else {
            if (academy !== '') {
                const academyFiltered = filterByAcademy(posts)
                posts = academyFiltered
            }
            if (jobsFamily !== '') {
                const familyFiltered = filterByJobFamily(posts)
                posts = familyFiltered
            }

            setFilteredPostDataRows(posts)
        }
    }, [region])

    const academyArry = () => {
        // const allNullItems = [...academyAll]

        const mixedAcademyMenuItems: JSX.Element[] = []

        // allNullItems.forEach(_academy => {
        //     mixedAcademyMenuItems.push(
        //         <MenuItem key={_academy.academyCode} value={_academy.academyCode}>
        //             <ListItemText primary={_academy.academy} />
        //         </MenuItem>
        //     )
        // })

        const academyMenuItems = () =>
            academies.forEach(_academy => {
                mixedAcademyMenuItems.push(
                    <MenuItem key={_academy.academyCode} value={_academy.academyCode}>
                        <ListItemText primary={_academy.academy} />
                    </MenuItem>
                )
            })

        academyMenuItems()

        return mixedAcademyMenuItems
    }
    const jobFamilyArry = () => [...jobFamilies]
    const regionArry = () => [...regions]

    const theme = useTheme()

    return (
        <Box sx={{ p: 1 }} display='flex' flexDirection='column'>
            <Grid container mb={2} spacing={2}>
                <Grid item xs={4}>
                    <FormControl
                        sx={{
                            m: 0,
                            width: '100%'
                        }}
                    >
                        <InputLabel
                            id='demo-multiple-name-label'
                            sx={{ backgroundColor: 'white', padding: '0px 2px' }}
                            shrink
                        >
                            Job Family
                        </InputLabel>
                        <Select
                            labelId='demo-multiple-name-label'
                            id='demo-multiple-name'
                            displayEmpty
                            value={jobsFamily}
                            onChange={jobsFieldChange}
                            input={<OutlinedInput label='Job Family' />}
                            renderValue={
                                jobsFamily !== ''
                                    ? selected => selected
                                    : () => <Placeholder>Select Job Family</Placeholder>
                            }
                            MenuProps={MenuProps}
                            endAdornment={
                                jobsFamily !== '' ? (
                                    <IconButton
                                        onClick={() => {
                                            setJobsFamily('')
                                        }}
                                    >
                                        <CloseRoundedIcon />
                                    </IconButton>
                                ) : null
                            }
                        >
                            {jobFamilyArry().map(JobFamilyItem => (
                                <MenuItem key={JobFamilyItem} value={JobFamilyItem}>
                                    <ListItemText primary={JobFamilyItem} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl
                        sx={{
                            m: 0,
                            width: '100%'
                        }}
                    >
                        <InputLabel
                            id='demo-multiple-name-label'
                            sx={{ backgroundColor: 'white', padding: '0px 2px' }}
                            shrink
                        >
                            Region
                        </InputLabel>
                        <Select
                            labelId='demo-multiple-name-label'
                            id='demo-multiple-name'
                            displayEmpty
                            value={region}
                            onChange={regionFieldChange}
                            input={<OutlinedInput label='Region' />}
                            renderValue={
                                region !== ''
                                    ? selected => selected
                                    : () => <Placeholder>Select Region</Placeholder>
                            }
                            MenuProps={MenuProps}
                            endAdornment={
                                region !== '' ? (
                                    <IconButton
                                        onClick={() => {
                                            setRegion('')
                                        }}
                                    >
                                        <CloseRoundedIcon />
                                    </IconButton>
                                ) : null
                            }
                        >
                            {regionArry().map(regionItem => (
                                <MenuItem
                                    key={regionItem}
                                    value={regionItem}
                                    style={getStyles(regionItem, regions, theme)}
                                >
                                    <ListItemText primary={regionItem} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl
                        sx={{
                            m: 0,
                            width: '100%'
                        }}
                    >
                        <InputLabel
                            id='demo-multiple-name-label'
                            sx={{ backgroundColor: 'white', padding: '0px 2px' }}
                            shrink
                        >
                            Academy
                        </InputLabel>
                        <Select
                            labelId='demo-multiple-name-label'
                            id='demo-multiple-name'
                            displayEmpty
                            value={academy}
                            onChange={academyFieldChange}
                            input={<OutlinedInput label='Academy' />}
                            renderValue={
                                academy !== ''
                                    ? selected => selected
                                    : () => <Placeholder>Select Academy</Placeholder>
                            }
                            MenuProps={MenuProps}
                            endAdornment={
                                academy !== '' ? (
                                    <IconButton
                                        onClick={() => {
                                            setAcademy('')
                                        }}
                                    >
                                        <CloseRoundedIcon />
                                    </IconButton>
                                ) : null
                            }
                        >
                            {academyArry()}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <DataGrid
                sx={{ minHeight: window.innerHeight - 300 }}
                rows={filteredPostDataRows.map(postData => ({
                    buttonid: postData.id,
                    _createdAt: calcFirebaseDate(postData.createdAt),
                    ...postData
                }))}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
            />

            <AlertDialog
                alertDialog={alertDialog}
                setAlertDialog={setAlertDialog}
                onConfirmAction={handleDelete}
            />
        </Box>
    )
}

export default ListPost
