import { Box } from '@mui/material'
import { keyframes } from '@mui/system'
import { memo } from 'react'

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const Loader = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            {/* <Player
                autoplay
                loop
                src='animations/mortarboard.json'
                style={{ height: '150%', width: '150%' }}
            /> */}
            <Box
                sx={{
                    animation: `${fadeInUp} 1s ease-out`,
                    opacity: 0,
                    animationFillMode: 'forwards'
                }}
            >
                <Box
                    component='img'
                    src={`${process.env.PUBLIC_URL}/images/png/lift_school_green_logo.png`}
                    width={250}
                    height={80}
                    alt='Lift school Logo'
                />
            </Box>
        </Box>
    )
}

export default memo(Loader)
