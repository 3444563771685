import PermissionsIcon from '@mui/icons-material/Key'
import { Box, Fab } from '@mui/material'
import { altColor } from 'components/navbar/types'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const NavigateToPermissionsPageButton = () => {
    const navigate = useNavigate()
    const { theme } = useCustomTheme()
    const [isHovered, setIsHovered] = useState(false)

    const navigateToPermissionsPage = useCallback(() => {
        setIsHovered(false)
        navigate('/permissions')
    }, [navigate])

    const location = useLocation()

    const showButton = !location.pathname.startsWith('/permissions')

    const handleMouseEnter = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)

    return showButton ? (
        <Box
            sx={{
                'backgroundColor': '#ffffff',
                'border': `3px solid ${isHovered ? theme.palette.common.pink : altColor}`,
                'borderRadius': '50%',
                'padding': 0,
                'display': 'inline-flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                '&:hover': {
                    transform: 'scale(1.1)'
                },
                'transition': 'transform .4s, border-color .4s',
                'width': '46px',
                'height': '46px',
                'mt': '12px'
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Fab
                sx={{
                    'width': '36px',
                    'height': '36px',
                    'backgroundColor': isHovered
                        ? theme.palette.common.pink
                        : theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: isHovered
                            ? theme.palette.common.pink
                            : theme.palette.primary.main
                    },
                    'transition': 'background-color .4s'
                }}
                onClick={navigateToPermissionsPage}
            >
                <PermissionsIcon sx={{ color: '#ffffff' }} />
            </Fab>
        </Box>
    ) : null
}
