/* eslint-disable import/extensions */
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import PublicNews from 'pages/PublicNews/PublicNews'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AppProvider } from 'store/contexts/AppContext'

import 'froala-editor/js/froala_editor.pkgd.min.js'

// Require Editor CSS files.
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'

import 'froala-editor/css/plugins.pkgd.min.css'
import 'froala-editor/js/plugins.pkgd.min.js'

import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/image_manager.min.js'

import 'froala-editor/css/third_party/font_awesome.min.css'
import 'froala-editor/js/third_party/font_awesome.min.js'

// import 'froala-editor/css/third_party/embedly.min.css'
// import 'froala-editor/js/third_party/embedly.min.js'

// import 'froala-editor/css/third_party/image_tui.min.css'
// import 'froala-editor/js/third_party/image_tui.min.js'

// import 'froala-editor/css/third_party/spell_checker.min.css'
// import 'froala-editor/js/third_party/spell_checker.min.js'

import 'pages/FroalaCSS/FroalaEditorCustomCssClasses.css'

// Require Font Awesome.
import { GlobalProvider } from 'contexts/global-context'
import 'font-awesome/css/font-awesome.css'
import { useCustomTheme } from 'hooks/useCustomTheme'
import App from './App'

const container = document.getElementById('root')
if (container) {
    const root = createRoot(container)

    const CustomThemeProvider = ({ children }: any) => {
        const { theme } = useCustomTheme()
        return <ThemeProvider theme={theme}>{children}</ThemeProvider>
    }

    root.render(
        <>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <AppProvider>
                <BrowserRouter>
                    <GlobalProvider>
                        <CustomThemeProvider>
                            <Routes>
                                <Route path='/PublicNews/:id' element={<PublicNews />} />
                                <Route path='*' element={<App />} />
                            </Routes>
                        </CustomThemeProvider>
                    </GlobalProvider>
                </BrowserRouter>
            </AppProvider>
            <ToastContainer />
        </>
    )
}
