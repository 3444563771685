import { Box } from '@mui/material'
import { UnwrapRecordValue } from 'components/navbar/types'
import { CollapsedGroupExceptions } from 'pages/Permissions/PermissionsPage'
import { useCallback } from 'react'

export const GroupLinkFilterButton = ({
    groupId,
    state,
    stateTitles,
    onClick
}: {
    groupId?: string
    state: UnwrapRecordValue<CollapsedGroupExceptions>
    stateTitles?: Record<UnwrapRecordValue<CollapsedGroupExceptions>, string>
    onClick?: (groupId?: string) => void
}) => {
    const localOnClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()
            onClick?.(groupId)
        },
        [groupId, onClick]
    )

    const titles: Record<
        UnwrapRecordValue<CollapsedGroupExceptions>,
        string
    > = stateTitles || {
        open: 'Showing all links in folder',
        closed: 'Hiding all links in folder',
        show_active: 'Showing only links which are active for the selected user'
    }

    const labels: Record<UnwrapRecordValue<CollapsedGroupExceptions>, string> = {
        open: '[+]',
        closed: '[-]',
        show_active: '[.]'
    }

    return (
        <Box
            onClick={localOnClick}
            sx={{
                mr: '4px',
                fontFamily: 'monospace',
                cursor: 'pointer'
            }}
            title={titles[state]}
        >
            {labels[state]}
        </Box>
    )
}
