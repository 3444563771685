import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material'
import { useCustomTheme } from 'hooks/useCustomTheme'

export const enum AlertActionTypes {
    Yes = 'Yes',
    Submit = 'Submit',
    Agree = 'Agree',
    Confirm = 'Confirm',
    Delete = 'Delete',
    No = 'No',
    Cancel = 'Cancel',
    Disagree = 'Disagree',
    Close = 'Close'
}

interface IAlertProps {
    isOpen: boolean
    title: string
    message: string
    noButton: AlertActionTypes
    yesButton: AlertActionTypes
}

export const defaultAlertProps: IAlertProps = {
    isOpen: false,
    title: '',
    message: '',
    noButton: AlertActionTypes.No,
    yesButton: AlertActionTypes.Yes
}
interface IAlertDialog {
    alertDialog: IAlertProps
    setAlertDialog: React.Dispatch<React.SetStateAction<IAlertProps>>
    onConfirmAction?: () => void
    onRejectAction?: () => void
}

export function AlertDialog({
    alertDialog,
    setAlertDialog,
    onConfirmAction,
    onRejectAction
}: IAlertDialog) {
    const { fontColor, themeColor } = useCustomTheme()

    return (
        <Dialog
            open={alertDialog.isOpen}
            onClick={() => setAlertDialog({ ...alertDialog, isOpen: false })}
        >
            <DialogTitle sx={{ color: themeColor }} variant='h4'>
                {alertDialog.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: fontColor }}>
                    {alertDialog.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='outlined'
                    onClick={() => {
                        if (onRejectAction) {
                            onRejectAction()
                        }
                    }}
                >
                    {alertDialog.noButton}
                </Button>
                <Button variant='contained' onClick={onConfirmAction}>
                    {alertDialog.yesButton}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AlertDialog.defaultProps = {
    onConfirmAction: null,
    onRejectAction: null
}
