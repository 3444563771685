/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import AccessIcon from '@mui/icons-material/CheckBox'
import NoAccessIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { alpha } from '@mui/material'

import { altColor } from 'components/navbar/types'
// import { PermXCoordinate } from 'pages/Permissions/PermissionsPage'
import { useCallback } from 'react'
// import { Permission } from 'service/model/SingletonsModel'

const titleLookup = {
    false: 'No access',
    true: 'Access'
} as Record<string, string>

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
const loFi = 'png' as 'text' | 'png' | 'svg'

export const NavPermissionAssignmentButton = <S,>({
    assigned,
    isChanged,
    state,
    onAssignmentChanged,
    emphasized
}: {
    assigned: boolean
    isChanged: boolean
    state: S // { permissionName: Permission } & PermXCoordinate

    onAssignmentChanged: (
        state: S, // { permissionName: Permission } & PermXCoordinate
        assigned: boolean,
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void
    emphasized: boolean
}) => {
    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()

            onAssignmentChanged(state, !assigned, e)
        },
        [onAssignmentChanged, assigned, state]
    )

    const iconStyle = {
        color: altColor,
        ...(isChanged
            ? {
                  outline: '3px inset red',
                  borderRadius: '2px',
                  outlineOffset: '2px'
              }
            : {})
    }

    const fadedColor = alpha(altColor, 0.15)

    return (
        <div
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                padding: '0.25em 0.5em',
                display: 'flex',
                cursor: 'pointer'
            }}
            onClick={handleClick}
            title={`${titleLookup[assigned.toString()]}${isChanged ? ' (modified)' : ''}`}
        >
            {loFi === 'text' ? (
                !assigned ? (
                    <span>-</span>
                ) : (
                    <span>a</span>
                )
            ) : loFi === 'png' ? (
                !assigned ? (
                    <img
                        alt='Visibility'
                        width={18}
                        height={18}
                        src={
                            // eslint-disable-next-line prefer-template
                            process.env.PUBLIC_URL +
                            `/images/png/PermissionsPage/Unchecked${
                                emphasized ? '-dark' : ''
                            }.png`
                        }
                        style={iconStyle}
                    />
                ) : (
                    <img
                        alt='Visibility'
                        width={18}
                        height={18}
                        src={
                            // eslint-disable-next-line prefer-template
                            process.env.PUBLIC_URL +
                            '/images/png/PermissionsPage/Checked.png'
                        }
                        style={iconStyle}
                    />
                )
            ) : loFi === 'svg' ? (
                !assigned ? (
                    <NoAccessIcon sx={{ ...iconStyle, color: fadedColor }} />
                ) : (
                    <AccessIcon sx={iconStyle} />
                )
            ) : (
                <span />
            )}
        </div>
    )
}
