/* eslint-disable no-nested-ternary */
import { Box, Stack } from '@mui/material'
import {
    BlockAdjacency,
    cellEmphasisColor,
    GroupChange
} from 'pages/Permissions/PermissionsPage'
import { Fragment, memo } from 'react'
import { getEmojiPrefixAndRemainder, makeMapLookup } from 'service/utils'

import { useCustomTheme } from 'hooks/useCustomTheme'
import { GroupRowsParams } from 'pages/Permissions/PermissionsPage/GroupRows'
import { NavGroupPermButton } from 'pages/Permissions/PermissionsPage/NavGroupPermButton'
import { GroupModel } from 'service/model/GroupModel'
import { LinkModel } from 'service/model/LinkModel'

export const LinkRows = memo(
    ({
        blockAdjacency,
        group,
        links,
        knownEmails,
        jobFamilies,
        academies,
        regions,
        changedGroup,
        emphasized,
        emphasizedRowBackgroundColor,
        onLinkClick,
        onLinkPermChanged,
        transitionStr,
        emphasizeGroup,
        lastKnownEmailIndex,
        lastJobFamilyIndex,
        lastAcademyIndex,
        lastRegionIndex
    }: Omit<GroupRowsParams, 'groups' | 'collapsedGroupExceptions'> & {
        group: GroupModel
        links: LinkModel[]
        changedGroup: GroupChange
        emphasizeGroup: boolean
        lastKnownEmailIndex: number
        lastJobFamilyIndex: number
        lastAcademyIndex: number
        lastRegionIndex: number
        blockAdjacency: BlockAdjacency
    }) => {
        const { theme } = useCustomTheme()

        return (
            <>
                {links.map(link => {
                    const localOnLinkClick = () => {
                        if (group.id) {
                            onLinkClick?.(group.id, link.url)
                        }
                    }

                    const linkKnownEmailPerms = makeMapLookup(
                        link.requiredKnownEmails || [],
                        ({ email }) => email,
                        ({ permission }) => permission
                    )
                    const linkJobFamilyPerms = makeMapLookup(
                        link.requiredJobFamilies || [],
                        ({ name }) => name,
                        ({ permission }) => permission
                    )
                    const linkAcademyPerms = makeMapLookup(
                        link.requiredAcademies || [],
                        ({ academyCode }) => academyCode,
                        ({ permission }) => permission
                    )
                    const linkRegionPerms = makeMapLookup(
                        link.requiredRegions || [],
                        ({ regionName }) => regionName,
                        ({ permission }) => permission
                    )

                    const changedLink = changedGroup?.changedLinks?.[link.url]

                    const changedLinkOriginalKnownEmailGroupPermissions = changedLink
                        ? changedLink.originalKnownEmailPermissions
                        : linkKnownEmailPerms

                    const changedLinkOriginalJobFamilyGroupPermissions = changedLink
                        ? changedLink.originalJobFamilyPermissions
                        : linkJobFamilyPerms

                    const changedLinkOriginalAcademyGroupPermissions = changedLink
                        ? changedLink.originalAcademyPermissions
                        : linkAcademyPerms

                    const changedLinkOriginalRegionGroupPermissions = changedLink
                        ? changedLink.originalRegionPermissions
                        : linkRegionPerms

                    const emphasizeLinkRow =
                        emphasized.groupIds[group.id ?? '']?.linkUrls?.[link.url]

                    const emojiSplit = getEmojiPrefixAndRemainder(link.name)
                    const linkName = !emojiSplit ? link.name : emojiSplit[1]

                    const {
                        knownEmailsHasFollowingBlock,
                        jobFamiliesIsFirstBlock,
                        jobFamiliesHasFollowingBlock,
                        academiesHasFollowingBlock,
                        academiesIsFirstBlock,
                        regionsIsFirstBlock
                    } = blockAdjacency

                    const topBorderWidth = 0 // rowIndex ? '0' : '1px'

                    return (
                        <Fragment key={`link:${group.id}/${link.url}`}>
                            <Box
                                sx={{
                                    backgroundColor: emphasizeGroup
                                        ? emphasizedRowBackgroundColor
                                        : emphasizeLinkRow
                                        ? cellEmphasisColor
                                        : '#ffffff',
                                    padding: '0.25em 0.5em 0.25em 2em',
                                    whiteSpace: 'nowrap',
                                    position: 'sticky',
                                    top: 0,
                                    left: 0,
                                    zIndex: 100,
                                    ...(emphasizeGroup
                                        ? { transition: transitionStr }
                                        : {}),
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    borderWidth: `${topBorderWidth} 1px 1px 1px`,
                                    cursor: 'pointer',
                                    color: theme.palette.primary.main
                                }}
                                onClick={localOnLinkClick}
                            >
                                <Stack direction='row' alignItems='center'>
                                    {emojiSplit ? (
                                        <span
                                            style={{
                                                fontSize: 22,
                                                margin: '-3px'
                                            }}
                                        >
                                            {emojiSplit[0]}
                                        </span>
                                    ) : (
                                        <img
                                            src={link.favicon24Url}
                                            width={24}
                                            height={24}
                                            alt='favicon'
                                            style={{ flex: 0 }}
                                        />
                                    )}
                                    <span
                                        style={{
                                            marginLeft: '8px',
                                            whiteSpace: 'nowrap'
                                        }}
                                        title={link.url}
                                    >
                                        {linkName}
                                    </span>
                                </Stack>
                            </Box>

                            {knownEmails.map((knownEmail, i) => {
                                const perm = linkKnownEmailPerms.get(knownEmail)
                                const orig =
                                    changedLinkOriginalKnownEmailGroupPermissions?.get(
                                        knownEmail
                                    )
                                const isChanged = orig !== perm
                                const lastCol = i === lastKnownEmailIndex
                                const cellEmphasized =
                                    emphasizeLinkRow || emphasized.knownEmails[knownEmail]

                                return (
                                    <Box
                                        key={knownEmail}
                                        sx={{
                                            backgroundColor: emphasizeGroup
                                                ? emphasizedRowBackgroundColor
                                                : cellEmphasized
                                                ? cellEmphasisColor
                                                : '#ffffff',
                                            ...(emphasizeGroup
                                                ? { transition: transitionStr }
                                                : {}),
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            borderWidth: `${topBorderWidth} 1px 1px 0`,
                                            marginRight:
                                                lastCol && knownEmailsHasFollowingBlock
                                                    ? '10px'
                                                    : 0
                                            // width: '46px'
                                        }}
                                    >
                                        {/* {perm} */}
                                        <NavGroupPermButton
                                            perm={perm || 'none'}
                                            isChanged={isChanged}
                                            state={{
                                                groupId: group.id!,
                                                linkUrl: link.url,
                                                knownEmail
                                            }}
                                            onPermChanged={onLinkPermChanged}
                                            emphasized={cellEmphasized}
                                        />
                                    </Box>
                                )
                            })}
                            {jobFamilies.map(({ text: jf }, i) => {
                                const perm = linkJobFamilyPerms.get(jf)
                                const orig =
                                    changedLinkOriginalJobFamilyGroupPermissions?.get(jf)
                                const isChanged = orig !== perm
                                const lastCol = i === lastJobFamilyIndex
                                const cellEmphasized =
                                    emphasizeLinkRow || emphasized.jobFamilies[jf]

                                return (
                                    <Box
                                        key={jf}
                                        sx={{
                                            backgroundColor: emphasizeGroup
                                                ? emphasizedRowBackgroundColor
                                                : cellEmphasized
                                                ? cellEmphasisColor
                                                : '#ffffff',
                                            ...(emphasizeGroup
                                                ? { transition: transitionStr }
                                                : {}),
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            borderWidth:
                                                !jobFamiliesIsFirstBlock && i === 0
                                                    ? `${topBorderWidth} 1px 1px 1px`
                                                    : `${topBorderWidth} 1px 1px 0`,
                                            marginRight:
                                                lastCol && jobFamiliesHasFollowingBlock
                                                    ? '10px'
                                                    : 0
                                            // width: '46px'
                                        }}
                                    >
                                        {/* {perm} */}
                                        <NavGroupPermButton
                                            perm={perm || 'none'}
                                            isChanged={isChanged}
                                            state={{
                                                groupId: group.id!,
                                                linkUrl: link.url,
                                                jobFamily: jf
                                            }}
                                            onPermChanged={onLinkPermChanged}
                                            emphasized={cellEmphasized}
                                        />
                                    </Box>
                                )
                            })}
                            {academies.map(({ academyCode }, i) => {
                                const perm = linkAcademyPerms.get(academyCode)
                                const orig =
                                    changedLinkOriginalAcademyGroupPermissions?.get(
                                        academyCode
                                    )
                                const isChanged = orig !== perm
                                const lastCol = i === lastAcademyIndex
                                const cellEmphasized =
                                    emphasizeLinkRow ||
                                    emphasized.academyCodes[academyCode]

                                return (
                                    <Box
                                        key={academyCode}
                                        sx={{
                                            backgroundColor: emphasizeGroup
                                                ? emphasizedRowBackgroundColor
                                                : cellEmphasized
                                                ? cellEmphasisColor
                                                : '#ffffff',
                                            ...(emphasizeGroup
                                                ? { transition: transitionStr }
                                                : {}),
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            borderWidth:
                                                !academiesIsFirstBlock && i === 0
                                                    ? `${topBorderWidth} 1px 1px 1px`
                                                    : `${topBorderWidth} 1px 1px 0`,
                                            marginRight:
                                                lastCol && academiesHasFollowingBlock
                                                    ? '10px'
                                                    : 0
                                            // width: '46px'
                                        }}
                                    >
                                        {/* {perm} */}
                                        <NavGroupPermButton
                                            perm={perm || 'none'}
                                            isChanged={isChanged}
                                            state={{
                                                groupId: group.id!,
                                                linkUrl: link.url,
                                                academy: academyCode
                                            }}
                                            onPermChanged={onLinkPermChanged}
                                            emphasized={cellEmphasized}
                                        />
                                    </Box>
                                )
                            })}
                            {regions.map((rgn, i) => {
                                const perm = linkRegionPerms.get(rgn)
                                const orig =
                                    changedLinkOriginalRegionGroupPermissions?.get(rgn)
                                const isChanged = orig !== perm
                                const lastCol = i === lastRegionIndex
                                const cellEmphasized =
                                    emphasizeLinkRow || emphasized.regions[rgn]

                                return (
                                    <Box
                                        key={rgn}
                                        sx={{
                                            backgroundColor: emphasizeGroup
                                                ? emphasizedRowBackgroundColor
                                                : cellEmphasized
                                                ? cellEmphasisColor
                                                : '#ffffff',
                                            ...(emphasizeGroup
                                                ? { transition: transitionStr }
                                                : {}),
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            borderWidth:
                                                !regionsIsFirstBlock && i === 0
                                                    ? `${topBorderWidth} 1px 1px 1px`
                                                    : `${topBorderWidth} 1px 1px 0`,
                                            marginRight: lastCol ? 0 : 0
                                            // width: '46px'
                                        }}
                                    >
                                        {/* {perm} */}
                                        <NavGroupPermButton
                                            perm={perm || 'none'}
                                            isChanged={isChanged}
                                            state={{
                                                groupId: group.id!,
                                                linkUrl: link.url,
                                                region: rgn
                                            }}
                                            onPermChanged={onLinkPermChanged}
                                            emphasized={cellEmphasized}
                                        />
                                    </Box>
                                )
                            })}
                        </Fragment>
                    )
                })}
            </>
        )
    }
)
