import { useCustomTheme } from 'hooks/useCustomTheme'
import { useCallback, useEffect } from 'react'

const useUserWayStyling = (): void => {
    const { theme } = useCustomTheme()

    const applyHoverStyles = useCallback(
        (icon: HTMLElement) => {
            const originalBackground = icon.style.background

            const handleMouseOver = (): void => {
                icon.style.setProperty(
                    'background',
                    theme.palette.common.pink,
                    'important'
                )
                icon.style.setProperty(
                    'background-color',
                    theme.palette.common.pink,
                    'important'
                )
            }

            const handleMouseOut = (): void => {
                icon.style.setProperty('background', originalBackground, 'important')
            }

            icon.addEventListener('mouseover', handleMouseOver)
            icon.addEventListener('mouseout', handleMouseOut)

            return () => {
                icon.removeEventListener('mouseover', handleMouseOver)
                icon.removeEventListener('mouseout', handleMouseOut)
            }
        },
        [theme]
    )

    const applyPositionStyles = useCallback(() => {
        const wrapper = document.querySelector('.userway_buttons_wrapper') as HTMLElement
        if (wrapper) {
            wrapper.style.setProperty('bottom', '6px', 'important')
            wrapper.style.setProperty('left', 'calc(100vw - 14px)', 'important')
        }
    }, [])

    const applyUserwayStyles = useCallback(() => {
        const icon = document.getElementById('userwayAccessibilityIcon')
        if (icon) {
            icon.style.transition = 'all 0.4s'

            const cleanupHover = applyHoverStyles(icon)
            applyPositionStyles()

            return () => {
                cleanupHover()
            }
        }
        const timeoutId = setTimeout(applyUserwayStyles, 100)
        return () => clearTimeout(timeoutId)
    }, [applyHoverStyles, applyPositionStyles])

    useEffect(() => {
        const cleanup = applyUserwayStyles()
        return cleanup
    }, [applyUserwayStyles])
}

export default useUserWayStyling
