/* eslint-disable no-nested-ternary */
import GroupIcon from '@mui/icons-material/GroupWork'
import {
    BlockAdjacency,
    CollapsedGroupExceptions,
    CollapsedPerAcademyPermissionExceptions,
    defaultCollapsedPerAcademyPermissionState,
    Emphasized,
    PerAcademyPermChange,
    PermXCoordinate
} from 'pages/Permissions/PermissionsPage'

import { Fragment, memo } from 'react'

import { Store } from 'appData/store'
import { altColor, PartialRecord, UnwrapRecordValue } from 'components/navbar/types'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { GridSection } from 'pages/Permissions/PermissionsPage/GridSection'
import { NavPermissionAssignmentButton } from 'pages/Permissions/PermissionsPage/NavPermissionAssignmentButton'
import { PerAcademyPermissionAcademyRows } from 'pages/Permissions/PermissionsPage/PerAcademyPermissionAcademyRows'
import { PerAcademyPermissionFilterButton } from 'pages/Permissions/PermissionsPage/PerAcademyPermissionFilterButton'
import {
    PerAcademyPermission,
    PerAcademyPermissionSingletonModel
} from 'service/model/SingletonsModel'

const PerAcademyPermissionRowTitle = ({
    pap,
    collapsedException,
    onClick,
    iconColor
}: {
    pap: PerAcademyPermission
    collapsedException: UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions>
    onClick?: (pap: PerAcademyPermission) => void
    iconColor: string
}) => (
    <>
        <PerAcademyPermissionFilterButton
            perAcademyPermission={pap}
            state={collapsedException}
            onClick={onClick}
        />
        <GroupIcon
            style={{
                color: iconColor
            }}
        />
        <span style={{ marginLeft: '8px', whiteSpace: 'nowrap' }}>{pap}</span>
    </>
)
const PerAcademyPermissionRow = memo(
    ({
        blockAdjacency,
        perAcademyPermission,
        academyCodeLookup,
        collapsedException,
        knownEmails,
        jobFamilies,
        academies,
        regions,
        changedPerAcademyPerms,
        transitionStr,
        gridSectionTop,
        gridSectionHeight,
        onPerAcademyPermAssignmentChanged,
        onPerAcademyPermissionClick,
        onPerAcademyPermissionsAcademyFilterClicked,
        emphasized
    }: Omit<
        PerAcademyPermissionRowsParams,
        'perAcademyPermissions' | 'collapsedPerAcademyPermissionExceptions'
    > & {
        perAcademyPermission: PerAcademyPermissionSingletonModel['perAcademyPermissions'][0] // & { changed: boolean }
        academyCodeLookup: Store['academyCodeLookup']
        collapsedException: UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions>
    }) => {
        const { theme } = useCustomTheme()
        const knownEmailCount = knownEmails.length
        const jobFamilyCount = jobFamilies.length
        const academyCount = academies.length
        const regionCount = regions.length

        return (
            <Fragment key={perAcademyPermission.name}>
                <GridSection
                    colWidths={[
                        knownEmailCount,
                        jobFamilyCount,
                        academyCount,
                        regionCount
                    ]}
                    top={gridSectionTop}
                    height={gridSectionHeight}
                    color={theme.palette.primary.main}
                    backgroundColor='#e0e0e0'
                    borderColor={theme.palette.primary.main}
                    topBorder
                    bottomBorder
                >
                    <PerAcademyPermissionRowTitle
                        pap={perAcademyPermission.name}
                        collapsedException={collapsedException}
                        onClick={onPerAcademyPermissionsAcademyFilterClicked}
                        iconColor={altColor}
                    />
                </GridSection>

                <PerAcademyPermissionAcademyRows
                    blockAdjacency={blockAdjacency}
                    academyCodeLookup={academyCodeLookup}
                    collapsedException={collapsedException}
                    perAcademyPermission={perAcademyPermission}
                    knownEmails={knownEmails}
                    jobFamilies={jobFamilies}
                    academies={academies}
                    regions={regions}
                    changedPerAcademyPerms={changedPerAcademyPerms}
                    transitionStr={transitionStr}
                    onPerAcademyPermAssignmentChanged={onPerAcademyPermAssignmentChanged}
                    onPerAcademyPermissionClick={onPerAcademyPermissionClick}
                    emphasized={emphasized}
                />
            </Fragment>
        )
    }
)

export type PerAcademyPermissionRowsParams = {
    blockAdjacency: BlockAdjacency
    perAcademyPermissions: PerAcademyPermissionSingletonModel['perAcademyPermissions']
    collapsedPerAcademyPermissionExceptions: CollapsedPerAcademyPermissionExceptions
    knownEmails: Store['knownEmails']
    jobFamilies: { prefix: string; suffix: string; text: string }[]
    academies: Store['academies']
    regions: string[]
    changedPerAcademyPerms: PartialRecord<
        PerAcademyPermission,
        Record<string, PerAcademyPermChange>
    >
    transitionStr: string
    gridSectionTop?: number
    gridSectionHeight?: number
    onPerAcademyPermAssignmentChanged: Parameters<
        typeof NavPermissionAssignmentButton<
            {
                perAcademyPermission: PerAcademyPermission
                papAcademyCode: string
            } & PermXCoordinate
        >
    >[0]['onAssignmentChanged']
    onPerAcademyPermissionClick?: (
        perAcademyPermission: PerAcademyPermission,
        papAcademyCode: string
    ) => void
    onPerAcademyPermissionsAcademyFilterClicked?: (
        perAcademyPermission: PerAcademyPermission
    ) => void
    emphasized: Emphasized
}
export const PerAcademyPermissionRows = memo(
    ({
        blockAdjacency,
        perAcademyPermissions,
        academyCodeLookup,
        collapsedPerAcademyPermissionExceptions,
        knownEmails,
        jobFamilies,
        academies,
        regions,
        changedPerAcademyPerms,
        transitionStr,
        gridSectionTop,
        gridSectionHeight,
        onPerAcademyPermAssignmentChanged,
        onPerAcademyPermissionClick,
        onPerAcademyPermissionsAcademyFilterClicked,
        emphasized
    }: PerAcademyPermissionRowsParams & {
        academyCodeLookup: Store['academyCodeLookup']
    }) => {
        return (
            <>
                {perAcademyPermissions.map(pap => {
                    const collapsedException: UnwrapRecordValue<CollapsedGroupExceptions> =
                        collapsedPerAcademyPermissionExceptions[pap.name] ||
                        defaultCollapsedPerAcademyPermissionState

                    return (
                        <PerAcademyPermissionRow
                            key={pap.name}
                            blockAdjacency={blockAdjacency}
                            perAcademyPermission={pap}
                            academyCodeLookup={academyCodeLookup}
                            collapsedException={collapsedException}
                            knownEmails={knownEmails}
                            jobFamilies={jobFamilies}
                            academies={academies}
                            regions={regions}
                            changedPerAcademyPerms={changedPerAcademyPerms}
                            transitionStr={transitionStr}
                            gridSectionTop={gridSectionTop}
                            gridSectionHeight={gridSectionHeight}
                            onPerAcademyPermAssignmentChanged={
                                onPerAcademyPermAssignmentChanged
                            }
                            onPerAcademyPermissionClick={onPerAcademyPermissionClick}
                            onPerAcademyPermissionsAcademyFilterClicked={
                                onPerAcademyPermissionsAcademyFilterClicked
                            }
                            emphasized={emphasized}
                        />
                    )
                })}
            </>
        )
    }
)
