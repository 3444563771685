import { Box } from '@mui/material'
import { useCustomTheme } from 'hooks/useCustomTheme'

import { memo } from 'react'

interface IColumnHeaders<T> {
    columnData: T[]
    dataExtractor: (t: T) => {
        key: string
        name: string
        title?: string
        markerTitle: string
        selectedUserHasItem: boolean
    }
    headerHeight: number
    markerHeight: number
    onClick: (value: string) => void
    isFinalBlock: boolean
}
const ColumnHeadersImpl = <T,>({
    columnData,
    dataExtractor,
    headerHeight,
    markerHeight,
    onClick,
    isFinalBlock
}: IColumnHeaders<T>) => {
    const lastColIndex = columnData.length - 1
    const { theme } = useCustomTheme()

    return (
        <>
            {columnData.map((item, i) => {
                const { key, name, title, markerTitle, selectedUserHasItem } =
                    dataExtractor(item)
                const lastCol = i === lastColIndex

                return (
                    <Box
                        key={key}
                        sx={{
                            backgroundColor: theme.palette.common.darkGreen,
                            height: `${headerHeight}px`,
                            padding: 0,
                            borderRight: `1px solid ${
                                lastCol
                                    ? theme.palette.common.darkGreen
                                    : theme.palette.common.white
                            }`,
                            borderTop: `1px solid ${theme.palette.common.darkGreen}`,
                            minWidth: '46px',
                            // width: '46px',
                            position: 'sticky',
                            top: 0,
                            zIndex: 103,
                            marginRight: lastCol && !isFinalBlock ? '10px' : 0
                        }}
                        onClick={() => onClick(key)}
                    >
                        <Box
                            sx={{
                                ...(selectedUserHasItem
                                    ? {
                                          backgroundColor: '#ffffff',
                                          margin: !lastCol
                                              ? '2px 3px 0 3px'
                                              : '2px 2px 0 3px'
                                      }
                                    : {}),
                                height: markerHeight,
                                cursor: 'help',
                                zIndex: 101,
                                position: 'relative'
                            }}
                            title={markerTitle}
                        />
                        <Box
                            sx={{
                                textAlign: 'center',

                                width: 0,
                                position: 'relative',
                                top: `${headerHeight - markerHeight}px`,
                                margin: '0 auto',
                                cursor: 'pointer'
                            }}
                        >
                            <Box
                                sx={{
                                    transform: 'rotate(-90deg)',
                                    transformOrigin: 'left center',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block',
                                    position: 'absolute',
                                    width: `${headerHeight}px`,
                                    padding: '0 0.5em',
                                    textAlign: 'left',
                                    color: '#ffffff',
                                    top: '-13px'
                                }}
                                title={title}
                            >
                                {name}
                            </Box>
                        </Box>
                    </Box>
                )
            })}
        </>
    )
}

export const ColumnHeaders = memo(ColumnHeadersImpl) as typeof ColumnHeadersImpl
