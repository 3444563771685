/* eslint-disable no-nested-ternary */
import { NavDeleteIcon } from 'components/navbar/Content/Styled/NavDeleteIcon'
import { NavButton } from 'components/navbar/Content/Styled/StyledNavComponents'
import { useCallback, useState } from 'react'

export type ButtonStatus = 'unclicked' | 'clickedOnce' | 'confirmed'

export const NavDeleteButton = ({
    allBlue,
    altColor,
    onClick,
    autoResetTimeout = 3000,
    onStatusChanged
}: {
    allBlue: boolean
    altColor: string
    autoResetTimeout?: number
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onStatusChanged: (status: ButtonStatus) => void
}) => {
    const [status, _setStatus] = useState<ButtonStatus>('unclicked')

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const doSetStatus = (newStatus: ButtonStatus) => {
                onStatusChanged?.(newStatus)
                _setStatus(newStatus)
            }
            e.stopPropagation()

            if (status === 'unclicked') {
                doSetStatus('clickedOnce')
                setTimeout(() => {
                    doSetStatus('unclicked')
                }, autoResetTimeout)
            } else if (status === 'clickedOnce') {
                doSetStatus('confirmed')

                onClick(e)
                doSetStatus('unclicked')
            }
        },
        [autoResetTimeout, onClick, onStatusChanged, status]
    )

    return (
        <NavButton
            sx={{
                justifyContent: 'end',
                alignItems: 'flex-end',
                flexGrow: 0
            }}
            onClick={handleClick}
            title={
                status === 'unclicked'
                    ? 'Click to delete'
                    : status === 'clickedOnce'
                    ? 'Click to confirm'
                    : ''
            }
        >
            <NavDeleteIcon
                allBlue={allBlue}
                altColor={altColor}
                requestConfirmation={status !== 'unclicked'}
            />
        </NavButton>
    )
}
