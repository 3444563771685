import {
    getSystemGroups,
    isSimilarToSystemGroupName,
    systemGroupNames
} from 'appData/store/systemGroups'
import { addDoc, doc, getDocs, query, runTransaction, where } from 'firebase/firestore'
import { annotateGroupsWithUserPermission } from 'service/controller/User'
import {
    AcademyGroupPermission,
    GroupModel,
    groupSorter,
    JobFamilyGroupPermission,
    KnownEmailGroupPermission,
    RegionGroupPermission
} from 'service/model/GroupModel'
import { LinkModel, linkSorter } from 'service/model/LinkModel'
import { UserModel } from 'service/model/User'
import { getFavIconUrlForSite, log, makeLookup, sortedInsertInPlace } from 'service/utils'
import { db, groupsCollection, usersCollection } from '../firebase'

export const _addLinksForGroup = async (groupId: string, links: LinkModel[]) => {
    let updatedGroup: GroupModel | undefined

    const groupRef = doc(groupsCollection, groupId)
    await runTransaction(db, async transaction => {
        const group = await transaction.get(groupRef)
        if (!group.exists()) {
            throw new Error(`Group '${groupId}' doesn't exist!`)
        }
        const groupData = group.data() as GroupModel
        const linkLookup = makeLookup(
            groupData?.links,
            l => l.url,
            l => l
        )
        const newLinks = links.filter(nl => !linkLookup[nl.url])
        if (newLinks.length > 0) {
            const updatedLinks = [...groupData.links, ...newLinks].sort(linkSorter)
            updatedGroup = {
                ...groupData,
                id: group.id,
                links: updatedLinks
            }
            transaction.update(groupRef, updatedGroup)
        }
    })

    return updatedGroup
}

export const _addLinksToUserLinksFolder = async (
    userId: UserModel['uid'],
    links: LinkModel[]
) => {
    let updatedGroup: GroupModel | undefined
    const userRef = doc(usersCollection, userId)

    await runTransaction(db, async transaction => {
        const user = await transaction.get(userRef)
        if (!user.exists()) {
            throw new Error(`User '${userId}' doesn't exist`)
        }
        const userData = user.data() as UserModel
        const linkLookup = makeLookup(
            userData?.userLinksFolder?.links || [],
            l => l.url,
            l => l
        )
        const newLinks: LinkModel[] = links
            .filter(nl => !linkLookup[nl.url])
            .map(l => ({ ...l, userPermission: 'edit' }))

        if (newLinks.length > 0) {
            const userLinksFolder = _getUserLinksFolder(userData)
            const updatedLinks = [...userLinksFolder.links, ...newLinks].sort(linkSorter)
            updatedGroup = {
                ...userLinksFolder,
                links: updatedLinks
            }
            const updatedUser = {
                ...userData,
                userLinksFolder: updatedGroup
            }

            transaction.update(userRef, updatedUser)
        }
    })

    return updatedGroup
}

export const _updateLinkForGroup = async (
    curUrl: string,
    groupId: string,
    { name, url, description }: { name: string; url: string; description: string }
) => {
    let updatedGroup: GroupModel | undefined

    const groupRef = doc(groupsCollection, groupId)
    await runTransaction(db, async transaction => {
        const group = await transaction.get(groupRef)
        if (!group.exists()) {
            throw new Error(`Group '${groupId}' doesn't exist!`)
        }
        const groupData = group.data() as GroupModel
        const existing = groupData.links.find(l => l.url === curUrl)
        const newLink: LinkModel = {
            ...existing,
            name,
            url,
            description,
            favicon24Url: getFavIconUrlForSite(url),
            favicon32Url: getFavIconUrlForSite(url, 32),
            userPermission: 'edit'
        }

        const updatedLinks = groupData.links.filter(
            l => l.url !== curUrl && l.url !== url
        )
        sortedInsertInPlace(updatedLinks, newLink, linkSorter)

        updatedGroup = {
            ...groupData,
            id: group.id,
            links: updatedLinks
        }
        transaction.update(groupRef, updatedGroup)
    })

    return updatedGroup
}

export const _updateLinkForUserLinksFolder = async (
    userId: UserModel['uid'],
    curUrl: string,
    { name, url, description }: { name: string; url: string; description: string }
) => {
    let updatedGroup: GroupModel | undefined
    const userRef = doc(usersCollection, userId)

    await runTransaction(db, async transaction => {
        const user = await transaction.get(userRef)
        if (!user.exists()) {
            throw new Error(`User '${userId}' doesn't exist`)
        }
        const userData = user.data() as UserModel
        const userLinksFolder = _getUserLinksFolder(userData)
        const existing = userLinksFolder.links.find(l => l.url === curUrl)
        const newLink = {
            ...existing,
            name,
            url,
            description,
            favicon24Url: getFavIconUrlForSite(url),
            favicon32Url: getFavIconUrlForSite(url, 32)
        } as LinkModel
        const updatedLinks = userLinksFolder.links.filter(
            l => l.url !== curUrl && l.url !== url
        )
        sortedInsertInPlace(updatedLinks, newLink, linkSorter)

        updatedGroup = {
            ...userLinksFolder,
            links: updatedLinks
        }
        const updatedUser = {
            ...userData,
            userLinksFolder: updatedGroup
        }

        transaction.update(userRef, updatedUser)
    })

    return updatedGroup
}

export const _removeLinkFromGroup = async (groupId: string, linkUrl: string) => {
    let updatedGroup: GroupModel | undefined

    const groupRef = doc(groupsCollection, groupId)
    await runTransaction(db, async transaction => {
        const group = await transaction.get(groupRef)
        if (!group.exists()) {
            throw new Error(`Group '${groupId}' doesn't exist!`)
        }
        const groupData = group.data() as GroupModel
        const links = groupData.links.filter(({ url }) => url !== linkUrl)
        updatedGroup = {
            ...groupData,
            links
        }
        transaction.update(groupRef, updatedGroup)
    })

    return updatedGroup
}

export const _removeLinkFromUserLinksFolder = async (userId: string, linkUrl: string) => {
    let updatedGroup: GroupModel | undefined
    const userRef = doc(usersCollection, userId)

    await runTransaction(db, async transaction => {
        const user = await transaction.get(userRef)
        if (!user.exists()) {
            throw new Error(`User '${userId}' doesn't exist!`)
        }
        const userData = user.data() as UserModel
        const userLinksFolder = _getUserLinksFolder(userData)
        const links = userLinksFolder.links.filter(({ url }) => url !== linkUrl)

        updatedGroup = {
            ...userLinksFolder,
            links
        }
        const updatedUser = {
            ...userData,
            userLinksFolder: updatedGroup
        }

        transaction.update(userRef, updatedUser)
    })

    return updatedGroup
}

// ----------------

export const _createGroup = async (group: GroupModel, isSystem = false) => {
    if (group.name.startsWith('_') && !isSystem) {
        return undefined
    }
    if (!(await _findGroupByName(group.name))) {
        const docRef = await addDoc(groupsCollection, group)
        return docRef
    }
    return undefined
}

export const _createAndReturnGroup = async (group: GroupModel, isSystem = false) => {
    // eslint-disable-next-line no-param-reassign
    group = { ...group, mayDelete: true, mayRename: true }

    const docRef = await _createGroup(group, isSystem)
    if (docRef) {
        const { id } = docRef
        return { ...group, id } as GroupModel
    }
    return undefined
}

export const _updateGroupName = async (groupId: string, name: string) => {
    let updatedGroup: GroupModel | undefined

    // eslint-disable-next-line no-param-reassign
    name = name.trim()
    if (isSimilarToSystemGroupName(name)) {
        return undefined
    }

    const groupRef = doc(groupsCollection, groupId)
    await runTransaction(db, async transaction => {
        const group = await transaction.get(groupRef)
        if (!group.exists()) {
            throw new Error(`Group '${groupId}' doesn't exist!`)
        }
        const groupData = group.data() as GroupModel

        updatedGroup = {
            ...groupData,
            id: groupId,
            name
        }
        transaction.update(groupRef, updatedGroup)
    })

    return updatedGroup
}

// export const _updateGroupPermissions = async (
//     groupId: string,
//     requiredJobFamilies: JobFamilyGroupPermission[]
// ) => {
//     let updatedGroup: GroupModel | undefined

//     const groupRef = doc(groupsCollection, groupId)
//     await runTransaction(db, async transaction => {
//         const group = await transaction.get(groupRef)
//         if (!group.exists()) {
//             throw new Error(`Group '${groupId}' doesn't exist!`)
//         }
//         const groupData = group.data() as GroupModel

//         updatedGroup = {
//             ...groupData,
//             id: groupId,
//             requiredJobFamilies
//         }
//         transaction.update(groupRef, updatedGroup)
//     })

//     return updatedGroup
// }

export const _bulkUpdateGroupPermissions = async (
    updates: {
        id: string
        requiredKnownEmails: KnownEmailGroupPermission[]
        requiredJobFamilies: JobFamilyGroupPermission[]
        requiredAcademies: AcademyGroupPermission[]
        requiredRegions: RegionGroupPermission[]
        linkPerms: {
            requiredKnownEmails: KnownEmailGroupPermission[]
            requiredJobFamilies: JobFamilyGroupPermission[]
            requiredAcademies: AcademyGroupPermission[]
            requiredRegions: RegionGroupPermission[]
        }[]
    }[]
) => {
    try {
        await runTransaction(db, async transaction => {
            const job = await Promise.all(
                updates.map(
                    async ({
                        id,
                        requiredKnownEmails,
                        requiredAcademies,
                        requiredJobFamilies,
                        requiredRegions,
                        linkPerms
                    }) => {
                        const docRef = doc(groupsCollection, id)
                        const dsGroup = await transaction.get(docRef)
                        if (!dsGroup.exists()) {
                            throw new Error(`Group '${id}' doesn't exist!`)
                        }
                        const group = dsGroup.data() as GroupModel
                        const updatedLinks = group.links.map((link, i) => {
                            const lp = linkPerms[i]
                            return {
                                ...link,
                                requiredKnownEmails: lp.requiredKnownEmails,
                                requiredJobFamilies: lp.requiredJobFamilies,
                                requiredAcademies: lp.requiredAcademies,
                                requiredRegions: lp.requiredRegions
                            } as LinkModel
                        })
                        const updatedGroup = {
                            ...group,
                            links: updatedLinks,
                            id,
                            requiredKnownEmails,
                            requiredJobFamilies,
                            requiredAcademies,
                            requiredRegions
                        }
                        return {
                            id,
                            requiredKnownEmails,
                            requiredJobFamilies,
                            requiredAcademies,
                            requiredRegions,
                            docRef,
                            group: dsGroup,
                            updatedGroup
                        }
                    }
                )
            )

            job.forEach(({ docRef, updatedGroup }) => {
                transaction.update(docRef, updatedGroup)
            })
        })
        return true
    } catch (err) {
        log(`_bulkUpdateGroupPermissions -> ${err}`)
        return false
    }
}

export const _removeGroup = async (groupId: string) => {
    let removedGroup: GroupModel | undefined

    const groupRef = doc(groupsCollection, groupId)
    await runTransaction(db, async transaction => {
        const group = await transaction.get(groupRef)
        if (!group.exists()) {
            throw new Error(`Group '${groupId}' doesn't exist!`)
        }
        removedGroup = { ...group.data(), id: groupId }

        transaction.delete(groupRef)
    })

    return removedGroup
}

export const _createMyLinksGroup = async () => {
    const group = (await getSystemGroups())._navBarLinks
    const docRef = await _createGroup(group, true)
    if (docRef) {
        const { id } = docRef
        return { ...group, id } as GroupModel
    }
    return undefined
}

export const _findGroupByName = async (groupName: string) => {
    const q = query(groupsCollection, where('name', '==', groupName))
    const existingGroups = await getDocs(q)
    return existingGroups.docs?.[0]
}

export const _getUserLinksFolder = (user?: UserModel) => {
    const defaultUserLinksFolder: GroupModel = {
        id: systemGroupNames.__userLinks,
        name: systemGroupNames.__userLinks,
        links: [
            // {
            //     name: 'LinkedIn',
            //     url: 'https://linkedin.com/in/leedoranconstant/',
            //     description: 'My LinkedIn',
            //     favicon24Url:
            //         'https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://linkedin.com/in/leedoranconstant/&size=24',
            //     favicon32Url:
            //         'https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://linkedin.com/in/leedoranconstant/&size=24',
            //     userPermission: 'edit'
            // }
        ],
        mayRename: false,
        mayDelete: false,
        isSystem: true,
        userPermission: 'edit'
    }
    const userLinksFolder = { ...(user?.userLinksFolder || defaultUserLinksFolder) }

    return userLinksFolder
}

export const _getGroups = async (
    user: UserModel,
    {
        knownEmailsSet,
        jobFamiliesSet,
        academyCodeSet,
        regionSet
    }: {
        knownEmailsSet: Set<string>
        jobFamiliesSet: Set<string>
        academyCodeSet: Set<string>
        regionSet: Set<string>
    }
) => {
    const existingGroups = await getDocs(groupsCollection)
    const groups = existingGroups.docs.map(g => {
        const { id } = g
        const data = g.data()
        const {
            requiredKnownEmails,
            requiredJobFamilies,
            requiredAcademies,
            requiredRegions,
            ...props
        } = data

        return {
            ...props,
            id,
            requiredKnownEmails: requiredKnownEmails || [],
            requiredJobFamilies: requiredJobFamilies || [],
            requiredAcademies: requiredAcademies || [],
            requiredRegions: requiredRegions || []
        } as GroupModel
    })

    const userLinksFolder = _getUserLinksFolder(user)

    const userGroups = [
        userLinksFolder,
        ...annotateGroupsWithUserPermission(groups, {
            knownEmailsSet,
            jobFamiliesSet,
            academyCodeSet,
            regionSet
        })
    ].sort(groupSorter)

    return userGroups
}
