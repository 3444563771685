/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import SubMenuIcon from '@mui/icons-material/ChevronRight'
import GroupIcon from '@mui/icons-material/GroupWork'
import { Box, Divider, ListItem, Stack } from '@mui/material'
import { NavEditIcon } from 'components/navbar/Content/Styled/NavEditIcon'
import { NavPermissionsIcon } from 'components/navbar/Content/Styled/NavPermissionsIcon'
import {
    NavButton,
    NavGroup,
    NavItem,
    NavItemText
} from 'components/navbar/Content/Styled/StyledNavComponents'
import { NavDeleteButton } from 'components/navbar/NavDeleteButton'
import shallow from 'zustand/shallow'

import { Store, useStore } from 'appData/store'
import { systemGroupNames } from 'appData/store/systemGroups'
import { AddEditGroup } from 'components/navbar/AddEditGroup'
import { StyledScrollbars } from 'components/navbar/StyledScrollbars'
import { altColor, NavBarContentProps, white } from 'components/navbar/types'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GroupViewModel, toGroupViewModel } from 'service/model/GroupModel'
import { getEmojiPrefixAndRemainder, getIconSize } from 'service/utils'

const groupsStoreSelector = (store: Store) => ({
    settings: store.settings,
    groups: store.groups,

    editGroup: store.editGroup,
    setEditGroup: store.setEditGroup,
    clearEditGroup: store.clearEditGroup,

    // permissionsGroup: store.permissionsGroup,
    // setPermissionsGroup: store.setPermissionsGroup,
    // clearPermissionsGroup: store.clearPermissionsGroup,

    renameEditGroup: store.renameEditGroup,
    userHasPermission: store.userHasPermission,

    addGroup: store.addGroup,
    removeGroup: store.removeGroup
})

const needsPersistentVisibility = ({ withinDeleteCycle }: GroupViewModel) =>
    withinDeleteCycle

export const GroupsContent = ({
    open,
    navBarItemClickedHandler,
    navInfo,
    allBlue
}: NavBarContentProps) => {
    const {
        settings,
        groups,

        editGroup,
        setEditGroup,
        clearEditGroup,

        // permissionsGroup,
        // setPermissionsGroup,
        // clearPermissionsGroup,

        renameEditGroup,
        userHasPermission,

        addGroup,
        removeGroup
    } = useStore(groupsStoreSelector, shallow)

    const [_rerenderHackCounter, setRerenderHackCounter] = useState(0)
    const rerenderHack = () => setRerenderHackCounter(current => current + 1)

    const [groupViewModels, setGroupViewModels] = useState<GroupViewModel[]>(
        groups.filter(g => g.userPermission !== 'none').map(g => toGroupViewModel(g)) ||
            []
    )

    const navigate = useNavigate()

    useEffect(() => {
        setGroupViewModels(
            groups
                .filter(g => g.userPermission !== 'none')
                .map(g => toGroupViewModel(g)) || []
        )
    }, [groups])

    const iconSize = getIconSize(open)

    const userMayEditPermissions = userHasPermission('edit permissions')
    const userMayEditGroups = userHasPermission('edit groups')
    return (
        <ListItem
            sx={{
                padding: 0,
                flexDirection: 'column',
                alignItems: 'stretch',
                flex: '1 1',
                display: 'flex'
            }}
            className='no-hover'
        >
            <Box
                sx={{
                    position: 'relative',
                    flexGrow: 1
                }}
            >
                <NavGroup
                    sx={{
                        display: 'flex',
                        overflowX: 'hidden',
                        overflowY: open ? 'auto' : 'hidden',
                        flexGrow: 1,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        padding: 0
                    }}
                    className='no-hover'
                >
                    <StyledScrollbars open={open} allBlue={allBlue}>
                        {groupViewModels?.map(group => {
                            const { id: groupId, name } = group
                            const text = name
                            const userMayEditGroup = group.userPermission === 'edit'
                            const showEditGroupUi =
                                !!editGroup &&
                                editGroup.id === groupId &&
                                group.mayRename &&
                                userMayEditGroup
                            const groupHasPermissions =
                                group.name !== systemGroupNames.__userLinks

                            const showStandardUi = !showEditGroupUi
                            const emojiSplit = getEmojiPrefixAndRemainder(name)
                            const folderName_ = emojiSplit ? emojiSplit[1] : text
                            const folderName = !group.isSystem
                                ? folderName_
                                : `# ${folderName_}`

                            return (
                                <NavItem key={group.id} open={open}>
                                    {showEditGroupUi && (
                                        <AddEditGroup
                                            open={open}
                                            groupToEdit={group}
                                            completed={async newName => {
                                                if (await renameEditGroup(newName)) {
                                                    clearEditGroup()
                                                    return true
                                                }
                                                return false
                                            }}
                                            cancelled={clearEditGroup}
                                            allBlue={allBlue}
                                        />
                                    )}
                                    {showStandardUi && (
                                        <NavButton
                                            sx={{
                                                ...(needsPersistentVisibility(group)
                                                    ? {
                                                          '.MuiSvgIcon-root': {
                                                              display: 'inline-block'
                                                          }
                                                      }
                                                    : {}),
                                                '&:hover': {
                                                    '& .MuiSvgIcon-root': {
                                                        'display': 'inline-block',
                                                        '&.sub-menu-icon':
                                                            settings?.editingMenu
                                                                ? {
                                                                      display: 'none'
                                                                  }
                                                                : {}
                                                    }
                                                },
                                                'display': 'flex',
                                                ...(!open
                                                    ? {
                                                          alignItems: 'center',
                                                          flexDirection: 'column'
                                                      }
                                                    : {}),
                                                'justifyContent': 'space-between'
                                                // '& > *': {
                                                //     'border': '1px solid red',
                                                //     '& > *': {
                                                //         'border': '1px solid green',
                                                //         '& *': {
                                                //             'border': '1px solid magenta',
                                                //             '& > *': {
                                                //                 border: '1px solid orange'
                                                //             }
                                                //         }
                                                //     }
                                                // }
                                            }}
                                            onClick={() =>
                                                navBarItemClickedHandler(
                                                    `_menu_item_group_${group.id}`,
                                                    group.name
                                                )
                                            }
                                            title={!open ? group.name : ''}
                                        >
                                            <Stack
                                                direction='row'
                                                justifyContent='space-between'
                                                alignItems='center'
                                                sx={{
                                                    'flexGrow': 1,
                                                    'overflow': 'hidden',
                                                    '&:hover': {
                                                        // flexGrow: 0
                                                    }
                                                }}
                                            >
                                                <Stack
                                                    direction='row'
                                                    justifyContent='flex-start'
                                                    alignItems='center'
                                                    sx={{
                                                        flexGrow: 0,
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    {!emojiSplit && (
                                                        <>
                                                            <GroupIcon
                                                                style={{
                                                                    color: allBlue
                                                                        ? white
                                                                        : altColor
                                                                }}
                                                            />
                                                            <NavItemText
                                                                open={open}
                                                                altColor={
                                                                    allBlue
                                                                        ? white
                                                                        : altColor
                                                                }
                                                                title={folderName}
                                                            >
                                                                {folderName}
                                                            </NavItemText>
                                                        </>
                                                    )}
                                                    {!!emojiSplit && (
                                                        <>
                                                            <span
                                                                style={{
                                                                    fontSize: 22,
                                                                    margin: '-3px'
                                                                }}
                                                            >
                                                                {emojiSplit[0]}
                                                            </span>
                                                            <NavItemText
                                                                open={open}
                                                                altColor={
                                                                    allBlue
                                                                        ? white
                                                                        : altColor
                                                                }
                                                                title={folderName}
                                                            >
                                                                {folderName}
                                                            </NavItemText>
                                                        </>
                                                    )}
                                                </Stack>

                                                {open && (
                                                    <SubMenuIcon
                                                        className='sub-menu-icon'
                                                        style={{
                                                            color:
                                                                navInfo.navType === 'top'
                                                                    ? white
                                                                    : allBlue
                                                                    ? white
                                                                    : altColor,
                                                            width: iconSize,
                                                            height: iconSize
                                                        }}
                                                    />
                                                )}
                                            </Stack>
                                            <Stack
                                                direction='row'
                                                sx={{ ml: 1, flexGrow: 0 }}
                                                alignItems='center'
                                            >
                                                {settings?.editingMenu &&
                                                    open &&
                                                    userMayEditPermissions &&
                                                    groupHasPermissions && (
                                                        <NavButton
                                                            sx={{
                                                                justifyContent: 'end',
                                                                alignItems: 'flex-end',
                                                                flexGrow: 0
                                                            }}
                                                            title='Edit permissions for this Folder'
                                                            onClick={e => {
                                                                e.stopPropagation()

                                                                rerenderHack()

                                                                navigate('/permissions', {
                                                                    state: {
                                                                        permissionsGroupId:
                                                                            group.id
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <NavPermissionsIcon
                                                                allBlue={allBlue}
                                                                altColor={altColor}
                                                            />
                                                        </NavButton>
                                                        // </UnstyledRouterLink>
                                                    )}
                                                {settings?.editingMenu &&
                                                    open &&
                                                    group.mayRename &&
                                                    userMayEditGroup && (
                                                        <NavButton
                                                            sx={{
                                                                justifyContent: 'end',
                                                                alignItems: 'flex-end',
                                                                flexGrow: 0
                                                            }}
                                                            title='Rename this Folder'
                                                            onClick={e => {
                                                                e.stopPropagation()

                                                                setEditGroup(group)

                                                                rerenderHack()
                                                            }}
                                                        >
                                                            <NavEditIcon
                                                                allBlue={allBlue}
                                                                altColor={altColor}
                                                            />
                                                        </NavButton>
                                                    )}
                                                {settings?.editingMenu &&
                                                    open &&
                                                    group.mayDelete &&
                                                    userMayEditGroup && (
                                                        <NavDeleteButton
                                                            allBlue={allBlue}
                                                            altColor={altColor}
                                                            onStatusChanged={status => {
                                                                group.withinDeleteCycle =
                                                                    status !== 'unclicked'
                                                                rerenderHack()
                                                            }}
                                                            onClick={e => {
                                                                e.stopPropagation()

                                                                removeGroup(groupId!)
                                                            }}
                                                        />
                                                    )}
                                            </Stack>
                                        </NavButton>
                                    )}
                                </NavItem>
                            )
                        })}
                    </StyledScrollbars>
                </NavGroup>
            </Box>
            {open && settings?.editingMenu && !editGroup && userMayEditGroups && (
                <>
                    {open && <Divider />}
                    <NavGroup sx={{ flexDirection: 'row', flexGrow: 0 }}>
                        <NavItem
                            key='addLink'
                            open={open}
                            sx={{ display: 'flex' }}
                            className='no-hover'
                        >
                            <AddEditGroup
                                open={open}
                                completed={async (name: string) => {
                                    if (await addGroup(name)) {
                                        return true
                                    }
                                    return false
                                }}
                                cancelled={() => {}}
                                allBlue={allBlue}
                            />
                        </NavItem>
                    </NavGroup>
                </>
            )}
        </ListItem>
    )
}
