/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { alpha, Box, Divider, ListItem, Stack } from '@mui/material'
import { Store, useStore } from 'appData/store'
import { systemGroupNames } from 'appData/store/systemGroups'
import { AddEditLink } from 'components/navbar/AddEditLink'
import { NavCopyIcon } from 'components/navbar/Content/Styled/NavCopyIcon'
import { NavEditIcon } from 'components/navbar/Content/Styled/NavEditIcon'
import {
    NavButton,
    NavGroup,
    NavItem,
    NavItemSubText,
    NavItemText
} from 'components/navbar/Content/Styled/StyledNavComponents'
import { NavDeleteButton } from 'components/navbar/NavDeleteButton'
import { StyledScrollbars } from 'components/navbar/StyledScrollbars'
import { altColor, NavBarContentProps, white } from 'components/navbar/types'
import { useEffect, useState } from 'react'
import { LinkViewModel, toLinkViewModel } from 'service/model/LinkModel'
import { getEmojiPrefixAndRemainder, getIconSize } from 'service/utils'
import shallow from 'zustand/shallow'

const applicationsStoreSelector = (store: Store) => ({
    addLinksToCurrentGroup: store.addLinksToCurrentGroup,
    removeLinkFromCurrentGroup: store.removeLinkFromCurrentGroup,
    toggleLinkToClipboard: store.toggleLinkToClipboard,
    settings: store.settings,
    setCurrentGroup: store.setCurrentGroup,
    currentGroup: store.currentGroup,
    linkClipboard: store.linkClipboard,
    editLink: store.editLink,
    setEditLink: store.setEditLink,
    updateEditLink: store.updateEditLink,
    clearEditLink: store.clearEditLink
})

const needsPersistentVisibility = ({ onClipboard, withinDeleteCycle }: LinkViewModel) =>
    onClipboard || withinDeleteCycle

export const ApplicationsContent = ({ open, allBlue, navInfo }: NavBarContentProps) => {
    const {
        addLinksToCurrentGroup,
        removeLinkFromCurrentGroup,
        toggleLinkToClipboard,
        settings,
        setCurrentGroup,
        currentGroup,
        linkClipboard,
        editLink,
        setEditLink,
        updateEditLink,
        clearEditLink
    } = useStore(applicationsStoreSelector, shallow)

    useEffect(() => {
        if (!navInfo.groupId) {
            throw new Error(
                `navInfo.groupId should have value but is '${navInfo.groupId}'`
            )
        }
        setCurrentGroup(navInfo.groupId)
    }, [navInfo, setCurrentGroup])

    const [_rerenderHackCounter, setRerenderHackCounter] = useState(0)
    const rerenderHack = () => setRerenderHackCounter(current => current + 1)

    const [links, setLinks] = useState<LinkViewModel[]>(
        currentGroup?.links
            .filter(l => l.userPermission !== 'none')
            .map(link => toLinkViewModel(link)) || []
    )

    useEffect(() => {
        setLinks(
            currentGroup?.links
                .filter(l => l.userPermission !== 'none')
                .map(link =>
                    toLinkViewModel(
                        link,
                        ({ url }) =>
                            currentGroup?.id === linkClipboard.sourceGroupId &&
                            !!linkClipboard?.linkLookup[url]
                    )
                ) || []
        )
    }, [currentGroup, linkClipboard?.linkLookup, linkClipboard.sourceGroupId])

    const userMayEditGroup = currentGroup?.userPermission === 'edit'

    const borderColor = allBlue ? '#ffffff' : altColor
    const backColor = allBlue ? altColor : '#ffffff'
    const chromeColor = alpha(borderColor, 0.256)

    const iconSize = getIconSize(open)

    const whiteCircles = [
        systemGroupNames.__navbarLinks,
        systemGroupNames.__userLinks
    ].includes(currentGroup?.name || '')

    console.log('LINKS => ', links)

    return (
        <ListItem
            sx={{
                padding: 0,
                flexDirection: 'column',
                alignItems: 'stretch',
                flex: '1 1',
                display: 'flex'
            }}
            className='no-hover'
        >
            <Box
                sx={{
                    position: 'relative',
                    flexGrow: 1
                }}
            >
                <NavGroup
                    sx={{
                        display: 'flex',
                        overflowX: 'hidden',
                        overflowY: open ? 'auto' : 'hidden',
                        flexGrow: 1,
                        width: '100%',
                        height: '100%',
                        position: 'absolute'
                    }}
                    className='no-hover'
                >
                    <StyledScrollbars open={open} allBlue={allBlue}>
                        {links?.map(link => {
                            const { url, favicon24Url, favicon32Url, name, description } =
                                link
                            const userMayEditLink = link?.userPermission === 'edit'
                            const showEditLinkUi =
                                !!editLink && editLink.url === url && userMayEditLink
                            const showStandardUi = !editLink || editLink.url !== url
                            const emojiSplit = getEmojiPrefixAndRemainder(name)

                            return (
                                <NavItem
                                    key={url}
                                    open={open}
                                    sx={{
                                        'mb': 1.5,
                                        'border': `1px solid ${backColor}`,
                                        '&:hover': {
                                            border: `1px solid ${chromeColor}`
                                        }
                                    }}
                                >
                                    {showEditLinkUi && (
                                        <AddEditLink
                                            open={open}
                                            linkToEdit={link}
                                            completed={async (
                                                newName,
                                                newUrl,
                                                newDescription
                                            ) => {
                                                if (
                                                    await updateEditLink(
                                                        newName,
                                                        newUrl,
                                                        newDescription
                                                    )
                                                ) {
                                                    clearEditLink()
                                                    return true
                                                }
                                                return false
                                            }}
                                            cancelled={clearEditLink}
                                            allBlue={allBlue}
                                        />
                                    )}
                                    {showStandardUi && (
                                        <NavButton
                                            sx={{
                                                ...(needsPersistentVisibility(link)
                                                    ? {
                                                          '.MuiSvgIcon-root': {
                                                              display: 'inline-block'
                                                          }
                                                      }
                                                    : {}),
                                                '&:hover': {
                                                    '& .MuiSvgIcon-root': {
                                                        display: 'inline-block'
                                                    }
                                                },
                                                'display': 'flex',
                                                ...(!open
                                                    ? {
                                                          alignItems: 'center',
                                                          flexDirection: 'column'
                                                      }
                                                    : {}),
                                                'justifyContent': 'space-between'
                                                // '& > *': {
                                                //     'border': '1px solid red',
                                                //     '& > *': {
                                                //         'border': '1px solid green',
                                                //         '& *': {
                                                //             'border': '1px solid magenta',
                                                //             '& > *': {
                                                //                 border: '1px solid orange'
                                                //             }
                                                //         }
                                                //     }
                                                // }
                                            }}
                                            onClick={() => window.open(url)}
                                            title={!open ? name : ''}
                                        >
                                            <Stack
                                                direction='column'
                                                sx={{ flexGrow: 0, overflow: 'hidden' }}
                                            >
                                                <Stack
                                                    direction='row'
                                                    alignItems='center'
                                                    sx={{ flexGrow: 0 }}
                                                >
                                                    {!emojiSplit && (
                                                        <>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    ...(whiteCircles
                                                                        ? {
                                                                              'backgroundColor':
                                                                                  'white',
                                                                              'borderRadius':
                                                                                  '24px',
                                                                              'border':
                                                                                  '5px solid white',
                                                                              'width':
                                                                                  '34px',
                                                                              'height':
                                                                                  '34px',
                                                                              '&:hover':
                                                                                  !open
                                                                                      ? {
                                                                                            backgroundColor:
                                                                                                '#ffffff'
                                                                                        }
                                                                                      : {}
                                                                          }
                                                                        : {})
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        open
                                                                            ? favicon24Url
                                                                            : favicon32Url // favicon32Url <-- TODO: recently, this is empty in the DB for some reason
                                                                    }
                                                                    width={iconSize}
                                                                    height={iconSize}
                                                                    alt='favicon'
                                                                    style={{ flex: 0 }}
                                                                />
                                                            </Box>
                                                            <NavItemText
                                                                open={open}
                                                                altColor={
                                                                    allBlue
                                                                        ? white
                                                                        : altColor
                                                                }
                                                            >
                                                                {name}
                                                            </NavItemText>
                                                        </>
                                                    )}
                                                    {!!emojiSplit && (
                                                        <>
                                                            <span
                                                                style={{
                                                                    fontSize: 22,
                                                                    margin: '-3px'
                                                                }}
                                                            >
                                                                {emojiSplit[0]}
                                                            </span>
                                                            <NavItemText
                                                                open={open}
                                                                altColor={
                                                                    allBlue
                                                                        ? white
                                                                        : altColor
                                                                }
                                                            >
                                                                {emojiSplit[1]}
                                                            </NavItemText>
                                                        </>
                                                    )}
                                                </Stack>
                                                <NavItemSubText
                                                    open={open}
                                                    altColor={allBlue ? white : altColor}
                                                    sx={{
                                                        mb: 0,
                                                        textAlign: 'left',
                                                        pl: 0
                                                    }}
                                                    lineClamp={(() => {
                                                        const calc = Math.min(
                                                            Math.ceil(
                                                                description.length / 46
                                                            ),
                                                            3
                                                        )
                                                        return calc
                                                    })()}
                                                >
                                                    {description}
                                                </NavItemSubText>
                                            </Stack>
                                            <Stack
                                                direction='column'
                                                sx={{ flexGrow: 0 }}
                                            >
                                                <Stack
                                                    direction='row'
                                                    sx={{ ml: 1, flexGrow: 0 }}
                                                >
                                                    {settings?.editingMenu && open && (
                                                        // userMayEditLink && // always allow copy
                                                        <NavButton
                                                            sx={{
                                                                justifyContent: 'end',
                                                                alignItems: 'flex-end',
                                                                flexGrow: 0
                                                            }}
                                                            title={
                                                                link.onClipboard
                                                                    ? 'unmark for copying'
                                                                    : 'mark for copying'
                                                            }
                                                            onClick={e => {
                                                                e.stopPropagation()

                                                                const onClipboard =
                                                                    toggleLinkToClipboard(
                                                                        link
                                                                    )

                                                                link.onClipboard =
                                                                    onClipboard

                                                                rerenderHack()
                                                            }}
                                                        >
                                                            <NavCopyIcon
                                                                allBlue={allBlue}
                                                                altColor={altColor}
                                                                selected={
                                                                    link.onClipboard
                                                                }
                                                            />
                                                        </NavButton>
                                                    )}
                                                    {settings?.editingMenu &&
                                                        open &&
                                                        userMayEditLink &&
                                                        !link.onClipboard && (
                                                            <NavDeleteButton
                                                                allBlue={allBlue}
                                                                altColor={altColor}
                                                                onStatusChanged={status => {
                                                                    link.withinDeleteCycle =
                                                                        status !==
                                                                        'unclicked'
                                                                    rerenderHack()
                                                                }}
                                                                onClick={e => {
                                                                    e.stopPropagation()

                                                                    removeLinkFromCurrentGroup(
                                                                        url
                                                                    )
                                                                }}
                                                            />
                                                        )}
                                                </Stack>
                                                <Stack
                                                    direction='row'
                                                    sx={{ ml: 1, flexGrow: 0 }}
                                                >
                                                    {settings?.editingMenu &&
                                                        open &&
                                                        userMayEditLink && (
                                                            <NavButton
                                                                sx={{
                                                                    justifyContent: 'end',
                                                                    alignItems:
                                                                        'flex-end',
                                                                    flexGrow: 0
                                                                }}
                                                                title='Edit this link'
                                                                onClick={e => {
                                                                    e.stopPropagation()

                                                                    setEditLink(link)

                                                                    rerenderHack()
                                                                }}
                                                            >
                                                                <NavEditIcon
                                                                    allBlue={allBlue}
                                                                    altColor={altColor}
                                                                />
                                                            </NavButton>
                                                        )}
                                                </Stack>
                                            </Stack>
                                        </NavButton>
                                    )}
                                </NavItem>
                            )
                        })}
                    </StyledScrollbars>
                </NavGroup>
            </Box>
            {open && settings?.editingMenu && !editLink && userMayEditGroup && (
                <>
                    {open && <Divider />}
                    <NavGroup sx={{ flexDirection: 'row', flexGrow: 0 }}>
                        <NavItem
                            key='addLink'
                            open={open}
                            sx={{ display: 'flex' }}
                            className='no-hover'
                        >
                            <AddEditLink
                                open={open}
                                completed={async (
                                    name: string,
                                    url: string,
                                    description: string
                                ) => {
                                    if (
                                        await addLinksToCurrentGroup([
                                            {
                                                name,
                                                url,
                                                description
                                            }
                                        ])
                                    ) {
                                        return true
                                    }
                                    return false
                                }}
                                cancelled={() => {}}
                                allBlue={allBlue}
                            />
                        </NavItem>
                    </NavGroup>
                </>
            )}
        </ListItem>
    )
}
