import EditIcon from '@mui/icons-material/Edit'
import { alpha, styled } from '@mui/material'

interface NavEditIconProps {
    altColor: string
    allBlue: boolean
    selected?: boolean
}
export const NavEditIcon = styled(EditIcon, {
    shouldForwardProp: prop => {
        const include = !['altColor', 'allBlue'].includes(prop.toString())
        return include
    }
})<NavEditIconProps>(({ theme, altColor, allBlue, selected }) => ({
    'width': 24,
    'height': 24,
    'transform': 'scale(0.9)',
    'color': alpha(allBlue ? '#ffffff' : altColor, selected ? 1 : 0.256),
    'transition': `${theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short
    })}`,
    'display': 'none',
    '&:hover': {
        color: allBlue ? '#ffffff' : altColor,
        transform: 'scale(1.05)'
    }
}))
