import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Store, useStore } from 'appData/store'
import { FloatingLinks } from 'components/floatingLinks/FloatingLinks'
import Header from 'components/header/Header'
import Loader from 'components/loader/Loader'
import NavBar from 'components/navbar/Navbar'
import useUserWayStyling from 'hooks/useUserWayStyling'
import { NavigateToPermissionsPageButton } from 'pages/Permissions/PermissionsPage/NavigateToPermissionsPageButton'
import React, { FC, useState } from 'react'
import shallow from 'zustand/shallow'

type AppLayoutProps = {
    children: React.ReactNode
    headerHeight: number
}

const Main = styled('main')<{
    open?: boolean
}>(({ open }) => {
    return {
        flexGrow: 1,
        marginLeft: open ? 308 : 58,
        marginTop: 83,
        // border: '1px solid red',
        overflowY: 'auto',
        transition: 'margin 0.2s ease-in'
    }
})

const storeSelector = (store: Store) => ({
    currentUser: store.currentUser,
    loading: store.loading,
    userHasPermission: store.userHasPermission
})

const AppLayout: FC<AppLayoutProps> = ({ children, headerHeight }) => {
    const {
        currentUser: zCurrentUser,
        loading,
        userHasPermission
    } = useStore(storeSelector, shallow)
    const [navBarOpen, setNavBarOpen] = useState(false)

    /* const userHasPerAcademyPermissions =
        zCurrentUser?.userPerAcademyPermissions[keyUserPerAcademyPermissions] &&
        zCurrentUser.userPerAcademyPermissions[keyUserPerAcademyPermissions].length > 0 */

    const userMayEditPermissions = userHasPermission('edit permissions')
    useUserWayStyling()

    return (
        <Stack sx={{ height: '100%' }} direction='column'>
            <Header height={headerHeight} propmarginleft={60} />
            <NavBar onOpenChanged={open => setNavBarOpen(open)} />
            <Main open={navBarOpen}>
                {loading && <Loader />}
                {children}
            </Main>
            <FloatingLinks>
                {/* {!!userMayEditPermissions && !userHasPerAcademyPermissions && (
                    <NavigateToPermissionsPageButton />
                )} */}
                {!!userMayEditPermissions && <NavigateToPermissionsPageButton />}
            </FloatingLinks>

            {/* <Container
                maxWidth={false}
                disableGutters
                sx={{
                    mt: '100px',
                    mb: 0.1,
                    marginLeft: '50px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                
            </Container> */}
        </Stack>
    )
}

export default AppLayout
