/* eslint-disable no-console */
import { systemGroupNames } from 'appData/store/systemGroups'
import { PartialRecord } from 'components/navbar/types'
import { PermXCoordinate } from 'pages/Permissions/PermissionsPage'
import { AcademyLookupItem, _getAcademiesLookup } from 'service/controller/Academy'
import { _getJobFamilies } from 'service/controller/JobFamilies'

import {
    _getKnownEmails,
    _updateKnownEmails,
    knownEmailSorter
} from 'service/controller/KnownEmail'
import {
    _addLinksForGroup,
    _addLinksToUserLinksFolder,
    _bulkUpdateGroupPermissions,
    _createAndReturnGroup,
    _getGroups,
    _getUserLinksFolder,
    _removeGroup,
    _removeLinkFromGroup,
    _removeLinkFromUserLinksFolder,
    _updateGroupName,
    _updateLinkForGroup,
    _updateLinkForUserLinksFolder
} from 'service/controller/Menu'
import {
    _getPerAcademyPermissions,
    _updatePerAcademyPermissions
} from 'service/controller/PerAcademyPermissions'
import { _getPermissions, _updatePermissions } from 'service/controller/Permission'
import {
    _getUserSummary,
    _userHasPermission,
    annotateGroupWithUserPermission,
    annotateGroupsWithUserPermission
} from 'service/controller/User'
import { academyLookupSorter } from 'service/model/AcademyModel'
import {
    AcademyGroupPermission,
    GroupModel,
    JobFamilyGroupPermission,
    KnownEmailGroupPermission,
    RegionGroupPermission,
    groupSorter,
    requiredAcademyGroupPermissionSorter,
    requiredJobFamiliesGroupPermissionSorter,
    requiredKnownEmailsGroupPermissionSorter,
    requiredRegionGroupPermissionSorter
} from 'service/model/GroupModel'
import { LinkModel } from 'service/model/LinkModel'
import { WidgetOptions } from 'service/model/Post'
import {
    JobFamiliesSingletonModel,
    PerAcademyPermission,
    PerAcademyPermissionSingletonModel,
    Permission,
    PermissionsSingletonModel,
    SingletonsModel
} from 'service/model/SingletonsModel'
import { UserModel, keyUserPerAcademyPermissions } from 'service/model/User'
import {
    getFavIconUrlForSite,
    isDeepEqual,
    json,
    log,
    makeLookup,
    nameOrEmailPrefix
} from 'service/utils'
import getGoogleGroups from 'utils/getGoogleGroups'
import create from 'zustand'
import { persist } from 'zustand/middleware'
// eslint-disable-next-line @typescript-eslint/ban-types

type UserInfo = {
    name: {
        firstName: string
        surName: string
        displayName: string
    }
    photoUri: string
    email: string
}

type Settings = {
    singleColorNav?: boolean
    editingMenu?: boolean
}

const _getGoogleGroups = async (userKey: string) => {
    return getGoogleGroups(userKey)
}

export type PermissionsPageFilters = {
    text: Record<keyof PermXCoordinate, string>
    visibility: Record<keyof PermXCoordinate, boolean>
}

export type CurrentUser = {
    user: UserModel
    userKnownEmailsSet: Set<string>
    userJobFamiliesSet: Set<string>
    userAcademySet: Set<string>
    userRegionSet: Set<string>
    userAcademyPhasesSet: Set<string>
    userPerAcademyPermissions: PartialRecord<PerAcademyPermission, string[]>
    accessToken?: string
}
export interface IGoogleGroup {
    name: string
    id: string
    email: string
}
export interface IMyFocusFilter {
    region: string
    academics: AcademyLookupItem[]
    jobFamilies: string[]
    googleGroups: string[]
}

export type Store = {
    filter:
        | WidgetOptions
        | 'relevant'
        | 'read'
        | 'like'
        | 'allPopupAnnouncement'
        | 'expired'
        | undefined
        | null
    setFilter: (
        filter:
            | WidgetOptions
            | 'relevant'
            | 'read'
            | 'like'
            | 'allPopupAnnouncement'
            | 'expired'
            | undefined
            | null
    ) => void
    myFocusFilters: IMyFocusFilter
    // myFocus: string
    _getPermissionSets: (filter?: IMyFocusFilter) => {
        knownEmailsSet: Set<string>
        jobFamiliesSet: Set<string>
        academyCodeSet: Set<string>
        regionSet: Set<string>
    }
    // setMyFocus: (myFocus: string) => void

    setMyFocusFilters: (filter: IMyFocusFilter) => void

    permissionsPageFilters: PermissionsPageFilters
    setPermissionsPageFilters: (newFilters: PermissionsPageFilters) => void

    _hasHydrated: boolean
    setHasHydrated: (hydrated: boolean) => void

    clearPersistentStorage: () => void

    loading: boolean
    setLoading: (loading: boolean) => void

    updatingLinks?: boolean
    setUpdatingLinks: () => void
    clearUpdatingLinks: () => void

    _userIndependentInitialiseStore: () => Promise<void[]>
    _userDependentInitialiseStore: () => Promise<void>
    _normaliseSorting: () => void
    initialiseStore: () => Promise<void>

    currentUserInfo?: UserInfo
    setCurrentUserInfo: (info: UserInfo) => void

    currentUser?: CurrentUser
    _userPermissionsCache: Record<Permission, boolean>
    _getCurrentUserPerAcademyPermissions: (
        user: CurrentUser
    ) => CurrentUser['userPerAcademyPermissions']
    _updateCurrentUserPerAcademyPermissions: () => void
    setCurrentUser: (user?: UserModel, accessToken?: string) => void
    userHasPermission: (permissionName: Permission) => boolean
    getUserLinksFolder: () => GroupModel

    settings?: Settings
    toggleSingleColorNav: () => void
    toggleEditingMenu: () => void

    regions: string[]
    academies: AcademyLookupItem[]
    academyDomainLookup: Record<string, AcademyLookupItem>
    academyCodeLookup: Record<string, AcademyLookupItem>
    loadAcademiesLookup: () => Promise<void>
    knownEmails: string[]
    loadKnownEmails: () => Promise<void>
    updateKnownEmails: (emailsToRemove: string[], emailsToAdd: string[]) => Promise<void>
    getKnownEmailPermTargets: (emails: string[]) => Record<
        string,
        {
            groups: Set<string>
            groupLinks: Set<{
                groupId: string
                linkUrl: string
            }>
            permissions: Set<Permission>
        }
    >

    userSummary: NonNullable<Awaited<ReturnType<typeof _getUserSummary>>>
    loadUserSummary: () => Promise<void>

    permissions: PermissionsSingletonModel['permissions']
    permissionsLookup: Record<Permission, PermissionsSingletonModel['permissions'][0]>
    updatePerms: (
        updates: {
            name: Permission
            requiredKnownEmails: string[]
            requiredJobFamilies: string[]
            requiredAcademies: string[]
            requiredRegions: string[]
        }[]
    ) => Promise<boolean>
    loadPermissions: () => Promise<void>

    perAcademyPermissions: PerAcademyPermissionSingletonModel['perAcademyPermissions']
    perAcademyPermissionsLookup: Record<
        string,
        Record<
            string,
            {
                academyCode: string
                requiredKnownEmails?: string[] | undefined
                requiredJobFamilies?: string[] | undefined
                requiredAcademies?: string[] | undefined
                requiredRegions?: string[] | undefined
            }
        >
    >
    updatePerAcademyPermissions: (
        updates: {
            name: PerAcademyPermission
            academyPermissions: {
                academyCode: string
                requiredKnownEmails: string[]
                requiredJobFamilies: string[]
                requiredAcademies: string[]
                requiredRegions: string[]
            }[]
        }[]
    ) => Promise<boolean>
    loadPerAcademyPermissions: () => Promise<void>
    googleGroups: IGoogleGroup[]
    jobFamilies: JobFamiliesSingletonModel['jobFamilies']
    jobFamiliesSet: Set<JobFamiliesSingletonModel['jobFamilies'][0]>
    // longestJobFamily: string
    loadJobFamilies: () => Promise<void>
    loadGoogleGroups: () => Promise<void>

    groups: GroupModel[]
    currentGroup?: GroupModel
    myLinksGroup?: GroupModel
    findMyLinksGroup: () => void

    editGroup: GroupModel | null
    setEditGroup: (group: GroupModel) => void
    clearEditGroup: () => void

    addGroup: (groupName: string) => Promise<boolean>
    renameEditGroup: (name: string) => Promise<boolean>
    bulkUpdateGroupPermissions: (
        updates: {
            id: string
            requiredKnownEmails: KnownEmailGroupPermission[]
            requiredJobFamilies: JobFamilyGroupPermission[]
            requiredAcademies: AcademyGroupPermission[]
            requiredRegions: RegionGroupPermission[]
            linkPerms: {
                requiredKnownEmails: KnownEmailGroupPermission[]
                requiredJobFamilies: JobFamilyGroupPermission[]
                requiredAcademies: AcademyGroupPermission[]
                requiredRegions: RegionGroupPermission[]
            }[]
        }[]
    ) => Promise<boolean>
    removeGroup: (groupId: string) => Promise<boolean>
    loadGroups: (forceRefresh?: boolean) => Promise<void>
    setCurrentGroup: (groupId: string) => void

    editLink: LinkModel | null
    setEditLink: (link: LinkModel) => void
    clearEditLink: () => void
    updateEditLink: (name: string, url: string, description: string) => Promise<boolean>

    linkClipboard: { sourceGroupId: string; linkLookup: Record<string, LinkModel> }
    _copyLink: (link: LinkModel) => boolean
    _uncopyLink: (link: LinkModel) => boolean
    toggleLinkToClipboard: (link: LinkModel) => boolean
    pasteLinksToCurrentGroup: () => void

    addLinksToCurrentGroup: (
        links: Pick<LinkModel, 'name' | 'url' | 'description'>[]
    ) => Promise<boolean>

    removeLinkFromCurrentGroup: (linkUrl: string) => Promise<void>
}

const buildStore = () => {
    const useStore = create<Store>()(
        persist(
            (set, get) => ({
                _hasHydrated: false,
                setHasHydrated: (hydrated: boolean) => {
                    set({
                        _hasHydrated: hydrated
                    })
                },

                clearPersistentStorage: () => {
                    useStore.persist.clearStorage()
                },

                loading: false,
                setLoading: (loading: boolean) => {
                    set({ loading })
                },

                loadGoogleGroups: async () => {
                    const { currentUser } = get()

                    if (currentUser && currentUser.user.email) {
                        const googleGroups = await _getGoogleGroups(
                            currentUser.user.email
                        )
                        if (googleGroups) {
                            set({
                                googleGroups
                            })
                        } else {
                            set({
                                googleGroups: []
                            })
                        }
                    }
                },

                filter: 'relevant',
                setFilter: (
                    filter:
                        | WidgetOptions
                        | 'relevant'
                        | 'read'
                        | 'like'
                        | 'allPopupAnnouncement'
                        | 'expired'
                        | undefined
                        | null
                ) => {
                    set({ filter })
                },
                myFocusFilters: {
                    region: '',
                    academics: [],
                    jobFamilies: [],
                    googleGroups: []
                },
                myFocus: 'All',
                /* _getPermissionSets: (focus?: string) => {
                    const { myFocus: storeMyFocus, currentUser } = get()
                    const myFocus = focus || storeMyFocus

                    let ret = {
                        knownEmailsSet: new Set<string>(),
                        jobFamiliesSet: new Set<string>(),
                        academyCodeSet: new Set<string>(),
                        regionSet: new Set<string>()
                    }
                    if (myFocus === 'All') {
                        if (currentUser) {
                            const {
                                userKnownEmailsSet,
                                userJobFamiliesSet,
                                userAcademySet,
                                userRegionSet
                            } = currentUser
                            ret = {
                                knownEmailsSet: userKnownEmailsSet,
                                jobFamiliesSet: userJobFamiliesSet,
                                academyCodeSet: userAcademySet,
                                regionSet: userRegionSet
                            }
                        }
                    } else if (myFocus.indexOf(';') !== -1) {
                        const [coord, id] = myFocus.split(';')
                        ret = {
                            knownEmailsSet: new Set([]),
                            jobFamiliesSet: new Set(coord === 'jobfamilies' ? [id] : []),
                            academyCodeSet: new Set(coord === 'academy' ? [id] : []),
                            regionSet: new Set(coord === 'region' ? [id] : [])
                        }
                    } else {
                        throw new Error(`Unexpected myFocus value '${myFocus}'`)
                    }
                    return ret
                }, */
                _getPermissionSets: (filter?: IMyFocusFilter) => {
                    const { myFocusFilters: storeMyFocusFilters, currentUser } = get()
                    const myFocusFilter = filter || storeMyFocusFilters

                    let ret = {
                        knownEmailsSet: new Set<string>(),
                        jobFamiliesSet: new Set<string>(),
                        academyCodeSet: new Set<string>(),
                        regionSet: new Set<string>()
                    }
                    if (currentUser) {
                        const {
                            userKnownEmailsSet,
                            userJobFamiliesSet,
                            userAcademySet,
                            userRegionSet
                        } = currentUser
                        ret = {
                            knownEmailsSet: userKnownEmailsSet,
                            jobFamiliesSet: userJobFamiliesSet,
                            academyCodeSet: userAcademySet,
                            regionSet: userRegionSet
                        }
                    }
                    /* const hasFilter =
                        myFocusFilter.academics.length > 0 ||
                        myFocusFilter.jobFamilies.length > 0 ||
                        myFocusFilter.region !== ''
                    if (!hasFilter) {
                        if (currentUser) {
                            const {
                                userKnownEmailsSet,
                                userJobFamiliesSet,
                                userAcademySet,
                                userRegionSet
                            } = currentUser
                            ret = {
                                knownEmailsSet: userKnownEmailsSet,
                                jobFamiliesSet: userJobFamiliesSet,
                                academyCodeSet: userAcademySet,
                                regionSet: userRegionSet
                            }
                        }
                    } else {
                        // const [coord, id] = myFocus.split(';')
                        ret = {
                            knownEmailsSet: new Set(
                                currentUser?.userJobFamiliesSet
                                    ? currentUser?.userJobFamiliesSet
                                    : []
                            ),
                            jobFamiliesSet: new Set(myFocusFilter.jobFamilies),
                            academyCodeSet: new Set(
                                myFocusFilter.academics.length > 0
                                    ? myFocusFilter.academics.map(
                                          academic => academic.academyCode
                                      )
                                    : []
                            ),
                            regionSet: new Set(
                                myFocusFilter.region !== '' ? [myFocusFilter.region] : []
                            )
                        }
                    } */
                    // } else {
                    //     throw new Error(`Unexpected myFocus value '${myFocus}'`)
                    // }
                    return ret
                },
                setMyFocusFilters: (filter: IMyFocusFilter) => {
                    const {
                        groups,
                        _getPermissionSets,
                        currentGroup,
                        setCurrentGroup,
                        findMyLinksGroup
                    } = get()
                    const updatedGroups = annotateGroupsWithUserPermission(
                        groups,
                        _getPermissionSets(filter)
                    )
                    // console.log('updatedGroups => ', updatedGroups)

                    set({ myFocusFilters: filter, groups: updatedGroups })
                    if (currentGroup?.id) {
                        setCurrentGroup(currentGroup.id)
                        if (get().currentGroup?.userPermission === 'none') {
                            set({ currentGroup: undefined })
                        }
                    }
                    findMyLinksGroup()
                },
                // setMyFocus: (myFocus: string) => {
                //     const {
                //         groups,
                //         _getPermissionSets,
                //         currentGroup,
                //         setCurrentGroup,
                //         findMyLinksGroup
                //     } = get()

                //     const updatedGroups = annotateGroupsWithUserPermission(
                //         groups,
                //         _getPermissionSets(myFocus)
                //     )

                //     set({ myFocus, groups: updatedGroups })
                //     if (currentGroup?.id) {
                //         setCurrentGroup(currentGroup.id)
                //         if (get().currentGroup?.userPermission === 'none') {
                //             set({ currentGroup: undefined })
                //         }
                //     }
                //     findMyLinksGroup()
                // },

                permissionsPageFilters: {
                    text: {
                        knownEmail: '',
                        jobFamily: '',
                        academy: '',
                        region: ''
                    },
                    visibility: {
                        knownEmail: true,
                        jobFamily: true,
                        academy: true,
                        region: true
                    }
                },
                setPermissionsPageFilters: (newFilters: PermissionsPageFilters) => {
                    set({ permissionsPageFilters: newFilters })
                },
                updatingLinks: false,
                setUpdatingLinks: () => set({ updatingLinks: true }),
                clearUpdatingLinks: () => set({ updatingLinks: false }),

                initialiseStore: async () => {
                    const {
                        _userIndependentInitialiseStore,
                        _userDependentInitialiseStore,
                        _normaliseSorting
                    } = get()

                    await Promise.all([
                        _userIndependentInitialiseStore(),
                        _userDependentInitialiseStore()
                    ])
                    _normaliseSorting()
                },

                _userIndependentInitialiseStore: async () => {
                    const {
                        loadJobFamilies,
                        loadAcademiesLookup,
                        loadPermissions,
                        loadPerAcademyPermissions,
                        loadUserSummary,
                        loadKnownEmails,
                        loadGoogleGroups
                    } = get()

                    const promises = Promise.all([
                        loadJobFamilies(),
                        loadAcademiesLookup(),
                        loadPermissions(),
                        loadPerAcademyPermissions(),
                        loadUserSummary(),
                        loadKnownEmails(),
                        loadGoogleGroups()
                    ])

                    return promises
                },
                _userDependentInitialiseStore: async () => {
                    const { loadGroups } = get()

                    await loadGroups()
                    set({ _userPermissionsCache: {} as Record<Permission, boolean> })
                },
                _normaliseSorting: () => {
                    const { groups, permissions } = get()

                    const checkGroupPerms = ({
                        successMessage,
                        failureMessage,
                        requiredKnownEmails,
                        requiredJobFamilies,
                        requiredAcademies,
                        requiredRegions
                    }: {
                        successMessage?: string
                        failureMessage: string
                        requiredKnownEmails?: KnownEmailGroupPermission[]
                        requiredJobFamilies?: JobFamilyGroupPermission[]
                        requiredAcademies?: AcademyGroupPermission[]
                        requiredRegions?: RegionGroupPermission[]
                    }) => {
                        const sortedRequiredKnownEmails = [
                            ...(requiredKnownEmails || [])
                        ].sort(requiredKnownEmailsGroupPermissionSorter)
                        const sortedRequiredJobFamilies = [
                            ...(requiredJobFamilies || [])
                        ].sort(requiredJobFamiliesGroupPermissionSorter)
                        const sortedRequiredAcademies = [
                            ...(requiredAcademies || [])
                        ].sort(requiredAcademyGroupPermissionSorter)
                        const sortedRequiredRegions = [...(requiredRegions || [])].sort(
                            requiredRegionGroupPermissionSorter
                        )
                        const before = [
                            requiredKnownEmails || [],
                            requiredJobFamilies || [],
                            requiredAcademies || [],
                            requiredRegions || []
                        ]
                        const after = [
                            sortedRequiredKnownEmails,
                            sortedRequiredJobFamilies,
                            sortedRequiredAcademies,
                            sortedRequiredRegions
                        ]
                        const comparisons = before.map((_, i) =>
                            isDeepEqual(before[i], after[i])
                        )
                        const isEqual = comparisons.every(c => c)
                        if (!isEqual) {
                            log(`${failureMessage} - (${json(comparisons)}`)
                        } else if (successMessage) {
                            log(successMessage)
                        }
                    }

                    const checkPermPerms = ({
                        successMessage,
                        failureMessage,
                        requiredKnownEmails,
                        requiredJobFamilies,
                        requiredAcademies,
                        requiredRegions
                    }: {
                        successMessage?: string
                        failureMessage: string
                        requiredKnownEmails?: string[]
                        requiredJobFamilies?: string[]
                        requiredAcademies?: string[]
                        requiredRegions?: string[]
                    }) => {
                        const sortedRequiredKnownEmails = [
                            ...(requiredKnownEmails || [])
                        ].sort(knownEmailSorter)
                        const sortedRequiredJobFamilies = [
                            ...(requiredJobFamilies || [])
                        ].sort()
                        const sortedRequiredAcademies = [
                            ...(requiredAcademies || [])
                        ].sort()
                        const sortedRequiredRegions = [...(requiredRegions || [])].sort()
                        const before = [
                            requiredKnownEmails || [],
                            requiredJobFamilies || [],
                            requiredAcademies || [],
                            requiredRegions || []
                        ]
                        const after = [
                            sortedRequiredKnownEmails,
                            sortedRequiredJobFamilies,
                            sortedRequiredAcademies,
                            sortedRequiredRegions
                        ]
                        const comparisons = before.map((_, i) =>
                            isDeepEqual(before[i], after[i])
                        )
                        const isEqual = comparisons.every(c => c)
                        if (!isEqual) {
                            log(`${failureMessage} - (${json(comparisons)}`)
                        } else if (successMessage) {
                            log(successMessage)
                        }
                    }

                    if (groups?.length > 0 && permissions?.length > 0) {
                        groups.forEach(g => {
                            checkGroupPerms({
                                // successMessage: `✅ checkGroupPerms group '${g.name}'`,
                                failureMessage: `❌ checkGroupPerms group '${g.name}'`,
                                ...g
                            })
                            g.links.forEach(l => {
                                checkGroupPerms({
                                    // successMessage: `✅ checkGroupPerms group '${g.name}' link '${l.name}' -> '${l.url}'`,
                                    failureMessage: `❌ checkGroupPerms group '${g.name}' link '${l.name}' -> '${l.url}'`,
                                    ...l
                                })
                            })
                        })
                        permissions.forEach(p => {
                            checkPermPerms({
                                // successMessage: `✅ checkPermPerms perm '${p.name}'`,
                                failureMessage: `❌ checkPermPerms perm '${p.name}'`,
                                ...p
                            })
                        })
                    }
                },

                currentUserInfo: undefined,
                setCurrentUserInfo: (info: UserInfo) => {
                    set({ currentUserInfo: info })
                },

                currentUser: undefined,
                _userPermissionsCache: {} as Record<Permission, boolean>,
                _getCurrentUserPerAcademyPermissions: (user: CurrentUser) => {
                    const { perAcademyPermissions } = get()

                    console.log('perAcademyPermissions => ', perAcademyPermissions)

                    if (perAcademyPermissions && user) {
                        const {
                            userKnownEmailsSet,
                            userJobFamiliesSet,
                            userAcademySet,
                            userRegionSet
                        } = user

                        const userPerAcademyPermissions = perAcademyPermissions.reduce(
                            (acc1, { name, academyPermissions }) => {
                                const academies = academyPermissions.reduce(
                                    (
                                        acc2,
                                        {
                                            academyCode,
                                            requiredKnownEmails,
                                            requiredJobFamilies,
                                            requiredAcademies,
                                            requiredRegions
                                        }
                                    ) => {
                                        if (
                                            requiredKnownEmails?.some(rke =>
                                                userKnownEmailsSet.has(rke)
                                            ) ||
                                            requiredJobFamilies?.some(rjf =>
                                                userJobFamiliesSet.has(rjf)
                                            ) ||
                                            requiredAcademies?.some(ra =>
                                                userAcademySet.has(ra)
                                            ) ||
                                            requiredRegions?.some(rr =>
                                                userRegionSet.has(rr)
                                            )
                                        ) {
                                            acc2.push(academyCode)
                                        }
                                        return acc2
                                    },
                                    [] as string[]
                                )
                                return { ...acc1, [name]: academies }
                            },
                            {} as PartialRecord<PerAcademyPermission, string[]>
                        )
                        return userPerAcademyPermissions
                    }
                    return {}
                },
                _updateCurrentUserPerAcademyPermissions: () => {
                    const {
                        perAcademyPermissions,
                        currentUser,
                        _getCurrentUserPerAcademyPermissions
                    } = get()
                    if (currentUser && perAcademyPermissions) {
                        const userPerAcademyPermissions =
                            _getCurrentUserPerAcademyPermissions(currentUser)
                        set({
                            currentUser: { ...currentUser, userPerAcademyPermissions }
                        })
                    }
                },
                setCurrentUser: (user?: UserModel, accessToken?: string) => {
                    const { _getCurrentUserPerAcademyPermissions } = get()
                    if (user && accessToken) {
                        const currentUser = {
                            user,
                            userKnownEmailsSet: new Set([
                                user?.email,
                                ...(user?.linkedEmails || [])
                            ]),
                            userJobFamiliesSet: new Set(user?.jobFamilies || []),
                            userAcademySet: new Set(user?.academyCodes || []),
                            userRegionSet: new Set(user?.regions || []),
                            userAcademyPhasesSet: new Set(user?.academyPhases || []),
                            userPerAcademyPermissions: {},
                            accessToken
                        }
                        const userPerAcademyPermissions =
                            _getCurrentUserPerAcademyPermissions({
                                ...currentUser,
                                userKnownEmailsSet: new Set([user?.email])
                            })

                        console.log(
                            'userPerAcademyPermissions => ',
                            userPerAcademyPermissions
                        )

                        // log(`🍅🍅🍅 setting currentUser to: ${json(currentUser)} `)
                        if (userPerAcademyPermissions) {
                            // Override actual academies of the user
                            const academies =
                                userPerAcademyPermissions[keyUserPerAcademyPermissions]
                            if (academies && academies?.length > 0) {
                                currentUser.userAcademySet = new Set(academies)
                                currentUser.user.academyCodes = academies
                            }
                        }
                        set({
                            currentUser: { ...currentUser, userPerAcademyPermissions }
                        })

                        get()._userDependentInitialiseStore()
                    } else {
                        // log(`🍅🍅🍅 setting currentUser to: undefined `)
                        set({ currentUser: undefined })
                    }
                },
                userHasPermission: (permissionName: Permission) => {
                    const { _userPermissionsCache, permissionsLookup, currentUser } =
                        get()

                    // console.log('_userPermissionsCache => ', _userPermissionsCache)
                    // console.log('permissionsLookup => ', permissionsLookup)
                    // console.log('currentUser => ', currentUser)

                    const perm = _userPermissionsCache[permissionName]
                    if (perm !== undefined) {
                        return perm
                    }

                    const permission = permissionsLookup[permissionName]
                    if (!permission) {
                        if (Object.keys(permissionsLookup).length > 0) {
                            throw new Error(
                                `Permission with name '${permissionName}' should exist`
                            )
                        }
                        // log(
                        //     `❌ returning a fake/temporary true for userHasPermission('${permissionName}' as the permissions have not yet loaded)`
                        // )
                        return true // temporary true
                    }
                    // log(`all ok so far, permission for '${permissionName}' was found...`)

                    if (!currentUser) {
                        return false
                    }

                    const uhp = _userHasPermission(permission, currentUser)

                    _userPermissionsCache[permissionName] = uhp

                    return uhp
                },

                getUserLinksFolder: () => {
                    const { currentUser } = get()
                    return _getUserLinksFolder(currentUser?.user)
                },

                settings: {
                    singleColorNav: true,
                    editingMenu: true
                },
                toggleSingleColorNav: () => {
                    set({
                        settings: {
                            ...get().settings,
                            singleColorNav: !get().settings?.singleColorNav
                        }
                    })
                },
                toggleEditingMenu: () => {
                    set({
                        settings: {
                            ...get().settings,
                            editingMenu: !get().settings?.editingMenu
                        }
                    })
                },

                regions: [],
                academies: [],
                academyDomainLookup: {},
                academyCodeLookup: {},
                loadAcademiesLookup: async () => {
                    const academies = (await _getAcademiesLookup())?.sort(
                        academyLookupSorter
                    )
                    if (academies) {
                        const regions = [
                            ...academies
                                .reduce(
                                    (acc, { region }) => acc.add(region),
                                    new Set<string>()
                                )
                                .values()
                        ].sort((one, two) => one.localeCompare(two))
                        const academyDomainLookup = makeLookup(
                            academies,
                            ({ emailDomain }) => emailDomain,
                            academy => academy
                        )
                        const academyCodeLookup = makeLookup(
                            academies,
                            ({ academyCode }) => academyCode,
                            academy => academy
                        )

                        set({
                            academies,
                            regions,
                            academyDomainLookup,
                            academyCodeLookup
                        })
                    }
                },

                knownEmails: [],
                loadKnownEmails: async () => {
                    const knownEmails = ((await _getKnownEmails()) || [])?.sort(
                        knownEmailSorter
                    )
                    set({ knownEmails })
                },
                updateKnownEmails: async (
                    emailsToRemove: string[],
                    emailsToAdd: string[]
                ) => {
                    const emailsToRemoveSet = new Set(emailsToRemove)
                    await _updateKnownEmails((lookupModel: SingletonsModel) => {
                        if (lookupModel.flavour === 'knownEmails') {
                            const knownEmails2 = lookupModel.knownEmails
                                .filter(ke => !emailsToRemoveSet.has(ke))
                                .concat(emailsToAdd)
                                .sort(knownEmailSorter)

                            const updatedKnownEmails = {
                                ...lookupModel,
                                knownEmails: knownEmails2
                            }
                            set({ knownEmails: knownEmails2 })
                            return updatedKnownEmails
                        }
                        return undefined
                    })
                },
                getKnownEmailPermTargets: (emails: string[]) => {
                    const emailSet = new Set(emails)
                    const targetsByEmail: Record<
                        string,
                        {
                            groups: Set<string>
                            groupLinks: Set<{ groupId: string; linkUrl: string }>
                            permissions: Set<Permission>
                        }
                    > = {}

                    const getTarget = (email: string) => {
                        if (!targetsByEmail[email]) {
                            targetsByEmail[email] = {
                                groups: new Set<string>(),
                                groupLinks: new Set<{
                                    groupId: string
                                    linkUrl: string
                                }>(),
                                permissions: new Set<Permission>()
                            }
                        }
                        return targetsByEmail[email]
                    }

                    const { groups, permissions } = get()
                    groups.forEach(({ id: groupId, requiredKnownEmails, links }) => {
                        requiredKnownEmails?.forEach(ke => {
                            if (emailSet.has(ke.email)) {
                                getTarget(ke.email).groups.add(groupId!)
                            }
                        })
                        links?.forEach(l =>
                            l.requiredKnownEmails?.forEach(lke => {
                                if (emailSet.has(lke.email)) {
                                    getTarget(lke.email).groupLinks.add({
                                        groupId: groupId!,
                                        linkUrl: l.url
                                    })
                                }
                            })
                        )
                    })
                    permissions.forEach(({ name: perm, requiredKnownEmails }) =>
                        requiredKnownEmails?.forEach(e => {
                            if (emailSet.has(e)) {
                                getTarget(e).permissions.add(perm)
                            }
                        })
                    )
                    return targetsByEmail
                },

                userSummary: [],
                // userLookup: {},
                loadUserSummary: async () => {
                    const userSummary = (await _getUserSummary())?.sort(
                        (
                            { name: name1, email: email1 },
                            { name: name2, email: email2 }
                        ) =>
                            nameOrEmailPrefix(name1, email1).localeCompare(
                                nameOrEmailPrefix(name2, email2)
                            )
                    )
                    if (userSummary) {
                        //     const userLookup = makeLookup(
                        //         userSummary,
                        //         ({ email }) => email,
                        //         u => u
                        //     )
                        //     set({ userLookup })
                        set({ userSummary })
                    }
                },

                permissions: [],
                permissionsLookup: {} as Record<
                    Permission,
                    PermissionsSingletonModel['permissions'][0]
                >,
                updatePerms: async (
                    updates: {
                        name: Permission
                        requiredKnownEmails: string[]
                        requiredJobFamilies: string[]
                        requiredAcademies: string[]
                        requiredRegions: string[]
                    }[]
                ) => {
                    const ret = await _updatePermissions(updates)
                    if (ret) {
                        get().loadPermissions()
                    }
                    return ret
                },
                loadPermissions: async () => {
                    const permissions = await _getPermissions()

                    if (permissions) {
                        const permissionsLookup = makeLookup(
                            permissions,
                            ({ name }) => name,
                            p => p
                        )

                        set({
                            permissions,
                            permissionsLookup,
                            _userPermissionsCache: {} as Record<Permission, boolean>
                        })
                    }
                },

                perAcademyPermissions: [],
                perAcademyPermissionsLookup: {} as Record<
                    string,
                    Record<
                        string,
                        {
                            academyCode: string
                            requiredKnownEmails?: string[] | undefined
                            requiredJobFamilies?: string[] | undefined
                            requiredAcademies?: string[] | undefined
                            requiredRegions?: string[] | undefined
                        }
                    >
                >,
                updatePerAcademyPermissions: async (
                    updates: {
                        name: PerAcademyPermission
                        academyPermissions: {
                            academyCode: string
                            requiredKnownEmails: string[]
                            requiredJobFamilies: string[]
                            requiredAcademies: string[]
                            requiredRegions: string[]
                        }[]
                    }[]
                ) => {
                    const status = await _updatePerAcademyPermissions(updates)
                    if (status) {
                        get().loadPerAcademyPermissions()
                    }
                    return status
                },
                loadPerAcademyPermissions: async () => {
                    const { _updateCurrentUserPerAcademyPermissions } = get()
                    const perAcademyPermissions = await _getPerAcademyPermissions()

                    if (perAcademyPermissions) {
                        const perAcademyPermissionsLookup = makeLookup(
                            perAcademyPermissions,
                            ({ name }) => name,
                            u =>
                                makeLookup(
                                    u.academyPermissions,
                                    ({ academyCode }) => academyCode,
                                    ap => ap
                                )
                        )

                        set({
                            perAcademyPermissions,
                            perAcademyPermissionsLookup,
                            _userPermissionsCache: {} as Record<Permission, boolean>
                        })
                        _updateCurrentUserPerAcademyPermissions()
                    }
                },
                googleGroups: [],
                jobFamilies: [],
                jobFamiliesSet: new Set([]),
                // longestJobFamily: '',
                loadJobFamilies: async () => {
                    const jobFamilies = await _getJobFamilies()
                    if (jobFamilies) {
                        const jobFamiliesSet = new Set(jobFamilies)
                        // const longestJobFamily = jobFamilies.reduce(
                        //     (acc, jf) => (jf.length > acc.length ? jf : acc),
                        //     ''
                        // )

                        set({
                            jobFamilies,
                            jobFamiliesSet
                            // , longestJobFamily
                        })
                    } else {
                        set({
                            jobFamilies: [],
                            jobFamiliesSet: new Set()
                            // longestJobFamily: ''
                        })
                    }
                },

                groups: [] as GroupModel[],
                currentGroup: undefined,
                myLinksGroup: undefined,
                findMyLinksGroup: () => {
                    const myLinksGroup = get().groups.find(
                        g => g.name === systemGroupNames.__navbarLinks
                    )
                    if (!myLinksGroup) {
                        throw new Error(`'Navbar Links' Folder should exist`)
                    }
                    set({ myLinksGroup })
                },

                editGroup: null,
                setEditGroup: (group: GroupModel) => {
                    set({ editGroup: group })
                },
                clearEditGroup: () => {
                    set({ editGroup: null })
                },

                addGroup: async (groupName: string) => {
                    const store = get()

                    const newGroup_ = await _createAndReturnGroup({
                        name: groupName,
                        links: []
                    })
                    if (newGroup_?.id && store?.currentUser) {
                        const {
                            userKnownEmailsSet,
                            userJobFamiliesSet,
                            userAcademySet,
                            userRegionSet
                        } = store.currentUser

                        const newGroup = annotateGroupWithUserPermission(newGroup_, {
                            knownEmailsSet: userKnownEmailsSet,
                            jobFamiliesSet: userJobFamiliesSet,
                            academyCodeSet: userAcademySet,
                            regionSet: userRegionSet
                        })
                        set({
                            groups: [...get().groups, newGroup].sort(groupSorter),
                            editGroup: null
                        })
                        return true
                    }
                    return false
                },
                renameEditGroup: async (name: string) => {
                    const store = get()
                    if (store.editGroup?.id) {
                        const updatedGroup = annotateGroupsWithUserPermission(
                            [(await _updateGroupName(store.editGroup.id, name))!],
                            store._getPermissionSets()
                        )[0]

                        if (updatedGroup?.id) {
                            set({
                                groups: [
                                    ...get().groups.filter(g => g.id !== updatedGroup.id),
                                    updatedGroup
                                ].sort(groupSorter),
                                editGroup: null
                            })
                            return true
                        }
                    }
                    return false
                },
                bulkUpdateGroupPermissions: async (
                    updates: {
                        id: string
                        requiredKnownEmails: KnownEmailGroupPermission[]
                        requiredJobFamilies: JobFamilyGroupPermission[]
                        requiredAcademies: AcademyGroupPermission[]
                        requiredRegions: RegionGroupPermission[]
                        linkPerms: {
                            requiredKnownEmails: KnownEmailGroupPermission[]
                            requiredJobFamilies: JobFamilyGroupPermission[]
                            requiredAcademies: AcademyGroupPermission[]
                            requiredRegions: RegionGroupPermission[]
                        }[]
                    }[]
                ) => {
                    const ret = await _bulkUpdateGroupPermissions(updates)
                    if (ret) {
                        get().loadGroups(true)
                    }
                    return ret
                },
                removeGroup: async (groupId: string) => {
                    const removedGroup = await _removeGroup(groupId)
                    if (removedGroup?.id) {
                        set({
                            groups: [
                                ...get().groups.filter(g => g.id !== removedGroup.id)
                            ],
                            editGroup: null
                        })
                        return true
                    }
                    return false
                },
                loadGroups: async (forceRefresh = false) => {
                    const {
                        currentUser,
                        currentGroup,
                        _normaliseSorting,
                        _getPermissionSets,
                        findMyLinksGroup
                    } = get()

                    if (!currentUser) {
                        set({ groups: [], myLinksGroup: undefined })
                        return
                    }

                    if (!get().groups?.length || forceRefresh) {
                        const { user } = currentUser
                        const groups = await _getGroups(user, _getPermissionSets())

                        set({ groups })

                        _normaliseSorting()

                        if (currentGroup?.id) {
                            get().setCurrentGroup(currentGroup.id)
                        }
                    }

                    findMyLinksGroup()
                },
                setCurrentGroup: async (groupId: string) => {
                    const sg = get().groups
                    if (sg.length === 0) {
                        await get().loadGroups()
                    }
                    const group = get().groups.find(g => g.id === groupId)
                    if (group) {
                        set({
                            currentGroup: group
                        })
                    }
                },

                linkClipboard: {
                    sourceGroupId: '',
                    linkLookup: {} as Record<string, LinkModel>
                },
                editLink: null,
                setEditLink: (link: LinkModel) => {
                    set({ editLink: link })
                },
                clearEditLink: () => {
                    set({ editLink: null })
                },
                updateEditLink: async (
                    name: string,
                    url: string,
                    description: string
                ) => {
                    // const store = get()
                    const {
                        currentGroup: curGroup,
                        editLink,
                        currentUser,
                        _getPermissionSets,
                        findMyLinksGroup
                    } = get()

                    if (curGroup?.id && editLink) {
                        let updatedGroup: GroupModel
                        const isUserLinksFolder =
                            curGroup.name === systemGroupNames.__userLinks

                        if (isUserLinksFolder) {
                            if (!currentUser?.user?.uid) {
                                throw new Error('Cannot save userLinksFolder - no uid')
                            }
                            updatedGroup = (await _updateLinkForUserLinksFolder(
                                currentUser?.user?.uid,
                                editLink.url,
                                { name, url, description }
                            ))!
                        } else {
                            updatedGroup = annotateGroupWithUserPermission(
                                (await _updateLinkForGroup(editLink.url, curGroup.id, {
                                    name,
                                    url,
                                    description
                                }))!,
                                _getPermissionSets()
                            )
                        }

                        if (updatedGroup?.id) {
                            set({
                                groups: [
                                    ...get().groups.filter(g => g.id !== updatedGroup.id),
                                    updatedGroup
                                ].sort(groupSorter),
                                currentGroup: updatedGroup,
                                ...(isUserLinksFolder && currentUser
                                    ? {
                                          currentUser: {
                                              ...currentUser,
                                              user: {
                                                  ...currentUser.user,
                                                  userLinksFolder: updatedGroup
                                              }
                                          }
                                      }
                                    : {})
                            })

                            findMyLinksGroup()

                            return true
                        }
                    }
                    return false
                },

                _copyLink: (link: LinkModel) => {
                    const curGroupId = get().currentGroup?.id
                    if (curGroupId) {
                        const newLinks =
                            curGroupId !== get().linkClipboard.sourceGroupId
                                ? { [link.url]: link }
                                : { ...get().linkClipboard.linkLookup, [link.url]: link }
                        set({
                            linkClipboard: {
                                sourceGroupId: curGroupId,
                                linkLookup: newLinks
                            }
                        })
                        return true
                    }
                    throw new Error('curGroupId should not be undefined')
                },
                _uncopyLink: (link: LinkModel) => {
                    const curGroupId = get().currentGroup?.id
                    if (curGroupId) {
                        const newLinks =
                            curGroupId !== get().linkClipboard.sourceGroupId
                                ? { [link.url]: link }
                                : Object.values(get().linkClipboard.linkLookup)
                                      .filter(({ url }) => url !== link.url)
                                      .reduce(
                                          (acc, l) => ({
                                              ...acc,
                                              [l.url]: l
                                          }),
                                          {} as Record<string, LinkModel>
                                      )
                        set({
                            linkClipboard: {
                                sourceGroupId: curGroupId,
                                linkLookup: newLinks
                            }
                        })
                        return false
                    }
                    throw new Error('curGroupId should not be undefined')
                },
                toggleLinkToClipboard: ({
                    name,
                    url,
                    favicon24Url,
                    description
                }: LinkModel) => {
                    const link = { name, url, favicon24Url, description }
                    if (get().linkClipboard.linkLookup[link.url]) {
                        return get()._uncopyLink(link)
                    }
                    return get()._copyLink(link)
                },
                pasteLinksToCurrentGroup: () => {
                    get().addLinksToCurrentGroup(
                        Object.values(get().linkClipboard.linkLookup)
                    )
                    set({
                        linkClipboard: {
                            sourceGroupId: '',
                            linkLookup: {} as Record<string, LinkModel>
                        }
                    })
                },

                addLinksToCurrentGroup: async (
                    links: Pick<LinkModel, 'name' | 'url' | 'description'>[]
                ) => {
                    const {
                        currentGroup: curGroup,
                        _getPermissionSets,
                        findMyLinksGroup,
                        currentUser
                    } = get()

                    if (curGroup) {
                        const { id: groupId } = curGroup

                        const linksWithFavicons = links.map(l => ({
                            ...l,
                            favicon24Url: getFavIconUrlForSite(l.url),
                            favicon32Url: getFavIconUrlForSite(l.url, 32)
                        }))

                        let updatedGroup: GroupModel
                        const isUserLinksFolder =
                            curGroup.name === systemGroupNames.__userLinks
                        if (isUserLinksFolder) {
                            if (!currentUser?.user?.uid) {
                                throw new Error('Cannot save userLinksFolder - no uid')
                            }
                            updatedGroup = (await _addLinksToUserLinksFolder(
                                currentUser?.user?.uid,
                                linksWithFavicons
                            ))!
                        } else {
                            updatedGroup = annotateGroupWithUserPermission(
                                (await _addLinksForGroup(groupId!, linksWithFavicons))!,
                                _getPermissionSets()
                            )
                        }

                        if (updatedGroup?.id) {
                            set({
                                groups: [
                                    ...get().groups.filter(g => g.id !== updatedGroup.id),
                                    updatedGroup
                                ].sort(groupSorter),
                                currentGroup: updatedGroup,
                                ...(updatedGroup.name === systemGroupNames.__navbarLinks
                                    ? { myLinksGroup: updatedGroup }
                                    : {}),
                                ...(isUserLinksFolder && currentUser
                                    ? {
                                          currentUser: {
                                              ...currentUser,
                                              user: {
                                                  ...currentUser.user,
                                                  userLinksFolder: updatedGroup
                                              }
                                          }
                                      }
                                    : {})
                            })

                            findMyLinksGroup()

                            return true
                        }
                    }
                    return false
                },

                removeLinkFromCurrentGroup: async (linkUrl: string) => {
                    const {
                        currentGroup: curGroup,
                        _getPermissionSets,
                        findMyLinksGroup,
                        currentUser
                    } = get()
                    if (curGroup) {
                        const { id: groupId } = curGroup
                        let updatedGroup: GroupModel

                        const isUserLinksFolder =
                            curGroup.name === systemGroupNames.__userLinks
                        if (isUserLinksFolder) {
                            if (!currentUser?.user?.uid) {
                                throw new Error('Cannot save userLinksFolder - no uid')
                            }
                            updatedGroup = (await _removeLinkFromUserLinksFolder(
                                currentUser?.user?.uid,
                                linkUrl
                            ))!
                        } else {
                            updatedGroup = annotateGroupWithUserPermission(
                                (await _removeLinkFromGroup(groupId!, linkUrl))!,
                                _getPermissionSets()
                            )
                        }

                        if (updatedGroup?.id) {
                            set({
                                groups: [
                                    ...get().groups.filter(g => g.id !== updatedGroup.id),
                                    updatedGroup
                                ].sort(groupSorter),
                                currentGroup: updatedGroup,
                                ...(updatedGroup.name === systemGroupNames.__navbarLinks
                                    ? { myLinksGroup: updatedGroup }
                                    : {}),
                                ...(isUserLinksFolder && currentUser
                                    ? {
                                          currentUser: {
                                              ...currentUser,
                                              user: {
                                                  ...currentUser.user,
                                                  userLinksFolder: updatedGroup
                                              }
                                          }
                                      }
                                    : {})
                            })
                            findMyLinksGroup()
                        }
                    }
                }
            }),
            {
                name: 'aet-store',
                partialize: (state: Store) => ({
                    settings: state.settings
                }),
                onRehydrateStorage: () => (state: any, errors: any) => {
                    if (errors) {
                        log(`🏪 Store rehydration had errors: ${json(errors)}`)
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        state!.setHasHydrated(true)
                    }
                }
            }
        )
    )
    return useStore
}

export const useStore = buildStore()
