import { IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledIconButton = styled(IconButton)`
    ${({ theme }) => `
    cursor: pointer;
    box-shadow:0px 2px 4px -1px rgba(51,51,51,0.2), 0px 4px 5px 0px rgba(51,51,51,0.14), 0px 1px 10px 0px rgba(51,51,51,0.12);
    transition: ${theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard
    })};
    &:hover {
        transform: scale(1.1);
    }
  `}
`
