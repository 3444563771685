import { _doSingletonUpdate, _getSingleton } from 'service/controller/Singletons'
import {
    flavour,
    PerAcademyPermission,
    SingletonsModel
} from 'service/model/SingletonsModel'
import { makeMapLookup } from 'service/utils'

export const _getPerAcademyPermissions = async () => {
    const data = await _getSingleton('perAcademyPermissions')
    if (data?.flavour === 'perAcademyPermissions') {
        return data.perAcademyPermissions
    }
    return undefined
}

export const _updatePerAcademyPermissions = async (
    updates: {
        name: PerAcademyPermission
        academyPermissions: {
            academyCode: string
            requiredKnownEmails: string[]
            requiredJobFamilies: string[]
            requiredAcademies: string[]
            requiredRegions: string[]
        }[]
    }[]
) => {
    return _doSingletonUpdate((lookupModel: SingletonsModel) => {
        if (lookupModel.flavour !== 'perAcademyPermissions') {
            throw new Error(`Flavour should be '${flavour('perAcademyPermissions')}'`)
        }
        const metaUpdateMap = makeMapLookup(
            updates,
            ({ name }) => name,
            u =>
                makeMapLookup(
                    u.academyPermissions,
                    ({ academyCode }) => academyCode,
                    ap => ap
                )
        )

        const updated = {
            ...lookupModel,
            perAcademyPermissions: lookupModel.perAcademyPermissions.map(p => {
                const acMap = metaUpdateMap.get(p.name)
                const academyPermissions = p.academyPermissions.map(origPerm => {
                    const changedPerm = acMap?.get(origPerm.academyCode)
                    return changedPerm || origPerm
                })
                return { ...p, academyPermissions }
            })
        }

        return updated
    }, 'perAcademyPermissions')
}
