import {
    CollectionReference,
    documentId,
    getDocs,
    query,
    QueryDocumentSnapshot,
    where
} from 'firebase/firestore'

export async function getContentByIdInArry<T>(
    ids: string[],
    collectionData: QueryDocumentSnapshot<T>[]
) {
    // don't run if there aren't any ids or a path for the collection
    if (!ids || !ids.length || !collectionData) {
        return []
    }

    // const collectionRef = collection(db, path)
    const batches: T[] = []

    collectionData.forEach(result => {
        // eslint-disable-next-line no-console
        // console.log('getContentByIdArry-collectionData', result.id)
        ids.forEach(id => {
            // eslint-disable-next-line no-console
            // console.log('getContentByIdArry-compareWithId', result.id, id)
            if (result.id === id) {
                batches.push({
                    ...result.data(),
                    id: result.id
                })
            }
        })
    })

    // after all of the data is fetched, return it
    return batches
}

export function getContentByIdNotInArry<T>(
    ids: string[],
    collectionData: QueryDocumentSnapshot<T>[]
) {
    // don't run if there aren't any ids or a path for the collection
    if (!ids || !ids.length || !collectionData) {
        return []
    }

    // const collectionRef = collection(db, path)
    const batches: T[] = []

    let cachedCollectionData = [...collectionData]

    // console.log('getContentByIdNotInArry-log', result.id)
    ids.forEach(id => {
        cachedCollectionData = cachedCollectionData.filter(record => record.id !== id)
        // eslint-disable-next-line no-console
        // console.log('getContentByIdNotInArry', id, cachedCollectionData.length)
    })

    cachedCollectionData.forEach(result => {
        batches.push({
            ...result.data(),
            id: result.id
        })
    })

    // after all of the data is fetched, return it
    return batches
}

export async function getContentById<T>(
    ids: string[],
    collection_: CollectionReference<T>
) {
    // don't run if there aren't any ids or a path for the collection
    if (!ids || !ids.length || !collection_) {
        return []
    }

    // const collectionRef = collection(db, path)
    const batches = []

    while (ids.length) {
        // firestore limits batches to 10
        const batch = ids.splice(0, 10)
        // const c = collection(db, path)
        const q = query<T>(collection_, where(documentId(), 'in', [...batch]))
        // add the batch request to to a queue
        batches.push(
            getDocs(q).then(results =>
                results.docs.map(result => ({
                    /* id: result.id, */ ...result.data()
                }))
            )
        )
    }

    // after all of the data is fetched, return it
    return Promise.all(batches).then(content => content.flat())
}
