import AccountCircle from '@mui/icons-material/AccountCircle'
import { alpha, Box, BoxProps, SvgIcon } from '@mui/material'
import { useCustomTheme } from 'hooks/useCustomTheme'

type ImgProps = {
    alt?: string
    src: string
}

const imageWidth = 60

export function ImageComponent(props: BoxProps & ImgProps) {
    const { fontColor } = useCustomTheme()
    const emptyLogo = (
        <SvgIcon
            sx={{ fontSize: props.width }}
            color='primary'
            component={AccountCircle}
            inheritViewBox
        />
    )
    const comp = props.src ? (
        <Box component='img' {...props} boxShadow={1} borderRadius={imageWidth / 2} />
    ) : (
        <Box
            {...props}
            sx={{ backgroundColor: alpha(fontColor, 0.25) }}
            boxShadow={1}
            borderRadius={imageWidth / 2}
            textAlign='center'
            justifyContent='center'
        >
            {emptyLogo}
        </Box>
    )
    return comp
}

ImageComponent.defaultProps = {
    alt: ''
}
