import { LinkModel } from 'service/model/LinkModel'
import { getEmojiPrefixAndRemainder } from 'service/utils'

export type GroupPermission = 'none' | 'view' | 'edit'
export type KnownEmailGroupPermission = {
    email: string
    permission: GroupPermission
}
export type JobFamilyGroupPermission = {
    name: string
    permission: GroupPermission
}
export type AcademyGroupPermission = { academyCode: string; permission: GroupPermission }
export type RegionGroupPermission = { regionName: string; permission: GroupPermission }
export interface GroupModel {
    id?: string
    name: string
    imageUrl?: string
    links: LinkModel[]
    mayRename?: boolean
    mayDelete?: boolean
    isSystem?: boolean
    requiredKnownEmails?: KnownEmailGroupPermission[]
    requiredJobFamilies?: JobFamilyGroupPermission[]
    requiredAcademies?: AcademyGroupPermission[]
    requiredRegions?: RegionGroupPermission[]
    userPermission?: GroupPermission
}

export type GroupViewModel = GroupModel & {
    withinDeleteCycle: boolean
}
export const toGroupViewModel = (link: GroupModel) =>
    ({
        ...link,
        withinDeleteCycle: false
    } as GroupViewModel)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const toGroupModel = ({ withinDeleteCycle, ...otherProps }: GroupViewModel) =>
    ({
        ...otherProps
    } as GroupModel)

export const groupSorter = (
    { isSystem: isSystem1, name: groupName1 }: GroupModel,
    { isSystem: isSystem2, name: groupName2 }: GroupModel
) => {
    const emojiSplit1 = getEmojiPrefixAndRemainder(groupName1)
    const emojiSplit2 = getEmojiPrefixAndRemainder(groupName2)

    const name1 = emojiSplit1 ? emojiSplit1[1] : groupName1
    const name2 = emojiSplit2 ? emojiSplit2[1] : groupName2

    if (!!isSystem1 === !!isSystem2) {
        return name1.localeCompare(name2)
    }
    if (isSystem1 && !isSystem2) {
        return -1
    }
    return +1
}

export const requiredKnownEmailsGroupPermissionSorter = (
    { email: email1 }: KnownEmailGroupPermission,
    { email: email2 }: KnownEmailGroupPermission
) => email1.localeCompare(email2)

export const requiredJobFamiliesGroupPermissionSorter = (
    { name: name1 }: JobFamilyGroupPermission,
    { name: name2 }: JobFamilyGroupPermission
) => name1.localeCompare(name2)

export const requiredAcademyGroupPermissionSorter = (
    { academyCode: academyCode1 }: AcademyGroupPermission,
    { academyCode: academyCode2 }: AcademyGroupPermission
) => academyCode1.localeCompare(academyCode2)

export const requiredRegionGroupPermissionSorter = (
    { regionName: regionName1 }: RegionGroupPermission,
    { regionName: regionName2 }: RegionGroupPermission
) => regionName1.localeCompare(regionName2)
