import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledNewsCard = styled(Card)`
    ${({ theme }) => `
    border: 0;
    cursor: pointer;
    transition: ${theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard
    })};
    &:hover {
        transform: scale(1.02);
    }
  `}
`

export const StyledNormalNewsCard = styled(Card)`
    ${({ theme }) => `
    border: 0;
    cursor: pointer;
    transition: ${theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard
    })};
  `}
`
