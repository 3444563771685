import { Box } from '@mui/material'
import {
    NavButton,
    NavItem,
    NavItemText
} from 'components/navbar/Content/Styled/StyledNavComponents'
import { NavBarContentProps } from 'components/navbar/types'
import { LinkModel } from 'service/model/LinkModel'
import { getEmojiPrefixAndRemainder } from 'service/utils'

export const SecondaryLinksSection = ({
    links,
    iconSize,
    nbcp: { open, navBarItemClickedHandler }
}: {
    links?: LinkModel[]
    iconSize: number
    nbcp: NavBarContentProps
}) => {
    const filteredLinks = (links || [])?.filter(l => l.userPermission !== 'none')

    return (
        <>
            {filteredLinks.map(({ name, url, favicon24Url, favicon32Url }) => {
                const emojiSplit = getEmojiPrefixAndRemainder(name)

                return (
                    <NavItem open={open} key={url}>
                        <NavButton
                            onClick={() => navBarItemClickedHandler('myLinksLink', url)}
                            title={!open ? name : ''}
                        >
                            {!emojiSplit && (
                                <>
                                    <Box
                                        sx={{
                                            'backgroundColor': 'white',
                                            'borderRadius': '24px',
                                            'border': '5px solid white',
                                            'width': '34px',
                                            'height': '34px',
                                            '&:hover': !open
                                                ? {
                                                      backgroundColor: '#ffffff'
                                                  }
                                                : {}
                                        }}
                                    >
                                        <img
                                            src={
                                                iconSize === 24
                                                    ? favicon24Url
                                                    : favicon32Url
                                            }
                                            width={24} // even favicon32 should be 24x24
                                            height={24} // even favicon32 should be 24x24
                                            alt=''
                                            style={{ flex: 0 }}
                                        />
                                    </Box>
                                    <NavItemText open={open}>{name}</NavItemText>
                                </>
                            )}
                            {!!emojiSplit && (
                                <>
                                    <span
                                        style={{
                                            fontSize: 22,
                                            margin: '-3px'
                                        }}
                                    >
                                        {emojiSplit[0]}
                                    </span>
                                    <NavItemText open={open}>{emojiSplit[1]}</NavItemText>
                                </>
                            )}
                        </NavButton>
                    </NavItem>
                )
            })}
        </>
    )
}
