/* eslint-disable no-nested-ternary */
import { Box, Stack } from '@mui/material'
import { Fragment, memo } from 'react'

import PermissionsIcon from '@mui/icons-material/Key'
import { useCustomTheme } from 'hooks/useCustomTheme'
import {
    BlockAdjacency,
    cellEmphasisColor,
    Emphasized,
    PermChange,
    PermXCoordinate
} from 'pages/Permissions/PermissionsPage'
import { NavPermissionAssignmentButton } from 'pages/Permissions/PermissionsPage/NavPermissionAssignmentButton'
import { Permission } from 'service/model/SingletonsModel'
import { Store } from '../../../appData/store'

const PermissionRow = memo(
    ({
        rowIndex,
        blockAdjacency,
        permission,
        knownEmails,
        jobFamilies,
        academies,
        regions,
        changedPerms,
        onPermAssignmentChanged,
        onClick,
        emphasized
    }: Omit<PermissionRowsParams, 'permissions'> & {
        rowIndex: number
        permission: Store['permissions'][0]
    }) => {
        const { theme } = useCustomTheme()
        const permKnownEmailPerms = new Set(permission.requiredKnownEmails || [])
        const permJobFamilyPerms = new Set(permission.requiredJobFamilies || [])
        const permAcademyPerms = new Set(permission.requiredAcademies || [])
        const permRegionPerms = new Set(permission.requiredRegions || [])
        const changedPerm = changedPerms[permission.name]

        const changedPermOriginalKnownEmailRights = changedPerm
            ? changedPerm?.originalKnownEmailRights
            : permKnownEmailPerms

        const changedPermOriginalJobFamilyRights = changedPerm
            ? changedPerm?.originalJobFamilyRights
            : permJobFamilyPerms

        const changedPermOriginalAcademyRights = changedPerm
            ? changedPerm?.originalAcademyRights
            : permAcademyPerms

        const changedPermOriginalRegionRights = changedPerm
            ? changedPerm?.originalRegionRights
            : permRegionPerms

        const lastKnownEmailIndex = knownEmails.length - 1
        const lastJobFamilyIndex = jobFamilies.length - 1
        const lastAcademyIndex = academies.length - 1
        const lastRegionIndex = regions.length - 1

        const localOnClick = () => {
            onClick(permission.name)
        }
        const emphasizeRow = emphasized.permissionNames[permission.name]

        const {
            knownEmailsHasFollowingBlock,
            jobFamiliesIsFirstBlock,
            jobFamiliesHasFollowingBlock,
            academiesHasFollowingBlock,
            academiesIsFirstBlock,
            regionsIsFirstBlock
        } = blockAdjacency

        const topBorderWidth = rowIndex ? '0' : '1px'

        return (
            <Fragment key={permission.name}>
                <Box
                    sx={{
                        backgroundColor: emphasizeRow ? cellEmphasisColor : '#ffffff',
                        padding: '0.25em 0.5em',
                        whiteSpace: 'nowrap',
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        zIndex: 100,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderWidth: `${topBorderWidth} 1px 1px 1px`,
                        cursor: 'pointer',
                        color: theme.palette.primary.main
                    }}
                    key={`${permission.name}`}
                    onClick={localOnClick}
                >
                    <Stack direction='row'>
                        <PermissionsIcon />
                        <span style={{ marginLeft: '8px', whiteSpace: 'nowrap' }}>
                            {permission.name}
                        </span>
                    </Stack>
                </Box>
                {knownEmails.map((knownEmail, i) => {
                    const assigned = permKnownEmailPerms.has(knownEmail)
                    const orig = changedPermOriginalKnownEmailRights?.has(knownEmail)
                    const isChanged = orig !== assigned
                    const lastCol = i === lastKnownEmailIndex
                    const cellEmphasized =
                        emphasizeRow || emphasized.knownEmails[knownEmail]

                    return (
                        <Box
                            key={knownEmail}
                            sx={{
                                backgroundColor: cellEmphasized
                                    ? cellEmphasisColor
                                    : '#ffffff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderWidth: `${topBorderWidth} 1px 1px 0`,
                                marginRight:
                                    lastCol && knownEmailsHasFollowingBlock ? '10px' : 0
                                // width: '46px'
                            }}
                        >
                            {/* {assigned ? 'Y' : 'N'} */}
                            <NavPermissionAssignmentButton
                                assigned={assigned}
                                isChanged={isChanged}
                                state={{
                                    permissionName: permission.name,
                                    knownEmail
                                }}
                                onAssignmentChanged={onPermAssignmentChanged}
                                emphasized={cellEmphasized}
                            />
                        </Box>
                    )
                })}
                {jobFamilies.map(({ text: jf }, i) => {
                    const assigned = permJobFamilyPerms.has(jf)
                    const orig = changedPermOriginalJobFamilyRights?.has(jf)
                    const isChanged = orig !== assigned
                    const lastCol = i === lastJobFamilyIndex
                    const cellEmphasized = emphasizeRow || emphasized.jobFamilies[jf]

                    return (
                        <Box
                            key={jf}
                            sx={{
                                backgroundColor: cellEmphasized
                                    ? cellEmphasisColor
                                    : '#ffffff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderWidth:
                                    !jobFamiliesIsFirstBlock && i === 0
                                        ? `${topBorderWidth} 1px 1px 1px`
                                        : `${topBorderWidth} 1px 1px 0`,
                                marginRight:
                                    lastCol && jobFamiliesHasFollowingBlock ? '10px' : 0
                                // width: '46px'
                            }}
                        >
                            {/* {assigned ? 'Y' : 'N'} */}
                            <NavPermissionAssignmentButton
                                assigned={assigned}
                                isChanged={isChanged}
                                state={{
                                    permissionName: permission.name,
                                    jobFamily: jf
                                }}
                                onAssignmentChanged={onPermAssignmentChanged}
                                emphasized={cellEmphasized}
                            />
                        </Box>
                    )
                })}
                {academies.map(({ academyCode }, i) => {
                    const assigned = permAcademyPerms.has(academyCode)
                    const orig = changedPermOriginalAcademyRights?.has(academyCode)
                    const isChanged = orig !== assigned
                    const lastCol = i === lastAcademyIndex
                    const cellEmphasized =
                        emphasizeRow || emphasized.academyCodes[academyCode]

                    return (
                        <Box
                            key={academyCode}
                            sx={{
                                backgroundColor: cellEmphasized
                                    ? cellEmphasisColor
                                    : '#ffffff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderWidth:
                                    !academiesIsFirstBlock && i === 0
                                        ? `${topBorderWidth} 1px 1px 1px`
                                        : `${topBorderWidth} 1px 1px 0`,
                                marginRight:
                                    lastCol && academiesHasFollowingBlock ? '10px' : 0
                                // width: '46px'
                            }}
                        >
                            {/* {assigned ? 'Y' : 'N'} */}
                            <NavPermissionAssignmentButton
                                assigned={assigned}
                                isChanged={isChanged}
                                state={{
                                    permissionName: permission.name,
                                    academy: academyCode
                                }}
                                onAssignmentChanged={onPermAssignmentChanged}
                                emphasized={cellEmphasized}
                            />
                        </Box>
                    )
                })}

                {regions.map((region, i) => {
                    const assigned = permRegionPerms.has(region)
                    const orig = changedPermOriginalRegionRights?.has(region)
                    const isChanged = orig !== assigned
                    const lastCol = i === lastRegionIndex
                    const cellEmphasized = emphasizeRow || emphasized.regions[region]

                    return (
                        <Box
                            key={region}
                            sx={{
                                backgroundColor: cellEmphasized
                                    ? cellEmphasisColor
                                    : '#ffffff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderWidth:
                                    !regionsIsFirstBlock && i === 0
                                        ? `${topBorderWidth} 1px 1px 1px`
                                        : `${topBorderWidth} 1px 1px 0`,
                                marginRight: lastCol ? 0 : 0
                                // width: '46px'
                            }}
                        >
                            {/* {assigned ? 'Y' : 'N'} */}
                            <NavPermissionAssignmentButton
                                assigned={assigned}
                                isChanged={isChanged}
                                state={{
                                    permissionName: permission.name,
                                    region
                                }}
                                onAssignmentChanged={onPermAssignmentChanged}
                                emphasized={cellEmphasized}
                            />
                        </Box>
                    )
                })}
            </Fragment>
        )
    }
)

type PermissionRowsParams = {
    blockAdjacency: BlockAdjacency
    permissions: Store['permissions']
    knownEmails: Store['knownEmails']
    jobFamilies: { prefix: string; suffix: string; text: string }[]
    academies: Store['academies']
    regions: string[]
    changedPerms: Record<Permission, PermChange>
    transitionStr: string
    onPermAssignmentChanged: Parameters<
        typeof NavPermissionAssignmentButton<
            { permissionName: Permission } & PermXCoordinate
        >
    >[0]['onAssignmentChanged']
    onClick: (permission: Permission) => void
    emphasized: Emphasized
}
export const PermissionsRows = memo(
    ({
        blockAdjacency,
        permissions,
        knownEmails,
        jobFamilies,
        academies,
        regions,
        changedPerms,
        transitionStr,
        onPermAssignmentChanged,
        onClick,
        emphasized
    }: PermissionRowsParams) => {
        return (
            <>
                {permissions.map((permission, i) => (
                    <PermissionRow
                        rowIndex={i}
                        blockAdjacency={blockAdjacency}
                        key={permission.name}
                        permission={permission}
                        knownEmails={knownEmails}
                        jobFamilies={jobFamilies}
                        academies={academies}
                        regions={regions}
                        changedPerms={changedPerms}
                        onPermAssignmentChanged={onPermAssignmentChanged}
                        transitionStr={transitionStr}
                        onClick={onClick}
                        emphasized={emphasized}
                    />
                ))}
            </>
        )
    }
)
