import Froalaeditor from 'froala-editor'

Froalaeditor.DefineIcon('alert', { NAME: 'info', SVG_KEY: 'help' })
Froalaeditor.RegisterCommand('alert', {
    title: 'Hello',
    focus: false,
    undo: false,
    refreshAfterCallback: false,
    callback() {
        alert('Hello!')
    }
})

Froalaeditor.DefineIcon('clear', {
    NAME: 'remove',
    SVG_KEY: 'remove'
})
Froalaeditor.RegisterCommand('clear', {
    title: 'Clear HTML',
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    callback() {
        this.html.set('')
        this.events.focus()
    }
})

Froalaeditor.DefineIcon('insert', { NAME: 'plus', SVG_KEY: 'add' })
Froalaeditor.RegisterCommand('insert', {
    title: 'Insert HTML',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback() {
        this.html.insert('My Html')
    }
})

Froalaeditor.DefineIcon('insert2', { NAME: 'plus', SVG_KEY: 'add' })
Froalaeditor.RegisterCommand('insert2', {
    title: 'Insert Image',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback() {
        this.html.insert(
            '<p><img src="/images/media/OffasMeadAcademy1.png" class="fr-fic fr-dib"></p>'
        )
    }
})

// Froalaeditor.DefineIcon('insertTile', {
//     NAME: 'plus',
//     SVG_KEY: 'add'
// })
Froalaeditor.RegisterCommand('Sample 1', {
    title: 'Sample 1',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback() {
        this.html.set('')
        this.html.insert(
            '<table style="width: 100%;"><tbody><tr><td style="width: 100.0000%;"><img src="/images/media/sample.jpeg" style="width: 300px;" class="fr-fic fr-dib"></td></tr></tbody></table>'
        )
        this.undo.saveStep()
    }
})

Froalaeditor.RegisterCommand('Sample 2', {
    title: 'Sample 2',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback() {
        this.html.set('')
        this.html.insert(
            '<table style="width: 100%;"><tbody><tr><td style="width: 100.0000%;"><img src="/images/media/OffasMeadAcademy1.png" style="width: 300px;" class="fr-fic fr-dib"></td></tr><tr><td class="tblCellLeftPadding tblCellTopPadding" style="width: 100.0000%;"><span style="font-family: Manrope,sans-serif;">Watch Offa&#39;s Mead&#39;s new video</span><br></td></tr></tbody></table>'
        )
        this.undo.saveStep()
    }
})

Froalaeditor.RegisterCommand('Sample 3', {
    title: 'Sample 3',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback() {
        this.html.set('')
        this.html.insert(
            '<div style="display:flex;justify-content:center;"><table><tbody><tr><td><div style="display:flex;justify-content:center;"><img src="/images/media/alarm.png" style="width: 124px;" class="fr-fic fr-dib"></div></td></tr><tr><td class="tblCellLeftPadding tblCellTopPadding" style="width: 100%; text-align: center;">Routine Weekly Meeting<br></td></tr><tr><td style="width: 100%; text-align: center;">20.10.2022 10:00 AM<br></td></tr></tbody></table></div>'
        )
        this.undo.saveStep()
    }
})

Froalaeditor.RegisterCommand('Template 1', {
    title: 'Template 1',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback() {
        this.html.set('')
        this.html.insert(
            '<table style="width: 100%;"><tbody><tr><td style="width: 100.0000%;"><img src="/images/media/sample.jpeg" style="width: 600px;" class="fr-fic fr-dib"></td></tr></tbody></table>'
        )
        this.undo.saveStep()
    }
})

Froalaeditor.RegisterCommand('Template 2', {
    title: 'Template 2',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback() {
        this.html.set('')
        this.html.insert(
            '<table style="width: 100%;"><tbody><tr><td style="width: 100.0000%;"><img src="/images/media/OffasMeadAcademy1.png" style="width: 600px;" class="fr-fic fr-dib"></td></tr><tr><td class="tblCellLeftPadding tblCellTopPadding" style="width: 100.0000%;"><span style="font-family: Manrope,sans-serif;">Watch Offa&#39;s Mead&#39;s new video</span><br></td></tr></tbody></table>'
        )
        this.undo.saveStep()
    }
})
