import { Box } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import MyFocus from 'components/myfocus/MyFocus'
import SearchBox from 'components/searchbox/SearchBox'
import { useGlobalContext } from 'contexts/global-context'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { FC } from 'react'

interface AppBarProps extends MuiAppBarProps {
    propmarginleft: number
}

const AppBar = styled(
    MuiAppBar,
    {}
)<AppBarProps>(({ theme, propmarginleft }) => ({
    background: 'white'
    // zIndex: theme.zIndex.drawer + 1,
    // marginLeft: `${propmarginleft}px`
    // width: `calc(100% - ${propmarginleft}px)`
    // transition: theme.transitions.create(['width', 'margin'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen
    // })
}))

type HeaderProps = {
    height: number
    propmarginleft: number
}

const Header: FC<HeaderProps> = ({ height, propmarginleft }) => {
    const { mode } = useGlobalContext()
    const theme = useCustomTheme()
    const isLightModeOn = mode === 'light'

    return (
        <AppBar
            position='fixed'
            propmarginleft={propmarginleft}
            style={{ backgroundColor: isLightModeOn ? theme.themeColor : '#212121' }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height,
                    marginLeft: { xs: 3, md: 5, lg: 5, xl: 5 },
                    marginRight: { xs: 0, md: 5, lg: 4, xl: 2 },
                    padding: 2
                }}
            >
                <SearchBox />
                <MyFocus />
            </Box>
        </AppBar>
    )
}

export default Header
