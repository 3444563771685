import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SaveIcon from '@mui/icons-material/Save'
import SettingsIcon from '@mui/icons-material/Settings'
import { Button, Stack } from '@mui/material'
import { altColor } from 'components/navbar/types'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledIconButton } from 'utils/animatedComponents/StyledIconButton'

export const ButtonBar = memo(
    ({
        initialise,
        saveChanges,
        unsavedChanges,
        onSettingsClicked,
        settingsButtonDisabled
    }: {
        initialise: () => void
        saveChanges: () => Promise<void>
        unsavedChanges: boolean
        onSettingsClicked: () => void
        settingsButtonDisabled: boolean
    }) => {
        const navigate = useNavigate()
        const { themeColor } = useCustomTheme()

        return (
            <Stack
                direction='row'
                justifyContent='space-between'
                flex='0'
                sx={{
                    mt: 1,
                    mr: 0,
                    left: 0,
                    right: 0,
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    border: `1px dashed ${altColor}`,
                    zIndex: 101,
                    paddingRight: '52px',
                    height: '56px',
                    alignItems: 'center',
                    borderWidth: '1px 0 0 0'
                }}
            >
                <Stack direction='row' alignItems='center'>
                    <Button
                        startIcon={
                            <SettingsIcon
                                color={settingsButtonDisabled ? 'disabled' : 'primary'}
                            />
                        }
                        onClick={onSettingsClicked}
                        variant='outlined'
                        disabled={settingsButtonDisabled}
                    >
                        Ad-hoc Permissions
                    </Button>
                </Stack>
                <Stack direction='row'>
                    <Button
                        sx={{
                            mr: '10px',
                            height: '42px',
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '17px'
                        }}
                        onClick={initialise}
                        variant='outlined'
                        disabled={!unsavedChanges}
                    >
                        Reset
                    </Button>
                    <Button
                        startIcon={
                            <SaveIcon
                                sx={{
                                    color: !unsavedChanges ? '#A6A6A6' : '#ffffff'
                                }}
                            />
                        }
                        sx={{
                            mr: '10px',
                            height: '42px',
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '17px'
                        }}
                        onClick={saveChanges}
                        variant='contained'
                        disabled={!unsavedChanges}
                    >
                        Save Changes
                    </Button>
                    <StyledIconButton
                        disableRipple
                        sx={{
                            mr: 2,
                            height: 42,
                            width: 42,
                            background: themeColor,
                            color: '#ffffff'
                        }}
                        onClick={() => {
                            navigate('/')
                        }}
                    >
                        <CloseRoundedIcon />
                    </StyledIconButton>
                </Stack>
            </Stack>
        )
    }
)
