/* eslint-disable prefer-template */
import SubMenuIcon from '@mui/icons-material/ChevronRight'
import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import ApplicationsIcon from '@mui/icons-material/StarOutlineRounded'

import { ListItem } from '@mui/material'
import {
    NavButton,
    NavGroup,
    NavItem,
    NavItemText,
    UnstyledRouterLink
} from 'components/navbar/Content/Styled/StyledNavComponents'
// import { NavPortal } from 'components/navbar/NavPortal'
import { NavBarContentProps, white } from 'components/navbar/types'

export const PrimaryListItems = ({
    open,
    navBarItemClickedHandler
}: NavBarContentProps) => {
    return (
        <ListItem
            sx={{
                padding: 0,
                flexDirection: 'row'
            }}
            className='no-hover'
        >
            <NavGroup>
                <NavItem open={open}>
                    <UnstyledRouterLink to='/'>
                        <NavButton title={!open ? 'Dashboard' : ''}>
                            <DashboardIcon
                                style={{
                                    color: white,
                                    width: 24,
                                    height: 24
                                }}
                            />
                            <NavItemText open={open}>Dashboard</NavItemText>
                        </NavButton>
                    </UnstyledRouterLink>
                </NavItem>
                <NavItem open={open}>
                    <NavButton
                        onClick={() =>
                            navBarItemClickedHandler('Applications', 'Applications')
                        }
                        title={!open ? 'Applications' : ''}
                    >
                        <ApplicationsIcon
                            style={{
                                color: white,
                                width: 22.82,
                                height: 21.82
                            }}
                        />
                        <NavItemText open={open}>Applications</NavItemText>
                        {open && (
                            <SubMenuIcon
                                style={{
                                    color: white,
                                    width: 22.82,
                                    height: 21.82
                                }}
                            />
                        )}
                    </NavButton>
                </NavItem>
                {/* <NavItem open={open}>
                    <NavButton
                        onClick={() =>
                            navBarItemClickedHandler(
                                'Staff Directory!',
                                'Staff Directory!'
                            )
                        }
                    >
                        <StaffDirectoryIcon
                            style={{
                                color: white,
                                width: 24,
                                height: 18
                            }}
                        />
                        <NavItemText open={open}>Staff Directory</NavItemText>
                    </NavButton>
                </NavItem> */}
            </NavGroup>
        </ListItem>
    )
}
