import AddLinkIcon from '@mui/icons-material/AddCircle'
import { Button, Stack } from '@mui/material'
import { NavBarEdit } from 'components/navbar/Content/Styled/NavBarEdit'
import {
    NavButton,
    NavItemText
} from 'components/navbar/Content/Styled/StyledNavComponents'
import { otherColor, white } from 'components/navbar/types'
import { useCallback, useState } from 'react'
import { LinkModel } from 'service/model/LinkModel'
import { object, string } from 'yup'

type Mode = 'ready' | 'editing'

const linkSchema = object({
    linkName: string().required(),
    linkUrl: string().required().url().nullable(),
    description: string().required()
})
export const AddEditLink = ({
    open,
    completed,
    cancelled,
    allBlue,
    linkToEdit
}: {
    open: boolean
    completed: (name: string, url: string, description: string) => Promise<boolean>
    cancelled: () => void
    allBlue: boolean
    linkToEdit?: LinkModel
}) => {
    const initialData = linkToEdit || { name: '', url: '', description: '' }

    const [mode, setMode] = useState<Mode>(linkToEdit ? 'editing' : 'ready')
    const [linkName, setLinkName] = useState(initialData.name)
    const [linkUrl, setLinkUrl] = useState(initialData.url)
    const [description, setDescription] = useState(initialData.description)

    const doSetLinkName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        doValidation({ linkName: e.target.value })
        setLinkName(e.target.value)
    }
    const doSetLinkUrl = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        doValidation({ linkUrl: e.target.value })
        setLinkUrl(e.target.value)
    }
    const doSetDescription = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        doValidation({ description: e.target.value })
        setDescription(e.target.value)
    }

    const [validations, setValidations] = useState<Record<string, string[]>>()

    const doValidation = useCallback(
        (curTargetPatch: Record<string, string>) => {
            const formData = {
                linkName,
                linkUrl,
                description,
                ...curTargetPatch
            }
            linkSchema
                .validate(formData, { strict: true, abortEarly: false })
                .then(_data => {
                    setValidations({})
                })
                .catch(val => {
                    const vals = (val.inner as { path: string; errors: string[] }[])
                        .map(({ path, errors }) => ({
                            field: path,
                            errors
                        }))
                        .reduce(
                            (acc, { field, errors }) => ({ ...acc, [field]: errors }),
                            {} as Record<string, string[]>
                        )
                    setValidations(vals)
                })
        },
        [description, linkName, linkUrl]
    )

    const valid = !!validations && Object.keys(validations).length === 0

    const color = allBlue ? white : otherColor
    return (
        <Stack sx={{ flex: '1 0' }}>
            {mode === 'ready' && (
                <NavButton onClick={() => {}}>
                    <AddLinkIcon
                        style={{
                            color,
                            width: 24,
                            height: 24
                        }}
                    />
                    <NavItemText
                        open={open}
                        onClick={() => setMode('editing')}
                        altColor={color}
                    >
                        Add a Link to this Folder
                    </NavItemText>
                </NavButton>
            )}
            {mode === 'editing' && (
                <Stack direction='column' sx={{ flex: 1 }}>
                    <NavBarEdit
                        id='nameField'
                        label='Link name'
                        variant='outlined'
                        value={linkName}
                        onChange={doSetLinkName}
                        sx={{ color, borderColor: color, mt: 2 }}
                        altColor={allBlue}
                        allBlue={allBlue}
                        autoComplete='off'
                        error={!!validations?.linkName}
                        helperText={validations?.linkName}
                        autoFocus
                    />
                    <NavBarEdit
                        id='urlField'
                        label='Address'
                        variant='outlined'
                        value={linkUrl}
                        onChange={doSetLinkUrl}
                        sx={{ color, borderColor: color, mt: 2 }}
                        altColor={allBlue}
                        allBlue={allBlue}
                        autoComplete='off'
                        error={!!validations?.linkUrl}
                        helperText={validations?.linkUrl}
                    />
                    <NavBarEdit
                        id='urlField'
                        label='Description'
                        variant='outlined'
                        value={description}
                        onChange={doSetDescription}
                        sx={{ color, borderColor: color, mt: 2 }}
                        altColor={allBlue}
                        allBlue={allBlue}
                        autoComplete='off'
                        error={!!validations?.description}
                        helperText={validations?.description}
                        multiline
                    />
                    <Stack
                        direction='row'
                        spacing={1}
                        justifyContent='space-between'
                        sx={{ pt: 1 }}
                    >
                        <Button
                            sx={{ flex: 1 }}
                            variant='contained'
                            onClick={() => {
                                cancelled()
                                setValidations(undefined)
                                setMode('ready')
                                setLinkName('')
                                setLinkUrl('')
                                setDescription('')
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{ flex: 1 }}
                            variant='contained'
                            onClick={async () => {
                                const success = await completed(
                                    linkName,
                                    linkUrl,
                                    description
                                )
                                if (success) {
                                    setMode('ready')
                                    setLinkName('')
                                    setLinkUrl('')
                                    setDescription('')
                                }
                            }}
                            disabled={!valid}
                        >
                            {linkToEdit ? 'Update Link' : 'Add Link'}
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
}
