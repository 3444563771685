import { Box } from '@mui/material'
import { UnwrapRecordValue } from 'components/navbar/types'
import { CollapsedPerAcademyPermissionExceptions } from 'pages/Permissions/PermissionsPage'
import { useCallback } from 'react'
import { PerAcademyPermission } from 'service/model/SingletonsModel'

export const PerAcademyPermissionFilterButton = ({
    perAcademyPermission,
    state,
    stateTitles,
    onClick
}: {
    perAcademyPermission: PerAcademyPermission
    state: UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions>
    stateTitles?: Record<
        UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions>,
        string
    >
    onClick?: (perAcademyPermission: PerAcademyPermission) => void
}) => {
    const localOnClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()
            onClick?.(perAcademyPermission)
        },
        [perAcademyPermission, onClick]
    )

    const titles: Record<
        UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions>,
        string
    > = stateTitles || {
        open: 'Showing all academies for permission',
        closed: 'Hiding all academies for permission'
        // show_active: 'Showing only links which are active for the selected user'
    }

    const labels: Record<
        UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions>,
        string
    > = {
        open: '[+]',
        closed: '[-]'
        // show_active: '[.]'
    }

    return (
        <Box
            onClick={localOnClick}
            sx={{
                mr: '4px',
                fontFamily: 'monospace',
                cursor: 'pointer'
            }}
            title={titles[state]}
        >
            {labels[state]}
        </Box>
    )
}
