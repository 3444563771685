import { Box } from '@mui/material'
import { useCustomTheme } from 'hooks/useCustomTheme'
import React from 'react'

export const FloatingLinks = ({ children }: { children: React.ReactNode }) => {
    const { theme } = useCustomTheme()
    return (
        <Box
            sx={{
                'position': 'fixed',
                'right': '10px',
                'bottom': '68px',
                'width': '49px',
                'display': {
                    xs: 'none',
                    md: 'none',
                    lg: 'block'
                },
                // Workaround for hover to the edit outlined icon as it couldn't be found in due time. Sorry!
                '& button:has(svg[data-testid="EditOutlinedIcon"])': {
                    'transition': 'all 0.4s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                        backgroundColor: theme.palette.common.pink
                    }
                }
            }}
        >
            <Box id='floating-links-homepage-portal-root' />
            {children}
        </Box>
    )
}
