/* eslint-disable no-nested-ternary */
import { Box, Stack } from '@mui/material'
import {
    BlockAdjacency,
    cellEmphasisColor,
    CollapsedGroupExceptions,
    CurrentUserLookups,
    defaultCollapsedGroupState,
    Emphasized,
    GroupChange
} from 'pages/Permissions/PermissionsPage'
import { Fragment, memo } from 'react'
import { getEmojiPrefixAndRemainder, makeMapLookup } from 'service/utils'

import GroupIcon from '@mui/icons-material/GroupWork'
import { Store } from 'appData/store'
import { altColor, UnwrapRecordValue } from 'components/navbar/types'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { annotateLinksWithUserPermission } from 'service/controller/User'
import { GroupModel } from 'service/model/GroupModel'
import { GroupLinkFilterButton } from './GroupLinkFilterButton'
import { LinkRows } from './LinkRows'
import { NavGroupPermButton } from './NavGroupPermButton'

const GroupRow = memo(
    ({
        rowIndex,
        blockAdjacency,
        group,
        collapsedException,
        knownEmails,
        jobFamilies,
        academies,
        regions,
        changedGroups,
        permissionGroupId,
        emphasizedRowBackgroundColor,
        transitionStr,
        onGroupPermChanged,
        onLinkPermChanged,
        onClick,
        onLinkClick,
        onLinkFilterClicked,
        emphasized,
        selectedUserLookups
    }: Omit<GroupRowsParams, 'groups' | 'collapsedGroupExceptions'> & {
        rowIndex: number
        group: GroupModel
        collapsedException: UnwrapRecordValue<CollapsedGroupExceptions>
    }) => {
        const { theme } = useCustomTheme()

        const groupKnownEmailPerms = makeMapLookup(
            group.requiredKnownEmails || [],
            ({ email }) => email,
            ({ permission }) => permission
        )
        const groupJobFamilyPerms = makeMapLookup(
            group.requiredJobFamilies || [],
            ({ name }) => name,
            ({ permission }) => permission
        )
        const groupAcademyPerms = makeMapLookup(
            group.requiredAcademies || [],
            ({ academyCode }) => academyCode,
            ({ permission }) => permission
        )
        const groupRegionPerms = makeMapLookup(
            group.requiredRegions || [],
            ({ regionName }) => regionName,
            ({ permission }) => permission
        )

        const changedGroup = changedGroups[group.id!]

        const changedGroupOriginalKnownEmailGroupPermissions = changedGroup
            ? changedGroup?.originalKnownEmailPermissions
            : groupKnownEmailPerms

        const changedGroupOriginalJobFamilyGroupPermissions = changedGroup
            ? changedGroup?.originalJobFamilyPermissions
            : groupJobFamilyPerms

        const changedGroupOriginalAcademyGroupPermissions = changedGroup
            ? changedGroup?.originalAcademyPermissions
            : groupAcademyPerms

        const changedGroupOriginalRegionGroupPermissions = changedGroup
            ? changedGroup?.originalRegionPermissions
            : groupRegionPerms

        const emphasizeGroup = group.id === permissionGroupId && !!permissionGroupId
        const emphasizeRow = emphasized.groupIds[group.id ?? '']

        const lastKnownEmailIndex = knownEmails.length - 1
        const lastJobFamilyIndex = jobFamilies.length - 1
        const lastAcademyIndex = academies.length - 1
        const lastRegionIndex = regions.length - 1

        const localOnClick = () => {
            if (group.id) {
                onClick?.(group.id)
            }
        }
        const {
            knownEmailsHasFollowingBlock,
            jobFamiliesIsFirstBlock,
            jobFamiliesHasFollowingBlock,
            academiesHasFollowingBlock,
            academiesIsFirstBlock,
            regionsIsFirstBlock
        } = blockAdjacency

        const emojiSplit = getEmojiPrefixAndRemainder(group.name)
        const groupName = !emojiSplit ? group.name : emojiSplit[1]

        const origLinks = changedGroup ? changedGroup.originalGroup.links : group.links

        const links =
            collapsedException === 'show_active'
                ? annotateLinksWithUserPermission(
                      origLinks,
                      {
                          knownEmailsSet: selectedUserLookups.knownEmailsSet,
                          jobFamiliesSet: selectedUserLookups.jobFamilySet,
                          academyCodeSet: selectedUserLookups.academyCodeSet,
                          regionSet: selectedUserLookups.regionSet
                      },
                      group.links
                  ).filter(({ userPermission }) => userPermission !== 'none')
                : collapsedException === 'closed'
                ? []
                : group.links

        const countText =
            collapsedException === 'show_active'
                ? ` (${links.length}/${group.links.length})`
                : ` (${group.links.length})`

        const topBorderWidth = rowIndex ? '0' : '1px'
        return (
            <Fragment key={group.id}>
                <Box
                    sx={{
                        backgroundColor: emphasizeGroup
                            ? emphasizedRowBackgroundColor
                            : emphasizeRow?.groupEmphasis
                            ? cellEmphasisColor
                            : '#ffffff',
                        padding: '0.25em 0.5em',
                        whiteSpace: 'nowrap',
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        zIndex: 100,
                        ...(emphasizeGroup ? { transition: transitionStr } : {}),
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderWidth: `${topBorderWidth} 1px 1px 1px`,
                        cursor: 'pointer',
                        color: theme.palette.primary.main
                    }}
                    onClick={localOnClick}
                >
                    <Stack direction='row' alignItems='center'>
                        <GroupLinkFilterButton
                            groupId={group.id!}
                            state={collapsedException}
                            onClick={onLinkFilterClicked}
                        />
                        {emojiSplit ? (
                            <span
                                style={{
                                    fontSize: 22,
                                    margin: '-3px'
                                }}
                            >
                                {emojiSplit[0]}
                            </span>
                        ) : (
                            <GroupIcon
                                style={{
                                    color: altColor
                                }}
                            />
                        )}
                        <span
                            style={{
                                marginLeft: '8px',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {groupName}
                            {countText}
                        </span>
                    </Stack>
                </Box>

                {knownEmails.map((knownEmail, i) => {
                    const perm = groupKnownEmailPerms.get(knownEmail)
                    const orig =
                        changedGroupOriginalKnownEmailGroupPermissions?.get(knownEmail)
                    const isChanged = orig !== perm
                    const lastCol = i === lastKnownEmailIndex
                    const cellEmphasized =
                        emphasizeRow?.groupEmphasis || emphasized.knownEmails[knownEmail]

                    return (
                        <Box
                            key={knownEmail}
                            sx={{
                                backgroundColor: emphasizeGroup
                                    ? emphasizedRowBackgroundColor
                                    : cellEmphasized
                                    ? cellEmphasisColor
                                    : '#ffffff',
                                ...(emphasizeGroup ? { transition: transitionStr } : {}),
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderWidth: `${topBorderWidth} 1px 1px 0`,
                                marginRight:
                                    lastCol && knownEmailsHasFollowingBlock ? '10px' : 0
                                // width: '46px'
                            }}
                        >
                            {/* {perm} */}
                            <NavGroupPermButton
                                perm={perm || 'none'}
                                isChanged={isChanged}
                                state={{ groupId: group.id!, knownEmail }}
                                onPermChanged={onGroupPermChanged}
                                emphasized={cellEmphasized}
                            />
                        </Box>
                    )
                })}
                {jobFamilies.map(({ text: jf }, i) => {
                    const perm = groupJobFamilyPerms.get(jf)
                    const orig = changedGroupOriginalJobFamilyGroupPermissions?.get(jf)
                    const isChanged = orig !== perm
                    const lastCol = i === lastJobFamilyIndex
                    const cellEmphasized =
                        emphasizeRow?.groupEmphasis || emphasized.jobFamilies[jf]

                    return (
                        <Box
                            key={jf}
                            sx={{
                                backgroundColor: emphasizeGroup
                                    ? emphasizedRowBackgroundColor
                                    : cellEmphasized
                                    ? cellEmphasisColor
                                    : '#ffffff',
                                ...(emphasizeGroup ? { transition: transitionStr } : {}),
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderWidth:
                                    !jobFamiliesIsFirstBlock && i === 0
                                        ? `${topBorderWidth} 1px 1px 1px`
                                        : `${topBorderWidth} 1px 1px 0`,
                                marginRight:
                                    lastCol && jobFamiliesHasFollowingBlock ? '10px' : 0
                                // width: '46px'
                            }}
                        >
                            {/* {perm} */}
                            <NavGroupPermButton
                                perm={perm || 'none'}
                                isChanged={isChanged}
                                state={{ groupId: group.id!, jobFamily: jf }}
                                onPermChanged={onGroupPermChanged}
                                emphasized={cellEmphasized}
                            />
                        </Box>
                    )
                })}
                {academies.map(({ academyCode }, i) => {
                    const perm = groupAcademyPerms.get(academyCode)
                    const orig =
                        changedGroupOriginalAcademyGroupPermissions?.get(academyCode)
                    const isChanged = orig !== perm
                    const lastCol = i === lastAcademyIndex
                    const cellEmphasized =
                        emphasizeRow?.groupEmphasis ||
                        emphasized.academyCodes[academyCode]

                    return (
                        <Box
                            key={academyCode}
                            sx={{
                                backgroundColor: emphasizeGroup
                                    ? emphasizedRowBackgroundColor
                                    : cellEmphasized
                                    ? cellEmphasisColor
                                    : '#ffffff',
                                ...(emphasizeGroup ? { transition: transitionStr } : {}),
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderWidth:
                                    !academiesIsFirstBlock && i === 0
                                        ? `${topBorderWidth} 1px 1px 1px`
                                        : `${topBorderWidth} 1px 1px 0`,
                                marginRight:
                                    lastCol && academiesHasFollowingBlock ? '10px' : 0
                                // width: '46px'
                            }}
                        >
                            {/* {perm} */}
                            <NavGroupPermButton
                                perm={perm || 'none'}
                                isChanged={isChanged}
                                state={{
                                    groupId: group.id!,
                                    academy: academyCode
                                }}
                                onPermChanged={onGroupPermChanged}
                                emphasized={cellEmphasized}
                            />
                        </Box>
                    )
                })}
                {regions.map((rgn, i) => {
                    const perm = groupRegionPerms.get(rgn)
                    const orig = changedGroupOriginalRegionGroupPermissions?.get(rgn)
                    const isChanged = orig !== perm
                    const lastCol = i === lastRegionIndex
                    const cellEmphasized =
                        emphasizeRow?.groupEmphasis || emphasized.regions[rgn]

                    return (
                        <Box
                            key={rgn}
                            sx={{
                                backgroundColor: emphasizeGroup
                                    ? emphasizedRowBackgroundColor
                                    : cellEmphasized
                                    ? cellEmphasisColor
                                    : '#ffffff',
                                ...(emphasizeGroup ? { transition: transitionStr } : {}),
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderWidth:
                                    !regionsIsFirstBlock && i === 0
                                        ? `${topBorderWidth} 1px 1px 1px`
                                        : `${topBorderWidth} 1px 1px 0`,
                                marginRight: lastCol ? 0 : 0
                                // width: '46px'
                            }}
                        >
                            {/* {perm} */}
                            <NavGroupPermButton
                                perm={perm || 'none'}
                                isChanged={isChanged}
                                state={{ groupId: group.id!, region: rgn }}
                                onPermChanged={onGroupPermChanged}
                                emphasized={cellEmphasized}
                            />
                        </Box>
                    )
                })}
                <LinkRows
                    blockAdjacency={blockAdjacency}
                    key={`link:${group.id}/links`}
                    group={group}
                    links={links}
                    knownEmails={knownEmails}
                    jobFamilies={jobFamilies}
                    academies={academies}
                    regions={regions}
                    changedGroups={changedGroups}
                    changedGroup={changedGroup}
                    emphasized={emphasized}
                    selectedUserLookups={selectedUserLookups}
                    emphasizedRowBackgroundColor={emphasizedRowBackgroundColor}
                    onLinkClick={onLinkClick}
                    onGroupPermChanged={onGroupPermChanged}
                    onLinkPermChanged={onLinkPermChanged}
                    transitionStr={transitionStr}
                    permissionGroupId={permissionGroupId}
                    emphasizeGroup={emphasizeGroup}
                    lastKnownEmailIndex={lastKnownEmailIndex}
                    lastJobFamilyIndex={lastJobFamilyIndex}
                    lastAcademyIndex={lastAcademyIndex}
                    lastRegionIndex={lastRegionIndex}
                />
            </Fragment>
        )
    }
)

export type GroupRowsParams = {
    blockAdjacency: BlockAdjacency
    groups: GroupModel[]
    collapsedGroupExceptions: CollapsedGroupExceptions
    knownEmails: Store['knownEmails']
    jobFamilies: { prefix: string; suffix: string; text: string }[]
    academies: Store['academies']
    regions: string[]
    changedGroups: Record<string, GroupChange>
    permissionGroupId?: string
    emphasizedRowBackgroundColor: string
    transitionStr: string
    onGroupPermChanged: Parameters<typeof NavGroupPermButton>[0]['onPermChanged']
    onLinkPermChanged: Parameters<typeof NavGroupPermButton>[0]['onPermChanged']
    onClick?: (value: string) => void
    onLinkClick?: (groupId: string, linkUrl: string) => void
    onLinkFilterClicked?: (groupId?: string) => void
    emphasized: Emphasized
    selectedUserLookups: CurrentUserLookups
}
export const GroupRows = memo(
    ({
        blockAdjacency,
        groups,
        collapsedGroupExceptions,
        knownEmails,
        jobFamilies,
        academies,
        regions,
        changedGroups,
        permissionGroupId,
        emphasizedRowBackgroundColor,
        transitionStr,
        onGroupPermChanged,
        onLinkPermChanged,
        onClick,
        onLinkClick,
        onLinkFilterClicked,
        emphasized,
        selectedUserLookups
    }: GroupRowsParams) => {
        return (
            <>
                {groups.map((group, i) => {
                    const collapsedException: UnwrapRecordValue<CollapsedGroupExceptions> =
                        group.id
                            ? collapsedGroupExceptions[group.id] ||
                              defaultCollapsedGroupState
                            : defaultCollapsedGroupState

                    return (
                        <GroupRow
                            key={group.id}
                            rowIndex={i}
                            blockAdjacency={blockAdjacency}
                            group={{
                                ...group
                            }}
                            collapsedException={collapsedException}
                            knownEmails={knownEmails}
                            jobFamilies={jobFamilies}
                            academies={academies}
                            regions={regions}
                            changedGroups={changedGroups}
                            permissionGroupId={permissionGroupId}
                            emphasizedRowBackgroundColor={emphasizedRowBackgroundColor}
                            transitionStr={transitionStr}
                            onGroupPermChanged={onGroupPermChanged}
                            onLinkPermChanged={onLinkPermChanged}
                            onClick={onClick}
                            onLinkClick={onLinkClick}
                            onLinkFilterClicked={onLinkFilterClicked}
                            emphasized={emphasized}
                            selectedUserLookups={selectedUserLookups}
                        />
                    )
                })}
            </>
        )
    }
)
//     ,
//     (prevProps, newProps) => {
//         console.time('jsonPropsAreEqual')

//         // const changeablePropsPrev = [
//         //     prevProps.changedGroups,
//         //     prevProps.groups,
//         //     prevProps.permissionGroupId,
//         //     !!prevProps.jobFamilies,
//         //     !!prevProps.academies,
//         //     !!prevProps.regions
//         // ]
//         // const changeablePropsNew = [
//         //     newProps.changedGroups,
//         //     newProps.groups,
//         //     newProps.permissionGroupId,
//         //     !!newProps.jobFamilies,
//         //     !!newProps.academies,
//         //     !!newProps.regions
//         // ]

//         const timestamp_ = prevProps.timestamp === newProps.timestamp
//         log(
//             `🔴 timestamp_ = ${timestamp_} (${prevProps.timestamp} === ${newProps.timestamp})`
//         )

//         log(
//             `🔴1 changedGroups same instance? ${
//                 prevProps.changedGroups === newProps.changedGroups
//             }`
//         )
//         const changedGroups_ = isDeepEqual(
//             prevProps.changedGroups,
//             newProps.changedGroups
//         )
//         const groups_ = isDeepEqual(prevProps.groups, newProps.groups)
//         log(`🔴1 groups same instance? ${prevProps.groups === newProps.groups}`)
//         log(
//             `🔴 changedGroups_ = ${changedGroups_} (${json(
//                 prevProps.changedGroups
//             )} === ${json(newProps.changedGroups)})`
//         )
//         log(
//             `🔴🔴 groups_ = ${groups_} (${json(prevProps.groups)} === ${json(
//                 newProps.groups
//             )})`
//         )
//         // const deepEqual = isDeepEqual(changeablePropsPrev, changeablePropsNew)
//         console.timeEnd('jsonPropsAreEqual')
//         return changedGroups_ && groups_
//         // return deepEqual
//     }
// )
