/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import { Box, Typography } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
    deepClone,
    firstNonEmptyKeyValue,
    groupProject,
    isDeepEqual,
    makeLookup,
    makeMapLookup,
    maxProject,
    sortedInsertInPlace,
    venn
} from 'service/utils'
import shallow from 'zustand/shallow'

import { systemGroupNames } from 'appData/store/systemGroups'
import {
    PartialRecord,
    UnwrapRecordKey,
    UnwrapRecordValue
} from 'components/navbar/types'
import { useCustomTheme } from 'hooks/useCustomTheme'
import { AdHocPermissions } from 'pages/Permissions/PermissionsPage/AdHocPermissions'
import { ButtonBar } from 'pages/Permissions/PermissionsPage/ButtonBar'
import { ColumnHeaders } from 'pages/Permissions/PermissionsPage/ColumnHeaders'
import { GridSection } from 'pages/Permissions/PermissionsPage/GridSection'
import { GroupLinkFilterButton } from 'pages/Permissions/PermissionsPage/GroupLinkFilterButton'
import { PerAcademyPermissionRows } from 'pages/Permissions/PermissionsPage/PerAcademyPermissionRows'
import { PermissionsRows } from 'pages/Permissions/PermissionsPage/PermissionsRows'
import { knownEmailSorter } from 'service/controller/KnownEmail'
import { getAcademyTooltip } from 'service/model/AcademyModel'
import {
    AcademyGroupPermission,
    GroupModel,
    GroupPermission,
    JobFamilyGroupPermission,
    KnownEmailGroupPermission,
    RegionGroupPermission,
    requiredAcademyGroupPermissionSorter,
    requiredJobFamiliesGroupPermissionSorter,
    requiredKnownEmailsGroupPermissionSorter,
    requiredRegionGroupPermissionSorter
} from 'service/model/GroupModel'
import { LinkModel } from 'service/model/LinkModel'
import {
    PerAcademyPermission,
    PerAcademyPermissionSingletonModel,
    Permission,
    PermissionsSingletonModel,
    UsersSummarySingletonModel
} from 'service/model/SingletonsModel'
import { typedEntries } from 'utils/typedEntries'
import { Store, useStore } from '../../appData/store'
import { mapUserToUserSummaryModel } from '../../service/controller/User'
import { GroupRows } from './PermissionsPage/GroupRows'
import { TopLeftCell } from './PermissionsPage/TopLeftCell'

const makePermissionsLookup = (permissions: PermissionsSingletonModel['permissions']) => {
    return makeLookup(
        permissions,
        ({ name }) => name,
        p => p
    )
}

const makePerAcademyPermissionsLookup = (
    perAcademyPermissions: PerAcademyPermissionSingletonModel['perAcademyPermissions']
) => {
    const papl: PartialRecord<
        PerAcademyPermission,
        Record<
            string,
            PerAcademyPermissionSingletonModel['perAcademyPermissions'][0]['academyPermissions'][0]
        >
    > = makeLookup(
        perAcademyPermissions,
        ({ name }) => name,
        u =>
            makeLookup(
                u.academyPermissions,
                ({ academyCode }) => academyCode,
                ap => ap
            )
    )
    return papl
}

export type LinkChange = {
    originalLink: LinkModel
    originalKnownEmailPermissions: Map<string, GroupPermission>
    originalRegionPermissions: Map<string, GroupPermission>
    originalAcademyPermissions: Map<string, GroupPermission>
    originalJobFamilyPermissions: Map<string, GroupPermission>
}

export type GroupChange = {
    originalGroup: GroupModel
    originalKnownEmailPermissions: Map<string, GroupPermission>
    originalJobFamilyPermissions: Map<string, GroupPermission>
    originalAcademyPermissions: Map<string, GroupPermission>
    originalRegionPermissions: Map<string, GroupPermission>
    changedLinks: Record<string, LinkChange>
}

export type PermChange = {
    originalPerm: PermissionsSingletonModel['permissions'][0]
    originalKnownEmailRights: Set<string>
    originalJobFamilyRights: Set<string>
    originalAcademyRights: Set<string>
    originalRegionRights: Set<string>
}

export type PerAcademyPermChange = {
    originalPerm: PerAcademyPermissionSingletonModel['perAcademyPermissions'][0]['academyPermissions'][0]
    originalKnownEmailRights: Set<string>
    originalJobFamilyRights: Set<string>
    originalAcademyRights: Set<string>
    originalRegionRights: Set<string>
}

export const makeUserLookups = (
    user: UsersSummarySingletonModel['users'][0] | undefined
) => {
    return {
        knownEmailsSet: new Set(
            [user?.email, ...(user?.linkedEmails || [])].filter(x => !!x) as string[]
        ),
        jobFamilySet: new Set(user?.jobFamilies),
        academyCodeSet: new Set(user?.academyCodes),
        regionSet: new Set(user?.regions)
    }
}

export type PermXCoordinate = {
    knownEmail?: string
    jobFamily?: string
    academy?: string
    region?: string
}

export type PermYCoordinate = {
    groupId?: string
    linkUrl?: string
    permName?: string
    perAcademyPermission?: PerAcademyPermission
    papAcademyCode?: string
}

const nextGroupPermMap: Record<GroupPermission, GroupPermission> = {
    none: 'view',
    view: 'edit',
    edit: 'none'
}

export const nextGroupPerm = (perm: GroupPermission) => nextGroupPermMap[perm]

const jfSplitRegex = /([^]+) - (.+)/

export const cellEmphasisColor = '#b6fffd'

export type Emphasized = {
    groupIds: Record<
        string,
        {
            groupEmphasis: boolean
            linkUrls: Record<string, boolean>
        }
    >
    perAcademyPermissions: Record<PerAcademyPermission, Record<string, boolean>>
    permissionNames: Record<Permission, boolean>
    knownEmails: Record<string, boolean>
    jobFamilies: Record<string, boolean>
    academyCodes: Record<string, boolean>
    regions: Record<string, boolean>
}

const storeSelector = (store: Store) => ({
    setLoading: store.setLoading,
    groups: store.groups,
    permissions: store.permissions,
    perAcademyPermissions: store.perAcademyPermissions,
    academyCodeLookup: store.academyCodeLookup,
    regions: store.regions,
    academies: store.academies,
    jobFamilies: store.jobFamilies,
    userSummary: store.userSummary,
    bulkUpdateGroupPermissions: store.bulkUpdateGroupPermissions,
    updatePerms: store.updatePerms,
    updatePerAcademyPermissions: store.updatePerAcademyPermissions,
    userHasPermission: store.userHasPermission,
    currentUser: store.currentUser,
    knownEmails: store.knownEmails,
    updateKnownEmails: store.updateKnownEmails,
    permissionsPageFilters: store.permissionsPageFilters,
    setPermissionsPageFilters: store.setPermissionsPageFilters,
    getKnownEmailPermTargets: store.getKnownEmailPermTargets
})

type GridCoordinate = (PermYCoordinate & PermXCoordinate) | undefined

type Filtered = {
    knownEmails: Store['knownEmails']
    jobFamilies: { prefix: string; suffix: string; text: string }[]
    academies: Store['academies']
    regions: Store['regions']
}

export type CurrentUserLookups = ReturnType<typeof makeUserLookups>

export type CollapsedGroupExceptions = Record<string, 'open' | 'show_active' | 'closed'>
export type CollapsedPerAcademyPermissionExceptions = Record<
    PerAcademyPermission,
    | 'open'
    // | 'show_active'
    | 'closed'
>

export const defaultCollapsedGroupState: UnwrapRecordValue<CollapsedGroupExceptions> =
    'closed'

export const defaultCollapsedPerAcademyPermissionState: UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions> =
    'closed'

export type HorizontalBlock = {
    name: keyof PermXCoordinate
    maxIndex: number
    findIndex: (value: string) => number
    forEach: (callback: (value: string, index: number) => void) => void
    isFirstBlock?: boolean
    hasFollowingBlock?: boolean
}

export type BlockAdjacency = {
    knownEmailsHasFollowingBlock: boolean
    jobFamiliesIsFirstBlock: boolean
    jobFamiliesHasFollowingBlock: boolean
    academiesHasFollowingBlock: boolean
    academiesIsFirstBlock: boolean
    regionsIsFirstBlock: boolean
}

export const PermissionsPage = () => {
    const {
        setLoading,
        groups: storeGroups,
        permissions: storePermissions,
        perAcademyPermissions: storePerAcademyPermissions,
        academyCodeLookup,
        regions,
        academies,
        jobFamilies: storeJobFamilies,
        userSummary,
        bulkUpdateGroupPermissions,
        updatePerms,
        updatePerAcademyPermissions,
        userHasPermission,
        currentUser,
        knownEmails,
        updateKnownEmails,
        permissionsPageFilters,
        setPermissionsPageFilters,
        getKnownEmailPermTargets
    } = useStore(storeSelector, shallow)

    const { theme } = useCustomTheme()

    const [jobFamilies, setJobFamilies] = useState<
        { prefix: string; suffix: string; text: string }[]
    >([])

    const [filtered, setFiltered] = useState<Filtered>({
        knownEmails,
        jobFamilies,
        academies,
        regions
    })

    const lastClick = useRef<
        {
            groupId?: string
            linkUrl?: string
            permName?: string
            perAcademyPermission?: PerAcademyPermission
            papAcademyCode?: string
        } & PermXCoordinate
    >()

    const [longestHeader, setLongestHeader] = useState<string>('')

    useEffect(() => {
        if (storeJobFamilies) {
            // console.time('body of useEffect')
            const splitJobFamilies = storeJobFamilies.map(jf => {
                const m = jfSplitRegex.exec(jf)
                if (m && m.length === 3) {
                    const prefix = m[1]
                    const suffix = m[2]
                    return { prefix, suffix, text: m[0] }
                }

                return { prefix: 'Uncategorized', suffix: jf, text: jf }
                throw new Error(
                    `Unexpected Job Family '${jf}' - expected <prefix> - <suffix>`
                )
            })
            // console.timeEnd('map jfs')
            // console.timeEnd('body of useEffect')

            setJobFamilies(splitJobFamilies)
        }
    }, [academies, regions, storeJobFamilies])

    useEffect(() => {
        if (
            filtered.knownEmails &&
            filtered.jobFamilies &&
            filtered.academies &&
            filtered.regions
        ) {
            // console.time('getLongestHeader')
            // console.time('getLongestHeader.headers')
            const headers = [
                ...filtered.knownEmails,
                ...filtered.jobFamilies.map(({ suffix }) => suffix),
                ...filtered.academies.map(({ academy }) => academy),
                ...filtered.regions
            ]
            // console.timeEnd('getLongestHeader.headers')
            // console.time('getLongestHeader.calc')
            setLongestHeader(
                headers.reduce((acc, h) => (h.length > acc.length ? h : acc), '')
            )
            // console.timeEnd('getLongestHeader.calc')
            // console.timeEnd('getLongestHeader')
        }
    }, [filtered.academies, filtered.jobFamilies, filtered.knownEmails, filtered.regions])

    useEffect(() => {
        // log(`💥 longestHeader changed - setting rect to undefined`)
        setRect(undefined)
    }, [longestHeader])

    const [rect, setRect] = useState<DOMRect | undefined>()
    const measureNodeRef = useCallback((node: HTMLElement) => {
        // log(
        //     `_🌼🌼🌼🌼🌼🌼🌼🌼 useMeasureNode - node is set?: ${!!node} - innerHTML: ${
        //         node?.innerHTML
        //     }`
        // )
        if (node) {
            const cr = node.getBoundingClientRect()
            // log(
            //     `_🌼🌼🌼🌼🌼🌼🌼🌼 useMeasureNode - node is set?: ${!!node} - cr: ${json(
            //         cr
            //     )}`
            // )
            setRect(cr)
        }
    }, [])

    const [groups, setGroups] = useState<GroupModel[]>([])
    const [selectedUser, setSelectedUser] = useState<
        UsersSummarySingletonModel['users'][0] | undefined
    >(currentUser ? mapUserToUserSummaryModel(currentUser?.user) : undefined)

    const [currentUserLookups, setCurrentUserLookups] = useState<CurrentUserLookups>()
    const selectedUserLookups = useRef<CurrentUserLookups>()

    const doSetSelectedUser = useCallback((user: typeof selectedUser) => {
        selectedUserLookups.current = makeUserLookups(user)
        setSelectedUser(user)
    }, [])

    const [permissions, setPermissions] = useState<
        PermissionsSingletonModel['permissions']
    >([])
    const permissionLookup =
        useRef<Record<string, PermissionsSingletonModel['permissions'][0]>>()

    const [perAcademyPermissions, setPerAcademyPermissions] = useState<
        PerAcademyPermissionSingletonModel['perAcademyPermissions']
    >([])
    const perAcademyPermissionLookup =
        useRef<
            PartialRecord<
                PerAcademyPermission,
                Record<
                    string,
                    PerAcademyPermissionSingletonModel['perAcademyPermissions'][0]['academyPermissions'][0]
                >
            >
        >()

    const initialiseGroups = useCallback(() => {
        const nonHiddenGroups = storeGroups.filter(
            g => g.name !== systemGroupNames.__userLinks
        )
        const clonedGroups = deepClone(nonHiddenGroups)

        changedGroups.current = {}

        setGroups(clonedGroups)
    }, [storeGroups])

    const initialisePerms = useCallback(() => {
        const clonedPermissions = deepClone(storePermissions)

        changedPerms.current = {}

        permissionLookup.current = makePermissionsLookup(clonedPermissions)

        setPermissions(clonedPermissions)
    }, [storePermissions])

    const initialisePerAcademyPerms = useCallback(() => {
        const clonedPerAcademyPermissions = deepClone(storePerAcademyPermissions)

        changedPerAcademyPerms.current = {}

        perAcademyPermissionLookup.current = makePerAcademyPermissionsLookup(
            clonedPerAcademyPermissions
        )

        setPerAcademyPermissions(clonedPerAcademyPermissions)
    }, [storePerAcademyPermissions])

    const initialise = useCallback(() => {
        initialiseGroups()
        initialisePerms()
        initialisePerAcademyPerms()
        setSettingsMessages([])
    }, [initialiseGroups, initialisePerms, initialisePerAcademyPerms])

    const userPathsToPerm = useCallback(
        (
            lookups: NonNullable<CurrentUserLookups>,
            permission: PermissionsSingletonModel['permissions'][0]
        ) => {
            const {
                requiredKnownEmails,
                requiredJobFamilies,
                requiredAcademies,
                requiredRegions
            } = permission

            const knownEmail =
                requiredKnownEmails?.filter(rke => lookups.knownEmailsSet.has(rke)) || []

            const jobFamily =
                requiredJobFamilies?.filter(rjf => lookups.jobFamilySet.has(rjf)) || []

            const acdms =
                requiredAcademies?.filter(rac => lookups.academyCodeSet.has(rac)) || []

            const rgns = requiredRegions?.filter(rr => lookups.regionSet.has(rr)) || []

            const paths = {
                knownEmail,
                jobFamily,
                academies: acdms,
                regions: rgns,
                length: knownEmail.length + jobFamily.length + acdms.length + rgns.length
            }
            return paths
        },
        []
    )

    const knownEmailColumnHeaderDataExtractor = useCallback(
        (email: Filtered['knownEmails'][0]) => {
            const key = email
            const name = email
            const selectedUserHasItem =
                !!selectedUserLookups.current?.knownEmailsSet?.has(email)

            const markerTitle = selectedUserHasItem
                ? `${selectedUser?.name} has this email`
                : ''

            const data = {
                key,
                name,
                markerTitle,
                selectedUserHasItem
            }
            return data
        },
        [selectedUser?.name]
    )
    const jobFamilyColumnHeaderDataExtractor = useCallback(
        ({ suffix, text }: Filtered['jobFamilies'][0]) => {
            const key = text
            const name = suffix
            const title = text
            const selectedUserHasItem =
                !!selectedUserLookups.current?.jobFamilySet?.has(text)

            const markerTitle = selectedUserHasItem
                ? `${selectedUser?.name} has this jobFamily`
                : ''

            const data = { key, name, title, markerTitle, selectedUserHasItem }
            return data
        },
        [selectedUser?.name]
    )
    const academyColumnHeaderDataExtractor = useCallback(
        (academy: Filtered['academies'][0]) => {
            const { academyCode, academy: academyName } = academy
            const key = academyCode
            const title = getAcademyTooltip(academy)
            const selectedUserHasItem =
                !!selectedUserLookups.current?.academyCodeSet?.has(academyCode)
            const markerTitle = selectedUserHasItem
                ? `${selectedUser?.name} is associated with this academy`
                : ''

            const data = {
                key,
                name: academyName,
                title,
                markerTitle,
                selectedUserHasItem
            }
            return data
        },
        [selectedUser?.name]
    )
    const regionColumnHeaderDataExtractor = useCallback(
        (region: Filtered['regions'][0]) => {
            const key = region
            const name = region
            const selectedUserHasItem =
                !!selectedUserLookups.current?.regionSet?.has(region)

            const markerTitle = selectedUserHasItem
                ? `${selectedUser?.name} has an academy in this region`
                : ''

            const data = { key, name, markerTitle, selectedUserHasItem }
            return data
        },
        [selectedUser?.name]
    )
    const saveChanges = useCallback(async () => {
        const stagedGroupChanges = groups
            .map(
                ({
                    id,
                    requiredKnownEmails,
                    requiredJobFamilies,
                    requiredAcademies,
                    requiredRegions,
                    links
                }) =>
                    changedGroups.current[id!]
                        ? {
                              id: id!,
                              requiredKnownEmails: requiredKnownEmails || [],
                              requiredJobFamilies: requiredJobFamilies || [],
                              requiredAcademies: requiredAcademies || [],
                              requiredRegions: requiredRegions || [],
                              linkPerms: links?.map(link => ({
                                  requiredKnownEmails: link.requiredKnownEmails || [],
                                  requiredJobFamilies: link.requiredJobFamilies || [],
                                  requiredAcademies: link.requiredAcademies || [],
                                  requiredRegions: link.requiredRegions || []
                              }))
                          }
                        : undefined
            )
            .filter(x => !!x) as {
            id: string
            requiredKnownEmails: KnownEmailGroupPermission[]
            requiredJobFamilies: JobFamilyGroupPermission[]
            requiredAcademies: AcademyGroupPermission[]
            requiredRegions: RegionGroupPermission[]
            linkPerms: {
                requiredKnownEmails: KnownEmailGroupPermission[]
                requiredJobFamilies: JobFamilyGroupPermission[]
                requiredAcademies: AcademyGroupPermission[]
                requiredRegions: RegionGroupPermission[]
            }[]
        }[]
        // log(`💥💥💥 stagedGroupChanges: ${json(stagedGroupChanges)}`)

        const stagedPermChanges = permissions
            .map(
                ({
                    name,
                    requiredKnownEmails,
                    requiredJobFamilies,
                    requiredAcademies,
                    requiredRegions
                }) =>
                    changedPerms.current[name]
                        ? {
                              name,
                              requiredKnownEmails: requiredKnownEmails || [],
                              requiredJobFamilies: requiredJobFamilies || [],
                              requiredAcademies: requiredAcademies || [],
                              requiredRegions: requiredRegions || []
                          }
                        : undefined
            )
            .filter(x => !!x) as {
            name: Permission
            requiredKnownEmails: string[]
            requiredJobFamilies: string[]
            requiredAcademies: string[]
            requiredRegions: string[]
        }[]
        // log(`💥💥💥 stagedPermChanges: ${json(stagedPermChanges)}`)

        const stagedPerAcademyPermChanges = perAcademyPermissions
            .map(({ name, academyPermissions }) => {
                const acLookup = changedPerAcademyPerms.current[name]
                if (acLookup) {
                    return {
                        name,
                        academyPermissions: academyPermissions.map(ap => {
                            const {
                                academyCode,
                                requiredKnownEmails,
                                requiredJobFamilies,
                                requiredAcademies,
                                requiredRegions
                            } = ap

                            return acLookup[academyCode]
                                ? {
                                      academyCode,
                                      requiredKnownEmails: requiredKnownEmails || [],
                                      requiredJobFamilies: requiredJobFamilies || [],
                                      requiredAcademies: requiredAcademies || [],
                                      requiredRegions: requiredRegions || []
                                  }
                                : ap
                        })
                    }
                }
                return undefined
            })
            .filter(x => !!x) as {
            name: PerAcademyPermission
            academyPermissions: {
                academyCode: string
                requiredKnownEmails: string[]
                requiredJobFamilies: string[]
                requiredAcademies: string[]
                requiredRegions: string[]
            }[]
        }[]
        // log(`💥💥💥 stagedPerAcademyPermChanges: ${json(stagedPerAcademyPermChanges)}`)
        setLoading(true)

        await bulkUpdateGroupPermissions(stagedGroupChanges)
        await updatePerms(stagedPermChanges)
        await updatePerAcademyPermissions(stagedPerAcademyPermChanges)

        setLoading(false)
        setSettingsMessages([])
    }, [
        bulkUpdateGroupPermissions,
        groups,
        perAcademyPermissions,
        permissions,
        setLoading,
        updatePerAcademyPermissions,
        updatePerms
    ])

    useEffect(() => {
        const mappedCurrentUser = currentUser
            ? mapUserToUserSummaryModel(currentUser?.user)
            : undefined

        doSetSelectedUser(mappedCurrentUser)
        const cul = makeUserLookups(mappedCurrentUser)

        setCurrentUserLookups(cul)
    }, [currentUser, doSetSelectedUser])

    useEffect(() => {
        initialiseGroups()
    }, [initialiseGroups, storeGroups])

    useEffect(() => {
        initialisePerms()
    }, [initialisePerms, storePermissions])

    useEffect(() => {
        initialisePerAcademyPerms()
    }, [initialisePerAcademyPerms, storePerAcademyPermissions])

    const changedGroups = useRef<Record<string, GroupChange>>({})
    const changedPerms = useRef<Record<string, PermChange>>({})
    const changedPerAcademyPerms = useRef<
        PartialRecord<PerAcademyPermission, Record<string, PerAcademyPermChange>>
    >({})

    const [collapsedGroupExceptions, setCollapsedGroupExceptions] =
        useState<CollapsedGroupExceptions>({})

    const [
        collapsedPerAcademyPermissionExceptions,
        setCollapsedPerAcademyPermissionExceptions
    ] = useState<CollapsedPerAcademyPermissionExceptions>(
        {} as CollapsedPerAcademyPermissionExceptions
    )

    const allGroupsFilterButtonState = useRef<
        UnwrapRecordValue<CollapsedGroupExceptions>
    >(defaultCollapsedGroupState)

    const location = useLocation()
    const [permissionGroupId, setPermissionGroupId] = useState<string | undefined>()

    const [emphasized, setEmphasized] = useState<Emphasized>({
        groupIds: {},
        perAcademyPermissions: {} as Record<
            PerAcademyPermission,
            Record<string, boolean>
        >,
        permissionNames: {} as Record<Permission, boolean>,
        knownEmails: {},
        jobFamilies: {},
        academyCodes: {},
        regions: {}
    })

    const _toggleItemsInclusion = <T extends string>(
        hash: Record<string, boolean>,
        items?: T[]
    ) => {
        if (items) {
            items.forEach(item => {
                if (hash[item]) {
                    // eslint-disable-next-line no-param-reassign
                    delete hash[item]
                } else {
                    // eslint-disable-next-line no-param-reassign
                    hash[item] = true
                }
            })
        }
    }

    const toggleEmphasized = useCallback(
        (
            targets: Partial<{
                [key in keyof Omit<
                    Emphasized,
                    'groupIds' | 'perAcademyPermissionsssss'
                >]: UnwrapRecordKey<Emphasized[key]>[]
            }>
        ) => {
            const updatedEmphasized = deepClone(emphasized)

            _toggleItemsInclusion(
                updatedEmphasized.permissionNames,
                targets.permissionNames
            )
            _toggleItemsInclusion(updatedEmphasized.knownEmails, targets.knownEmails)
            _toggleItemsInclusion(updatedEmphasized.jobFamilies, targets.jobFamilies)
            _toggleItemsInclusion(updatedEmphasized.academyCodes, targets.academyCodes)
            _toggleItemsInclusion(updatedEmphasized.regions, targets.regions)

            setEmphasized(updatedEmphasized)
        },
        [emphasized]
    )

    const _toggleEmphasizedLinksInclusion = (
        hash: Emphasized['groupIds'],
        items?: Emphasized['groupIds']
    ) => {
        if (items) {
            typedEntries(items).forEach(([groupId, { groupEmphasis, linkUrls }]) => {
                // if there are no linkUrls - groupEmphasis === true
                // -> we're only toggling the stored groupEmphasis for this group

                // if there are linkUrls and groupEmphasis === false
                // -> we're toggling the urls in the linkUrls for this group

                // after the operation, whatever it is, if there are no links and the groupEmphasis
                // is false, we may remove the record for the group

                let existing = hash[groupId]
                if (!existing) {
                    // eslint-disable-next-line no-param-reassign
                    hash[groupId] = { groupEmphasis: false, linkUrls: {} }
                    existing = hash[groupId]
                }
                if (groupEmphasis) {
                    existing.groupEmphasis = !existing.groupEmphasis
                } else {
                    typedEntries(linkUrls).forEach(([url, _]) => {
                        if (existing.linkUrls[url]) {
                            delete existing.linkUrls[url]
                        } else {
                            existing.linkUrls[url] = true
                        }
                    })
                }
                if (
                    !existing.groupEmphasis &&
                    Object.keys(existing.linkUrls).length === 0
                ) {
                    // eslint-disable-next-line no-param-reassign
                    delete hash[groupId]
                }
            })
        }
    }

    const _toggleEmphasizedPerAcademyPermissionsInclusion = (
        hash: Emphasized['perAcademyPermissions'],
        items?: Emphasized['perAcademyPermissions']
    ) => {
        if (items) {
            typedEntries(items).forEach(([pap, ap]) => {
                let existing = hash[pap]
                typedEntries(ap).forEach(([academyCode, _include]) => {
                    if (existing) {
                        const academyCodes = hash[pap]
                        if (academyCodes[academyCode]) {
                            delete academyCodes[academyCode]
                        } else {
                            academyCodes[academyCode] = true
                        }
                    } else {
                        // eslint-disable-next-line no-param-reassign
                        hash[pap] = { academyCode: true }
                        existing = hash[pap]
                    }
                })
                if (Object.keys(existing).length === 0) {
                    // eslint-disable-next-line no-param-reassign
                    delete (hash as Record<string, Record<string, boolean>>)[
                        pap as string
                    ]
                }
            })
        }
    }

    const doToggleEmphasizedGroupLinks = useCallback(
        (targets: Emphasized['groupIds']) => {
            const updatedEmphasized = deepClone(emphasized)

            _toggleEmphasizedLinksInclusion(updatedEmphasized.groupIds, targets)

            setEmphasized(updatedEmphasized)
        },
        [emphasized]
    )

    const toggleEmphasizedGroupLink = useCallback(
        (groupId: string, linkUrl: string) => {
            doToggleEmphasizedGroupLinks({
                [groupId]: {
                    groupEmphasis: false,
                    linkUrls: { [linkUrl]: true }
                }
            })
        },
        [doToggleEmphasizedGroupLinks]
    )

    const doToggleEmphasizedPerAcademyPermissions = useCallback(
        (targets: Emphasized['perAcademyPermissions']) => {
            const updatedEmphasized = deepClone(emphasized)

            _toggleEmphasizedPerAcademyPermissionsInclusion(
                updatedEmphasized.perAcademyPermissions,
                targets
            )

            setEmphasized(updatedEmphasized)
        },
        [emphasized]
    )

    const toggleEmphasizedPerAcademyPermission = useCallback(
        (perAcademyPermission: PerAcademyPermission, papAcademyCode: string) => {
            doToggleEmphasizedPerAcademyPermissions({
                [perAcademyPermission]: {
                    [papAcademyCode]: true
                }
            })
        },
        [doToggleEmphasizedPerAcademyPermissions]
    )

    const doCycleLinkFilter = useCallback(
        (cge: UnwrapRecordValue<CollapsedGroupExceptions>) => {
            const nextCge = (
                {
                    closed: 'show_active',
                    show_active: 'open',
                    open: 'closed'
                } as Record<
                    UnwrapRecordValue<CollapsedGroupExceptions>,
                    UnwrapRecordValue<CollapsedGroupExceptions>
                >
            )[cge]
            return nextCge
        },
        []
    )

    const doCyclePerAcademyGroupAcademyFilter = useCallback(
        (cge: UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions>) => {
            const nextCge = (
                {
                    closed: 'open',
                    open: 'closed'
                } as Record<
                    UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions>,
                    UnwrapRecordValue<CollapsedPerAcademyPermissionExceptions>
                >
            )[cge]
            return nextCge
        },
        []
    )

    const suggestAllGroupsFilterButtonState = useCallback(
        (cges: Record<string, UnwrapRecordValue<CollapsedGroupExceptions>>) => {
            const icges = groups.reduce(
                (acc, { id: groupId }) => ({
                    ...acc,
                    [groupId!]: cges[groupId!] || defaultCollapsedGroupState
                }),
                {} as Record<string, UnwrapRecordValue<CollapsedGroupExceptions>>
            )

            const groupsByStatus = groupProject(
                typedEntries(icges),
                ([_k, v]) => v,
                ([k, _v]) => k
            )
            const counts_ = typedEntries(groupsByStatus)

            const counts = counts_.reduce(
                (acc, [state, groupIds]) => [...acc, { state, count: groupIds.length }],
                [] as {
                    state: UnwrapRecordValue<CollapsedGroupExceptions>
                    count: number
                }[]
            )

            const max = maxProject(counts, ({ count }) => count)
            const suggested = max?.state || defaultCollapsedGroupState

            return suggested
        },
        [groups]
    )
    const onGroupLinkFilterClicked = useCallback(
        (groupId?: string) => {
            if (groupId) {
                setCollapsedGroupExceptions(cges => {
                    const cge = cges[groupId] ?? defaultCollapsedGroupState
                    const nextCge = doCycleLinkFilter(cge)

                    const newCges =
                        nextCge !== defaultCollapsedGroupState
                            ? { ...cges, [groupId]: nextCge }
                            : typedEntries(cges).reduce(
                                  (acc, [gid, ccge]) =>
                                      gid !== groupId ? { ...acc, [gid]: ccge } : acc,
                                  {}
                              )

                    allGroupsFilterButtonState.current =
                        suggestAllGroupsFilterButtonState(newCges)

                    return newCges
                })
            }
        },
        [doCycleLinkFilter, suggestAllGroupsFilterButtonState]
    )

    const onAllGroupsLinkFilterClicked = useCallback(() => {
        const cge = allGroupsFilterButtonState.current ?? defaultCollapsedGroupState
        const nextCge = doCycleLinkFilter(cge)

        allGroupsFilterButtonState.current = nextCge
        const cges = groups.reduce(
            (acc, { id: groupId }) => ({ ...acc, [groupId!]: nextCge }),
            {}
        )

        setCollapsedGroupExceptions(cges)
    }, [doCycleLinkFilter, groups])

    const onPerAcademyPermissionsAcademyFilterClicked = useCallback(
        (perAcademyPermission: PerAcademyPermission) => {
            if (perAcademyPermission) {
                setCollapsedPerAcademyPermissionExceptions(cges => {
                    const cge =
                        cges[perAcademyPermission] ??
                        defaultCollapsedPerAcademyPermissionState
                    const nextCge = doCyclePerAcademyGroupAcademyFilter(cge)

                    const newCges =
                        nextCge !== defaultCollapsedPerAcademyPermissionState
                            ? { ...cges, [perAcademyPermission]: nextCge }
                            : typedEntries(cges).reduce(
                                  (acc, [gid, ccge]) =>
                                      gid !== perAcademyPermission
                                          ? { ...acc, [gid]: ccge }
                                          : acc,
                                  {} as CollapsedPerAcademyPermissionExceptions
                              )

                    // allGroupsFilterButtonState.current =
                    //     suggestAllGroupsFilterButtonState(newCges)

                    return newCges
                })
            }
        },
        [doCyclePerAcademyGroupAcademyFilter]
    )

    const toggleEmphasizedGroup = useCallback(
        (groupId: string) => {
            doToggleEmphasizedGroupLinks({
                [groupId]: {
                    groupEmphasis: true,
                    linkUrls: {}
                }
            })
        },
        [doToggleEmphasizedGroupLinks]
    )

    const toggleEmphasizedPermissions = useCallback(
        (value: Permission) => {
            toggleEmphasized({ permissionNames: [value] })
        },
        [toggleEmphasized]
    )

    const toggleEmphasizedKnownEmail = useCallback(
        (value: string) => {
            toggleEmphasized({ knownEmails: [value] })
        },
        [toggleEmphasized]
    )

    const toggleEmphasizedJobFamily = useCallback(
        (value: string) => {
            toggleEmphasized({ jobFamilies: [value] })
        },
        [toggleEmphasized]
    )

    const toggleEmphasizedAcademies = useCallback(
        (value: string) => {
            toggleEmphasized({ academyCodes: [value] })
        },
        [toggleEmphasized]
    )

    const toggleEmphasizedRegions = useCallback(
        (value: string) => {
            toggleEmphasized({ regions: [value] })
        },
        [toggleEmphasized]
    )

    useEffect(() => {
        if (permissionGroupId) {
            // trigger the row-hilight fade after 500ms
            setTimeout(() => {
                setPermissionGroupId(undefined)
            }, 500)
        }
    }, [permissionGroupId])

    useEffect(() => {
        const newValue = (location?.state as { permissionsGroupId: string })
            ?.permissionsGroupId
        setPermissionGroupId(newValue)
    }, [location?.state])

    const selectedUserChanged = useCallback(
        (event: React.SyntheticEvent, value: Store['userSummary'][0] | null) => {
            doSetSelectedUser(value ?? undefined)
        },
        [doSetSelectedUser]
    )

    const [sharedFilter, setSharedFilter] = useState(true)
    const onSharedFilterChanged = useCallback(
        (sharedFilters: boolean) => {
            setSharedFilter(sharedFilters)
        },
        [setSharedFilter]
    )

    useEffect(() => {
        const regexes = typedEntries(permissionsPageFilters.text).reduce(
            (acc, [k, v]) => {
                let re = /.*/
                try {
                    re = new RegExp(v.replaceAll(',', '|'), 'i')
                } catch (ex) {
                    // eat error
                }
                return {
                    ...acc,
                    [k]: re
                }
            },
            {} as Record<keyof typeof permissionsPageFilters['text'], RegExp>
        )
        const { visibility } = permissionsPageFilters
        const { knownEmail, jobFamily, academy, region } = regexes

        setFiltered({
            knownEmails: !visibility.knownEmail
                ? []
                : knownEmails?.filter(ke => {
                      try {
                          return knownEmail.test(ke)
                      } catch (ex) {
                          return false
                      }
                  }),
            jobFamilies: !visibility.jobFamily
                ? []
                : jobFamilies?.filter(({ text }) => {
                      try {
                          return jobFamily.test(text)
                      } catch (ex) {
                          return false
                      }
                  }),
            academies: !visibility.academy
                ? []
                : academies?.filter(({ academy: academyName }) => {
                      try {
                          return academy.test(academyName)
                      } catch (ex) {
                          return false
                      }
                  }),
            regions: !visibility.region
                ? []
                : regions?.filter(r => {
                      try {
                          return region.test(r)
                      } catch (ex) {
                          return false
                      }
                  })
        })
    }, [academies, jobFamilies, regions, permissionsPageFilters, knownEmails])

    const emphasizedRowBackgroundColor = permissionGroupId ? cellEmphasisColor : '#ffffff'

    const knownEmailCount = filtered.knownEmails.length
    const jobFamilyCount = filtered.jobFamilies.length
    const academyCount = filtered.academies.length
    const regionCount = filtered.regions.length

    const assocColCount = knownEmailCount + jobFamilyCount + academyCount + regionCount
    const assocRowCount = groups.length + permissions.length

    const markerHeight = 10
    const headerHeight = Math.max(
        // sharedFilter ? 0 : 444,
        200, // enough to show User drop-down, shared column filter and checkbox to toggle 'shared'
        rect ? Math.round(rect.width) + 20 + markerHeight : 0
    )
    // log(
    //     `1️⃣ headerHeight: ${headerHeight} | rect: (${rect?.x}, ${rect?.y}) (${rect?.width} x ${rect?.height})`
    // )
    const gridSectionTop = headerHeight
    const gridSectionHeight = 36
    const gridSectionNestedTop = gridSectionTop + gridSectionHeight
    const transitionStr = `background ${0.4}s ease-in`

    const [_rerenderHackCounter, setRerenderHackCounter] = useState(0)
    const rerenderHack = () => setRerenderHackCounter(current => current + 1)

    const ensureChangedGroup = useCallback((group: GroupModel) => {
        if (!group.id) {
            throw new Error('Group should have an id')
        }
        if (!changedGroups.current[group.id]) {
            const clonedGroup = deepClone(group)

            changedGroups.current = {
                ...changedGroups.current,
                [group.id]: {
                    originalGroup: {
                        ...clonedGroup,
                        ...(clonedGroup.requiredKnownEmails
                            ? {
                                  requiredKnownEmails:
                                      clonedGroup.requiredKnownEmails?.sort(
                                          requiredKnownEmailsGroupPermissionSorter
                                      )
                              }
                            : {}),
                        ...(clonedGroup.requiredJobFamilies
                            ? {
                                  requiredJobFamilies:
                                      clonedGroup.requiredJobFamilies?.sort(
                                          requiredJobFamiliesGroupPermissionSorter
                                      )
                              }
                            : {}),
                        ...(clonedGroup.requiredAcademies
                            ? {
                                  requiredAcademies: clonedGroup.requiredAcademies?.sort(
                                      requiredAcademyGroupPermissionSorter
                                  )
                              }
                            : {}),
                        ...(clonedGroup.requiredRegions
                            ? {
                                  requiredRegions: clonedGroup.requiredRegions?.sort(
                                      requiredRegionGroupPermissionSorter
                                  )
                              }
                            : {})
                    },
                    originalKnownEmailPermissions: makeMapLookup(
                        clonedGroup.requiredKnownEmails || [],
                        ({ email }) => email,
                        ({ permission }) => permission
                    ),
                    originalJobFamilyPermissions: makeMapLookup(
                        clonedGroup.requiredJobFamilies || [],
                        ({ name }) => name,
                        ({ permission }) => permission
                    ),
                    originalAcademyPermissions: makeMapLookup(
                        clonedGroup.requiredAcademies || [],
                        ({ academyCode: ac }) => ac,
                        ({ permission }) => permission
                    ),
                    originalRegionPermissions: makeMapLookup(
                        clonedGroup.requiredRegions || [],
                        ({ regionName }) => regionName,
                        ({ permission }) => permission
                    )
                } as GroupChange
            }
        }
    }, [])

    const ensureChangedLink = useCallback((group: GroupModel, linkUrl: string) => {
        if (!group.id) {
            throw new Error('Group should have an id')
        }
        const changedGroup = changedGroups.current[group.id]
        if (!changedGroup) {
            throw new Error('There should be a changedGroups entry for this group')
        }
        if (!changedGroup.changedLinks?.[linkUrl]) {
            const origLink = changedGroup.originalGroup.links.find(
                ({ url }) => url === linkUrl
            )
            if (!origLink) {
                throw new Error('origLink should exist')
            }
            const clonedLink = deepClone(origLink)
            changedGroup.changedLinks = {
                ...changedGroup.changedLinks,
                [linkUrl]: {
                    originalLink: {
                        ...clonedLink,
                        ...(clonedLink?.requiredKnownEmails
                            ? {
                                  requiredKnownEmails:
                                      clonedLink.requiredKnownEmails?.sort(
                                          requiredKnownEmailsGroupPermissionSorter
                                      )
                              }
                            : {}),
                        ...(clonedLink?.requiredRegions
                            ? {
                                  requiredRegions: clonedLink.requiredRegions?.sort(
                                      requiredRegionGroupPermissionSorter
                                  )
                              }
                            : {}),
                        ...(clonedLink?.requiredAcademies
                            ? {
                                  requiredAcademies: clonedLink.requiredAcademies?.sort(
                                      requiredAcademyGroupPermissionSorter
                                  )
                              }
                            : {}),
                        ...(clonedLink?.requiredJobFamilies
                            ? {
                                  requiredJobFamilies:
                                      clonedLink.requiredJobFamilies?.sort(
                                          requiredJobFamiliesGroupPermissionSorter
                                      )
                              }
                            : {})
                    },
                    originalKnownEmailPermissions: makeMapLookup(
                        clonedLink?.requiredKnownEmails || [],
                        ({ email }) => email,
                        ({ permission }) => permission
                    ),
                    originalJobFamilyPermissions: makeMapLookup(
                        clonedLink?.requiredJobFamilies || [],
                        ({ name }) => name,
                        ({ permission }) => permission
                    ),
                    originalAcademyPermissions: makeMapLookup(
                        clonedLink?.requiredAcademies || [],
                        ({ academyCode: ac }) => ac,
                        ({ permission }) => permission
                    ),
                    originalRegionPermissions: makeMapLookup(
                        clonedLink?.requiredRegions || [],
                        ({ regionName }) => regionName,
                        ({ permission }) => permission
                    )
                }
            }
        }
    }, [])

    const normalizeEmptyGroupChanges = useCallback((group: GroupModel) => {
        const origGroup = changedGroups.current[group.id!]?.originalGroup
        const origGroupSelector = [
            origGroup.id,
            origGroup.requiredKnownEmails || [],
            origGroup.requiredJobFamilies || [],
            origGroup.requiredAcademies || [],
            origGroup.requiredRegions || [],
            origGroup.links || []
        ] as const
        const groupSelector = [
            group.id,
            group.requiredKnownEmails || [],
            group.requiredJobFamilies || [],
            group.requiredAcademies || [],
            group.requiredRegions || [],
            group.links || []
        ] as const
        const isSameAsOrig = isDeepEqual(origGroupSelector, groupSelector)

        if (isSameAsOrig) {
            changedGroups.current = Object.entries(changedGroups.current)
                .filter(([k, _v]) => k !== group.id!)
                .reduce(
                    (acc, [k, v]) => ({ ...acc, [k]: v }),
                    {} as Record<string, GroupChange>
                )
        }
    }, [])

    const normalizeEmptyLinkChanges = useCallback(
        (group: GroupModel, link: LinkModel) => {
            const changedGroup = changedGroups.current[group.id!]
            const origLink = changedGroup?.changedLinks?.[link.url].originalLink
            const origLinkSelector = [
                origLink.requiredKnownEmails || [],
                origLink.requiredJobFamilies || [],
                origLink.requiredAcademies || [],
                origLink.requiredRegions || []
            ]
            const linkSelector = [
                link.requiredKnownEmails || [],
                link.requiredJobFamilies || [],
                link.requiredAcademies || [],
                link.requiredRegions || []
            ]
            const isSameAsOrig = isDeepEqual(origLinkSelector, linkSelector)

            if (isSameAsOrig) {
                changedGroup.changedLinks = Object.entries(changedGroup.changedLinks)
                    .filter(([k, _v]) => k !== link.url)
                    .reduce(
                        (acc, [k, v]) => ({ ...acc, [k]: v }),
                        {} as Record<string, LinkChange>
                    )
                normalizeEmptyGroupChanges(group)
            }
        },
        [normalizeEmptyGroupChanges]
    )

    const doGroupPermChanged = useCallback(
        (
            {
                groupId,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }: {
                groupId: string
            } & PermXCoordinate,
            perm: GroupPermission
        ) => {
            const group = groups.find(({ id }) => id === groupId)
            if (!group) {
                throw new Error('Group should not be undefined')
            }
            ensureChangedGroup(group)

            if (knownEmail) {
                const rkei = knownEmail
                    ? group.requiredKnownEmails?.findIndex(
                          ({ email }) => email === knownEmail
                      ) ?? -1
                    : -1

                if (rkei !== -1) {
                    if (perm === 'none') {
                        group.requiredKnownEmails = group.requiredKnownEmails!.filter(
                            ({ email }) => email !== knownEmail
                        )
                    } else {
                        group.requiredKnownEmails = group.requiredKnownEmails!.map(
                            (rke, i) =>
                                i !== rkei
                                    ? rke
                                    : {
                                          ...rke,
                                          permission: perm
                                      }
                        )
                    }
                } else if (perm !== 'none') {
                    group.requiredKnownEmails = [...(group.requiredKnownEmails || [])]
                    sortedInsertInPlace(
                        group.requiredKnownEmails,
                        {
                            email: knownEmail,
                            permission: perm
                        },
                        requiredKnownEmailsGroupPermissionSorter
                    )
                }
            }

            if (jobFamily) {
                const rjfi = jobFamily
                    ? group.requiredJobFamilies?.findIndex(
                          ({ name }) => name === jobFamily
                      ) ?? -1
                    : -1

                if (rjfi !== -1) {
                    if (perm === 'none') {
                        group.requiredJobFamilies = group.requiredJobFamilies!.filter(
                            ({ name }) => name !== jobFamily
                        )
                    } else {
                        group.requiredJobFamilies = group.requiredJobFamilies!.map(
                            (rjf, i) =>
                                i !== rjfi
                                    ? rjf
                                    : {
                                          ...rjf,
                                          permission: perm
                                      }
                        )
                    }
                } else if (perm !== 'none') {
                    group.requiredJobFamilies = [...(group.requiredJobFamilies || [])]
                    sortedInsertInPlace(
                        group.requiredJobFamilies,
                        {
                            name: jobFamily,
                            permission: perm
                        },
                        requiredJobFamiliesGroupPermissionSorter
                    )
                }
            }

            if (academyCode) {
                const rai = academyCode
                    ? group.requiredAcademies?.findIndex(
                          ({ academyCode: ac }) => ac === academyCode
                      ) ?? -1
                    : -1

                if (rai !== -1) {
                    if (perm === 'none') {
                        group.requiredAcademies = group.requiredAcademies!.filter(
                            ({ academyCode: ac }) => ac !== academyCode
                        )
                    } else {
                        group.requiredAcademies = group.requiredAcademies!.map((ra, i) =>
                            i !== rai ? ra : { ...ra, permission: perm }
                        )
                    }
                } else if (perm !== 'none') {
                    group.requiredAcademies = [...(group.requiredAcademies || [])]
                    sortedInsertInPlace(
                        group.requiredAcademies,
                        {
                            academyCode,
                            permission: perm
                        },
                        requiredAcademyGroupPermissionSorter
                    )
                }
            }

            if (region) {
                const rri = region
                    ? group.requiredRegions?.findIndex(
                          ({ regionName }) => regionName === region
                      ) ?? -1
                    : -1

                if (rri !== -1) {
                    if (perm === 'none') {
                        group.requiredRegions = group.requiredRegions!.filter(
                            ({ regionName }) => regionName !== region
                        )
                    } else {
                        group.requiredRegions = group.requiredRegions!.map((rr, i) =>
                            i !== rri
                                ? rr
                                : {
                                      ...rr,
                                      permission: perm
                                  }
                        )
                    }
                } else if (perm !== 'none') {
                    group.requiredRegions = [...(group.requiredRegions || [])]
                    sortedInsertInPlace(
                        group.requiredRegions,
                        {
                            regionName: region,
                            permission: perm
                        },
                        requiredRegionGroupPermissionSorter
                    )
                }
            }

            normalizeEmptyGroupChanges(group)
            setGroups(groups.map(g => (g.id !== groupId ? g : group)))
            rerenderHack()
        },
        [ensureChangedGroup, groups, normalizeEmptyGroupChanges]
    )

    const doLinkPermChanged = useCallback(
        (
            {
                groupId,
                linkUrl,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }: {
                groupId: string
                linkUrl?: string
            } & PermXCoordinate,
            perm: GroupPermission
        ) => {
            const group = groups.find(({ id }) => id === groupId)
            if (!group) {
                throw new Error('Group should not be undefined')
            }
            const link = group.links.find(({ url }) => url === linkUrl)
            if (!link) {
                throw new Error('Link should not be undefined')
            }

            ensureChangedGroup(group)
            ensureChangedLink(group, linkUrl!)

            if (knownEmail) {
                const rkei = knownEmail
                    ? link.requiredKnownEmails?.findIndex(
                          ({ email }) => email === knownEmail
                      ) ?? -1
                    : -1

                if (rkei !== -1) {
                    if (perm === 'none') {
                        link.requiredKnownEmails = link.requiredKnownEmails!.filter(
                            ({ email }) => email !== knownEmail
                        )
                    } else {
                        link.requiredKnownEmails = link.requiredKnownEmails!.map(
                            (rke, i) =>
                                i !== rkei
                                    ? rke
                                    : {
                                          ...rke,
                                          permission: perm
                                      }
                        )
                    }
                } else if (perm !== 'none') {
                    link.requiredKnownEmails = [...(link.requiredKnownEmails || [])]
                    sortedInsertInPlace(
                        link.requiredKnownEmails,
                        {
                            email: knownEmail,
                            permission: perm
                        },
                        requiredKnownEmailsGroupPermissionSorter
                    )
                }
            }

            if (jobFamily) {
                const rjfi = jobFamily
                    ? link.requiredJobFamilies?.findIndex(
                          ({ name }) => name === jobFamily
                      ) ?? -1
                    : -1

                if (rjfi !== -1) {
                    if (perm === 'none') {
                        link.requiredJobFamilies = link.requiredJobFamilies!.filter(
                            ({ name }) => name !== jobFamily
                        )
                    } else {
                        link.requiredJobFamilies = link.requiredJobFamilies!.map(
                            (rjf, i) =>
                                i !== rjfi
                                    ? rjf
                                    : {
                                          ...rjf,
                                          permission: perm
                                      }
                        )
                    }
                } else if (perm !== 'none') {
                    link.requiredJobFamilies = [...(link.requiredJobFamilies || [])]
                    sortedInsertInPlace(
                        link.requiredJobFamilies,
                        {
                            name: jobFamily,
                            permission: perm
                        },
                        requiredJobFamiliesGroupPermissionSorter
                    )
                }
            }

            if (academyCode) {
                const rai = academyCode
                    ? link.requiredAcademies?.findIndex(
                          ({ academyCode: ac }) => ac === academyCode
                      ) ?? -1
                    : -1

                if (rai !== -1) {
                    if (perm === 'none') {
                        link.requiredAcademies = link.requiredAcademies!.filter(
                            ({ academyCode: ac }) => ac !== academyCode
                        )
                    } else {
                        link.requiredAcademies = link.requiredAcademies!.map((ra, i) =>
                            i !== rai ? ra : { ...ra, permission: perm }
                        )
                    }
                } else if (perm !== 'none') {
                    link.requiredAcademies = [...(link.requiredAcademies || [])]
                    sortedInsertInPlace(
                        link.requiredAcademies,
                        {
                            academyCode,
                            permission: perm
                        },
                        requiredAcademyGroupPermissionSorter
                    )
                }
            }

            if (region) {
                const rri = region
                    ? link.requiredRegions?.findIndex(
                          ({ regionName }) => regionName === region
                      ) ?? -1
                    : -1

                if (rri !== -1) {
                    if (perm === 'none') {
                        link.requiredRegions = link.requiredRegions!.filter(
                            ({ regionName }) => regionName !== region
                        )
                    } else {
                        link.requiredRegions = link.requiredRegions!.map((rr, i) =>
                            i !== rri
                                ? rr
                                : {
                                      ...rr,
                                      permission: perm
                                  }
                        )
                    }
                } else if (perm !== 'none') {
                    link.requiredRegions = [...(link.requiredRegions || [])]
                    sortedInsertInPlace(
                        link.requiredRegions,
                        {
                            regionName: region,
                            permission: perm
                        },
                        requiredRegionGroupPermissionSorter
                    )
                }
            }

            normalizeEmptyLinkChanges(group, link)
            setGroups(groups.map(g => (g.id !== groupId ? g : group)))
            rerenderHack()
        },
        [ensureChangedGroup, ensureChangedLink, groups, normalizeEmptyLinkChanges]
    )

    const doPermAssignmentChanged = useCallback(
        (
            {
                permissionName,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }: { permissionName: Permission } & PermXCoordinate,
            assigned: boolean
        ) => {
            const perm = permissionLookup.current?.[permissionName]
            if (!perm) {
                throw new Error('Permission should not be undefined')
            }
            if (perm.name === 'edit permissions') {
                if (currentUserLookups) {
                    const paths = userPathsToPerm(currentUserLookups, perm)
                    // log(`🥝🥝🥝 userPathsToPerm: ${json(paths)}`)
                    if (paths.length === 1) {
                        if (
                            paths.knownEmail.length === 1 &&
                            paths.knownEmail[0] === knownEmail
                        ) {
                            // eslint-disable-next-line no-alert
                            alert(
                                `Removing knownEmail '${knownEmail}' from Permission '${perm.name}' will deny you access to this screen.`
                            )
                            return
                        }
                        if (
                            paths.jobFamily.length === 1 &&
                            paths.jobFamily[0] === jobFamily
                        ) {
                            // eslint-disable-next-line no-alert
                            alert(
                                `Removing jobFamily '${jobFamily}' from Permission '${perm.name}' will deny you access to this screen.`
                            )
                            return
                        }
                        if (
                            paths.academies.length === 1 &&
                            paths.academies[0] === academyCode
                        ) {
                            // eslint-disable-next-line no-alert
                            alert(
                                `Removing academy '${academyCode}' from Permission '${perm.name}' will deny you access to this screen.`
                            )
                            return
                        }
                        if (paths.regions.length === 1 && paths.regions[0] === region) {
                            // eslint-disable-next-line no-alert
                            alert(
                                `Removing region '${region}' from Permission '${perm.name}' will deny you access to this screen.`
                            )
                            return
                        }
                    }
                }
            }

            if (!changedPerms.current[permissionName]) {
                const clonedPerm = deepClone(perm)
                changedPerms.current = {
                    ...changedPerms.current,
                    [permissionName]: {
                        originalPerm: {
                            ...clonedPerm,
                            ...(clonedPerm.requiredKnownEmails
                                ? {
                                      requiredKnownEmails:
                                          clonedPerm.requiredKnownEmails?.sort(
                                              knownEmailSorter
                                          )
                                  }
                                : {}),
                            ...(clonedPerm.requiredRegions
                                ? { requiredRegions: clonedPerm.requiredRegions?.sort() }
                                : {}),
                            ...(clonedPerm.requiredAcademies
                                ? {
                                      requiredAcademies:
                                          clonedPerm.requiredAcademies?.sort()
                                  }
                                : {}),
                            ...(clonedPerm.requiredJobFamilies
                                ? {
                                      requiredJobFamilies:
                                          clonedPerm.requiredJobFamilies?.sort()
                                  }
                                : {})
                        },
                        originalKnownEmailRights: new Set(clonedPerm.requiredKnownEmails),
                        originalJobFamilyRights: new Set(clonedPerm.requiredJobFamilies),
                        originalAcademyRights: new Set(clonedPerm.requiredAcademies),
                        originalRegionRights: new Set(clonedPerm.requiredRegions)
                    } as PermChange
                }
            }

            if (knownEmail) {
                const rke = perm.requiredKnownEmails?.find(email => email === knownEmail)

                if (rke && !assigned) {
                    perm.requiredKnownEmails = perm.requiredKnownEmails!.filter(
                        email => email !== knownEmail
                    )
                } else if (!rke && assigned) {
                    perm.requiredKnownEmails = [...(perm.requiredKnownEmails || [])]
                    sortedInsertInPlace(
                        perm.requiredKnownEmails!,
                        knownEmail,
                        (one, two) => one!.localeCompare(two!)
                    )
                }
            }

            if (jobFamily) {
                const rjf = perm.requiredJobFamilies?.find(name => name === jobFamily)

                if (rjf && !assigned) {
                    perm.requiredJobFamilies = perm.requiredJobFamilies!.filter(
                        name => name !== jobFamily
                    )
                } else if (!rjf && assigned) {
                    perm.requiredJobFamilies = [...(perm.requiredJobFamilies || [])]
                    sortedInsertInPlace(
                        perm.requiredJobFamilies!,
                        jobFamily,
                        (one, two) => one!.localeCompare(two!)
                    )
                }
            }

            if (academyCode) {
                const ra = perm.requiredAcademies?.find(ac => ac === academyCode)

                if (ra && !assigned) {
                    perm.requiredAcademies = perm.requiredAcademies!.filter(
                        ac => ac !== academyCode
                    )
                } else if (!ra && assigned) {
                    perm.requiredAcademies = [...(perm.requiredAcademies || [])]
                    sortedInsertInPlace(
                        perm.requiredAcademies!,
                        academyCode,
                        (one, two) => one!.localeCompare(two!)
                    )
                }
            }

            if (region) {
                const rr = perm.requiredRegions?.find(regionName => regionName === region)

                if (rr && !assigned) {
                    perm.requiredRegions = perm.requiredRegions!.filter(
                        regionName => regionName !== region
                    )
                } else if (!rr && assigned) {
                    perm.requiredRegions = [...(perm.requiredRegions || [])]
                    sortedInsertInPlace(perm.requiredRegions!, region, (one, two) =>
                        one!.localeCompare(two!)
                    )
                }
            }

            const origPerm = changedPerms.current[perm.name]?.originalPerm
            const origPermSelector = [
                origPerm.name,
                origPerm.requiredKnownEmails || [],
                origPerm.requiredJobFamilies || [],
                origPerm.requiredAcademies || [],
                origPerm.requiredRegions || []
            ]
            const permSelector = [
                perm.name,
                perm.requiredKnownEmails || [],
                perm.requiredJobFamilies || [],
                perm.requiredAcademies || [],
                perm.requiredRegions || []
            ]
            const isSameAsOrig = isDeepEqual(origPermSelector, permSelector)

            if (isSameAsOrig) {
                changedPerms.current = Object.entries(changedPerms.current)
                    .filter(([k, _v]) => k !== permissionName)
                    .reduce(
                        (acc, [k, v]) => ({ ...acc, [k]: v }),
                        {} as Record<string, PermChange>
                    )
            }
            setPermissions(permissions.map(p => (p.name !== perm.name ? p : perm)))
            rerenderHack()
        },
        [currentUserLookups, permissions, userPathsToPerm]
    )

    const doPerAcademyPermAssignmentChanged = useCallback(
        (
            {
                perAcademyPermission,
                papAcademyCode,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }: {
                perAcademyPermission: PerAcademyPermission
                papAcademyCode: string
            } & PermXCoordinate,
            assigned: boolean
        ) => {
            const perm =
                perAcademyPermissionLookup.current?.[perAcademyPermission]?.[
                    papAcademyCode
                ]

            if (!perm) {
                throw new Error('Permission should not be undefined')
            }

            if (!changedPerAcademyPerms.current[perAcademyPermission]) {
                changedPerAcademyPerms.current = {
                    ...changedPerAcademyPerms.current,
                    [perAcademyPermission]: {}
                }
            }
            if (!changedPerAcademyPerms.current[perAcademyPermission]?.[papAcademyCode]) {
                const clonedPerm = deepClone(perm)
                changedPerAcademyPerms.current[perAcademyPermission] = {
                    ...changedPerAcademyPerms.current[perAcademyPermission],
                    [papAcademyCode]: {
                        originalPerm: {
                            ...clonedPerm,
                            ...(clonedPerm.requiredKnownEmails
                                ? {
                                      requiredKnownEmails: (
                                          clonedPerm.requiredKnownEmails || []
                                      )?.sort(knownEmailSorter)
                                  }
                                : {}),
                            ...(clonedPerm.requiredJobFamilies
                                ? {
                                      requiredJobFamilies: (
                                          clonedPerm.requiredJobFamilies || []
                                      )?.sort()
                                  }
                                : {}),
                            ...(clonedPerm.requiredAcademies
                                ? {
                                      requiredAcademies: (
                                          clonedPerm.requiredAcademies || []
                                      )?.sort()
                                  }
                                : {}),
                            ...(clonedPerm.requiredRegions
                                ? {
                                      requiredRegions: (
                                          clonedPerm.requiredRegions || []
                                      )?.sort()
                                  }
                                : {})
                        },
                        originalKnownEmailRights: new Set(clonedPerm.requiredKnownEmails),
                        originalJobFamilyRights: new Set(clonedPerm.requiredJobFamilies),
                        originalAcademyRights: new Set(clonedPerm.requiredAcademies),
                        originalRegionRights: new Set(clonedPerm.requiredRegions)
                    } as PerAcademyPermChange
                }
            }

            if (knownEmail) {
                const rke = perm.requiredKnownEmails?.find(email => email === knownEmail)

                if (rke && !assigned) {
                    perm.requiredKnownEmails = perm.requiredKnownEmails!.filter(
                        email => email !== knownEmail
                    )
                } else if (!rke && assigned) {
                    perm.requiredKnownEmails = [...(perm.requiredKnownEmails || [])]
                    sortedInsertInPlace(
                        perm.requiredKnownEmails!,
                        knownEmail,
                        (one, two) => one!.localeCompare(two!)
                    )
                }
            }

            if (jobFamily) {
                const rjf = perm.requiredJobFamilies?.find(name => name === jobFamily)

                if (rjf && !assigned) {
                    perm.requiredJobFamilies = perm.requiredJobFamilies!.filter(
                        name => name !== jobFamily
                    )
                } else if (!rjf && assigned) {
                    perm.requiredJobFamilies = [...(perm.requiredJobFamilies || [])]
                    sortedInsertInPlace(
                        perm.requiredJobFamilies!,
                        jobFamily,
                        (one, two) => one!.localeCompare(two!)
                    )
                }
            }

            if (academyCode) {
                const ra = perm.requiredAcademies?.find(ac => ac === academyCode)

                if (ra && !assigned) {
                    perm.requiredAcademies = perm.requiredAcademies!.filter(
                        ac => ac !== academyCode
                    )
                } else if (!ra && assigned) {
                    perm.requiredAcademies = [...(perm.requiredAcademies || [])]
                    sortedInsertInPlace(
                        perm.requiredAcademies!,
                        academyCode,
                        (one, two) => one!.localeCompare(two!)
                    )
                }
            }

            if (region) {
                const rr = perm.requiredRegions?.find(regionName => regionName === region)

                if (rr && !assigned) {
                    perm.requiredRegions = perm.requiredRegions!.filter(
                        regionName => regionName !== region
                    )
                } else if (!rr && assigned) {
                    perm.requiredRegions = [...(perm.requiredRegions || [])]
                    sortedInsertInPlace(perm.requiredRegions!, region, (one, two) =>
                        one!.localeCompare(two!)
                    )
                }
            }

            const origPerm =
                changedPerAcademyPerms.current[perAcademyPermission]?.[papAcademyCode]
                    ?.originalPerm
            const origPermSelector = [
                origPerm?.academyCode,
                origPerm?.requiredKnownEmails || [],
                origPerm?.requiredJobFamilies || [],
                origPerm?.requiredAcademies || [],
                origPerm?.requiredRegions || []
            ]
            const permSelector = [
                perm.academyCode,
                perm.requiredKnownEmails || [],
                perm.requiredJobFamilies || [],
                perm.requiredAcademies || [],
                perm.requiredRegions || []
            ]
            const isSameAsOrig = isDeepEqual(origPermSelector, permSelector)

            const acLookup = changedPerAcademyPerms.current[perAcademyPermission]
            if (isSameAsOrig) {
                if (acLookup) {
                    changedPerAcademyPerms.current[perAcademyPermission] = typedEntries(
                        acLookup
                    )
                        .filter(([k, _v]) => k !== papAcademyCode)
                        .reduce(
                            (acc, [k, v]) => ({ ...acc, [k]: v }),
                            {} as Record<string, PerAcademyPermChange>
                        )
                }
            }
            if (
                Object.keys(changedPerAcademyPerms.current[perAcademyPermission] || {})
                    .length === 0
            ) {
                changedPerAcademyPerms.current = typedEntries(
                    changedPerAcademyPerms.current
                )
                    .filter(([k, _v]) => k !== perAcademyPermission)
                    .reduce(
                        (acc, [k, v]) => ({ ...acc, [k]: v }),
                        {} as Record<
                            PerAcademyPermission,
                            Record<string, PerAcademyPermChange>
                        >
                    )
            }
            const newPaps = perAcademyPermissions.map(pap =>
                pap.name !== perAcademyPermission
                    ? pap
                    : {
                          ...pap,
                          academyPermissions: pap.academyPermissions.map(ap =>
                              ap.academyCode !== papAcademyCode ? ap : perm
                          )
                      }
            )
            // log(`🌼 newPaps: ${json(newPaps)}`)
            setPerAcademyPermissions(newPaps)
            rerenderHack()
        },
        [perAcademyPermissions]
    )

    const _getBiPermByName = (
        name: keyof (PermXCoordinate & PermYCoordinate),
        value: string,
        obj?: {
            requiredKnownEmails?: string[]
            requiredJobFamilies?: string[]
            requiredAcademies?: string[]
            requiredRegions?: string[]
        }
    ) => {
        return !!(name === 'knownEmail'
            ? obj?.requiredKnownEmails?.find(email => email === value)
            : name === 'jobFamily'
            ? obj?.requiredJobFamilies?.find(jf => jf === value)
            : name === 'academy'
            ? obj?.requiredAcademies?.find(academyCode => academyCode === value)
            : name === 'region'
            ? obj?.requiredRegions?.find(regionName => regionName === value)
            : undefined)
    }

    const _getTriPermByName = (
        name: keyof (PermXCoordinate & PermYCoordinate),
        value: string,
        obj?: {
            requiredKnownEmails?: KnownEmailGroupPermission[]
            requiredJobFamilies?: JobFamilyGroupPermission[]
            requiredAcademies?: AcademyGroupPermission[]
            requiredRegions?: RegionGroupPermission[]
        }
    ) => {
        return name === 'knownEmail'
            ? obj?.requiredKnownEmails?.find(({ email }) => email === value)
            : name === 'jobFamily'
            ? obj?.requiredJobFamilies?.find(({ name: jf }) => jf === value)
            : name === 'academy'
            ? obj?.requiredAcademies?.find(({ academyCode }) => academyCode === value)
            : name === 'region'
            ? obj?.requiredRegions?.find(({ regionName }) => regionName === value)
            : undefined
    }

    const horizontalBlocksBase: HorizontalBlock[] = [
        {
            name: 'knownEmail',
            maxIndex: filtered.knownEmails.length - 1,
            findIndex: (value: string) =>
                filtered.knownEmails.findIndex(knownEmail => knownEmail === value),
            forEach: (callback: (value: string, index: number) => void) => {
                filtered.knownEmails.forEach((knownEmail, i) => callback(knownEmail, i))
            }
        },
        {
            name: 'jobFamily',
            maxIndex: filtered.jobFamilies.length - 1,
            findIndex: (value: string) =>
                filtered.jobFamilies.findIndex(({ text }) => text === value),
            forEach: (callback: (value: string, index: number) => void) => {
                filtered.jobFamilies.forEach(({ text }, i) => callback(text, i))
            }
        },
        {
            name: 'academy',
            maxIndex: filtered.academies.length - 1,
            findIndex: (value: string) =>
                filtered.academies.findIndex(({ academyCode }) => academyCode === value),
            forEach: (callback: (value: string, index: number) => void) => {
                filtered.academies.forEach(({ academyCode }, i) =>
                    callback(academyCode, i)
                )
            }
        },
        {
            name: 'region',
            maxIndex: filtered.regions.length - 1,
            findIndex: (value: string) =>
                filtered.regions.findIndex(region => region === value),
            forEach: (callback: (value: string, index: number) => void) => {
                filtered.regions.forEach(callback)
            }
        }
    ]

    const { items: horizontalBlocksTmp } = horizontalBlocksBase.reduce(
        (acc, item) => {
            const { maxIndex } = item
            const { count, items } = acc
            const newCount = count + maxIndex + 1

            const newAcc: typeof acc = {
                count: newCount,
                items: [
                    ...(items || []),
                    {
                        ...item,
                        isFirstBlock: count === 0 && maxIndex > -1
                    }
                ]
            }

            return newAcc
        },
        { count: 0 } as {
            count: number
            items: HorizontalBlock[]
        }
    )

    const { items: horizontalBlocks } = horizontalBlocksTmp.reduceRight(
        (acc, item) => {
            const { maxIndex } = item
            const { count, items } = acc
            const newCount = count + maxIndex + 1
            const hasFollowingBlock = count !== 0

            const newAcc: typeof acc = {
                count: newCount,
                items: [
                    {
                        ...item,
                        hasFollowingBlock
                    },
                    ...(items || [])
                ]
            }

            return newAcc
        },
        { count: 0 } as {
            count: number
            items: HorizontalBlock[]
        }
    )

    const horizontalBlockLookup: Record<keyof PermXCoordinate, HorizontalBlock> =
        makeLookup(
            horizontalBlocks,
            ({ name }) => name,
            b => b
        )

    const { hasFollowingBlock: knownEmailsHasFollowingBlock } =
        horizontalBlockLookup.knownEmail

    const {
        isFirstBlock: jobFamiliesIsFirstBlock,
        hasFollowingBlock: jobFamiliesHasFollowingBlock
    } = horizontalBlockLookup.jobFamily

    const {
        isFirstBlock: academiesIsFirstBlock,
        hasFollowingBlock: academiesHasFollowingBlock
    } = horizontalBlockLookup.academy

    const { isFirstBlock: regionsIsFirstBlock } = horizontalBlockLookup.region

    const blockAdjacency: BlockAdjacency = {
        knownEmailsHasFollowingBlock: !!knownEmailsHasFollowingBlock,
        jobFamiliesIsFirstBlock: !!jobFamiliesIsFirstBlock,
        jobFamiliesHasFollowingBlock: !!jobFamiliesHasFollowingBlock,
        academiesIsFirstBlock: !!academiesIsFirstBlock,
        academiesHasFollowingBlock: !!academiesHasFollowingBlock,
        regionsIsFirstBlock: !!regionsIsFirstBlock
    }

    const processRange = useCallback(
        (first: GridCoordinate, second: GridCoordinate) => {
            if (first && second) {
                const keyOverlap = typedEntries(first).reduce(
                    (acc, [k, _v]) =>
                        first[k] && first[k] === second[k] ? [...acc, k] : acc,
                    [] as (keyof typeof first)[]
                )
                const axisLinkUrl =
                    keyOverlap.length === 2 &&
                    keyOverlap.every(k => k === 'groupId' || k === 'linkUrl')

                const axisPapAcademyCode =
                    keyOverlap.length === 2 &&
                    keyOverlap.every(
                        k => k === 'perAcademyPermission' || k === 'papAcademyCode'
                    )

                if (keyOverlap.length !== 1 && !axisLinkUrl && !axisPapAcademyCode) {
                    throw new Error('Expected one axis')
                }
                const getBookEnds = (poi: (keyof typeof first)[]) => {
                    const kv1 = firstNonEmptyKeyValue(first, poi)
                    const kv2 = firstNonEmptyKeyValue(second, poi)

                    if (kv1 && kv2) {
                        return {
                            start: { k: kv1[0], v: kv1[1] },
                            finish: { k: kv2[0], v: kv2[1] }
                        }
                    }
                    return undefined
                }
                const [axis]: [typeof keyOverlap[0]] = axisLinkUrl
                    ? ['linkUrl']
                    : axisPapAcademyCode
                    ? ['papAcademyCode']
                    : (keyOverlap as [typeof keyOverlap[0]])

                let be: ReturnType<typeof getBookEnds>
                let horz = false

                const axisId = first[axis]

                switch (axis) {
                    case 'groupId':
                    case 'linkUrl':
                    case 'permName':
                    case 'papAcademyCode':
                        be = getBookEnds(['knownEmail', 'jobFamily', 'academy', 'region'])
                        horz = true
                        break
                    case 'knownEmail':
                    case 'jobFamily':
                    case 'academy':
                    case 'region':
                        be = getBookEnds([
                            'groupId',
                            'linkUrl',
                            'permName',
                            'papAcademyCode'
                        ])
                        horz = false
                        break
                    default:
                        break
                }
                if (be) {
                    if (horz) {
                        const { start, finish } = be
                        const { k: k1, v: v1 } = start
                        const { k: k2, v: v2 } = finish

                        const blocks2 = horizontalBlocks.map(
                            ({ name, maxIndex, findIndex, forEach }, blockIndex) => {
                                const from = name === k1 ? v1 : undefined
                                const to = name === k2 ? v2 : undefined
                                const fromIndex = from ? findIndex(from) : -1
                                const toIndex = to ? findIndex(to) : -1

                                return {
                                    name,
                                    maxIndex,
                                    findIndex,
                                    forEach,
                                    blockIndex,
                                    from,
                                    to,
                                    fromIndex,
                                    toIndex
                                }
                            }
                        )
                        const fromBlock_ = blocks2.find(b => !!b.from)!
                        const toBlock_ = blocks2.find(b => !!b.to)!
                        const blockDirection = Math.sign(
                            toBlock_.blockIndex - fromBlock_.blockIndex
                        )
                        const fromBlock =
                            blockDirection >= 0
                                ? fromBlock_
                                : {
                                      ...toBlock_,
                                      from: toBlock_.to,
                                      fromIndex: toBlock_.toIndex,
                                      to: toBlock_.from,
                                      toIndex: toBlock_.fromIndex,
                                      blockIndex: toBlock_.blockIndex
                                  }
                        const toBlock =
                            blockDirection >= 0
                                ? toBlock_
                                : {
                                      ...fromBlock_,
                                      from: fromBlock_.to,
                                      fromIndex: fromBlock_.toIndex,
                                      to: fromBlock_.from,
                                      toIndex: fromBlock_.fromIndex,
                                      blockIndex: fromBlock_.blockIndex
                                  }
                        const blockDirection2 = Math.sign(
                            toBlock.blockIndex - fromBlock.blockIndex
                        )
                        if (blockDirection2 < 0) {
                            throw new Error(
                                'Block were expected to be in direction from -> to'
                            )
                        }
                        const blocks3 = blocks2.map((b, i) =>
                            i === fromBlock.blockIndex
                                ? fromBlock
                                : i === toBlock.blockIndex
                                ? toBlock
                                : b
                        )
                        const blocks4 = blocks3.map(b => {
                            const {
                                name,
                                blockIndex,
                                from,
                                fromIndex: fromIndex_,
                                to,
                                toIndex: toIndex_,
                                maxIndex,
                                // findIndex,
                                forEach
                            } = b

                            const involved =
                                blockIndex >= fromBlock.blockIndex &&
                                blockIndex <= toBlock.blockIndex

                            const fromIndex = !involved
                                ? -1
                                : from
                                ? Math.min(
                                      fromIndex_ !== -1 ? fromIndex_ : 0,
                                      toIndex_ !== -1 ? toIndex_ : maxIndex
                                  )
                                : 0

                            const toIndex = !involved
                                ? -1
                                : to
                                ? Math.max(
                                      fromIndex_ !== -1 ? fromIndex_ : 0,
                                      toIndex_ !== -1 ? toIndex_ : maxIndex
                                  )
                                : maxIndex

                            return {
                                name,
                                blockIndex,
                                from,
                                fromIndex,
                                to,
                                toIndex,
                                maxIndex,
                                // findIndex,
                                forEach
                            }
                        })

                        // log(`🌼🌼🌼 direction: ${json(blockDirection)}`)
                        // log(`🌼🌼🌼 direction2: ${json(blockDirection2)}`)
                        // log(`🌼🌼🌼 blocks1: ${json(blocks1)}`)
                        // log(`🌼🌼🌼 blocks2: ${json(blocks2)}`)
                        // log(`🌼🌼🌼 blocks3: ${json(blocks3)}`)
                        // log(`🌼🌼🌼 blocks4: ${json(blocks4)}`)
                        // log(`🌼🌼🌼 fromBlock: ${json(fromBlock)}`)
                        // log(`🌼🌼🌼 toBlock: ${json(toBlock)}`)

                        blocks4.forEach(
                            ({
                                name,
                                // blockIndex,
                                // from,
                                fromIndex,
                                // to,
                                toIndex,
                                // maxIndex,
                                // findIndex,
                                forEach
                            }) => {
                                let newGroupPerm: GroupPermission | undefined
                                let newPermAssignment: boolean | undefined

                                forEach((value, i) => {
                                    if (i >= fromIndex && i <= toIndex) {
                                        if (axis === 'groupId') {
                                            const group = groups.find(
                                                ({ id }) => id === axisId
                                            )

                                            if (!newGroupPerm) {
                                                newGroupPerm = _getTriPermByName(
                                                    fromBlock_.name,
                                                    fromBlock_.from!,
                                                    group
                                                )?.permission
                                            }

                                            doGroupPermChanged(
                                                {
                                                    groupId: axisId!,
                                                    ...(name === 'knownEmail'
                                                        ? { knownEmail: value }
                                                        : {}),
                                                    ...(name === 'jobFamily'
                                                        ? { jobFamily: value }
                                                        : {}),
                                                    ...(name === 'academy'
                                                        ? { academy: value }
                                                        : {}),
                                                    ...(name === 'region'
                                                        ? { region: value }
                                                        : {})
                                                },
                                                newGroupPerm || 'none'
                                            )
                                        }
                                        if (axis === 'papAcademyCode') {
                                            const { perAcademyPermission } = first

                                            const pap = perAcademyPermissions.find(
                                                ({ name: papName }) =>
                                                    papName === perAcademyPermission
                                            )
                                            const papAcPerms =
                                                pap?.academyPermissions.find(
                                                    ({ academyCode }) =>
                                                        academyCode === axisId
                                                )

                                            if (!newPermAssignment) {
                                                newPermAssignment = _getBiPermByName(
                                                    fromBlock_.name,
                                                    fromBlock_.from!,
                                                    papAcPerms
                                                )
                                            }

                                            doPerAcademyPermAssignmentChanged(
                                                {
                                                    perAcademyPermission:
                                                        perAcademyPermission!,
                                                    papAcademyCode: axisId!,
                                                    ...(name === 'knownEmail'
                                                        ? { knownEmail: value }
                                                        : {}),
                                                    ...(name === 'jobFamily'
                                                        ? { jobFamily: value }
                                                        : {}),
                                                    ...(name === 'academy'
                                                        ? { academy: value }
                                                        : {}),
                                                    ...(name === 'region'
                                                        ? { region: value }
                                                        : {})
                                                },
                                                newPermAssignment!
                                            )
                                        }
                                        if (axis === 'linkUrl') {
                                            const { groupId } = first

                                            const group = groups.find(
                                                ({ id }) => id === groupId
                                            )
                                            const link = group?.links.find(
                                                ({ url }) => url === axisId
                                            )

                                            if (!newGroupPerm) {
                                                newGroupPerm = _getTriPermByName(
                                                    fromBlock_.name,
                                                    fromBlock_.from!,
                                                    link
                                                )?.permission
                                            }

                                            doLinkPermChanged(
                                                {
                                                    groupId: groupId!,
                                                    linkUrl: axisId!,
                                                    ...(name === 'knownEmail'
                                                        ? { knownEmail: value }
                                                        : {}),
                                                    ...(name === 'jobFamily'
                                                        ? { jobFamily: value }
                                                        : {}),
                                                    ...(name === 'academy'
                                                        ? { academy: value }
                                                        : {}),
                                                    ...(name === 'region'
                                                        ? { region: value }
                                                        : {})
                                                },
                                                newGroupPerm || 'none'
                                            )
                                        }
                                        if (axis === 'permName') {
                                            const permission = permissions.find(
                                                ({ name: permName }) =>
                                                    permName === axisId
                                            )

                                            if (!newPermAssignment) {
                                                newPermAssignment = _getBiPermByName(
                                                    fromBlock_.name,
                                                    fromBlock_.from!,
                                                    permission
                                                )
                                            }

                                            doPermAssignmentChanged(
                                                {
                                                    permissionName: axisId! as Permission,
                                                    ...(name === 'knownEmail'
                                                        ? { knownEmail: value }
                                                        : {}),
                                                    ...(name === 'jobFamily'
                                                        ? { jobFamily: value }
                                                        : {}),
                                                    ...(name === 'academy'
                                                        ? { academy: value }
                                                        : {}),
                                                    ...(name === 'region'
                                                        ? { region: value }
                                                        : {})
                                                },
                                                newPermAssignment!
                                            )
                                        }
                                    }
                                })
                            }
                        )
                    } else {
                        // vert
                    }
                }
            }
        },
        [
            doGroupPermChanged,
            doLinkPermChanged,
            doPerAcademyPermAssignmentChanged,
            doPermAssignmentChanged,
            groups,
            horizontalBlocks,
            perAcademyPermissions,
            permissions
        ]
    )

    const onGroupPermChanged = useCallback(
        (
            {
                groupId,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }: {
                groupId: string
            } & PermXCoordinate,
            perm: GroupPermission,
            e: React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => {
            if (e.shiftKey) {
                if (lastClick.current) {
                    const thisClick = {
                        groupId,
                        knownEmail,
                        jobFamily,
                        academyCode,
                        region
                    }
                    processRange(lastClick.current, thisClick)
                }
                // lastClick.current = undefined
                return
            }
            lastClick.current = {
                groupId,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }

            doGroupPermChanged(
                { groupId, knownEmail, jobFamily, academy: academyCode, region },
                perm
            )
        },
        [doGroupPermChanged, processRange]
    )

    const onLinkPermChanged = useCallback(
        (
            {
                groupId,
                linkUrl,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }: {
                groupId: string
                linkUrl?: string
            } & PermXCoordinate,
            perm: GroupPermission,
            e: React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => {
            if (e.shiftKey) {
                if (lastClick.current) {
                    const thisClick = {
                        groupId,
                        linkUrl,
                        knownEmail,
                        jobFamily,
                        academyCode,
                        region
                    }
                    processRange(lastClick.current, thisClick)
                }
                // lastClick.current = undefined
                return
            }
            lastClick.current = {
                groupId,
                linkUrl,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }

            doLinkPermChanged(
                {
                    groupId,
                    linkUrl,
                    knownEmail,
                    jobFamily,
                    academy: academyCode,
                    region
                },
                perm
            )
        },
        [doLinkPermChanged, processRange]
    )

    const onPermAssignmentChanged = useCallback(
        (
            {
                permissionName,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }: { permissionName: Permission } & PermXCoordinate,
            assigned: boolean,
            e: React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => {
            if (e.shiftKey) {
                if (lastClick.current) {
                    const thisClick = {
                        permName: permissionName,
                        knownEmail,
                        jobFamily,
                        academyCode,
                        region
                    }
                    processRange(lastClick.current, thisClick)
                }
                // lastClick.current = undefined
                return
            }
            lastClick.current = {
                permName: permissionName,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }

            doPermAssignmentChanged(
                {
                    permissionName,
                    knownEmail,
                    jobFamily,
                    academy: academyCode,
                    region
                },
                assigned
            )
        },
        [doPermAssignmentChanged, processRange]
    )

    const onPerAcademyPermAssignmentChanged = useCallback(
        (
            {
                perAcademyPermission,
                papAcademyCode,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }: {
                perAcademyPermission: PerAcademyPermission
                papAcademyCode: string
            } & PermXCoordinate,
            assigned: boolean,
            e: React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => {
            if (e.shiftKey) {
                if (lastClick.current) {
                    const thisClick = {
                        perAcademyPermission,
                        papAcademyCode,
                        knownEmail,
                        jobFamily,
                        academyCode,
                        region
                    }
                    processRange(lastClick.current, thisClick)
                }
                // lastClick.current = undefined
                return
            }
            lastClick.current = {
                perAcademyPermission,
                papAcademyCode,
                knownEmail,
                jobFamily,
                academy: academyCode,
                region
            }

            doPerAcademyPermAssignmentChanged(
                {
                    perAcademyPermission,
                    papAcademyCode,
                    knownEmail,
                    jobFamily,
                    academy: academyCode,
                    region
                },
                assigned
            )
        },
        [doPerAcademyPermAssignmentChanged, processRange]
    )

    const unsavedChanges =
        Object.keys(changedGroups.current).length !== 0 ||
        Object.keys(changedPerms.current).length !== 0 ||
        Object.keys(changedPerAcademyPerms.current).length !== 0

    const [showSettings, setShowSettings] = useState(false)
    const [settingsMessages, setSettingsMessages] = useState<string[]>([])

    const onSettingsCancel = useCallback(() => {
        setSettingsMessages([])
        initialise()
        setShowSettings(false)
    }, [initialise])

    const onSettingsOk = useCallback(
        async ({ updatedKnownEmails }: { updatedKnownEmails: string[] }) => {
            if (unsavedChanges) {
                setShowSettings(false)
            } else {
                const sortedUpdatedKnownEmails = updatedKnownEmails
                    .map(ke => ke?.trim())
                    .filter(ke => !!ke)
                    .sort()
                const { leftOnly: removedEmails, rightOnly: addedEmails } = venn(
                    knownEmails,
                    sortedUpdatedKnownEmails,
                    l => l,
                    r => r
                )

                const removedInUse_ = getKnownEmailPermTargets(removedEmails)
                const removedInUse = typedEntries(removedInUse_).map(([k, _v]) => k)
                // log(`💥 inUse_: `, removedInUse_)
                if (removedInUse.length > 0) {
                    typedEntries(removedInUse_).forEach(
                        ([
                            knownEmail,
                            { groups: groups_, groupLinks, permissions: permissions_ }
                        ]) => {
                            const groupsAr = [...groups_.values()]
                            const groupLinksAr = [...groupLinks.values()]
                            const permissionsAr = [...permissions_.values()]

                            groupsAr.forEach(groupId => {
                                doGroupPermChanged(
                                    { groupId, knownEmail: knownEmail! },
                                    'none'
                                )
                            })
                            groupLinksAr.forEach(({ groupId, linkUrl }) => {
                                doLinkPermChanged(
                                    { groupId, linkUrl, knownEmail },
                                    'none'
                                )
                            })
                            permissionsAr.forEach(permissionName => {
                                doPermAssignmentChanged(
                                    { permissionName, knownEmail },
                                    false
                                )
                            })
                        }
                    )
                    setSettingsMessages([
                        'The following emails are associated with Permissions:',
                        ...removedInUse.map(e => ` * ${e}`),
                        'These permissions have been removed in a pending state',
                        'Please review, save and return to this screen or Cancel to abort and undo pending changes.'
                    ])
                } else {
                    setSettingsMessages([])
                    await updateKnownEmails(removedEmails, addedEmails)
                    setShowSettings(false)
                }
            }
        },
        [
            doGroupPermChanged,
            doLinkPermChanged,
            doPermAssignmentChanged,
            getKnownEmailPermTargets,
            knownEmails,
            updateKnownEmails,
            unsavedChanges
        ]
    )

    const onSettingsClicked = useCallback(() => {
        setShowSettings(true)
    }, [])

    if (!currentUser || !selectedUser) {
        return null
    }
    if (!userHasPermission('edit permissions')) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    ml: 2,
                    mt: '20%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography sx={{ opacity: 0.4 }} variant='h4' textAlign='center'>
                    404! - You don't have permission to manage permissions.
                </Typography>
            </Box>
        )
    }

    // log(`_🌼 longestHeader: '${longestHeader}' (length: ${longestHeader.length})`)

    return (
        <>
            {!!longestHeader && !rect && (
                <span ref={measureNodeRef} style={{ color: 'red' }}>
                    {longestHeader}
                </span>
            )}
            <Box
                className='perm-table'
                sx={{
                    display: 'inline-grid',
                    gridTemplateColumns: `auto repeat(${assocColCount}, 1fr)`,
                    gridTemplateRows: `auto repeat(${assocRowCount}, 1fr)`,
                    gridGap: '0px',
                    paddingRight: 1,
                    mb: 2,
                    userSelect: 'none'
                }}
            >
                <TopLeftCell
                    userSummary={userSummary}
                    selectedUser={selectedUser}
                    selectedUserChanged={selectedUserChanged}
                    onFiltersChanged={setPermissionsPageFilters}
                    sharedFilter={sharedFilter}
                    onSharedFilterChanged={onSharedFilterChanged}
                    columnCount={assocColCount}
                    userLookups={selectedUserLookups.current}
                    academyCodeLookup={academyCodeLookup}
                    height={headerHeight}
                    // allGroupsFilterButtonState={allGroupsFilterButtonState.current}
                    // onGroupLinkFilterButtonClicked={onAllGroupsLinkFilterClicked}
                />

                {!!selectedUserLookups.current && (
                    <>
                        <ColumnHeaders
                            columnData={filtered.knownEmails}
                            dataExtractor={knownEmailColumnHeaderDataExtractor}
                            headerHeight={headerHeight}
                            markerHeight={markerHeight}
                            onClick={toggleEmphasizedKnownEmail}
                            isFinalBlock={
                                jobFamilyCount === 0 &&
                                academyCount === 0 &&
                                regionCount === 0
                            }
                        />
                        <ColumnHeaders
                            columnData={filtered.jobFamilies}
                            dataExtractor={jobFamilyColumnHeaderDataExtractor}
                            headerHeight={headerHeight}
                            markerHeight={markerHeight}
                            onClick={toggleEmphasizedJobFamily}
                            isFinalBlock={academyCount === 0 && regionCount === 0}
                        />
                        <ColumnHeaders
                            columnData={filtered.academies}
                            dataExtractor={academyColumnHeaderDataExtractor}
                            headerHeight={headerHeight}
                            markerHeight={markerHeight}
                            onClick={toggleEmphasizedAcademies}
                            isFinalBlock={regionCount === 0}
                        />
                        <ColumnHeaders
                            columnData={filtered.regions}
                            dataExtractor={regionColumnHeaderDataExtractor}
                            headerHeight={headerHeight}
                            markerHeight={markerHeight}
                            onClick={toggleEmphasizedRegions}
                            isFinalBlock
                        />
                    </>
                )}

                {!!changedGroups.current && !!selectedUserLookups.current && (
                    <>
                        <GridSection
                            colWidths={[
                                knownEmailCount,
                                jobFamilyCount,
                                academyCount,
                                regionCount
                            ]}
                            top={gridSectionTop}
                            height={gridSectionHeight}
                            backgroundColor='#A7A7A7'
                            // backgroundColor={theme.palette.primary.main}
                            borderColor='#979797'
                        >
                            <GroupLinkFilterButton
                                state={allGroupsFilterButtonState.current}
                                onClick={onAllGroupsLinkFilterClicked}
                                stateTitles={{
                                    open: 'Showing links for all folders',
                                    closed: 'Hiding links in all folders',
                                    show_active:
                                        'Showing only folder-links which are active for the selected user'
                                }}
                            />
                            Folders
                        </GridSection>

                        <GroupRows
                            blockAdjacency={blockAdjacency}
                            groups={groups}
                            collapsedGroupExceptions={collapsedGroupExceptions}
                            knownEmails={filtered.knownEmails}
                            academies={filtered.academies}
                            jobFamilies={filtered.jobFamilies}
                            regions={filtered.regions}
                            changedGroups={changedGroups.current}
                            permissionGroupId={permissionGroupId}
                            emphasizedRowBackgroundColor={emphasizedRowBackgroundColor}
                            transitionStr={transitionStr}
                            onGroupPermChanged={onGroupPermChanged}
                            onLinkPermChanged={onLinkPermChanged}
                            onClick={toggleEmphasizedGroup}
                            onLinkClick={toggleEmphasizedGroupLink}
                            onLinkFilterClicked={onGroupLinkFilterClicked}
                            emphasized={emphasized}
                            selectedUserLookups={selectedUserLookups.current}
                        />
                        <GridSection
                            title='Admin Permissions'
                            colWidths={[
                                knownEmailCount,
                                jobFamilyCount,
                                academyCount,
                                regionCount
                            ]}
                            top={gridSectionTop}
                            height={gridSectionHeight}
                            backgroundColor='#D60808'
                            // backgroundColor={theme.palette.primary.main}
                            borderColor={theme.palette.primary.main}
                        />
                        <PermissionsRows
                            blockAdjacency={blockAdjacency}
                            permissions={permissions}
                            knownEmails={filtered.knownEmails}
                            jobFamilies={filtered.jobFamilies}
                            academies={filtered.academies}
                            regions={filtered.regions}
                            changedPerms={changedPerms.current}
                            transitionStr={transitionStr}
                            onPermAssignmentChanged={onPermAssignmentChanged}
                            onClick={toggleEmphasizedPermissions}
                            emphasized={emphasized}
                        />
                        <GridSection
                            title='Per-Academy Permissions'
                            colWidths={[
                                knownEmailCount,
                                jobFamilyCount,
                                academyCount,
                                regionCount
                            ]}
                            top={gridSectionTop}
                            height={gridSectionHeight}
                            backgroundColor='#EB7003'
                            // backgroundColor={theme.palette.primary.main}
                            borderColor={theme.palette.primary.main}
                        />
                        <PerAcademyPermissionRows
                            blockAdjacency={blockAdjacency}
                            perAcademyPermissions={perAcademyPermissions}
                            academyCodeLookup={academyCodeLookup}
                            collapsedPerAcademyPermissionExceptions={
                                collapsedPerAcademyPermissionExceptions
                            }
                            knownEmails={filtered.knownEmails}
                            jobFamilies={filtered.jobFamilies}
                            academies={filtered.academies}
                            regions={filtered.regions}
                            changedPerAcademyPerms={changedPerAcademyPerms.current}
                            transitionStr={transitionStr}
                            gridSectionTop={gridSectionNestedTop}
                            gridSectionHeight={gridSectionHeight}
                            onPerAcademyPermAssignmentChanged={
                                onPerAcademyPermAssignmentChanged
                            }
                            onPerAcademyPermissionClick={
                                toggleEmphasizedPerAcademyPermission
                            }
                            onPerAcademyPermissionsAcademyFilterClicked={
                                onPerAcademyPermissionsAcademyFilterClicked
                            }
                            emphasized={emphasized}
                        />
                    </>
                )}
            </Box>

            <ButtonBar
                initialise={initialise}
                saveChanges={saveChanges}
                unsavedChanges={unsavedChanges}
                onSettingsClicked={onSettingsClicked}
                settingsButtonDisabled={showSettings || unsavedChanges}
            />
            <AdHocPermissions
                open={showSettings}
                onCancel={onSettingsCancel}
                onOk={onSettingsOk}
                initialKnownEmails={knownEmails}
                messages={settingsMessages}
            />
        </>
    )
}
