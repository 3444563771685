import PasteIcon from '@mui/icons-material/ContentPaste'
import { alpha, styled } from '@mui/material'

interface NavPasteLinkIconProps {
    altColor: string
    allBlue: boolean
}
export const NavPasteLinkIcon = styled(PasteIcon, {
    shouldForwardProp: prop => {
        const include = !['altColor', 'allBlue'].includes(prop.toString())
        return include
    }
})<NavPasteLinkIconProps>(({ theme, altColor, allBlue }) => ({
    'width': 24,
    'height': 24,
    'color': alpha(allBlue ? '#ffffff' : altColor, 0.256),
    'transition': `${theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short
    })}`,
    '&:hover': {
        color: '#ffffff',
        transform: 'scale(1.15)'
    }
}))
