/* eslint-disable import/extensions */
import { useEffect, useState } from 'react'

// Include special components if required.
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

import { Box, Card, Typography } from '@mui/material'
import { Timestamp } from 'firebase/firestore'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { useNavigate, useParams } from 'react-router-dom'
import { getPostPublicNews } from 'service/controller/Post'
import { PostModel } from 'service/model/Post'

export default function HomePage() {
    const navigate = useNavigate()
    const { id } = useParams()

    const [postDataRows, setPostDataRows] = useState<PostModel[]>([])

    async function fetchData(_id: string) {
        // eslint-disable-next-line no-console
        let posts: PostModel[] = []
        posts = await getPostPublicNews(_id)
        if (posts.length > 0) {
            if (posts[0].postVisibility === 'public') {
                setPostDataRows(posts)
            } else {
                navigate(`/${id}`, { replace: true })
            }
        }
    }

    useEffect(() => {
        if (id) {
            fetchData(id)
        }
    }, [id])

    const contentBody = () => {
        let content: JSX.Element | null = (
            <Typography variant='h5'>Error Occured!!!</Typography>
        )

        if (postDataRows && postDataRows.length > 0) {
            if (
                postDataRows[0].expiryDate !== null &&
                postDataRows[0].expiryDate > Timestamp.now()
            ) {
                content = <FroalaEditorView model={postDataRows[0].content} />
            } else {
                content = (
                    <Typography variant='h6'>
                        Content expired. Ref. {postDataRows[0].id};
                    </Typography>
                )
            }
        } else {
            content = null
        }

        return content
    }

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '20px',
                marginLeft: '20px',
                width: 'calc(100% - 40px)'
                // height: { height },
                // border: 1
            }}
        >
            <Box>{contentBody()}</Box>
        </Card>
    )
}
