import { Timestamp } from 'firebase/firestore'

export declare type HeaderTypeOptions = 'static' | 'absolute'
export declare type LinksColourOptions = 'white' | 'black'
export declare type WidgetOptions =
    | 'none'
    | 'new'
    | 'trending'
    | 'notification'
    | 'interest'
export declare type QueryTypeOptions = 'or' | 'and'
export enum FilterTypeOptions {
    jobfamily = 'jobfamily',
    academic = 'academic',
    region = 'region',
    googleGroups = 'googleGroups'
}
export declare type PostVisibilityOptions = 'public' | 'private' | 'members'
export declare type PostAcademyGroupOptions = 'None' | 'Primary' | 'Secondary' | 'Special'
export declare type TileSizeOptions = 'single' | 'double'
export declare type PostTypeOptions =
    | 'news'
    | 'notification'
    // | 'announcement'
    | 'popupannouncement'
    | 'stat'
export declare type PostTypeAnnouncementStatus = 'later' | 'never'
export declare type PostStatusOptions = 'draft' | 'published' | 'archived'
export declare type PostPriorityOptions = 'High' | 'Medium' | 'Standard' | 'Low'
export declare type PostJobFamilyOptions =
    | 'All'
    | 'Administrator'
    | 'Teacher'
    | 'Principal'

export interface PostStatsModel {
    id: string
    likeCount: number | undefined
    readCount: string | undefined
    contentClickedCount: string | undefined

    last7DaysContentClicked: number | undefined
    lastMonthContentClicked: number | undefined

    last7DaysRead: number | undefined
    lastMonthRead: number | undefined

    last7DaysLiked: number | undefined
    lastMonthLiked: number | undefined
}
export interface Option {
    name: string
    id: string
}

export interface PostModel {
    id: string
    title: string
    slug: string
    link: string
    thumbnail: string
    content: string
    headerType: HeaderTypeOptions
    postType: PostTypeOptions
    linkColour: LinksColourOptions
    widget: WidgetOptions
    queryType: QueryTypeOptions
    postVisibility: PostVisibilityOptions
    tileSize: TileSizeOptions
    postStatus: PostStatusOptions
    postPriority: PostPriorityOptions
    postJobFamily: string[]
    postRegion: string[]
    postAcademy: string[]
    ownedBy: string
    createdAt: Timestamp | null
    updatedAt: Timestamp | null
    score: number | null
    academyGroups: string[]
    liveDate: Timestamp | null
    expiryDate: Timestamp | null
    selectedGoogleGroups: Array<Option>
    selectedGoogleGroupIds: Array<string>
}
