// import { log } from 'service/utils'

export const debounce = <F extends (...data: any[]) => any>(func: F, delay = 250) => {
    let timeoutHandle: NodeJS.Timeout | undefined

    const cancel = () => {
        if (timeoutHandle) {
            clearTimeout(timeoutHandle)
            timeoutHandle = undefined
        }
    }

    const debounced = (...parms: Parameters<F>) => {
        cancel()
        timeoutHandle = setTimeout(() => func(parms), delay)
    }

    return [debounced, cancel]
}

// let counter = 0

// const [logCounter] = debounce((msg: string) => {
//     log(`${msg}: ${counter}`)
// }, 5000)

// const logInc = () => {
//     logCounter(`At ${new Date()}, the counter value was`)
//     counter += 1
// }
// logInc()
// logInc()
// logInc()
// logInc()
//
// -> At <date-time>, the counter value was: 3
