import { Autocomplete, Box, TextField } from '@mui/material'
import { Store } from 'appData/store'
import { altColor } from 'components/navbar/types'
import { useCustomTheme } from 'hooks/useCustomTheme'
import React, { memo } from 'react'
import { nameOrEmailPrefix } from 'service/utils'

const Header = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box
            sx={{
                backgroundColor: altColor,
                color: '#ffffff'
            }}
        >
            {children}
        </Box>
    )
}
const Item = ({ children }: { children: React.ReactNode }) => {
    return (
        <ul
            style={{
                backgroundColor: '#ffffff',
                color: altColor
            }}
        >
            {children}
        </ul>
    )
}
export const UsersDropDown = memo(
    ({
        userSummary,
        selectedUser,
        selectedUserChanged
    }: {
        userSummary: Store['userSummary']
        selectedUser?: Store['userSummary'][0]
        selectedUserChanged: (
            event: React.SyntheticEvent,
            value: Store['userSummary'][0] | null
        ) => void
    }) => {
        const { theme } = useCustomTheme()

        const noDomain = '__no-domain'

        const domainSortedUsers = userSummary.sort(
            (
                { domain: domain1, name: name1, email: email1 },
                { domain: domain2, name: name2, email: email2 }
            ) =>
                domain1 !== domain2
                    ? (domain1 || noDomain).localeCompare(domain2 || noDomain)
                    : nameOrEmailPrefix(name1, email1).localeCompare(
                          nameOrEmailPrefix(name2, email2)
                      )
        )
        return (
            <Autocomplete
                disableClearable
                disablePortal
                getOptionLabel={({ name, email }) => nameOrEmailPrefix(name, email)}
                // filterOptions={x => x}
                isOptionEqualToValue={({ name: name1 }, { name: name2 }) =>
                    name1 === name2
                }
                groupBy={({ domain }) => domain || noDomain}
                options={domainSortedUsers}
                sx={{
                    'margin': 1,
                    'position': 'sticky',
                    'backgroundColor': theme.palette.common.darkGreen,
                    'mt': 1,
                    '& .MuiFormLabel-root': {
                        color: '#ffffff !important'
                    },
                    '& fieldset': {
                        borderColor: '#ffffff60 !important'
                    },
                    '&:hover fieldset': {
                        borderColor: '#ffffff !important'
                    },
                    '& .MuiButtonBase-root': {
                        color: '#ffffff'
                    },
                    '& .MuiInputBase-input': {
                        color: '#ffffff',
                        borderColor: '#ffffff'
                    }
                }}
                renderInput={params => <TextField {...params} label='User' />}
                value={selectedUser}
                renderGroup={({ group, children }) => (
                    <li key={group}>
                        <Header>{group}</Header>
                        <Item>{children}</Item>
                    </li>
                )}
                onChange={selectedUserChanged}
            />
        )
    }
)
