import { toast, TypeOptions } from 'react-toastify'

export const SuccessNotification: (message: string) => void = message =>
    Notification(message, 'success')

export const ErrorNotification: (message: string) => void = message =>
    Notification(message, 'error')

export const WarningNotification: (message: string) => void = message =>
    Notification(message, 'error')

const Notification: (message: string, type: TypeOptions) => void = (message, type) => {
    // const { fontFamily } = useCustomTheme();
    toast.info(message, {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        type,
        style: { marginBottom: 45, borderRadius: 5 }
    })
}

export default Notification
