/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { IMyFocusFilter } from 'appData/store'
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    Query,
    query,
    QueryDocumentSnapshot,
    runTransaction,
    setDoc,
    Timestamp,
    updateDoc,
    where
} from 'firebase/firestore'
import _ from 'lodash'
import { db, postsCollection, postsStatsCollection } from 'service/firebase'
import {
    FilterTypeOptions,
    PostModel,
    PostStatsModel,
    QueryTypeOptions
} from 'service/model/Post'
import { venn } from 'service/utils'
import { getContentByIdInArry, getContentByIdNotInArry } from './FirestoreTools'

export const queryContentClicked = async (userID: string, postID: string) => {
    const docRef = doc(db, `tiles/${postID}/tileContentClicked`, userID)
    const likedDoc = await getDoc(docRef)
    return likedDoc.data()
}

export const addContentClickedToPost = async (userID: string, postModel: PostModel) => {
    const docRef = doc(db, `tiles/${postModel.id}/tileContentClicked`, userID)
    await setDoc(docRef, {
        id: postModel.id,
        title: postModel.title,
        createdAt: Timestamp.now()
    })
}

// export const getPostPublicNews = async (id: string) => {
//     const postsData: PostModel[] = []
//     const postDocRef = doc(postsCollection, id)
//     const postDoc = await getDoc(postDocRef)
//     const post = postDoc.data()
//     if (post !== undefined) {
//         postsData.push({ ...post, id: postDoc.id })
//     }

//     return postsData
// }

export const getPostPublicNews = async (id: string) => {
    const postsData: PostModel[] = []
    const postQuery = query(postsCollection, where('slug', '==', id))

    const postDocs = await getDocs(postQuery)
    if (postDocs.docs.length > 0) {
        const post = postDocs.docs[0].data()

        if (post !== undefined) {
            postsData.push({ ...post, id: postDocs.docs[0].id })
        }
    }

    return postsData
}

export const getPost = async (id: string) => {
    const postDocRef = doc(postsCollection, id)
    const postDoc = await getDoc(postDocRef)
    return postDoc.data()
}

export const getPostStats = async (id: string) => {
    const postDocRef = doc(postsStatsCollection, id)
    const postDoc = await getDoc(postDocRef)
    return postDoc.data()
}

export const getPostStatsMore = async (id: string) => {
    const postDocRef = doc(postsStatsCollection, id)
    const postDoc = await getDoc(postDocRef)
    const postStat: PostStatsModel | undefined = postDoc.data()

    let d = new Date()
    d.setDate(d.getDate() - 7)
    let postQuery = query(
        collection(db, `tiles/${id}/tileContentClicked`),
        where('createdAt', '>=', d)
    )
    let Docs = await getDocs(postQuery)
    if (!postStat) {
        return postStat
    }
    postStat.last7DaysContentClicked = Docs.size

    d = new Date()
    d.setDate(d.getDate() - 30)
    postQuery = query(
        collection(db, `tiles/${id}/tileContentClicked`),
        where('createdAt', '>=', d)
    )
    Docs = await getDocs(postQuery)
    postStat.lastMonthContentClicked = Docs.size

    d = new Date()
    d.setDate(d.getDate() - 7)
    postQuery = query(collection(db, `tiles/${id}/tileRead`), where('createdAt', '>=', d))
    Docs = await getDocs(postQuery)
    if (!postStat) {
        return postStat
    }
    postStat.last7DaysRead = Docs.size

    // console.log('stat30', id, postStat.last7DaysRead)

    d = new Date()
    d.setDate(d.getDate() - 30)
    // postQuery = query(
    //     (postQuery = query(
    //         collection(db, `tiles/${id}/tileRead`),
    //         where('createdAt', '>=', d)
    //     ))
    // )
    postQuery = query(collection(db, `tiles/${id}/tileRead`), where('createdAt', '>=', d))
    Docs = await getDocs(postQuery)
    postStat.lastMonthRead = Docs.size

    d = new Date()
    d.setDate(d.getDate() - 7)
    postQuery = query(
        collection(db, `tiles/${id}/tileLiked`),
        where('createdAt', '>=', d)
    )
    Docs = await getDocs(postQuery)
    if (!postStat) {
        return postStat
    }
    postStat.last7DaysLiked = Docs.size

    d = new Date()
    d.setDate(d.getDate() - 30)
    postQuery = query(
        collection(db, `tiles/${id}/tileLiked`),
        where('createdAt', '>=', d)
    )
    Docs = await getDocs(postQuery)
    postStat.lastMonthLiked = Docs.size

    return postStat
}

// export const getPosts = async () => {
//     const postDocs = await getDocs(postsCollection)
//     postDocs.docs.forEach(postDoc => {
//         const post = postDoc.data()
//         console.log(post.title)
//     })
// }

export const getPostsWithQuery = async (query_: Query<PostModel>) =>
    getDocs<PostModel>(query_)

export const getLastPostCreatedAt = async () => {
    // eslint-disable-next-line no-console
    console.log('getLastPostsCreatedAt')

    const postQuery = query(postsCollection, orderBy('createdAt', 'desc'), limit(1))
    // const postQuery = query(postsCollection)
    const postDocs = await getDocs(postQuery)

    return postDocs.docs[0].data().createdAt
}

export const getAllPosts = async (
    filter: string,
    userID: string,
    userPerAcademyPermissions: string[] | undefined,
    postType: string
) => {
    // eslint-disable-next-line no-console
    // console.log('getAllPosts-userID-postType', userID, postType)

    let postDocumentSnapshot: QueryDocumentSnapshot<PostModel>[] | null = null
    const postsData: PostModel[] = []

    let queryConst = []

    // queryConst.push(where('postType', '==', postType))
    // queryConst.push(orderBy('createdAt', 'desc'))

    // const postQuery = query(postsCollection, ...queryConst)
    // const postDocs = await getDocs(postQuery)

    // postDocs.docs.forEach(postDoc => {
    //     postsData.push({ ...postDoc.data(), id: postDoc.id })
    // })

    if (postType === 'news') {
        queryConst.push(where('postType', '==', postType))

        if (userPerAcademyPermissions && userPerAcademyPermissions.length > 0) {
            queryConst.push(
                where('postAcademy', 'array-contains-any', userPerAcademyPermissions)
            )
        }
        queryConst.push(orderBy('createdAt', 'desc'))

        const postQuery = query(postsCollection, ...queryConst)
        const postDocs = await getDocs(postQuery)

        postDocs.docs.forEach(postDoc => {
            postsData.push({ ...postDoc.data(), id: postDoc.id })
        })
    } else {
        queryConst.push(where('postType', '==', 'notification'))
        if (userPerAcademyPermissions && userPerAcademyPermissions.length > 0) {
            queryConst.push(
                where('postAcademy', 'array-contains-any', userPerAcademyPermissions)
            )
        }
        queryConst.push(orderBy('createdAt', 'desc'))

        const postNotificationQuery = query(postsCollection, ...queryConst)
        const postDocsNotification = getDocs(postNotificationQuery)

        queryConst = []
        queryConst.push(where('postType', '==', 'popupannouncement'))
        if (userPerAcademyPermissions && userPerAcademyPermissions.length > 0) {
            queryConst.push(
                where('postAcademy', 'array-contains-any', userPerAcademyPermissions)
            )
        }
        queryConst.push(orderBy('createdAt', 'desc'))

        const postPopupAnnouncementQuery = query(postsCollection, ...queryConst)
        const postDocsPopupAnnouncement = getDocs(postPopupAnnouncementQuery)

        const [
            postDocsNotificationSnapshot,
            postDocsPopupAnnouncementSnapshot
            // postDocsAcademySnapshot
        ] = await Promise.all([postDocsNotification, postDocsPopupAnnouncement])

        const postDocsNotificationData = postDocsNotificationSnapshot.docs
        const postDocsPopupAnnouncementSnapshotData =
            postDocsPopupAnnouncementSnapshot.docs

        postDocumentSnapshot = _.concat(
            postDocsNotificationData,
            postDocsPopupAnnouncementSnapshotData
        )

        postDocumentSnapshot.forEach(postDoc => {
            postsData.push({ ...postDoc.data(), id: postDoc.id })
        })
    }

    return postsData
}

const fetchReadLikePosts = async (ids: string[], queryConst: any[]) => {
    queryConst.push(orderBy('createdAt', 'desc'))

    queryConst.push(limit(100))

    const postQuery = query(postsCollection, ...queryConst)
    const postDocs = await getDocs(postQuery)

    const postsData = await getContentByIdInArry<PostModel>(ids, postDocs.docs)

    return postsData
}

export const getPostsRead = async (userID: string, myFocusFilter: IMyFocusFilter) => {
    // eslint-disable-next-line no-console
    console.log('getPostsRead-userID', userID)
    let regionPosts: PostModel[] = []
    let academicsPosts: PostModel[] = []
    let jobFamiliesPosts: PostModel[] = []
    let ggPosts: PostModel[] = []

    // const postsData: PostModel[] = []

    const qUsers = query(collection(db, `users/${userID}/userRead`))
    const queryUserSnapshot = await getDocs(qUsers)

    const ids = queryUserSnapshot?.docs?.map(readDoc => readDoc.id)

    // const postQuery = query(postsCollection, orderBy('createdAt', 'desc'))

    const queryConst = []

    const { region, academics, jobFamilies, googleGroups } = myFocusFilter

    if (jobFamilies.length > 0) {
        queryConst.push(where('postJobFamily', 'array-contains', jobFamilies))
        jobFamiliesPosts = await fetchReadLikePosts(ids, queryConst)
    }

    if (googleGroups.length > 0) {
        queryConst.push(
            where('selectedGoogleGroupIds', 'array-contains-any', googleGroups)
        )
        ggPosts = await fetchReadLikePosts(ids, queryConst)
    }

    if (region !== '') {
        queryConst.push(where('postRegion', 'array-contains', [region]))
        regionPosts = await fetchReadLikePosts(ids, queryConst)
    }

    if (academics.length > 0) {
        queryConst.push(
            where(
                'postAcademy',
                'array-contains',
                academics.map(academic => academic.academyCode)
            )
        )
        academicsPosts = await fetchReadLikePosts(ids, queryConst)
    }

    // If any of the filter applied
    if (queryConst.length > 0) {
        const merged = [
            ...regionPosts,
            ...academicsPosts,
            ...jobFamiliesPosts,
            ...ggPosts
        ]

        let postAllQueryResult = merged
        postAllQueryResult = _.uniqWith(postAllQueryResult, _.isEqual)

        // orderby desc createdAt
        const compareCreateAt = (a: PostModel, b: PostModel) => {
            if (
                a.createdAt &&
                a.createdAt !== null &&
                b.createdAt &&
                b.createdAt !== null
            ) {
                if (
                    (a.createdAt as Timestamp).toMillis() >
                    (b.createdAt as Timestamp).toMillis()
                ) {
                    return -1
                }
                if (
                    (a.createdAt as Timestamp).toMillis() <
                    (b.createdAt as Timestamp).toMillis()
                ) {
                    return 1
                }
            }

            return 0
        }
        postAllQueryResult = postAllQueryResult.sort(compareCreateAt)

        return postAllQueryResult
    }

    const posts = await fetchReadLikePosts(ids, queryConst)
    return posts
}

export const getPostsLike = async (userID: string, myFocusFilter: IMyFocusFilter) => {
    // eslint-disable-next-line no-console
    console.log('getPostsRead-userID', userID)
    let regionPosts: PostModel[] = []
    let academicsPosts: PostModel[] = []
    let jobFamiliesPosts: PostModel[] = []
    let ggPosts: PostModel[] = []

    const qUsers = query(collection(db, `users/${userID}/userLiked`))
    const queryUserSnapshot = await getDocs(qUsers)

    const ids = queryUserSnapshot?.docs?.map(readDoc => readDoc.id)

    const queryConst = []

    const { region, academics, jobFamilies, googleGroups } = myFocusFilter

    if (jobFamilies.length > 0) {
        queryConst.push(where('postJobFamily', 'array-contains', jobFamilies))
        jobFamiliesPosts = await fetchReadLikePosts(ids, queryConst)
    }

    if (googleGroups.length > 0) {
        queryConst.push(
            where('selectedGoogleGroupIds', 'array-contains-any', googleGroups)
        )
        ggPosts = await fetchReadLikePosts(ids, queryConst)
    }

    if (region !== '') {
        queryConst.push(where('postRegion', 'array-contains', [region]))
        regionPosts = await fetchReadLikePosts(ids, queryConst)
    }

    if (academics.length > 0) {
        queryConst.push(
            where(
                'postAcademy',
                'array-contains',
                academics.map(academic => academic.academyCode)
            )
        )
        academicsPosts = await fetchReadLikePosts(ids, queryConst)
    }
    // If any of the filter applied
    if (queryConst.length > 0) {
        const merged = [
            ...regionPosts,
            ...academicsPosts,
            ...jobFamiliesPosts,
            ...ggPosts
        ]

        let postAllQueryResult = merged
        postAllQueryResult = _.uniqWith(postAllQueryResult, _.isEqual)

        // orderby desc createdAt
        const compareCreateAt = (a: PostModel, b: PostModel) => {
            if (
                a.createdAt &&
                a.createdAt !== null &&
                b.createdAt &&
                b.createdAt !== null
            ) {
                if (
                    (a.createdAt as Timestamp).toMillis() >
                    (b.createdAt as Timestamp).toMillis()
                ) {
                    return -1
                }
                if (
                    (a.createdAt as Timestamp).toMillis() <
                    (b.createdAt as Timestamp).toMillis()
                ) {
                    return 1
                }
            }

            return 0
        }
        postAllQueryResult = postAllQueryResult.sort(compareCreateAt)

        return postAllQueryResult
    }

    const posts = await fetchReadLikePosts(ids, queryConst)
    return posts
}

// export const getPosts = async (filter: string, userID: string) => {
//     // eslint-disable-next-line no-console
//     console.log('getPosts-userID', filter, userID)
//     const queryConst = []

//     if (userID) {
//         const qUsers = query(collection(db, `users/${userID}/userRead`))
//         const queryUserSnapshot = await getDocs(qUsers)

//         const ids = queryUserSnapshot?.docs?.map(readDoc => readDoc.id)
//         if (ids.length > 0) {
//             queryConst.push(where(documentId(), 'not-in', ids))
//         }
//     }

//     if (!filter) {
//         // ...
//     } else if (filter !== 'relevant') {
//         queryConst.push(where('widget', '==', filter))
//     } else {
//         // queryConst.push(orderBy('score', 'desc'))
//     }

//     const postsData: PostModel[] = []

//     const postQuery = query(postsCollection, ...queryConst)
//     const postDocs = await getDocs(postQuery)

//     postDocs.docs.forEach(postDoc => {
//         postsData.push({ ...postDoc.data(), id: postDoc.id })
//     })

//     return postsData
// }

const fnQueryConst = (filter: string, queryType: QueryTypeOptions) => {
    // const nowTimestamp = Timestamp.now()
    const queryConst = []

    switch (filter) {
        case 'new':
            // console.log('filter', filter)
            queryConst.push(where('queryType', '==', queryType))
            queryConst.push(orderBy('createdAt', 'desc'))
            break
        case 'trending':
            queryConst.push(where('widget', '==', filter))
            queryConst.push(where('queryType', '==', queryType))
            queryConst.push(orderBy('createdAt', 'desc'))
            break
        case 'expired':
            queryConst.push(where('queryType', '==', queryType))
            queryConst.push(orderBy('createdAt', 'desc'))
            break

        default:
            // relevant
            // queryConst.push(where('score', '>', 0))
            queryConst.push(where('queryType', '==', queryType))
            queryConst.push(orderBy('score', 'desc'))
            queryConst.push(orderBy('createdAt', 'desc'))
    }

    // queryConst.push(where('academyGroups', '<', Timestamp.now()))
    queryConst.push(limit(100))

    return queryConst
}

const convertpostDocumentSnapshotToPostsData = (
    postDocumentSnapshot: QueryDocumentSnapshot<PostModel>[]
) => {
    const postsData: PostModel[] = []
    postDocumentSnapshot.forEach(postDoc => {
        postsData.push({ ...postDoc.data(), id: postDoc.id })
    })
    return postsData
}

const postCheckUserReadPosts = async (
    userID: string,
    postDocumentSnapshot: QueryDocumentSnapshot<PostModel>[]
) => {
    let postsData: PostModel[] = []
    let isFullList = false
    if (userID) {
        const qUsers = query(collection(db, `users/${userID}/userRead`))
        const queryUserSnapshot = await getDocs(qUsers)

        const ids = queryUserSnapshot?.docs?.map(readDoc => readDoc.id)
        if (ids.length > 0) {
            // eslint-disable-next-line no-console
            // console.log('getContentByIdNotInArry', filter, userID)
            postsData = getContentByIdNotInArry<PostModel>(ids, postDocumentSnapshot)

            // console.log('postsData-length', postsData.length)
        } else {
            isFullList = true
        }
    } else {
        isFullList = true
    }

    if (isFullList && postDocumentSnapshot) {
        postsData = convertpostDocumentSnapshotToPostsData(postDocumentSnapshot)
    }

    return postsData
}

const postCheckUserReadPostsPopupAnnouncement = async (
    userID: string,
    postDocumentSnapshot: QueryDocumentSnapshot<PostModel>[]
) => {
    let postsData: PostModel[] = []
    let isFullList = false

    if (userID) {
        const qUsers = query(collection(db, `users/${userID}/userPopupAnnouncement`))
        const queryUserSnapshot = await getDocs(qUsers)

        const ids = queryUserSnapshot?.docs?.map(readDoc => readDoc.id)
        if (ids.length > 0 && postDocumentSnapshot) {
            // eslint-disable-next-line no-console
            // console.log('getContentByIdNotInArry', 'All', userID)
            postsData = await getContentByIdNotInArry<PostModel>(
                ids,
                postDocumentSnapshot
            )
            // console.log('postsData-length', postsData.length)
        } else {
            isFullList = true
        }
    } else {
        isFullList = true
    }

    if (isFullList && postDocumentSnapshot) {
        convertpostDocumentSnapshotToPostsData(postDocumentSnapshot)
    }

    return postsData
}

const postNotReadLocalFilters = (
    postsData: PostModel[],
    isPopupAnnouncement: boolean
) => {
    const nowSeconds = Timestamp.now().seconds
    const extraFilter: PostModel[] = []

    postsData.forEach(data => {
        if (
            data &&
            data?.liveDate &&
            data?.expiryDate &&
            data?.liveDate?.seconds < nowSeconds &&
            data?.expiryDate?.seconds > nowSeconds
            // && (isPopupAnnouncement || data?.postType !== 'popupannouncement')
        ) {
            extraFilter.push(data)
        }
    })

    return extraFilter
}

const postExpiredLocalFilters = (
    postsData: PostModel[] // ,
    // isPopupAnnouncement: boolean
) => {
    const nowSeconds = Timestamp.now().seconds
    const extraFilter: PostModel[] = []

    postsData.forEach(data => {
        if (
            data &&
            data?.liveDate &&
            data?.expiryDate &&
            data?.expiryDate?.seconds < nowSeconds // &&
            // (isPopupAnnouncement || data?.postType !== 'popupannouncement')
        ) {
            extraFilter.push(data)
        }
    })

    return extraFilter
}

const postReadExactRestrictionFilter = async (
    filterType: FilterTypeOptions,
    filter: IMyFocusFilter,
    queryType: QueryTypeOptions
) => {
    let queryConst = []

    const { region, academics, jobFamilies, googleGroups } = filter

    console.log(
        filterType,
        FilterTypeOptions.googleGroups,
        filterType === FilterTypeOptions.googleGroups
    )

    if (googleGroups.length > 0 && filterType === FilterTypeOptions.googleGroups) {
        queryConst.push(
            where('selectedGoogleGroupIds', 'array-contains-any', [...googleGroups])
        )
    }

    if (jobFamilies.length > 0 && filterType === FilterTypeOptions.jobfamily) {
        queryConst.push(
            where('postJobFamily', 'array-contains-any', ['All', ...jobFamilies])
        )
    }

    if (region !== '' && filterType === FilterTypeOptions.region) {
        queryConst.push(where('postRegion', 'array-contains-any', ['All', region]))
    }

    if (academics.length > 0 && filterType === FilterTypeOptions.academic) {
        queryConst.push(
            where('postAcademy', 'array-contains-any', [
                ...academics.map(academic => academic.academyCode),
                'All'
            ])
        )
    }

    // if (restriction === 'jobfamilies') {
    //     queryConst.push(where('postJobFamily', 'array-contains', filter))
    // }
    // if (restriction === 'region') {
    //     queryConst.push(where('postRegion', 'array-contains', filter))
    // }
    // if (restriction === 'academy') {
    //     queryConst.push(where('postAcademy', 'array-contains', filter))
    // }
    // if (restriction === 'academyGroups') {
    //     queryConst.push(where('academyGroups', 'array-contains', filter))
    // }

    queryConst = [...queryConst, ...fnQueryConst('filter', queryType)]

    // console.log('queryConst => ', queryConst)

    // queryConst.push(limit(100))

    const postQuery = query(postsCollection, ...queryConst)
    const postQuerySnapshot = await getDocs(postQuery)
    return postQuerySnapshot.docs
}

export const getPostsNotRead = async (
    filter: string,
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined,
    myFocusFilter: IMyFocusFilter
) => {
    const postOrQuery = getPostsNotReadNewOrCompound(
        filter,
        userID,
        userJobFamilies,
        userRegions,
        userAcademies,
        userAcademyGroups,
        myFocusFilter
    )

    const postAndQuery = getPostsNotReadAndCompound(
        filter,
        userID,
        userJobFamilies,
        userRegions,
        userAcademies,
        userAcademyGroups,
        myFocusFilter
    )

    const [postOrQueryResult, postAndQueryResult] = await Promise.all([
        postOrQuery,
        postAndQuery
    ])

    let postAllQueryResult = _.concat(postOrQueryResult, postAndQueryResult)
    postAllQueryResult = _.uniqWith(postAllQueryResult, _.isEqual)

    const ccc = postAllQueryResult.map(x => {
        return { title: x.title, score: x.score, createdAt: x.createdAt }
    })

    // orderby desc createdAt
    const compareCreateAt = (a: PostModel, b: PostModel) => {
        if (a.createdAt && a.createdAt !== null && b.createdAt && b.createdAt !== null) {
            return (
                (b.createdAt as Timestamp).toMillis() * 1 -
                (a.createdAt as Timestamp).toMillis() * 1
            )
        }
        return 0
    }

    // orderby desc score
    const compareScore = (a: PostModel, b: PostModel) => {
        if ((a.score as number) > (b.score as number)) {
            return -1
        }
        if ((a.score as number) < (b.score as number)) {
            return 1
        }
        return 0
    }

    postAllQueryResult = postAllQueryResult.sort(compareScore)

    postAllQueryResult = postAllQueryResult.sort(compareCreateAt)

    return postAllQueryResult
}

export const getPostsNotReadNewOrCompound = async (
    filter: string,
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined,
    myFocusFilter: IMyFocusFilter
) => {
    // log(`🍅🍅🍅 getPostsNotRead - ${json(userJobFamilies)}`)
    // eslint-disable-next-line no-console
    // console.log('getPostsNotRead', filter, myFocusFilter, userJobFamilies, userID)
    let queryConst = []
    let postsData: PostModel[] = []
    let postDocumentSnapshot: QueryDocumentSnapshot<PostModel>[] = []

    const arryPromisArry = []

    const hasFilter =
        myFocusFilter.academics.length > 0 ||
        myFocusFilter.jobFamilies.length > 0 ||
        myFocusFilter.region !== '' ||
        myFocusFilter.googleGroups.length > 0

    if (!hasFilter) {
        if (userJobFamilies && userJobFamilies?.length > 0) {
            const myFocusArray = ['All', ...userJobFamilies]
            queryConst.push(where('postJobFamily', 'array-contains-any', myFocusArray))
            queryConst = [...queryConst, ...fnQueryConst(filter, 'or')]

            const postQueryJobFamily = query(postsCollection, ...queryConst)
            const postDocsJobFamily = await getDocs(postQueryJobFamily)

            arryPromisArry.push(postDocsJobFamily)
        }

        /* else {
            queryConst.push(where('postJobFamily', 'array-contains', ['All']))
        } */

        // queryConst = []

        // if (userRegions && userRegions.length > 0) {
        //     // console.log('userRegions', userRegions)
        //     const myFocusArray = ['All', ...userRegions]
        //     queryConst.push(where('postRegion', 'array-contains-any', myFocusArray))
        // } else {
        //     const myFocusArray = ['All']
        //     queryConst.push(where('postRegion', 'array-contains', myFocusArray))
        // }

        // queryConst = [...queryConst, ...fnQueryConst(filter, 'or')]

        // const postQueryRegion = query(postsCollection, ...queryConst)
        // const postDocsRegion = getDocs(postQueryRegion)

        // arryPromisArry.push(postDocsRegion)

        queryConst = []

        if (userAcademies && userAcademies.length > 0) {
            const myFocusArray = ['All', ...userAcademies]
            queryConst.push(where('postAcademy', 'array-contains-any', myFocusArray))
            queryConst = [...queryConst, ...fnQueryConst(filter, 'or')]

            const postQueryAcademy = query(postsCollection, ...queryConst)
            const postDocsAcademy = await getDocs(postQueryAcademy)

            arryPromisArry.push(postDocsAcademy)
        } /* else {
            const myFocusArray = ['All']
            queryConst.push(where('postAcademy', 'array-contains', myFocusArray))
        } */

        // queryConst = []

        // if (userAcademyGroups && userAcademyGroups.length > 0) {
        //     const myFocusArray = ['All', 'None', ...userAcademyGroups]
        //     queryConst.push(where('academyGroups', 'array-contains-any', myFocusArray))
        // } else {
        //     queryConst.push(where('academyGroups', 'array-contains-any', ['All', 'None']))
        // }

        // queryConst = [...queryConst, ...fnQueryConst(filter, 'or')]

        // const postQueryAcademyGroups = query(postsCollection, ...queryConst)
        // const postDocsAcademyGroups = getDocs(postQueryAcademyGroups)

        // const [
        //     postDocsJobFamilySnapshot,
        //     postDocsRegionSnapshot,
        //     postDocsAcademySnapshot,
        //     postDocsAcademyGroupsSnapshot
        // ] = await Promise.all(arryPromisArry)

        // const postDocsJobFamilyData = postDocsJobFamilySnapshot.docs
        // const postDocsRegionData = postDocsRegionSnapshot.docs
        // const postDocsAcademyData = postDocsAcademySnapshot.docs
        // const postDocsAcademyGroupsData = postDocsAcademyGroupsSnapshot.docs

        // postDocumentSnapshot = _.concat(
        //     postDocsJobFamilyData,
        //     postDocsRegionData,
        //     postDocsAcademyData,
        //     postDocsAcademyGroupsData

        const arryPromiseResult = await Promise.all(arryPromisArry)

        arryPromiseResult.forEach(promiseResult => {
            postDocumentSnapshot = _.concat(postDocumentSnapshot, promiseResult.docs)
        })

        postDocumentSnapshot = _.uniqWith(postDocumentSnapshot, _.isEqual)
    } else {
        // const focusValArry = myFocusFilter.split(';')
        const { region, academics, jobFamilies, googleGroups } = myFocusFilter
        let regionPosts: QueryDocumentSnapshot<PostModel>[] = []
        let academicsPosts: QueryDocumentSnapshot<PostModel>[] = []
        let jobFamiliesPosts: QueryDocumentSnapshot<PostModel>[] = []
        let googleGroupsPosts: QueryDocumentSnapshot<PostModel>[] = []

        if (region !== '') {
            regionPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.region,
                myFocusFilter,
                'or'
            )
        }

        if (jobFamilies.length > 0) {
            jobFamiliesPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.jobfamily,
                myFocusFilter,
                'or'
            )
        }

        if (googleGroups.length > 0) {
            googleGroupsPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.googleGroups,
                myFocusFilter,
                'or'
            )
        }

        if (academics.length > 0) {
            academicsPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.academic,
                myFocusFilter,
                'or'
            )
        }

        /* // If no region posts
        if (regionPosts.length === 0 && jobFamilies.length > 0) {
            jobFamiliesPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.jobfamily,
                myFocusFilter,
                'or'
            )
        } else if (jobFamilies.length > 0) {
            // Filter region data for given job family
            jobFamiliesPosts = regionPosts.filter(doc => {
                return doc
                    .data()
                    .postJobFamily.filter(rgJob => jobFamilies.some(job => job === rgJob))
            })
        }

        if (jobFamiliesPosts.length === 0 && academics.length > 0) {
            academicsPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.academic,
                myFocusFilter,
                'or'
            )
        } else if (academics.length > 0) {
            // Filter job famili data for given academics
            jobFamiliesPosts.forEach(jbPost => {
                jbPost.data().postAcademy.forEach(postAcad => {
                    console.log('postAcad => ', postAcad)
                    console.log(
                        'academics => ',
                        academics.map(acad => acad.academyCode)
                    )

                    const filtered = academics.filter(
                        acad => acad.academyCode === postAcad
                    )
                    if (filtered.length > 0) {
                        academicsPosts.push(jbPost)
                    }
                })
            })

            // academicsPosts = jobFamiliesPosts.filter(doc => {
            //     return doc
            //         .data()
            //         .postAcademy.filter(jbAcad =>
            //             academics.some(acad => acad.academyCode === jbAcad)
            //         )
            // })
        } */

        const merged = [
            ...regionPosts,
            ...academicsPosts,
            ...jobFamiliesPosts,
            ...googleGroupsPosts
        ]

        postDocumentSnapshot = [...new Set(merged)]

        postDocumentSnapshot = _.uniqWith(postDocumentSnapshot, _.isEqual)
    }

    if (postDocumentSnapshot) {
        postsData = await postCheckUserReadPosts(userID, postDocumentSnapshot)
    }

    console.log('POST DATA Before my focus filter => ', postsData)

    postsData = sanitizedData(
        userJobFamilies,
        userRegions,
        userAcademies,
        myFocusFilter,
        postsData,
        hasFilter
    )

    console.log('POST DATA after sanitizing => ', postsData)

    let localFilters: PostModel[] = []

    if (filter !== 'expired') {
        localFilters = postNotReadLocalFilters(postsData, false)
    } else {
        localFilters = postExpiredLocalFilters(postsData)
    }

    return localFilters
}

export const getPostsNotReadOrCompound = async (
    filter: string,
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined,
    myFocusFilter: IMyFocusFilter
) => {
    // log(`🍅🍅🍅 getPostsNotRead - ${json(userJobFamilies)}`)
    // eslint-disable-next-line no-console
    // console.log('getPostsNotRead', filter, myFocusFilter, userJobFamilies, userID)
    let queryConst = []
    let postDocumentSnapshot: QueryDocumentSnapshot<PostModel>[] | undefined
    let postsData: PostModel[] = []

    const hasFilter =
        myFocusFilter.academics.length > 0 ||
        myFocusFilter.jobFamilies.length > 0 ||
        myFocusFilter.region !== ''

    if (!hasFilter) {
        if (userJobFamilies && userJobFamilies?.length > 0) {
            const myFocusArray = ['All', 'None', ...userJobFamilies]
            queryConst.push(where('postJobFamily', 'array-contains-any', myFocusArray))
        } else {
            queryConst.push(where('postJobFamily', 'array-contains-any', ['All', 'None']))
        }

        queryConst = [...queryConst, ...fnQueryConst(filter, 'or')]

        const postQueryJobFamily = query(postsCollection, ...queryConst)
        const postDocsJobFamily = await getDocs(postQueryJobFamily)

        queryConst = []

        if (userRegions && userRegions.length > 0) {
            // console.log('userRegions', userRegions)
            const myFocusArray = ['All', 'None', ...userRegions]
            queryConst.push(where('postRegion', 'array-contains-any', myFocusArray))
        } else {
            queryConst.push(where('postRegion', 'array-contains-any', ['All', 'None']))
        }

        queryConst = [...queryConst, ...fnQueryConst(filter, 'or')]

        const postQueryRegion = query(postsCollection, ...queryConst)
        const postDocsRegion = await getDocs(postQueryRegion)

        queryConst = []

        if (userAcademies && userAcademies.length > 0) {
            const myFocusArray = ['All', 'None', ...userAcademies]
            queryConst.push(where('postAcademy', 'array-contains-any', myFocusArray))
        } else {
            queryConst.push(where('postAcademy', 'array-contains-any', ['All', 'None']))
        }

        queryConst = [...queryConst, ...fnQueryConst(filter, 'or')]

        const postQueryAcademy = query(postsCollection, ...queryConst)
        const postDocsAcademy = await getDocs(postQueryAcademy)

        queryConst = []

        if (userAcademyGroups && userAcademyGroups.length > 0) {
            const myFocusArray = ['All', 'None', ...userAcademyGroups]
            queryConst.push(where('academyGroups', 'array-contains-any', myFocusArray))
        } else {
            queryConst.push(where('academyGroups', 'array-contains-any', ['All', 'None']))
        }

        queryConst = [...queryConst, ...fnQueryConst(filter, 'or')]

        const postQueryAcademyGroups = query(postsCollection, ...queryConst)
        const postDocsAcademyGroups = await getDocs(postQueryAcademyGroups)

        const [
            postDocsJobFamilySnapshot,
            postDocsRegionSnapshot,
            postDocsAcademySnapshot,
            postDocsAcademyGroupsSnapshot
        ] = await Promise.all([
            postDocsJobFamily,
            postDocsRegion,
            postDocsAcademy,
            postDocsAcademyGroups
        ])

        const postDocsJobFamilyData = postDocsJobFamilySnapshot.docs
        const postDocsRegionData = postDocsRegionSnapshot.docs
        const postDocsAcademyData = postDocsAcademySnapshot.docs
        const postDocsAcademyGroupsData = postDocsAcademyGroupsSnapshot.docs

        postDocumentSnapshot = _.concat(
            postDocsJobFamilyData,
            postDocsRegionData,
            postDocsAcademyData,
            postDocsAcademyGroupsData
        )
        postDocumentSnapshot = _.uniqWith(postDocumentSnapshot, _.isEqual)
    } else {
        // const focusValArry = myFocusFilter.split(';')
        const { region, academics, jobFamilies, googleGroups } = myFocusFilter
        let regionPosts: QueryDocumentSnapshot<PostModel>[] = []
        let academicsPosts: QueryDocumentSnapshot<PostModel>[] = []
        let jobFamiliesPosts: QueryDocumentSnapshot<PostModel>[] = []
        let googleGroupsPosts: QueryDocumentSnapshot<PostModel>[] = []

        if (googleGroups.length > 0) {
            googleGroupsPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.googleGroups,
                myFocusFilter,
                'or'
            )
        }
        if (region !== '') {
            regionPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.region,
                myFocusFilter,
                'or'
            )
        }

        if (academics.length > 0) {
            academicsPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.academic,
                myFocusFilter,
                'or'
            )
        }

        if (jobFamilies.length > 0) {
            jobFamiliesPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.jobfamily,
                myFocusFilter,
                'or'
            )
        }

        const merged = [
            ...regionPosts,
            ...academicsPosts,
            ...jobFamiliesPosts,
            ...googleGroupsPosts
        ]

        postDocumentSnapshot = [...new Set(merged)]

        // postDocumentSnapshot = await postReadExactRestrictionFilter(myFocusFilter, 'or')
    }

    if (postDocumentSnapshot) {
        postsData = await postCheckUserReadPosts(userID, postDocumentSnapshot)
    }

    let localFilters: PostModel[] = []

    if (filter !== 'expired') {
        localFilters = postNotReadLocalFilters(postsData, false)
    } else {
        localFilters = postExpiredLocalFilters(postsData)
    }

    return localFilters
}

export const getPostsNotReadAndCompound = async (
    filter: string,
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined,
    myFocusFilter: IMyFocusFilter
) => {
    // log(`🍅🍅🍅 getPostsNotRead - ${json(userJobFamilies)}`)
    // eslint-disable-next-line no-console
    // console.log('getPostsNotRead', filter, myFocusFilter, userJobFamilies, userID)
    let queryConst = []
    let postDocumentSnapshot: QueryDocumentSnapshot<PostModel>[] | undefined
    let tmpDocumentSnapshot: QueryDocumentSnapshot<PostModel>[] = []
    let postsData: PostModel[] = []

    const hasFilter =
        myFocusFilter.academics.length > 0 ||
        myFocusFilter.jobFamilies.length > 0 ||
        myFocusFilter.region !== ''

    if (!hasFilter) {
        // Top most filter is academic
        if (userAcademies && userAcademies.length > 0) {
            const myFocusArray = ['All', ...userAcademies]
            queryConst.push(where('postAcademy', 'array-contains-any', myFocusArray))
        }
        queryConst = [...queryConst, ...fnQueryConst(filter, 'and')]
        const postQueryAcademy = query(postsCollection, ...queryConst)
        const postDocsAcademy = await getDocs(postQueryAcademy)

        // if (userAcademies && userAcademies.length > 0) {
        //     filterArry = ['All', ...userAcademies]
        //     postDocumentSnapshot = [...tmpDocumentSnapshot]
        //     tmpDocumentSnapshot = []
        //     postDocumentSnapshot.forEach(post => {
        //         const { intersection } = venn(
        //             post.data().postAcademy,
        //             filterArry,
        //             l => l,
        //             r => r
        //         )

        //         if (intersection.length > 0) {
        //             tmpDocumentSnapshot.push(post)
        //         }
        //     })
        // }

        // If top most Region for a User is available fetch all data of that region
        /* if (userRegions && userRegions.length > 0) {
            const myFocusArray = ['All', ...userRegions]
            queryConst.push(where('postRegion', 'array-contains-any', myFocusArray))
        } else {
            queryConst.push(where('postRegion', 'array-contains', ['All']))
        }

        // if (userJobFamilies && userJobFamilies?.length > 0) {
        //     const myFocusArray = ['All', ...userJobFamilies]
        //     queryConst.push(where('postJobFamily', 'array-contains-any', myFocusArray))
        // } else {
        //     queryConst.push(where('postJobFamily', 'array-contains', ['All']))
        // }

        queryConst = [...queryConst, ...fnQueryConst(filter, 'and')]

        // const postQueryJobFamily = query(postsCollection, ...queryConst)
        // const postDocsJobFamily = await getDocs(postQueryJobFamily)

        const postQueryRegion = query(postsCollection, ...queryConst)
        const postDocsRegion = await getDocs(postQueryRegion) */

        let filterArry: string[] = []

        if (userJobFamilies && userJobFamilies.length > 0) {
            // console.log('userRegions', userRegions)
            filterArry = ['All', ...userJobFamilies]
            postDocsAcademy.docs.forEach(postDoc => {
                const { intersection } = venn(
                    postDoc.data().postJobFamily,
                    filterArry,
                    l => l,
                    r => r
                )

                if (intersection.length > 0) {
                    tmpDocumentSnapshot.push(postDoc)
                }
            })
        } /* else {
            filterArry = ['All']
        } */

        /* if (userAcademies && userAcademies.length > 0) {
            filterArry = ['All', ...userAcademies]
            postDocumentSnapshot = [...tmpDocumentSnapshot]
            tmpDocumentSnapshot = []
            postDocumentSnapshot.forEach(post => {
                const { intersection } = venn(
                    post.data().postAcademy,
                    filterArry,
                    l => l,
                    r => r
                )

                if (intersection.length > 0) {
                    tmpDocumentSnapshot.push(post)
                }
            })
        }  else {
            filterArry = ['All']
        } */

        /* if (userAcademyGroups && userAcademyGroups.length > 0) {
            filterArry = ['All', ...userAcademyGroups]
        } else {
            filterArry = ['All']
        }

        postDocumentSnapshot = [...tmpDocumentSnapshot]
        tmpDocumentSnapshot = []
        postDocumentSnapshot.forEach(post => {
            const { intersection } = venn(
                post.data().academyGroups,
                filterArry,
                l => l,
                r => r
            )

            if (intersection.length > 0) {
                tmpDocumentSnapshot.push(post)
            }
        }) */

        postDocumentSnapshot = [...tmpDocumentSnapshot]
        tmpDocumentSnapshot = []
    } else {
        // const focusValArry = myFocusFilter.split(';')
        const { region, academics, jobFamilies, googleGroups } = myFocusFilter
        // let regionPosts: QueryDocumentSnapshot<PostModel>[] = []
        let academicsPosts: QueryDocumentSnapshot<PostModel>[] = []
        let jobFamiliesPosts: QueryDocumentSnapshot<PostModel>[] = []
        let googleGroupsPosts: QueryDocumentSnapshot<PostModel>[] = []

        // if (region !== '') {
        //     regionPosts = await postReadExactRestrictionFilter(
        //         FilterTypeOptions.region,
        //         myFocusFilter,
        //         'and'
        //     )
        // }
        if (googleGroups.length > 0) {
            googleGroupsPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.googleGroups,
                myFocusFilter,
                'or'
            )
        }

        if (academics.length > 0) {
            academicsPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.academic,
                myFocusFilter,
                'and'
            )
        }

        if (jobFamilies.length > 0) {
            jobFamiliesPosts = await postReadExactRestrictionFilter(
                FilterTypeOptions.jobfamily,
                myFocusFilter,
                'and'
            )
        }

        const merged = [...academicsPosts, ...jobFamiliesPosts, ...googleGroupsPosts]

        postDocumentSnapshot = [...new Set(merged)]

        // postDocumentSnapshot = await postReadExactRestrictionFilter(myFocusFilter, 'and')
    }

    if (postDocumentSnapshot) {
        postsData = await postCheckUserReadPosts(userID, postDocumentSnapshot)
    }

    postsData = sanitizedData(
        userJobFamilies,
        userRegions,
        userAcademies,
        myFocusFilter,
        postsData,
        hasFilter
    )

    let localFilters: PostModel[] = []

    if (filter !== 'expired') {
        localFilters = postNotReadLocalFilters(postsData, false)
    } else {
        localFilters = postExpiredLocalFilters(postsData)
    }

    return localFilters
}

export const getPostsPopupAnnouncementAll = async (
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined,
    myFocusFilter: IMyFocusFilter
) => {
    // log(`🍅🍅🍅 getPostsNotRead - ${json(userJobFamilies)}`)
    // eslint-disable-next-line no-console
    // console.log('getPostsPopupAnnouncementAll', userJobFamilies, userID)

    let postsData: PostModel[] = []

    postsData = await getPostsPopupAnnouncement(
        userID,
        userJobFamilies,
        userRegions,
        userAcademies,
        userAcademyGroups,
        false,
        myFocusFilter
    )

    // console.log('getPostsPopupAnnouncement', postsData)

    return postsData
}

export const checkPostsWebNotificationPermission = (
    newPost: PostModel,
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined
) => {
    // eslint-disable-next-line no-console
    console.log('getPostsNotificationPermission', newPost.title, newPost.postType)

    // if (newPost.postType !== 'popupannouncement') {
    //     return false
    // }

    // if (newPost.queryType === 'or') {
    //     if (newPost.postJobFamily.includes('All')) {
    //         return true
    //     }

    //     if (userJobFamilies) {
    //         const { intersection } = venn(
    //             newPost.postJobFamily,
    //             userJobFamilies,
    //             l => l,
    //             r => r
    //         )

    //         // console.log('getPostsNotificationPermission', leftOnly, intersection, rightOnly)
    //         if (intersection.length > 0) {
    //             return true
    //         }
    //     }

    //     if (newPost.postRegion.includes('All')) {
    //         return true
    //     }

    //     if (userRegions) {
    //         const { intersection } = venn(
    //             newPost.postRegion,
    //             userRegions,
    //             l => l,
    //             r => r
    //         )

    //         // console.log('getPostsNotificationPermission', intersection)
    //         if (intersection.length > 0) {
    //             return true
    //         }
    //     }

    //     if (newPost.postAcademy.includes('All')) {
    //         return true
    //     }

    //     if (userAcademies) {
    //         const { intersection } = venn(
    //             newPost.postAcademy,
    //             userAcademies,
    //             l => l,
    //             r => r
    //         )

    //         // console.log('getPostsNotificationPermission', leftOnly, intersection, rightOnly)
    //         if (intersection.length > 0) {
    //             return true
    //         }
    //     }

    // if (newPost.academyGroups.includes('All')) {
    //     return true
    // }

    // if (userAcademyGroups) {
    //     const { intersection } = venn(
    //         newPost.academyGroups,
    //         userAcademyGroups,
    //         l => l,
    //         r => r
    //     )

    //     // console.log('getPostsNotificationPermission', leftOnly, intersection, rightOnly)
    //     if (intersection.length > 0) {
    //         return true
    //     }
    // }
    // } else {
    // let filterArry: string[] = []

    // if (userJobFamilies && userJobFamilies.length > 0) {
    //     // console.log('userRegions', userRegions)
    //     filterArry = [...userJobFamilies]
    // } else {
    //     filterArry = ['All']
    // }

    // if (userJobFamilies) {
    //     const { intersection } = venn(
    //         newPost.postJobFamily,
    //         filterArry,
    //         l => l,
    //         r => r
    //     )

    //     // console.log('getPostsNotificationPermission', leftOnly, intersection, rightOnly)
    //     if (intersection.length === 0) {
    //         return false
    //     }
    // }

    // if (userRegions && userRegions.length > 0) {
    //     // console.log('userRegions', userRegions)
    //     filterArry = [...userRegions]
    // } else {
    //     filterArry = ['All']
    // }

    // if (userRegions) {
    //     const { intersection } = venn(
    //         newPost.postRegion,
    //         filterArry,
    //         l => l,
    //         r => r
    //     )

    //     // console.log('getPostsNotificationPermission', intersection)
    //     if (intersection.length === 0) {
    //         return false
    //     }
    // }

    // if (userAcademies && userAcademies.length > 0) {
    //     // console.log('userRegions', userRegions)
    //     filterArry = [...userAcademies]
    // } else {
    //     filterArry = ['All']
    // }

    // if (userAcademies) {
    //     const { intersection } = venn(
    //         newPost.postAcademy,
    //         filterArry,
    //         l => l,
    //         r => r
    //     )

    //     // console.log('getPostsNotificationPermission', leftOnly, intersection, rightOnly)
    //     if (intersection.length === 0) {
    //         return true
    //     }
    // }

    /* if (userAcademyGroups && userAcademyGroups.length > 0) {
            // console.log('userRegions', userRegions)
            filterArry = ['All', ...userAcademyGroups]
        } else {
            filterArry = ['All']
        }

        if (userAcademyGroups) {
            const { intersection } = venn(
                newPost.academyGroups,
                filterArry,
                l => l,
                r => r
            )

            // console.log('getPostsNotificationPermission', leftOnly, intersection, rightOnly)
            if (intersection.length === 0) {
                return false
            }
        } */
    // }

    // New Logic

    let validated = false
    if (userRegions && userRegions.length > 0) {
        if (newPost.postVisibility === 'private') {
            let count = 0
            newPost.postRegion.forEach(regionA => {
                if (userRegions.includes(regionA)) {
                    count += 1
                }

                if (count > 0) {
                    validated = true
                } else {
                    validated = false
                }
            })
        } else {
            validated = true
        }
    }

    if (userJobFamilies && userJobFamilies.length > 0 && validated) {
        const jobs = [...userJobFamilies]

        if (newPost.postVisibility === 'private') {
            let count = 0
            newPost.postJobFamily.forEach(jobFa => {
                if (jobs.includes(jobFa)) {
                    count += 1
                }
                if (count > 0) {
                    validated = true
                } else {
                    validated = false
                }
            })
        } else {
            validated = true
        }
    }

    if (userAcademies && userAcademies.length > 0 && validated) {
        const academicCodes = [...userAcademies]

        if (newPost.postVisibility === 'private') {
            let count = 0
            newPost.postAcademy.forEach(acadA => {
                if (academicCodes.includes(acadA)) {
                    count += 1
                }
                if (count > 0) {
                    validated = true
                } else {
                    validated = false
                }
            })
        } else {
            validated = true
        }
    }

    // console.log('VALIDATED => ', validated)

    return validated
}

export const getPostsPopupAnnouncementNotRead = async (
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined
) => {
    // log(`🍅🍅🍅 getPostsNotRead - ${json(userJobFamilies)}`)
    // eslint-disable-next-line no-console
    console.log('getPostsPopupAnnouncementNotRead', userJobFamilies, userID)

    let postsData: PostModel[] = []

    postsData = await getPostsPopupAnnouncement(
        userID,
        userJobFamilies,
        userRegions,
        userAcademies,
        userAcademyGroups,
        true
    )

    // console.log('getPostsPopupAnnouncement', postsData.length)

    return postsData
}

export const getPostsPopupAnnouncement = async (
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined,
    filterPostsPopupAnnouncement: boolean,
    myFocusFilter?: IMyFocusFilter
) => {
    const postOrQuery = getPostsPopupAnnouncementNewOrCompound(
        userID,
        userJobFamilies,
        userRegions,
        userAcademies,
        userAcademyGroups,
        filterPostsPopupAnnouncement,
        myFocusFilter!
    )

    const postAndQuery = getPostsPopupAnnouncementAndCompound(
        userID,
        userJobFamilies,
        userRegions,
        userAcademies,
        userAcademyGroups,
        filterPostsPopupAnnouncement,
        myFocusFilter!
    )

    const [postOrQueryResult, postAndQueryResult] = await Promise.all([
        postOrQuery,
        postAndQuery
    ])

    let postAllQueryResult = _.concat(postOrQueryResult, postAndQueryResult)
    postAllQueryResult = _.uniqWith(postAllQueryResult, _.isEqual)

    // orderby desc createdAt
    const compareCreateAt = (a: PostModel, b: PostModel) => {
        if (a.createdAt && a.createdAt !== null && b.createdAt && b.createdAt !== null) {
            if (
                (a.createdAt as Timestamp).toMillis() >
                (b.createdAt as Timestamp).toMillis()
            ) {
                return -1
            }
            if (
                (a.createdAt as Timestamp).toMillis() <
                (b.createdAt as Timestamp).toMillis()
            ) {
                return 1
            }
        }

        return 0
    }

    // orderby desc score
    const compareScore = (a: PostModel, b: PostModel) => {
        if (a.score && a.score !== null && b.score && b.score !== null) {
            if ((a.score as number) > (b.score as number)) {
                return -1
            }
            if ((a.score as number) < (b.score as number)) {
                return 1
            }
        }
        return 0
    }

    postAllQueryResult = postAllQueryResult.sort(compareCreateAt)

    postAllQueryResult = postAllQueryResult.sort(compareScore)

    return postAllQueryResult
}

const sanitizedData = (
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    myFocusFilter: IMyFocusFilter,
    postsData: PostModel[],
    hasFilter: boolean
) => {
    // let postRegion = userRegions ?? []
    let postAcads = userAcademies ?? []
    let postJobFamilies = userJobFamilies ?? []
    let postGoogleGroups: string[] = []
    if (hasFilter) {
        // Add more check on data
        const { region, academics, jobFamilies, googleGroups } = myFocusFilter

        postJobFamilies = jobFamilies
        postGoogleGroups = googleGroups
        // postRegion = [region]
        postAcads = academics.map(acad => acad.academyCode)
    }
    let newPostsData: PostModel[] = postsData
    /* if (postRegion.length > 0) {
        const filtered: PostModel[] = []
        postsData.forEach(post => {
            if (post.postVisibility === 'private') {
                post.postRegion.forEach(regionA => {
                    if (postRegion.includes(regionA)) {
                        filtered.push(post)
                    }
                })
            } else {
                filtered.push(post)
            }
        })
        newPostsData = filtered
    } else {
        newPostsData = postsData
    } */

    if (postAcads.length > 0) {
        const filtered: PostModel[] = []
        const academicCodes = [...postAcads, 'All']
        newPostsData.forEach(post => {
            if (post.postVisibility === 'private') {
                post.postAcademy.forEach(acadA => {
                    if (academicCodes.includes(acadA)) {
                        filtered.push(post)
                    }
                })
            } else {
                filtered.push(post)
            }
        })
        newPostsData = filtered
    } else {
        // If no academy, there should not be any data to be filtered
        newPostsData = []
    }

    if (postJobFamilies.length > 0) {
        const jobs = [...postJobFamilies, 'All']
        const filtered: PostModel[] = []
        newPostsData.forEach(post => {
            if (post.postVisibility === 'private') {
                post.postJobFamily.forEach(jobFa => {
                    if (jobs.includes(jobFa)) {
                        filtered.push(post)
                    }
                })
            } else {
                filtered.push(post)
            }
        })
        newPostsData = filtered
    }

    if (postGoogleGroups.length > 0) {
        const groups = [...postGoogleGroups]
        const filtered: PostModel[] = []
        newPostsData.forEach(post => {
            if (post.postVisibility === 'private' && post.selectedGoogleGroupIds) {
                post.selectedGoogleGroupIds.forEach(id => {
                    if (groups.includes(id)) {
                        filtered.push(post)
                    }
                })
            } else {
                filtered.push(post)
            }
        })
        newPostsData = filtered
    }

    postsData = [...newPostsData]

    return postsData
}

export const getPostsPopupAnnouncementNewOrCompound = async (
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined,
    filterPostsPopupAnnouncement: boolean,
    myFocusFilter: IMyFocusFilter
) => {
    // log(`🍅🍅🍅 getPostsNotRead - ${json(userJobFamilies)}`)
    // eslint-disable-next-line no-console
    // console.log('getPostsPopupAnnouncementOrCompound', userJobFamilies, userID)

    const hasFilter =
        myFocusFilter &&
        (myFocusFilter.academics.length > 0 ||
            myFocusFilter.jobFamilies.length > 0 ||
            myFocusFilter.region !== '')

    let postDocumentSnapshot: QueryDocumentSnapshot<PostModel>[] = []
    let postsData: PostModel[] = []

    const arryPromisArry = []

    let queryConst = []
    if (!hasFilter) {
        if (userJobFamilies && userJobFamilies?.length > 0) {
            const myFocusArray = ['All', ...userJobFamilies]

            queryConst.push(where('postType', '==', 'popupannouncement'))
            queryConst.push(where('queryType', '==', 'or'))
            queryConst.push(where('postJobFamily', 'array-contains-any', myFocusArray))
            queryConst.push(orderBy('createdAt', 'desc'))
            queryConst.push(limit(100))

            const postQueryJobFamily = query(postsCollection, ...queryConst)
            const postDocsJobFamily = getDocs(postQueryJobFamily)

            arryPromisArry.push(postDocsJobFamily)
        }

        queryConst = []

        if (userRegions && userRegions.length > 0) {
            // console.log('userRegions', userRegions)
            const myFocusArray = ['All', ...userRegions]
            queryConst.push(where('postType', '==', 'popupannouncement'))
            queryConst.push(orderBy('createdAt', 'desc'))
            queryConst.push(where('postRegion', 'array-contains-any', myFocusArray))

            const postQueryRegion = query(postsCollection, ...queryConst)
            const postDocsRegion = getDocs(postQueryRegion)

            arryPromisArry.push(postDocsRegion)
        }

        queryConst = []

        if (userAcademies && userAcademies.length > 0) {
            const myFocusArray = ['All', ...userAcademies]
            queryConst.push(where('postType', '==', 'popupannouncement'))
            queryConst.push(where('postAcademy', 'array-contains-any', myFocusArray))
            queryConst.push(orderBy('createdAt', 'desc'))

            const postQueryAcademy = query(postsCollection, ...queryConst)
            const postDocsAcademy = getDocs(postQueryAcademy)

            arryPromisArry.push(postDocsAcademy)
        }
    } else {
        const { region, academics, jobFamilies } = myFocusFilter

        if (jobFamilies && jobFamilies?.length > 0) {
            const myFocusArray = [...jobFamilies] // ['All', ...userJobFamilies]

            queryConst.push(where('postType', '==', 'popupannouncement'))
            queryConst.push(where('queryType', '==', 'or'))
            queryConst.push(where('postJobFamily', 'array-contains-any', myFocusArray))
            queryConst.push(orderBy('createdAt', 'desc'))
            queryConst.push(limit(100))

            const postQueryJobFamily = query(postsCollection, ...queryConst)
            const postDocsJobFamily = getDocs(postQueryJobFamily)

            arryPromisArry.push(postDocsJobFamily)
        }

        queryConst = []

        if (region && region.length > 0) {
            // console.log('userRegions', userRegions)
            const myFocusArray = [...region]
            queryConst.push(where('postType', '==', 'popupannouncement'))
            queryConst.push(orderBy('createdAt', 'desc'))
            queryConst.push(where('postRegion', 'array-contains-any', myFocusArray))

            const postQueryRegion = query(postsCollection, ...queryConst)
            const postDocsRegion = getDocs(postQueryRegion)

            arryPromisArry.push(postDocsRegion)
        }

        queryConst = []

        if (academics && academics.length > 0) {
            const myFocusArray = [...academics] // ['All', ...userAcademies]
            queryConst.push(where('postType', '==', 'popupannouncement'))
            queryConst.push(where('postAcademy', 'array-contains-any', myFocusArray))
            queryConst.push(orderBy('createdAt', 'desc'))

            const postQueryAcademy = query(postsCollection, ...queryConst)
            const postDocsAcademy = getDocs(postQueryAcademy)

            arryPromisArry.push(postDocsAcademy)
        }
    }

    // queryConst = []

    // queryConst.push(where('postType', '==', 'popupannouncement'))
    // queryConst.push(orderBy('createdAt', 'desc'))

    // // console.log('popupannouncement-userAcademyGroups', userAcademyGroups)

    // if (userAcademyGroups && userAcademyGroups.length > 0) {
    //     const myFocusArray = ['All', 'None', ...userAcademyGroups]
    //     queryConst.push(where('academyGroups', 'array-contains-any', myFocusArray))
    // } else {
    //     queryConst.push(where('academyGroups', 'array-contains-any', ['All', 'None']))
    // }

    // const postQueryAcademyGroups = query(postsCollection, ...queryConst)
    // const postDocsAcademyGroups = getDocs(postQueryAcademyGroups)

    // const [
    //     postDocsJobFamilySnapshot,
    //     postDocsRegionSnapshot,
    //     postDocsAcademySnapshot,
    //     postDocsAcademyGroupsSnapshot
    // ] = await Promise.all([
    //     postDocsJobFamily,
    //     postDocsRegion,
    //     postDocsAcademy,
    //     postDocsAcademyGroups
    // ])

    // const postDocsJobFamilyData = postDocsJobFamilySnapshot.docs
    // const postDocsRegionData = postDocsRegionSnapshot.docs
    // const postDocsAcademyData = postDocsAcademySnapshot.docs
    // const postDocsAcademyGroupsData = postDocsAcademyGroupsSnapshot.docs

    // postDocumentSnapshot = _.concat(
    //     postDocsJobFamilyData,
    //     postDocsRegionData,
    //     postDocsAcademyData,
    //     postDocsAcademyGroupsData
    // )
    // postDocumentSnapshot = _.uniqWith(postDocumentSnapshot, _.isEqual)

    const arryPromiseResult = await Promise.all(arryPromisArry)

    arryPromiseResult.forEach(promiseResult => {
        postDocumentSnapshot = _.concat(postDocumentSnapshot, promiseResult.docs)
    })

    postDocumentSnapshot = _.uniqWith(postDocumentSnapshot, _.isEqual)

    if (postDocumentSnapshot) {
        if (filterPostsPopupAnnouncement) {
            postsData = await postCheckUserReadPostsPopupAnnouncement(
                userID,
                postDocumentSnapshot
            )
        } else {
            postsData = convertpostDocumentSnapshotToPostsData(postDocumentSnapshot)
        }
    }

    postsData = sanitizedData(
        userJobFamilies,
        userRegions,
        userAcademies,
        myFocusFilter,
        postsData,
        hasFilter
    )

    const localFilters = postNotReadLocalFilters(postsData, true)

    return localFilters
}

export const getPostsPopupAnnouncementOrCompound = async (
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined,
    filterPostsPopupAnnouncement: boolean,
    myFocusFilter: IMyFocusFilter
) => {
    // log(`🍅🍅🍅 getPostsNotRead - ${json(userJobFamilies)}`)
    // eslint-disable-next-line no-console
    // console.log('getPostsPopupAnnouncementOrCompound', userJobFamilies, userID)

    const hasFilter =
        myFocusFilter &&
        (myFocusFilter.academics.length > 0 ||
            myFocusFilter.jobFamilies.length > 0 ||
            myFocusFilter.region !== '')

    let queryConst = []
    let postDocumentSnapshot: QueryDocumentSnapshot<PostModel>[] = []
    let postsData: PostModel[] = []

    queryConst.push(where('postType', '==', 'popupannouncement'))
    queryConst.push(where('queryType', '==', 'or'))
    queryConst.push(orderBy('createdAt', 'desc'))
    queryConst.push(limit(100))

    if (!hasFilter) {
        if (userJobFamilies && userJobFamilies?.length > 0) {
            const myFocusArray = ['All', ...userJobFamilies]
            queryConst.push(where('postJobFamily', 'array-contains-any', myFocusArray))
        } else {
            queryConst.push(where('postJobFamily', 'array-contains', 'All'))
        }

        const postQueryJobFamily = query(postsCollection, ...queryConst)
        const postDocsJobFamily = getDocs(postQueryJobFamily)

        queryConst = []

        queryConst.push(where('postType', '==', 'popupannouncement'))
        queryConst.push(orderBy('createdAt', 'desc'))

        /* if (userRegions && userRegions.length > 0) {
            // console.log('userRegions', userRegions)
            const myFocusArray = ['All', ...userRegions]
            queryConst.push(where('postRegion', 'array-contains-any', myFocusArray))
        } else {
            queryConst.push(where('postRegion', 'array-contains', ['All']))
        }

        const postQueryRegion = query(postsCollection, ...queryConst)
        const postDocsRegion = getDocs(postQueryRegion) */

        queryConst = []

        queryConst.push(where('postType', '==', 'popupannouncement'))
        queryConst.push(orderBy('createdAt', 'desc'))

        if (userAcademies && userAcademies.length > 0) {
            const myFocusArray = ['All', ...userAcademies]
            queryConst.push(where('postAcademy', 'array-contains-any', myFocusArray))
        } /* else {
            queryConst.push(where('postAcademy', 'array-contains', ['All']))
        } */

        const postQueryAcademy = query(postsCollection, ...queryConst)
        const postDocsAcademy = getDocs(postQueryAcademy)

        queryConst = []

        queryConst.push(where('postType', '==', 'popupannouncement'))
        queryConst.push(orderBy('createdAt', 'desc'))

        // console.log('popupannouncement-userAcademyGroups', userAcademyGroups)

        if (userAcademyGroups && userAcademyGroups.length > 0) {
            const myFocusArray = ['All', ...userAcademyGroups]
            queryConst.push(where('academyGroups', 'array-contains-any', myFocusArray))
        } /* else {
            queryConst.push(where('academyGroups', 'array-contains', ['All']))
        } */

        const postQueryAcademyGroups = query(postsCollection, ...queryConst)
        const postDocsAcademyGroups = getDocs(postQueryAcademyGroups)

        /* const [
            postDocsJobFamilySnapshot,
            postDocsRegionSnapshot,
            postDocsAcademySnapshot,
            postDocsAcademyGroupsSnapshot
        ] = await Promise.all([
            postDocsJobFamily,
            postDocsRegion,
            postDocsAcademy,
            postDocsAcademyGroups
        ])

        const postDocsJobFamilyData = postDocsJobFamilySnapshot.docs
        const postDocsRegionData = postDocsRegionSnapshot.docs
        const postDocsAcademyData = postDocsAcademySnapshot.docs
        const postDocsAcademyGroupsData = postDocsAcademyGroupsSnapshot.docs

        postDocumentSnapshot = _.concat(
            postDocsJobFamilyData,
            postDocsRegionData,
            postDocsAcademyData,
            postDocsAcademyGroupsData
        ) */

        const [
            postDocsJobFamilySnapshot,
            postDocsAcademySnapshot,
            postDocsAcademyGroupsSnapshot
        ] = await Promise.all([postDocsJobFamily, postDocsAcademy, postDocsAcademyGroups])

        const postDocsJobFamilyData = postDocsJobFamilySnapshot.docs
        const postDocsAcademyData = postDocsAcademySnapshot.docs
        const postDocsAcademyGroupsData = postDocsAcademyGroupsSnapshot.docs

        postDocumentSnapshot = _.concat(
            postDocsJobFamilyData,
            postDocsAcademyData,
            postDocsAcademyGroupsData
        )
        postDocumentSnapshot = _.uniqWith(postDocumentSnapshot, _.isEqual)
    } else {
        const arryPromisArry = []
        const { region, academics, jobFamilies } = myFocusFilter

        if (jobFamilies && jobFamilies?.length > 0) {
            const myFocusArray = [...jobFamilies] // ['All', ...userJobFamilies]

            queryConst.push(where('postType', '==', 'popupannouncement'))
            queryConst.push(where('queryType', '==', 'or'))
            queryConst.push(where('postJobFamily', 'array-contains-any', myFocusArray))
            queryConst.push(orderBy('createdAt', 'desc'))
            queryConst.push(limit(100))

            const postQueryJobFamily = query(postsCollection, ...queryConst)
            const postDocsJobFamily = getDocs(postQueryJobFamily)

            arryPromisArry.push(postDocsJobFamily)
        }

        queryConst = []

        /* if (region && region.length > 0) {
            // console.log('userRegions', userRegions)
            const myFocusArray = [...region]
            queryConst.push(where('postType', '==', 'popupannouncement'))
            queryConst.push(orderBy('createdAt', 'desc'))
            queryConst.push(where('postRegion', 'array-contains-any', myFocusArray))

            const postQueryRegion = query(postsCollection, ...queryConst)
            const postDocsRegion = getDocs(postQueryRegion)

            arryPromisArry.push(postDocsRegion)
        } */

        queryConst = []

        if (academics && academics.length > 0) {
            const myFocusArray = [...academics] // ['All', ...userAcademies]
            queryConst.push(where('postType', '==', 'popupannouncement'))
            queryConst.push(where('postAcademy', 'array-contains-any', myFocusArray))
            queryConst.push(orderBy('createdAt', 'desc'))

            const postQueryAcademy = query(postsCollection, ...queryConst)
            const postDocsAcademy = getDocs(postQueryAcademy)

            arryPromisArry.push(postDocsAcademy)
        }

        const arryPromiseResult = await Promise.all(arryPromisArry)

        arryPromiseResult.forEach(promiseResult => {
            postDocumentSnapshot = _.concat(postDocumentSnapshot, promiseResult.docs)
        })

        postDocumentSnapshot = _.uniqWith(postDocumentSnapshot, _.isEqual)
    }

    if (postDocumentSnapshot) {
        if (filterPostsPopupAnnouncement) {
            postsData = await postCheckUserReadPostsPopupAnnouncement(
                userID,
                postDocumentSnapshot
            )
        } else {
            postsData = convertpostDocumentSnapshotToPostsData(postDocumentSnapshot)
        }
    }

    postsData = sanitizedData(
        userJobFamilies,
        userRegions,
        userAcademies,
        myFocusFilter,
        postsData,
        hasFilter
    )

    const localFilters = postNotReadLocalFilters(postsData, true)

    return localFilters
}

export const getPostsPopupAnnouncementAndCompound = async (
    userID: string,
    userJobFamilies: string[] | undefined,
    userRegions: string[] | undefined,
    userAcademies: string[] | undefined,
    userAcademyGroups: string[] | undefined,
    filterPostsPopupAnnouncement: boolean,
    myFocusFilter: IMyFocusFilter
) => {
    // log(`🍅🍅🍅 getPostsNotRead - ${json(userJobFamilies)}`)
    // eslint-disable-next-line no-console
    // console.log('getPostsNotRead', filter, myFocusFilter, userJobFamilies, userID)
    const queryConst = []
    let postDocumentSnapshot: QueryDocumentSnapshot<PostModel>[] | undefined
    let tmpDocumentSnapshot: QueryDocumentSnapshot<PostModel>[] = []
    let postsData: PostModel[] = []

    queryConst.push(where('postType', '==', 'popupannouncement'))
    queryConst.push(where('queryType', '==', 'and'))
    queryConst.push(orderBy('createdAt', 'desc'))
    queryConst.push(limit(100))

    const hasFilter =
        myFocusFilter &&
        (myFocusFilter.academics.length > 0 ||
            myFocusFilter.jobFamilies.length > 0 ||
            myFocusFilter.region !== '')

    if (!hasFilter) {
        if (userJobFamilies && userJobFamilies?.length > 0) {
            const myFocusArray = ['All', ...userJobFamilies]
            queryConst.push(where('postJobFamily', 'array-contains-any', myFocusArray))
        } else {
            queryConst.push(where('postJobFamily', 'array-contains', ['All']))
        }
    } else {
        const { region, academics, jobFamilies } = myFocusFilter
        if (jobFamilies && jobFamilies?.length > 0) {
            const myFocusArray = [...jobFamilies]
            queryConst.push(where('postJobFamily', 'array-contains-any', myFocusArray))
        }
        userRegions = [region]
        userAcademies = academics.map(acad => acad.academyCode)
    }

    const postQueryJobFamily = query(postsCollection, ...queryConst)
    const postDocsJobFamily = await getDocs(postQueryJobFamily)

    let filterArry: string[] = []

    if (!hasFilter) {
        if (userRegions && userRegions.length > 0) {
            // console.log('userRegions', userRegions)
            filterArry = ['All', ...userRegions]
        } else {
            filterArry = ['All']
        }
    } else if (userRegions && userRegions.length > 0) {
        // console.log('userRegions', userRegions)
        filterArry = [...userRegions]
    }

    postDocsJobFamily.docs.forEach(postDoc => {
        const { intersection } = venn(
            postDoc.data().postRegion,
            filterArry,
            l => l,
            r => r
        )

        if (intersection.length > 0) {
            tmpDocumentSnapshot.push(postDoc)
        }
    })

    if (!hasFilter) {
        if (userAcademies && userAcademies.length > 0) {
            filterArry = ['All', ...userAcademies]
        } else {
            filterArry = ['All']
        }
    } else if (userAcademies && userAcademies.length > 0) {
        filterArry = [...userAcademies]
    }

    postDocumentSnapshot = [...tmpDocumentSnapshot]
    tmpDocumentSnapshot = []
    postDocumentSnapshot.forEach(post => {
        const { intersection } = venn(
            post.data().postAcademy,
            filterArry,
            l => l,
            r => r
        )

        if (intersection.length > 0) {
            tmpDocumentSnapshot.push(post)
        }
    })

    if (!hasFilter) {
        if (userAcademyGroups && userAcademyGroups.length > 0) {
            filterArry = ['All', ...userAcademyGroups]
        } else {
            filterArry = ['All']
        }
    }

    postDocumentSnapshot = [...tmpDocumentSnapshot]
    tmpDocumentSnapshot = []
    postDocumentSnapshot.forEach(post => {
        const { intersection } = venn(
            post.data().academyGroups,
            filterArry,
            l => l,
            r => r
        )

        if (intersection.length > 0) {
            tmpDocumentSnapshot.push(post)
        }
    })

    postDocumentSnapshot = [...tmpDocumentSnapshot]
    tmpDocumentSnapshot = []

    if (postDocumentSnapshot) {
        if (filterPostsPopupAnnouncement) {
            postsData = await postCheckUserReadPostsPopupAnnouncement(
                userID,
                postDocumentSnapshot
            )
        } else {
            postsData = convertpostDocumentSnapshotToPostsData(postDocumentSnapshot)
        }
    }

    postsData = sanitizedData(
        userJobFamilies,
        userRegions,
        userAcademies,
        myFocusFilter,
        postsData,
        hasFilter
    )

    const localFilters = postNotReadLocalFilters(postsData, true)

    return localFilters
}

export const addPost = async (props: PostModel) => {
    // const postRef = doc(postsCollection, 'post_12345')
    // await setDoc(postRef, {
    //   age: 30,
    //   firstName: 'Jamie',
    //   lastName: 'Curnow'
    // })

    // {
    //     title: props.title,
    //     link: props.link,
    //     thumbnail: props.thumbnail,
    //     content: props.content,
    //     headerType: props.headerType,
    //     postType: props.postType,
    //     postVisibility: props.postVisibility,
    //     tileSize: props.tileSize
    // }

    const docRef = await addDoc(postsCollection, props)

    return docRef.id
}

export const setPost = async (props: PostModel, id: string) => {
    const postRef = doc(postsCollection, id)

    setDoc(postRef, props)
}

export const updatePost = async (id: string, props: { [x: string]: any }) => {
    const postRef = doc(postsCollection, id)

    // await setDoc(postRef, {
    //     age: 30,
    //     firstName: 'Jamie',
    //     lastName: 'Curnow'
    //   })

    await updateDoc(postRef, props)
}

export const deletePost = async (id: string) => {
    const postRef = doc(postsCollection, id)
    deleteDoc(postRef)
}

export const deletePostFull = async (id: string) => {
    await runTransaction(db, async transaction => {
        const tile = doc(postsCollection, id)
        const tilesLike = query(collection(db, `tiles/${id}/tileLiked`))
        const tilesRead = query(collection(db, `tiles/${id}/tileRead`))
        const tilesContentClick = query(collection(db, `tiles/${id}/tileContentClicked`))

        const qsTilesLike = await getDocs(tilesLike)
        const qsTilesRead = await getDocs(tilesRead)
        const qsTilesContentClick = await getDocs(tilesContentClick)

        const dsTile = await transaction.get(tile)
        if (dsTile.exists()) {
            qsTilesLike.forEach(x => {
                console.log('qsTilesLike', qsTilesLike)
                transaction.delete(x.ref)
            })

            qsTilesRead.forEach(x => {
                transaction.delete(x.ref)
            })

            qsTilesContentClick.forEach(x => {
                transaction.delete(x.ref)
            })

            transaction.delete(tile)
        }
    })
}
