/* eslint-disable array-callback-return */
import React, { createContext, useCallback, useContext, useState } from 'react'

import { NewsStateType, SetFilterFunction } from 'store/constants/news'

import { WidgetOptions } from 'service/model/Post'
import { UserModel } from 'service/model/User'
import { AuthStateType, DeniedFunction, LoginFunction } from 'store/constants/auth'
import shallow from 'zustand/shallow'
import { Store, useStore } from '../../appData/store'

// =================================================================================
type InitialState = {
    isHeaderFixed: boolean
    news: NewsStateType
    auth: AuthStateType
}

// =================================================================================

const initialState: InitialState = {
    isHeaderFixed: false,
    news: { filter: 'none', setFilter: filter => {} },
    auth: {
        hasPermission: null,
        currentUser: null,
        token: null,
        credentialToken: null,
        role: null,
        isAuthenticated: false,
        isFetching: false,
        error: false,
        denied: false,
        settings: null,
        login: (token, user, credentialToken) => {},
        logout: () => {},
        reset: () => {},
        deniedCallback: () => {}
    }
}

export interface ContextProps {
    state: InitialState
}

// let logoutTimer: NodeJS.Timer

// const calculateRemainingTime: CalculateRemainingTimeFunction = expirationTime => {
//     const currentTime = new Date().getTime()
//     const adjExpirationTime = new Date(expirationTime).getTime()

//     const remainingDuration = adjExpirationTime - currentTime

//     return remainingDuration
// }

// const retrieveStoredToken: RetrieveStoredTokenFunction = () => {
//     // console.log('retrieveStoredToken')
//     const storedToken = localStorage.getItem(localStorageKeys.token)
//     if (storedToken === null) {
//         return null
//     }
//     // eslint-disable-next-line no-console
//     console.log('retrieveStoredToken-getlocalStorage')

//     const storedExpirationDate = localStorage.getItem(localStorageKeys.expirationTime)

//     const remainingTime = calculateRemainingTime(storedExpirationDate ?? '')

//     const credentialToken = localStorage.getItem(localStorageKeys.credentialToken)

//     const currentUser = JSON.parse(
//         localStorage.getItem(localStorageKeys.currentUser) || '{}'
//     ) as UserModel | null

//     // if (remainingTime <= 3600) {
//     //     localStorage.removeItem(localStorageKeys.token)
//     //     localStorage.removeItem(localStorageKeys.expirationTime)
//     //     localStorage.removeItem(localStorageKeys.credentialToken)
//     //     localStorage.removeItem(localStorageKeys.currentUser)
//     //     return null
//     // }

//     // console.log(
//     //     'AppContext-hasPermission',
//     //     currentUser,
//     //     currentUser?.email,
//     //     hasPermission(currentUser?.email)
//     // )

//     return {
//         token: storedToken,
//         duration: remainingTime,
//         credentialToken,
//         currentUser,
//         hasPermission: hasPermission(currentUser?.email)
//     }
// }

const AppContext = createContext<ContextProps>({
    state: initialState
})

type AppProviderProps = {
    children: React.ReactNode
}

export const localStorageKeys = {
    token: 'token',
    expirationTime: 'expirationTime',
    credentialToken: 'credentialToken',
    currentUser: 'user',
    hasPermission: 'hasPermission'
}

type initialTokenProps = {
    token?: string | null
    credentialToken?: string | null
    currentUser?: UserModel | null
    hasPermission?: boolean | null
}

const storeSelector = (store: Store) => ({
    userHasPermission: store.userHasPermission
})

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    // #region Auth State
    // eslint-disable-next-line no-console
    console.log('AppProvider')
    // const tokenData = retrieveStoredToken()
    // console.log(
    //     'AppProvider-localStorage-tokenData-hasPermission',
    //     tokenData?.hasPermission
    // )
    // const initialToken: initialTokenProps = {
    //     token: tokenData?.token,
    //     credentialToken: tokenData?.credentialToken,
    //     currentUser: tokenData?.currentUser,
    //     hasPermission: tokenData?.hasPermission
    // }

    const { userHasPermission } = useStore(storeSelector, shallow)

    const [stateToken, setToken] = useState<initialTokenProps>()
    const [loginDenied, setLoginDenied] = useState(false)
    const userIsLoggedIn = !!stateToken?.token && !!stateToken?.credentialToken

    const hasPermission = useCallback(
        (email: string | undefined) => {
            if (email === undefined) {
                return false
            }
            return userHasPermission('edit posts')

            // return (
            //     email === 'rsparks@academiesenterprisetrust.org' ||
            //     email === 'fvickerstaff@academiesenterprisetrust.org' ||
            //     email === 'jnicell@academiesenterprisetrust.org' ||
            //     email === 'north27_developer@academiesenterprisetrust.org' ||
            //     email.includes('north27.co.uk')
            // )
        },
        [userHasPermission]
    )

    const loginHandler: LoginFunction = useCallback(
        (token, user, credentialToken) => {
            // eslint-disable-next-line no-console
            // console.log(
            //     `** loginHandler: token: '${token}', expirationTime: '${expirationTime}', credentialToken: '${credentialToken}'`
            // )

            const expirationTime = new Date(
                new Date().getTime() + 24 * 60 * 60 * 1000
            ).toISOString()

            setToken({
                token,
                credentialToken,
                currentUser: user,
                hasPermission: hasPermission(user?.email)
            })

            console.log('USER ====> ', user)

            localStorage.setItem(localStorageKeys.token, token)
            localStorage.setItem(localStorageKeys.expirationTime, expirationTime)
            localStorage.setItem(localStorageKeys.credentialToken, credentialToken)
            localStorage.setItem(localStorageKeys.currentUser, JSON.stringify(user))
            localStorage.setItem(
                localStorageKeys.hasPermission,
                hasPermission(user.email) ? 'true' : 'false'
            )

            // const remainingTime = calculateRemainingTime(expirationTime)
            // logoutTimer = setTimeout(logoutHandler, remainingTime)
        },
        [hasPermission]
    )

    const deniedHandler: DeniedFunction = useCallback(() => {
        setLoginDenied(true)
    }, [])

    const logoutHandler = useCallback(() => {
        setToken(undefined)
        // localStorage.removeItem(localStorageKeys.token)
        // localStorage.removeItem(localStorageKeys.expirationTime)
        // localStorage.removeItem(localStorageKeys.credentialToken)
        // localStorage.removeItem(localStorageKeys.currentUser)
        // localStorage.removeItem(localStorageKeys.hasPermission)

        localStorage.clear()
        indexedDB.databases().then(dbs => {
            const promises = dbs.map(db => {
                return new Promise((resolve, reject) => {
                    if (db.name) {
                        const req = indexedDB.deleteDatabase(db.name)
                        req.onsuccess = resolve
                        req.onerror = reject
                        req.onblocked = reject
                    }
                })
            })
            Promise.all(promises).then(console.log).catch(console.error)
        })
        // if (logoutTimer) {
        //     clearTimeout(logoutTimer)
        // }
    }, [])

    const resetHandler = useCallback(() => {
        const storedToken = localStorage.getItem(localStorageKeys.token)
        if (storedToken === null) {
            throw new Error('Not valid login data exist')
        }

        // const storedExpirationDate = localStorage.getItem(localStorageKeys.expirationTime)
        // const remainingTime = calculateRemainingTime(storedExpirationDate ?? '')

        const credentialToken = localStorage.getItem(localStorageKeys.credentialToken)
        const currentUser = JSON.parse(
            localStorage.getItem(localStorageKeys.currentUser) || '{}'
        ) as UserModel | null

        setToken({
            token: storedToken,
            credentialToken,
            currentUser,
            hasPermission: hasPermission(currentUser?.email)
        })
    }, [hasPermission])

    // useEffect(() => {
    //     if (tokenData) {
    //         // eslint-disable-next-line no-console
    //         // console.log(tokenData.duration)
    //         // logoutTimer = setTimeout(logoutHandler, tokenData.duration)
    //     }
    // }, [tokenData, logoutHandler])

    const auth: AuthStateType = {
        ...initialState.auth,
        ...stateToken,
        isAuthenticated: userIsLoggedIn,
        denied: loginDenied,
        login: loginHandler,
        logout: logoutHandler,
        reset: resetHandler,
        deniedCallback: deniedHandler
    }

    initialState.auth = { ...auth }

    // #endregion

    // #region news

    const [stateFilter, setStateFilter] = useState<
        WidgetOptions | 'relevant' | 'read' | 'like' | undefined
    >('relevant')

    const filterHandler: SetFilterFunction = filter => {
        setStateFilter(filter)
    }

    const news: NewsStateType = {
        ...initialState.news,
        filter: stateFilter,
        setFilter: filterHandler
    }

    initialState.news = { ...news }
    // #region

    // const contextValue = useMemo(() => {
    //     return { state: initialState }
    // }, [initialState])

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const contextValue = { state: initialState }

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext<ContextProps>(AppContext)

export default AppContext
