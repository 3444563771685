import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer'
import { CSSObject, styled, Theme } from '@mui/material/styles'

const openedMixin = (theme: Theme): CSSObject => ({
    width: theme.navBarWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 50
})

interface DrawerProps extends MuiDrawerProps {
    drawerWidth: number
    open?: boolean
}

export const Drawer = styled(MuiDrawer, {
    shouldForwardProp: prop => {
        const include = !['drawerWidth'].includes(prop.toString())
        return include
    }
})<DrawerProps>(({ theme, drawerWidth, open }) => ({
    '& .MuiDrawer-paper': {
        background: theme.palette.primary.main,
        overflowX: 'hidden',
        position: 'relative',
        whiteSpace: 'nowrap',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme)
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme)
        })
    }
}))
