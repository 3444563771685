import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { useCallback, useEffect, useState } from 'react'

export const AdHocPermissions = ({
    open,
    onCancel,
    onOk,
    initialKnownEmails,
    messages
}: {
    open: boolean
    onCancel: () => void
    onOk: ({ updatedKnownEmails }: { updatedKnownEmails: string[] }) => Promise<void>
    initialKnownEmails: string[]
    messages: string[]
}) => {
    const [knownEmailText, setKnownEmailText] = useState<string>('')

    useEffect(() => {
        setKnownEmailText(initialKnownEmails.join('\n'))
    }, [initialKnownEmails])

    const localOnCancel = useCallback(() => {
        setKnownEmailText(initialKnownEmails.join('\n'))
        onCancel()
    }, [initialKnownEmails, onCancel])

    const localOnOk = useCallback(() => {
        onOk({ updatedKnownEmails: knownEmailText.split('\n') })
    }, [knownEmailText, onOk])

    const knownEmailsChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value
        setKnownEmailText(text)
    }, [])

    const changed = knownEmailText !== initialKnownEmails.join('\n')
    return (
        <Dialog open={open} disableEscapeKeyDown fullWidth>
            <DialogTitle>{`Ad-hoc Permissions${
                changed ? ' (changed)' : ''
            }`}</DialogTitle>
            <DialogContent
                dividers
                sx={{
                    height: '500px',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <TextField
                    autoFocus
                    // margin='dense'
                    label='Emails for ad-hoc permissions'
                    type='text'
                    fullWidth
                    variant='outlined'
                    multiline
                    value={knownEmailText}
                    onChange={knownEmailsChange}
                    error={messages.length > 0}
                    sx={{
                        'flex': 1,
                        '& .MuiInputBase-root': {
                            flex: 1,
                            flexDirection: 'column'
                        }
                    }}
                />
                {messages.length > 0 && (
                    <div style={{ color: 'red' }}>
                        {messages.map(m => (
                            <div>{m}</div>
                        ))}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={localOnCancel}>
                    {messages.length === 0 ? 'Cancel' : 'Discard changes'}
                </Button>
                <Button onClick={localOnOk}>
                    {messages.length === 0 ? 'Save' : 'Close to review changes'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
